import React, { useState } from 'react';

const useDropdown = () => {
    const [dropdownOpened, setDropDownOpened] = useState(false);
    const anchorRef = React.useRef(null);

    const dropdownToggle = () => {
        setDropDownOpened((prevOpen) => !prevOpen);
    };

    const dropdownClose = (e) => {
        if (anchorRef.current && anchorRef.current.contains(e.target)) {
            return;
        }

        setDropDownOpened(false);
    };
    return { dropdownOpened, dropdownToggle, dropdownClose, anchorRef };
};

export default useDropdown;
