import {
    Button,
    Box,
    Typography,
    Grid,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Radio,
    FormLabel,
    Paper,
    ToggleButtonGroup,
    ToggleButton
} from '@mui/material';
import { makeStyles, useTheme, createStyles, withStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import apple from '../../../../assets/images/icons/application-apple.svg';
import common from '../../../../assets/images/icons/application-common.svg';
import docs from '../../../../assets/images/icons/application-docs.svg';

import { Logo, HeaderTextModal } from '@/components/createComponents';
import i18n from '@/core/i18n';

const { t } = i18n;

const BorderLessToggleButton = withStyles((theme) =>
    createStyles({
        root: {
            border: 'none',
            backgroundColor: 'white',
            '&:not(:first-of-type)': {
                borderLeft: 'none',
                marginLeft: theme.spacing(1),
                borderRadius: theme.shape.borderRadius
            },
            '&:not(:last-of-type)': {
                borderRadius: theme.shape.borderRadius
            }
        }
    })
)(ToggleButton);

const ModalApplication = ({ handleClose, setOpenFormTablePage }) => {
    const theme = useTheme();
    const [value, setValue] = useState(null);
    const navigate = useNavigate();

    const changeValue = (_, v) => {
        if (v !== null) setValue(v);
    };

    const buttons = [
        {
            name: 'FOOD'
        },
        {
            name: 'DOCS'
        },
        {
            name: 'COMMON'
        }
    ];

    const Icon = ({ fill, type }) => {
        if (type === 'FOOD') {
            return (
                <svg width="17" height="15" viewBox="0 0 17 15" fill="white" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.2117 1.01746H13.2116C12.1025 0.838438 10.9943 1.11984 10.0915 1.80965C9.15448 2.5257 7.84538 2.5257 6.90824 1.80965C6.18297 1.25543 5.32512 0.964844 4.44063 0.964844C4.22423 0.964844 4.00622 0.982227 3.78817 1.01742C1.51615 1.38394 -0.0411083 3.59026 0.31682 5.93568C0.967911 10.2022 2.20189 13.1057 3.88544 14.3325C4.6727 14.9061 5.5427 15.1062 6.54539 14.9445C7.15988 14.8454 7.64527 14.5951 8.02941 14.1792C8.15066 14.048 8.3212 13.9733 8.49987 13.9733C8.67855 13.9733 8.84913 14.048 8.97034 14.1792C9.35444 14.5951 9.83987 14.8454 10.4544 14.9445C11.4571 15.1064 12.3271 14.9061 13.1143 14.3325C14.7979 13.1057 16.0318 10.2022 16.6829 5.93568C17.0409 3.5903 15.4836 1.38398 13.2117 1.01746Z"
                        fill={fill}
                    />
                </svg>
            );
        }
        if (type === 'DOCS') {
            return (
                <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.5 2C0.5 1.46957 0.710714 0.960859 1.08579 0.585786C1.46086 0.210714 1.96957 0 2.5 0H7.086C7.61639 0.000113275 8.12501 0.210901 8.5 0.586L11.914 4C12.2891 4.37499 12.4999 4.88361 12.5 5.414V14C12.5 14.5304 12.2893 15.0391 11.9142 15.4142C11.5391 15.7893 11.0304 16 10.5 16H2.5C1.96957 16 1.46086 15.7893 1.08579 15.4142C0.710714 15.0391 0.5 14.5304 0.5 14V2ZM2.5 8C2.5 7.73478 2.60536 7.48043 2.79289 7.29289C2.98043 7.10536 3.23478 7 3.5 7H9.5C9.76522 7 10.0196 7.10536 10.2071 7.29289C10.3946 7.48043 10.5 7.73478 10.5 8C10.5 8.26522 10.3946 8.51957 10.2071 8.70711C10.0196 8.89464 9.76522 9 9.5 9H3.5C3.23478 9 2.98043 8.89464 2.79289 8.70711C2.60536 8.51957 2.5 8.26522 2.5 8ZM3.5 11C3.23478 11 2.98043 11.1054 2.79289 11.2929C2.60536 11.4804 2.5 11.7348 2.5 12C2.5 12.2652 2.60536 12.5196 2.79289 12.7071C2.98043 12.8946 3.23478 13 3.5 13H9.5C9.76522 13 10.0196 12.8946 10.2071 12.7071C10.3946 12.5196 10.5 12.2652 10.5 12C10.5 11.7348 10.3946 11.4804 10.2071 11.2929C10.0196 11.1054 9.76522 11 9.5 11H3.5Z"
                        fill={fill}
                    />
                </svg>
            );
        }
        return (
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.1 4.45224C18.1 4.43321 18.1 4.43321 18.1 4.41419C18.081 4.37613 18.081 4.33808 18.0619 4.30003V4.281C18.0429 4.24295 18.0049 4.20489 17.9858 4.18587L17.9668 4.16684C17.9478 4.14781 17.9097 4.12879 17.8907 4.10976L17.8717 4.09073H17.8526L17.8336 4.07171L9.74728 0.05708C9.59507 -0.0190267 9.4048 -0.0190267 9.23356 0.05708L6.51275 1.40797L14.6371 5.57481L14.6562 5.59384C14.6752 5.59384 14.6752 5.61286 14.6942 5.61286C14.7132 5.63189 14.7132 5.65092 14.7323 5.66995C14.7323 5.68897 14.7323 5.68897 14.7323 5.708V5.72703V10.1412C14.7323 10.2173 14.6942 10.2744 14.6371 10.3125L13.0008 11.1687C12.9057 11.2257 12.7915 11.1877 12.7345 11.0925C12.7154 11.0735 12.7154 11.0355 12.7154 10.9974V6.67836L4.49592 2.43541L4.4769 2.41639L1.18529 4.05268L1.16626 4.07171H1.14723L1.12821 4.09073C1.10918 4.10976 1.07113 4.12879 1.0521 4.14781L1.03307 4.16684C0.995019 4.20489 0.975993 4.24295 0.937939 4.281V4.30003C0.918913 4.33808 0.899886 4.37613 0.899886 4.41419C0.899886 4.43321 0.899886 4.43321 0.899886 4.45224C0.899886 4.49029 0.880859 4.50932 0.880859 4.54737V4.5664V13.4328C0.880859 13.6421 0.995019 13.8514 1.20431 13.9465L9.21454 17.9421C9.3287 17.9992 9.46188 18.0182 9.59507 17.9802L9.63312 17.9612C9.67118 17.9612 9.6902 17.9421 9.72826 17.9231L17.7956 13.9275C17.9858 13.8324 18.119 13.6421 18.119 13.4138V4.5664V4.54737C18.1 4.50932 18.1 4.49029 18.1 4.45224Z"
                    fill={fill}
                />
            </svg>
        );
    };

    return (
        <Box sx={{ p: 1 }}>
            <HeaderTextModal handleClose={handleClose} text={t('modals:modalApplication.creatingNewOrder')} />
            <Typography variant="h5" sx={{ color: theme.palette.grey[500], fontWeight: 100, mt: 3 }}>
                {t('modals:modalApplication.selectTypeOfProduct')}
            </Typography>
            <ToggleButtonGroup
                exclusive
                color="primary"
                value={value}
                onChange={changeValue}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem', border: 'none', gap: 2, mt: 1.5 }}
            >
                {buttons.map((item, i) => (
                    <BorderLessToggleButton
                        value={i}
                        key={i}
                        style={{
                            width: '10rem',
                            borderRadius: '10px',
                            backgroundColor: i === value ? theme.palette.primary[300] : theme.palette.background.paper
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                backgroundColor: i === value ? theme.palette.background.paper : theme.palette.primary[300],
                                width: '2rem',
                                height: '2rem',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 5
                            }}
                        >
                            <Icon fill={i === value ? '#6399D6' : '#fff'} type={item.name} />
                        </Box>
                        <Box sx={{ ml: 1, color: i === value ? 'white' : 'black' }}>{item.name}</Box>
                    </BorderLessToggleButton>
                ))}
            </ToggleButtonGroup>
            <Box sx={{ pt: 5, display: 'flex', justifyContent: 'space-around' }}>
                <Button
                    onClick={() => handleClose()}
                    sx={{ width: '40%', height: '50px', color: theme.palette.success.main, borderRadius: 3 }}
                >
                    {t('buttons:cancel')}
                </Button>
                <Button
                    disabled={!value}
                    onClick={() => navigate('/arm/applications/create')}
                    sx={{ width: '40%', height: '50px', backgroundColor: theme.palette.success.main, color: 'white', borderRadius: 3 }}
                >
                    {t('buttons:create')}
                </Button>
            </Box>
        </Box>
    );
};

export default ModalApplication;
