/* eslint-disable no-underscore-dangle,class-methods-use-this */
import axios from 'axios';

import { NOMINATIM_BASE_URL } from '@/core/utils/constants/constants';

const axiosGeocoder = axios.create({});

class Geocoder {
    /**
     * @param {("2gis"|"osm")} type - Geocoder type.
     */
    constructor(type) {
        this.type = type;
        this._2gisUrl = 'https://catalog.api.2gis.com/3.0/items/geocode?fields=items.point&key=rukiqk4169';
        this._2gisSuggestsUrl = `https://catalog.api.2gis.com/3.0/suggests?key=rukiqk4169&fields=items.name_ex,items.point,items.rubrics,items.org,items.adm_div,items.routes,items.type,items.subtype,items.address,items.search_attributes.personal_priority,items.search_attributes.dgis_source_type,items.search_attributes.dgis_found_by_address,items.segment_id,items.region_id,items.locale,items.group,items.context,search_attributes,items.flags,items.has_exchange,items.ads.options&type=adm_div.region,adm_div.district_area,adm_div.city,adm_div.settlement,adm_div.district,adm_div.living_area,adm_div.division,adm_div.place,street,branch,building,road,attraction,crossroad,route,route_type,station,station.metro,user_queries,attribute,rubric,meta_rubric,org,market.category,market.attribute,market.suggestor_category,special,coordinates,kilometer_road_sign`;
        this._osmUrl = `${NOMINATIM_BASE_URL}/search.php?format=json&addressdetails=1`;
    }

    async _2gisGetCityCoordinates(city) {
        const result = await axiosGeocoder.get(`${this._2gisUrl}&q=${city}`);
        const foundedCity = result?.data?.result?.items?.find((r) => r.subtype === 'city');
        return { latitude: foundedCity?.point?.lat, longitude: foundedCity?.point?.lon };
    }

    async _osmGetCityCoordinates(city) {
        const response = await axiosGeocoder.get(`${this._osmUrl}q=${city}`);
        if (response.data.length) {
            const { lon, lat } = response.data[0];
            return { latitude: +Number(lat).toFixed(8), longitude: +Number(lon).toFixed(8) };
        }
        return null;
    }

    async _osmGeocode(value, city) {
        const response = await axiosGeocoder.get(`${this._osmUrl}&city=${city}&street=${encodeURIComponent(value)}`);
        const addresses = response.data.map((item) => {
            const { lat, lon } = item;
            return { latitude: +Number(lat).toFixed(8), longitude: +Number(lon).toFixed(8), address: item.display_name };
        });
        return addresses;
    }

    async _2gisGeocode(value, city) {
        const response = await axiosGeocoder.get(`${this._2gisSuggestsUrl}&q=${encodeURIComponent(`${city}, ${value}`)}`);
        const results = response.data.result?.items
            ?.filter((r) => !!r.address_name)
            ?.map((r) => ({
                latitude: +Number(r.point.lat).toFixed(8),
                longitude: +Number(r.point.lon).toFixed(8),
                address: r.address_name
            }));
        return results;
    }

    async geocode(address, city) {
        const results = await this[`_${this.type}Geocode`](address, city);
        return results;
    }

    async getCoordinatesByCityName(city) {
        const result = await this[`_${this.type}GetCityCoordinates`](city);
        console.log(result);
        return result;
    }
}

export default Geocoder;
