/* eslint-disable jsx-a11y/label-has-associated-control */
import { getAnalytics, logEvent } from '@firebase/analytics';
import React, { useEffect, useRef, useState } from 'react';
import './sass/styles.scss';
import InputMask from 'react-input-mask';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import analysis from './icons/analysis.png';
import automation from './icons/automation.png';
import closeIcon from './icons/close_modal.svg';
import flag from './icons/flag.svg';
import freedom from './icons/freedon_logo.png';
import instagram from './icons/instagram.svg';
import integrations from './icons/integrations.png';
import linkedin from './icons/linkedin.svg';
import monitoring from './icons/monitoring.png';
import orders from './icons/orders.png';
import telegram from './icons/telegram.svg';
import tinkoff from './icons/tinkoff_logo.png';
import tsifra from './icons/tsifra_logo.png';
import about from './images/about.png';
import logo from './images/logo.svg';
import map from './images/map.jpg';
import promo from './images/promo.png';

import { httpClient } from '@/api/httpClient/httpClient';
import useModal from '@/core/hooks/useModal';
import { frontendUrl } from '@/core/utils/constants/constants';
import { addNotification } from '@/toolkit/reducers/toastr';

const StatisticsItems = ({ className = '' }) => (
    <div className={['statistics__list', className].join(' ')}>
        <div className="statistics__item">
            <h3 className="statistics__item-value">3000 точек</h3>
            <p className="statistics__item-description">распределяют доставку одновременно</p>
        </div>
        <div className="statistics__item">
            <h3 className="statistics__item-value">100K доставок</h3>
            <p className="statistics__item-description">было осуществлено за все время</p>
        </div>
        <div className="statistics__item">
            <h3 className="statistics__item-value">408 зон</h3>
            <p className="statistics__item-description">для доставок было создано</p>
        </div>
        <div className="statistics__item">
            <h3 className="statistics__item-value">97 городов</h3>
            <p className="statistics__item-description">участвуют в проекте</p>
        </div>
    </div>
);

const defaultValues = {
    name: '',
    phone: ''
    // email: ''
};

const Landing = () => {
    const dispatch = useDispatch();
    const [accepted, setAccepted] = React.useState(false);
    const [modalOpened, openModal, closeModal] = useModal();
    const [menuOpened, setMenuOpened] = useState(false);
    const [formValues, setFormValues] = useState(defaultValues);

    const onCloseModal = () => {
        setFormValues(defaultValues);
        setAccepted(false);
        closeModal();
    };
    const analytics = getAnalytics();

    useEffect(() => {
        document.title = 'Loggy - Главная';
        logEvent(analytics, 'landing_visited', { page_title: 'Loggy - Главная' });

        const scrollToTitle = (e) => {
            e.preventDefault();
            const id = e.currentTarget.getAttribute('href');

            const yOffset = -112;
            try {
                const element = document.querySelector(id);
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({ top: y, behavior: 'smooth' });
            } catch (error) {
                console.log(error);
            }
        };

        const menuLinks = document.querySelectorAll('.menu__link, .footer__link');
        menuLinks.forEach((link) => {
            link.addEventListener('click', scrollToTitle);
        });
    }, []);

    const callRequestMutation = useMutation((data) => httpClient.post('/call-request', data));

    const onSubmit = async (e) => {
        e.preventDefault();
        const payload = { ...formValues, phone: `+7 ${formValues.phone}` };
        try {
            await callRequestMutation.mutateAsync(payload);
            dispatch(addNotification({ message: 'Заявка успешно отправлена! Ожидайте, когда менеджер свяжется с вами' }));
            logEvent(analytics, 'call_request');
        } catch (e) {
            if (e?.error?.response?.data?.detail?.[0]?.msg?.includes('already exists')) {
                dispatch(
                    addNotification({
                        message: 'Ваша заявка уже принята, дождитесь звонка менеджера',
                        type: 'error'
                    })
                );
            } else {
                dispatch(
                    addNotification({
                        message: 'Возникла непредвиденная проблема. Попробуйте оставить заявку через несколько минут',
                        type: 'error'
                    })
                );
            }
        }

        onCloseModal();
    };

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setFormValues((state) => ({ ...state, [name]: value }));
    };

    const formRef = useRef(null);

    return (
        <>
            <div className="landing" id="landing">
                <header className="header">
                    <div className="header__container container">
                        <a href="#" className="header__logo logo">
                            <img src={logo} alt="Loggy Logo" className="logo__image" />
                        </a>
                        <nav className={['header__menu', 'menu', menuOpened ? 'opened' : ''].join(' ')}>
                            <ul className="menu__list">
                                <li className="menu__item">
                                    <a href="#landing" className="menu__link">
                                        Главная
                                    </a>
                                </li>
                                <li className="menu__item">
                                    <a href="#advantages" className="menu__link">
                                        Преимущества
                                    </a>
                                </li>
                                <li className="menu__item">
                                    <a href="#plans" className="menu__link">
                                        Тарифы
                                    </a>
                                </li>
                                <li className="menu__item">
                                    <a href="#statistics" className="menu__link">
                                        Агрегатор курьерских служб
                                    </a>
                                </li>
                                <li className="menu__item hide_on_mobile">
                                    <a href="#footer" className="menu__link">
                                        Контакты
                                    </a>
                                </li>
                            </ul>
                            <Link to="/login" href="#" className="menu__button">
                                Войти
                            </Link>
                        </nav>
                        <button type="button" className="header__burger" onClick={() => setMenuOpened((state) => !state)}>
                            <span />
                            <span />
                            <span />
                        </button>
                    </div>
                </header>
                <main>
                    <section
                        className="promo"
                        style={{
                            backgroundImage: `linear-gradient(90.96deg, rgba(6, 54, 112, 0.99) 18.13%, rgba(9, 18, 66, 0.3) 43.08%), url(${promo})`
                        }}
                    >
                        <div className="promo__container container">
                            <div className="promo__info">
                                <h1 className="promo__title title">Оптимизируйте вашу внутригородскую логистику с Loggy</h1>
                                <p className="promo__subtitle">Полный контроль над всеми этапами логистики в одном месте</p>
                                <button type="button" className="button promo__button" onClick={openModal}>
                                    Попробовать бесплатно
                                </button>
                            </div>
                        </div>
                    </section>
                    <section className="about" style={{ backgroundImage: `url(${about})` }}>
                        <div className="about__container container">
                            <div className="about__info">
                                <h2 className="about__title title">О нас</h2>
                                <article className="about__description">
                                    <p>
                                        В экосистеме Loggy покрыт полный цикл доставки банковских карт - от места эмбоссирования карты до
                                        конечного клиента. В этом вопросе есть много нюансов.
                                    </p>
                                    <p>
                                        Нашими партнерами являются: <strong>Freedom Bank, Тинькофф, Цифра Банк</strong>
                                    </p>
                                    <div className="about__images images">
                                        <img src={freedom} alt="Freedom Bank" className="images__item" />
                                        <img src={tinkoff} alt="Тинькофф" className="images__item" />
                                        <img src={tsifra} alt="Цифра Банк" className="images__item" />
                                    </div>
                                </article>
                            </div>
                        </div>
                    </section>
                    <section className="advantages" id="advantages">
                        <div className="advantages__container container">
                            <h2 className="advantages__title title">Наши преимущества</h2>
                            <div className="advantages__list">
                                <div className="advantages__item">
                                    <div className="advantages__image">
                                        <img src={orders} alt="" className="advantages__icon" />
                                    </div>
                                    <h3 className="advantages__item-title">Заказы</h3>
                                    <p className="advantages__item-text">
                                        Доставка посылок с автоматическим распределением курьеров по зоне внутри города.
                                    </p>
                                </div>
                                <div className="advantages__item">
                                    <div className="advantages__image">
                                        <img src={automation} alt="" className="advantages__icon" />
                                    </div>
                                    <h3 className="advantages__item-title">Автоматизация процессов по всем отправлениям</h3>
                                    <p className="advantages__item-text">Уменьшение ручной работы и ошибок.</p>
                                </div>
                                <div className="advantages__item">
                                    <div className="advantages__image">
                                        <img src={monitoring} alt="" className="advantages__icon" />
                                    </div>
                                    <h3 className="advantages__item-title">Отслеживание в реальном времени</h3>
                                    <p className="advantages__item-text">Контроль за доставкой на всех этапах.</p>
                                </div>
                                <div className="advantages__item">
                                    <div className="advantages__image">
                                        <img src={integrations} alt="" className="advantages__icon" />
                                    </div>
                                    <h3 className="advantages__item-title">Интеграции</h3>
                                    <p className="advantages__item-text">С легкостью подключайтесь к вашим текущим системам.</p>
                                </div>
                                <div className="advantages__item">
                                    <div className="advantages__image">
                                        <img src={analysis} alt="" className="advantages__icon" />
                                    </div>
                                    <h3 className="advantages__item-title">Аналитика и отчеты</h3>
                                    <p className="advantages__item-text">Принятие решений на основе данных.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="pricing" id="plans">
                        <div className="pricing__container container">
                            <h2 className="pricing__title title">Выберите подходящий тариф</h2>
                            <div className="pricing__plans-wrapper">
                                <div className="pricing__plans">
                                    <div className="pricing__plan">
                                        <h3 className="pricing__plan-title">Starter</h3>
                                        <p className="pricing__plan-price">
                                            <strong>10 000 тг</strong> / в месяц
                                        </p>
                                        <p className="pricing__plan-description">
                                            Основные функции для малого логистического бизнеса с ручной загрузкой отправлений.
                                        </p>
                                        <button type="button" className="button pricing__plan-button" onClick={openModal}>
                                            Выбрать тариф
                                        </button>
                                    </div>
                                    <div className="pricing__plan pricing__plan--featured">
                                        <h3 className="pricing__plan-title">Business</h3>
                                        <p className="pricing__plan-price">
                                            <strong>от 50 000 тг</strong> / в месяц
                                        </p>
                                        <p className="pricing__plan-description">
                                            Доступна загрузка отправлений через программу Excel и через интеграцию API.
                                        </p>
                                        <button type="button" className="button pricing__plan-button" onClick={openModal}>
                                            Выбрать тариф
                                        </button>
                                    </div>
                                    <div className="pricing__plan">
                                        <h3 className="pricing__plan-title">Enterprise</h3>
                                        <p className="pricing__plan-price">
                                            <strong>100 000 тг</strong> / в месяц
                                        </p>
                                        <p className="pricing__plan-description">
                                            Полный пакет функций + подключение к Агрегатору курьерских служб для получения сторонних
                                            заказов.
                                        </p>
                                        <button type="button" className="button pricing__plan-button" onClick={openModal}>
                                            Выбрать тариф
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="statistics" id="statistics">
                        <div className="statistics__inner" style={{ backgroundImage: `url(${map})` }}>
                            <h2 className="statistics__title title">Почему Loggy?</h2>
                            <div className="container statistics__container">
                                <StatisticsItems className="desktop" />
                            </div>
                        </div>
                        <StatisticsItems className="mobile" />
                    </section>
                </main>
                <footer className="footer" id="footer">
                    <div className="footer__container container">
                        <div className="footer__top">
                            <div className="footer__logo logo">
                                <img src={logo} alt="Loggy" className="logo__image" />
                            </div>
                            <nav className="footer__nav">
                                <ul className="footer__menu">
                                    <li className="footer__column-title">Страницы</li>
                                    <li className="footer__item">
                                        <a href="#landing" className="footer__link">
                                            Главная
                                        </a>
                                    </li>
                                    <li className="footer__item">
                                        <a href="#advantages" className="footer__link">
                                            Преимущества
                                        </a>
                                    </li>
                                    <li className="footer__item">
                                        <a href="#plans" className="footer__link">
                                            Тарифы
                                        </a>
                                    </li>
                                    <li className="footer__item">
                                        <a href="#statistics" className="footer__link">
                                            Агрегатор курьерских служб
                                        </a>
                                    </li>
                                </ul>
                                <div className="footer__contacts">
                                    <p className="footer__column-title">Контакты</p>
                                    <a href="mailto:sales@loggy.kz" className="footer__contact">
                                        sales@loggy.kz
                                    </a>
                                    <a href="tel:+77055660090" className="footer__contact tel-link">
                                        +7(705)-566-00-90
                                    </a>
                                    <span className="footer__contact tel">+7(705)-566-00-90</span>
                                    <p className="footer__contact">Жибек Жолы 135</p>
                                </div>
                                {/* <div className="footer__social"> */}
                                {/*     <p className="footer__column-title">Наши соц.сети</p> */}
                                {/*     <a href="#" className="footer__social-link"> */}
                                {/*         <img src={instagram} alt="instagram" /> */}
                                {/*     </a> */}
                                {/*     <a href="#" className="footer__social-link"> */}
                                {/*         <img src={telegram} alt="telegram" /> */}
                                {/*     </a> */}
                                {/*     <a href="#" className="footer__social-link"> */}
                                {/*         <img src={linkedin} alt="linkedin" /> */}
                                {/*     </a> */}
                                {/* </div> */}
                            </nav>
                        </div>
                        <div className="footer__copy">
                            <p>Copyright © Loggy 2024</p>
                        </div>
                    </div>
                </footer>

                <>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div className={['backdrop', modalOpened ? 'opened' : ''].join(' ')} onClick={onCloseModal} />
                    <div className={['modal', modalOpened ? 'opened' : ''].join(' ')}>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <div className="modal__close" onClick={onCloseModal} style={{ backgroundImage: `url(${closeIcon})` }} />
                        <h3 className="modal__title">Попробуйте программу бесплатно</h3>
                        <p className="modal__info">
                            Заполните форму и получите доступ к полной версии программы. Бесплатный период пользования — 30 дней.
                        </p>
                        <form className="form modal__form" onSubmit={onSubmit} ref={formRef}>
                            <div className="form__field">
                                <label className="form__label" htmlFor="name">
                                    Введите имя*
                                </label>
                                <input
                                    className="form__input"
                                    type="text"
                                    id="name"
                                    name="name"
                                    onChange={onChangeInput}
                                    value={formValues.name}
                                    required
                                />
                            </div>
                            <div className="form__field">
                                <label htmlFor="phone" className="form__label">
                                    Введите номер телефона*
                                </label>
                                <div className="form__field-phone">
                                    <div className="form__input-phone-code" style={{ backgroundImage: `url(${flag})` }}>
                                        +7
                                    </div>
                                    <InputMask
                                        mask="999 999 99 99"
                                        maskChar={' '}
                                        type="tel"
                                        id="phone"
                                        className="form__input"
                                        onChange={onChangeInput}
                                        value={formValues.phone}
                                        name="phone"
                                        required
                                    >
                                        {(inputProps) => <input {...inputProps} />}
                                    </InputMask>
                                </div>
                            </div>
                            {/* <div className="form__field"> */}
                            {/*     <label className="form__label" htmlFor="email"> */}
                            {/*         Введите Email* */}
                            {/*     </label> */}
                            {/*     <input */}
                            {/*         className="form__input" */}
                            {/*         type="email" */}
                            {/*         id="email" */}
                            {/*         name="email" */}
                            {/*         onChange={onChangeInput} */}
                            {/*         value={formValues.email} */}
                            {/*         required */}
                            {/*     /> */}
                            {/* </div> */}
                            <div className="form__accept">
                                <input
                                    type="checkbox"
                                    name="accept"
                                    id="accept"
                                    className="form__switch-checkbox"
                                    checked={accepted}
                                    onChange={(e) => {
                                        setAccepted(e.target.checked);
                                    }}
                                    required
                                />
                                <label htmlFor="accept" className="form__switch-label">
                                    <span className="form__switch-element" />
                                    <p>
                                        Вы подтверждаете, что согласны <br />
                                        <a href={`${frontendUrl}/data-policy`} target="_blank" rel="noreferrer">
                                            на сбор и обработку персональных данных
                                        </a>
                                    </p>
                                </label>
                            </div>
                            <button type="submit" className="button modal__button" disabled={!formRef.current?.checkValidity()}>
                                Отправить заявку
                            </button>
                        </form>
                    </div>
                </>
            </div>
        </>
    );
};

export default Landing;
