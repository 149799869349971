import { CircularProgress, FormControlLabel, IconButton, Popover } from '@mui/material';
import React, { useState } from 'react';

import CloseIcon from '@/assets/images/icons/close.svg';
import SettingIcon from '@/assets/images/icons/setting.svg';
import Checkbox from '@/components/checkbox';

const OrdersFilter = ({ setSelected, loading, items, selected }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const onSelectItem = (id) => {
        setSelected((prevState) => (!prevState.includes(id) ? prevState.concat(id) : prevState.filter((elem) => elem !== id)));
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div>
            <IconButton aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                <img src={SettingIcon} alt="settings" />
            </IconButton>
            <Popover
                id={id}
                PaperProps={{
                    style: { minWidth: '300px' }
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                {loading && (
                    <CircularProgress
                        size={20}
                        color="success"
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-10px',
                            marginLeft: '-10px'
                        }}
                    />
                )}
                <div style={{ position: 'relative' }}>
                    <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
                        <img src={CloseIcon} alt="settings" />
                    </IconButton>
                    <div
                        style={{
                            padding: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                            maxHeight: '250px',
                            overflowY: 'auto'
                        }}
                    >
                        {items?.map((item) => (
                            <div key={item.id}>
                                <FormControlLabel
                                    style={{ width: '100%' }}
                                    control={<Checkbox checked={selected?.includes(item.id)} onChange={() => onSelectItem(item.id)} />}
                                    label={item.name}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Popover>
        </div>
    );
};

export default OrdersFilter;
