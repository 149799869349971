import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_RANGE } from '@/core/utils/constants/constants';

const initialState = {
    selectedCities: [],
    selectedProducts: [],
    selectedPartners: [],
    isIncorrectAddress: false,
    selectedCourier: null,
    range: DEFAULT_RANGE
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setSelectedCities: (state, { payload }) => {
            state.selectedCities = payload;
        },
        setSelectedProducts: (state, { payload }) => {
            state.selectedProducts = payload;
        },
        setSelectedPartners: (state, { payload }) => {
            state.selectedPartners = payload;
        },
        setIsIncorrectAddress: (state, { payload }) => {
            state.isIncorrectAddress = payload;
        },
        setSelectedCourier: (state, { payload }) => {
            state.selectedCourier = payload;
        },
        setRange: (state, { payload }) => {
            state.range = payload;
        }
    }
});

export const { setSelectedCities, setSelectedProducts, setSelectedPartners, setIsIncorrectAddress, setSelectedCourier, setRange } =
    filtersSlice.actions;

export default filtersSlice.reducer;
