import React from 'react';
import { useSelector } from 'react-redux';

const HasAccess = ({ roles, children, access = true }) => {
    const user = useSelector((state) => state.auth?.user);
    const profileType = user?.data?.profile?.profile_type;
    if (!roles.includes(profileType) || !access) {
        return null;
    }
    return children;
};

export default HasAccess;
