import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { httpClient } from '@/api/httpClient/httpClient';
import loaderIcon from '@/assets/images/icons/loader.svg';
import HasAccess from '@/components/auth/permissions/hasAccess';
import { RoundedButton, RoundedOutlinedButton } from '@/components/button/styledButtons';
import DetailsInfoBlock from '@/components/detailsInfoBlock';
import FieldLabel from '@/components/formField/fieldLabel';
import UncontrolledSelect from '@/components/formField/uncontrolledSelect';
import UncontrolledTextInput from '@/components/formField/uncontrolledTextInput';
import ArmListLayoutHeader from '@/components/layouts/armListLayout/header';
import { COURIER, FILIAL_MANAGER, PROFILE_TYPES, SERVICE_MANAGER, SUPERVISOR } from '@/core/utils/constants/constants';
import { PROFILE } from '@/core/utils/constants/routes';
import { InputCode } from '@/pages/register/registerComponent/registerStepsComponent';
import { fetchCurrentUser, refreshUser } from '@/toolkit/actionCreators/auth';
import { addNotification } from '@/toolkit/reducers/toastr';
import PartnerSettings from '@/views/profile/components/partnerSettings';

const schema = yup.object({
    role: yup.string()
});

const Loader = styled(Box)`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8) url(${loaderIcon}) 50% 50% no-repeat;
    z-index: 1000;
`;

const ProfileDetails = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth?.user);
    const owner = user.organization.leader_data;
    const [loading, setLoading] = useState(false);

    const [emailEditable, setEmailEditable] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [code, setCode] = useState([...Array(4)].map(() => ''));
    const [email, setEmail] = useState('');

    const { getValues, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            role: user?.data?.profile?.profile_type
        },
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        dispatch(fetchCurrentUser());
    }, []);

    const onChangeRole = async (e) => {
        setLoading(true);
        try {
            await dispatch(
                refreshUser({
                    refreshToken: user.refresh_token,
                    profile: user.data.profiles.find((p) => p.type === e.target.value)
                })
            ).unwrap();
            setValue('role', e.target.value);
            await dispatch(fetchCurrentUser()).unwrap();
            dispatch(addNotification({ message: `Вы успешно сменили роль на ${PROFILE_TYPES[e.target.value]}` }));
        } catch (e) {
            dispatch(addNotification({ message: `Не удалось войти под ролью: ${PROFILE_TYPES[e.target.value]}`, type: 'error' }));
        }
        setLoading(false);
    };

    return (
        <>
            <ArmListLayoutHeader
                title={user.data.first_name ? `${user.data.first_name} ${user.data.last_name}` : t('profile:nameNotExists')}
                buttons={
                    <HasAccess roles={[SERVICE_MANAGER]}>
                        <RoundedOutlinedButton key="edit" onClick={() => navigate(`${PROFILE}/update`)}>
                            {t('buttons:edit')}
                        </RoundedOutlinedButton>
                    </HasAccess>
                }
            />
            <Box>
                <DetailsInfoBlock title={t('profile:managerData')}>
                    <DetailsInfoBlock.Child label={t('profile:surname')} info={user.data?.last_name || '-'} />
                    <DetailsInfoBlock.Child label={t('profile:name')} info={user.data?.first_name || '-'} />
                    <DetailsInfoBlock.Child
                        label={t('profile:middleName')}
                        info={user.data?.middle_name}
                        visible={!!user.data?.middle_name}
                    />
                    <DetailsInfoBlock.Child label={t('profile:phone')} info={user.data?.phone_number} />
                    {!emailEditable ? (
                        <DetailsInfoBlock.Child
                            label={t('profile:email')}
                            info={
                                <div>
                                    {user.data?.email || t('profile:noEmail')} <br />
                                    <a
                                        href="#"
                                        onClick={() => {
                                            setEmailEditable(true);
                                            setEmail(user?.data?.email || '');
                                        }}
                                        style={{ fontSize: '12px', color: 'var(--accent-color)' }}
                                    >
                                        Изменить email
                                    </a>
                                </div>
                            }
                        />
                    ) : (
                        <DetailsInfoBlock.CustomChild>
                            {!verifyEmail ? (
                                <>
                                    <UncontrolledTextInput
                                        label="Email"
                                        type="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        id={email}
                                    />
                                    <RoundedButton
                                        onClick={async () => {
                                            try {
                                                await httpClient.put(`/user/${user?.data?.id}/change_email`, { email });
                                                dispatch(
                                                    addNotification({
                                                        message:
                                                            'На указанную электронную почту был отправлен код. Введите его для подтверждения адреса электронной почты'
                                                    })
                                                );
                                                setVerifyEmail(true);
                                            } catch (e) {
                                                dispatch(addNotification({ message: 'Не удалось изменить Email', type: 'error' }));
                                            }
                                        }}
                                        sx={{ fontSize: '12px', height: 'auto', minWidth: 'auto', padding: '7px', ml: '5px' }}
                                        disabled={!email.length}
                                    >
                                        OK
                                    </RoundedButton>
                                </>
                            ) : (
                                <>
                                    <FieldLabel>
                                        Введите код отправленный на почту <b>{email}</b>
                                    </FieldLabel>
                                    <InputCode
                                        length={4}
                                        loading={loading}
                                        onComplete={async (code) => {
                                            try {
                                                await httpClient.put(`/user/${user?.data?.id}/veryfi_email`, { otp: code });
                                                dispatch(
                                                    addNotification({
                                                        message: 'Email успешно изменен'
                                                    })
                                                );
                                                setEmailEditable(false);
                                                setVerifyEmail(false);
                                                await dispatch(fetchCurrentUser()).unwrap();
                                            } catch (e) {
                                                dispatch(addNotification({ message: 'Не удалось изменить Email', type: 'error' }));
                                            }
                                        }}
                                        code={code}
                                        setCode={setCode}
                                    />
                                </>
                            )}
                        </DetailsInfoBlock.CustomChild>
                    )}

                    <DetailsInfoBlock.Child label={t('profile:role')} info={PROFILE_TYPES[user.data?.profile.profile_type]} />
                    <DetailsInfoBlock.CustomChild visible={user.data.profiles.filter((p) => p.type !== COURIER).length > 1}>
                        <UncontrolledSelect
                            label={t('profile:enterWithRole')}
                            options={user.data.profiles
                                .filter((p) => p.type !== COURIER)
                                .map((p) => ({ id: p.type, name: PROFILE_TYPES[p.type] }))}
                            value={getValues().role}
                            onChange={onChangeRole}
                        />
                    </DetailsInfoBlock.CustomChild>
                </DetailsInfoBlock>
                <DetailsInfoBlock title={t('profile:organizationData')}>
                    <DetailsInfoBlock.Child label={t('profile:orgName')} info={user.organization?.name_ru} />
                    <DetailsInfoBlock.Child label={t('profile:bin')} info={user.organization?.identifier} />
                    <DetailsInfoBlock.Child
                        sxInfo={{ textTransform: 'capitalize' }}
                        label={t('profile:leader')}
                        info={`${owner?.last_name || '-'} ${owner?.first_name || '-'} ${owner?.middle_name || ''}`.toLocaleLowerCase()}
                    />

                    <HasAccess roles={[SERVICE_MANAGER, SUPERVISOR, FILIAL_MANAGER]}>
                        <DetailsInfoBlock.Child
                            label={t('profile:hoursOfWork')}
                            info={`${user.organization.start_work_hour || '-'} - ${user.organization.end_work_hour || '-'}`}
                        />
                    </HasAccess>
                </DetailsInfoBlock>
                <PartnerSettings profileType={user.data.profile.profile_type} organizationId={user.organization.id} />
            </Box>
            {loading && <Loader />}
        </>
    );
};

export default ProfileDetails;
