import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ORDER_DETAILS } from '@/core/utils/constants/routes';

const OrderRow = ({ order }) => {
    const navigate = useNavigate();

    const clickHandler = () => {
        navigate(ORDER_DETAILS.format(order.id));
    };
    return (
        <TableRow onClick={clickHandler} style={{ cursor: 'pointer' }} key={order.id}>
            <TableCell>{order.id}</TableCell>
            <TableCell>{order.receiver_phone_number}</TableCell>
            <TableCell>{order.receiver_iin}</TableCell>
            <TableCell>{order.addresses?.[0]?.place?.address || 'Не указан'}</TableCell>
            <TableCell>{order.receiver_name}</TableCell>
        </TableRow>
    );
};

export default OrderRow;
