import { createAsyncThunk } from '@reduxjs/toolkit';

import { httpClient } from '@/api/httpClient/httpClient';

export const fetchCurrentUser = createAsyncThunk('auth/getCurrentUser', async () => {
    const response = await httpClient.get('/user/me?with_profile=true');
    return response.data;
});

export const fetchPartner = createAsyncThunk('auth/getPartner', async (id) => {
    const response = await httpClient.get(`/partner/${id}`);
    return response.data;
});

export const login = createAsyncThunk('auth/login', async ({ username, password, profile }) => {
    const formData = new URLSearchParams();
    formData.set('grant_type', 'password');
    formData.set('username', username);
    formData.set('password', password);

    let url = '/token';

    if (profile) {
        url += `?profile_id=${profile.id}&profile_type=${profile.type}`;
    }

    const response = await httpClient.post(url, formData);

    httpClient.setToken(response.data.access_token);

    return { ...response.data };
});

export const refreshUser = createAsyncThunk('auth/refresh', async ({ refreshToken, profile }) => {
    const formData = new URLSearchParams();
    formData.set('refresh_token', refreshToken);
    const url = `/token/refresh?profile_id=${profile.id}&profile_type=${profile.type}`;

    const response = await httpClient.post(url, formData);

    httpClient.setToken(response.data.access_token);

    return { ...response.data };
});
