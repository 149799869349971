import { httpClient } from '@/api/httpClient/httpClient';

export const areaServices = {
    getList(cityId = 2) {
        return httpClient.get(`/area/list?city_id=${cityId}`);
    },
    create(data) {
        return httpClient.post('/area', data);
    },
    getArea(id) {
        return httpClient.get(`/area/${id}`);
    },
    delete(id) {
        return httpClient.delete(`/area/${id}`);
    },
    update({ id, data }) {
        return httpClient.put(`/area/${id}`, data);
    },
    archive(id) {
        return httpClient.put(`/area/${id}/archive`);
    },
    activate(id) {
        return httpClient.put(`/area/${id}/archive`);
    }
};
