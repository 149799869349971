import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isHideStatuses: +localStorage.getItem('hide_statuses')
};

export const orders = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setHideStatuses: (state) => {
            state.isHideStatuses = !state.isHideStatuses;
            localStorage.setItem('hide_statuses', String(+state.isHideStatuses));
        }
    }
});

export const { setHideStatuses } = orders.actions;

export default orders.reducer;
