import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Grid, Typography, Checkbox, FormControl, FormControlLabel, FormGroup, Box, LinearProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import * as yup from 'yup';

import CheckboxCheckedIcon from '@/assets/images/icons/checkbox-checked.svg';
import CheckboxIcon from '@/assets/images/icons/checkbox.svg';
import { RoundedButton } from '@/components/button/styledButtons';
import MaskedInput from '@/components/formField/maskedInput';
import SelectInput from '@/components/formField/selectInput';
import TextInput from '@/components/formField/textInput';
import i18n from '@/core/i18n';
import { authServices } from '@/core/services/authServices';
import { partnerServices } from '@/core/services/partnerServices';
import { userServices } from '@/core/services/userServices';
import { FILIAL_MANAGER, PHONE_REGEX, SERVICE_MANAGER, SUPERVISOR } from '@/core/utils/constants/constants';
import { devices } from '@/core/utils/devices';
import { getSchemaEmptyValues } from '@/core/utils/schemas';
import { checkIin, normalizePhoneNumber } from '@/core/utils/utils';
import StyledPaper from '@/pages/register/registerComponent/StyledPapper';
import store from '@/toolkit/store';

const { t } = i18n;

const schema = yup.object({
    number: yup.string().required(t('errors:required')),
    // .matches(PHONE_REGEX, t('errors:incorrectPhone')),
    uin: yup
        .string()
        .required(t('errors:required'))
        .min(12, t('errors:binLengthRequired'))
        .test('uin', t('errors:incorrectUin'), (value) => checkIin(value)),
    organizationType: yup.string().required(t('errors:required')),
    organizationName: yup
        .string()
        .when('organizationType', {
            is: 'ip',
            then: (schema) => schema.required(i18n.t('errors:required'))
        })
        .when('organizationType', {
            is: 'fl',
            then: (schema) => schema.required(i18n.t('errors:required'))
        }),
    usl_sogl: yup.bool().required().oneOf([true], t('errors:acceptRulesAgreement')),
    baza_gbd: yup.bool().required().oneOf([true], t('errors:acceptPersonalDataAgreement'))
});

const FormRegister = ({ setPage, setNumber, setIin }) => {
    const user = store.getState().auth?.user;
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate('/arm/orders');
        }
    }, [user]);

    const {
        control,
        register,
        handleSubmit,
        setError,
        watch,
        formState: { isValid, errors: inputErrors },
        getValues
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: getSchemaEmptyValues(schema)
    });
    const recaptcha = useRef();
    const [captcha, setCaptcha] = useState(false);

    const handleRegisterOTP = useMutation(['RegisterOTP'], (res) => authServices.registerOTP(res));
    const createServiceMutation = useMutation(['registerService'], partnerServices.createDeliveryService);

    const onSubmit = async () => {
        const normalizedPhone = normalizePhoneNumber(getValues().number);

        // eslint-disable-next-line no-unreachable
        setNumber(getValues().number);
        setIin(getValues().uin);
        const result = { phone_number: normalizedPhone, type: 'auth' };

        const serviceData = {
            service_manager_phone_number: normalizedPhone,
            identifier: getValues().uin,
            type: getValues().organizationType
        };
        if (['fl', 'ip'].includes(watch().organizationType)) {
            serviceData.name_ru = getValues().organizationName;
        }

        createServiceMutation
            .mutateAsync(serviceData)
            .then((response) => {
                handleRegisterOTP
                    .mutateAsync(result)
                    .then(() => {
                        navigate('', {
                            state: {
                                organization: response.data,
                                manual: {
                                    uin: getValues().uin,
                                    phone: getValues().phone,
                                    organizationName: getValues().organizationName
                                }
                            }
                        });
                        setPage(1);
                    })
                    .catch(() => {
                        setError('number', { type: 'focus', message: t('errors:userAlreadyExists') });
                    });
            })
            .catch((e) => {
                const error = e.error.response.data.detail;
                if (error.includes('phone_number')) {
                    return setError('number', { type: 'focus', message: t('errors:phoneAlreadyExists') });
                }
                if (error.includes('Key (name')) {
                    return setError('iin', { type: 'focus', message: t('errors:binAlreadyExists') });
                }
                if (error.includes('Субъект с указанным БИН не зарегистрирован')) {
                    return setError('iin', { type: 'focus', message: error });
                }
                return setError('number', { type: 'focus', message: error });
            });
    };

    return (
        <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
            {createServiceMutation.isLoading ? <LinearProgress color="success" /> : null}
            <Grid container flexDirection="column">
                <StyledPaper>
                    <Grid item xs={12} style={{ marginBottom: '30px' }}>
                        <MaskedInput
                            id="number"
                            label="Номер телефона"
                            control={control}
                            errors={inputErrors}
                            placeholder="+7 (---) --- -- --"
                            mask="+7 (999) 999-99-99"
                            ContainerProps={{
                                maxWidth: '60%',
                                sx: {
                                    [`@media screen and ${devices.mobileL}`]: {
                                        maxWidth: '100%'
                                    }
                                }
                            }}
                            {...register('number')}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '30px' }}>
                        <SelectInput
                            id="organizationType"
                            label="Тип организации"
                            ContainerProps={{
                                maxWidth: '60%',
                                [`@media screen and ${devices.mobileL}`]: {
                                    maxWidth: '100%'
                                }
                            }}
                            control={control}
                            errors={inputErrors}
                            placeholder="Выберите тип организации"
                            options={[
                                { id: 'too', name: 'ТОО' },
                                { id: 'ip', name: 'ИП' },
                                { id: 'fl', name: 'Физ. лицо' }
                            ]}
                            {...register('organizationType')}
                        />
                    </Grid>
                    {watch().organizationType === 'too' && (
                        <Grid item xs={12}>
                            <MaskedInput
                                id="uin"
                                label="БИН организации"
                                control={control}
                                errors={inputErrors}
                                placeholder="Введите БИН"
                                mask="999999999999"
                                ContainerProps={{
                                    maxWidth: '60%',
                                    sx: {
                                        [`@media screen and ${devices.mobileL}`]: {
                                            maxWidth: '100%'
                                        }
                                    }
                                }}
                                {...register('uin')}
                            />
                        </Grid>
                    )}
                    {['fl', 'ip'].includes(watch().organizationType) && (
                        <>
                            <Grid item xs={12} style={{ marginBottom: '30px' }}>
                                <MaskedInput
                                    id="uin"
                                    label="ИИН"
                                    control={control}
                                    errors={inputErrors}
                                    placeholder="Введите ИИН"
                                    mask="999999999999"
                                    ContainerProps={{
                                        maxWidth: '60%',
                                        sx: {
                                            [`@media screen and ${devices.mobileL}`]: {
                                                maxWidth: '100%'
                                            }
                                        }
                                    }}
                                    {...register('uin')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    id="organizationName"
                                    label="Название организации"
                                    control={control}
                                    errors={inputErrors}
                                    placeholder="Название организации"
                                    ContainerProps={{
                                        maxWidth: '60%',
                                        sx: {
                                            [`@media screen and ${devices.mobileL}`]: {
                                                maxWidth: '100%'
                                            }
                                        }
                                    }}
                                    {...register('organizationName')}
                                />
                            </Grid>
                        </>
                    )}
                </StyledPaper>
                <Grid item xs={12}>
                    <StyledPaper>
                        <FormControl component="fieldset" variant="standard">
                            <FormGroup>
                                <Box>
                                    <FormControlLabel
                                        style={{ height: '30px' }}
                                        control={
                                            <Checkbox
                                                defaultChecked
                                                icon={<ReactSVG src={CheckboxIcon} />}
                                                checkedIcon={<ReactSVG src={CheckboxCheckedIcon} />}
                                                name="baza_gbd"
                                                {...register('baza_gbd', { required: true })}
                                            />
                                        }
                                        label={
                                            <>
                                                Я соглашаюсь на сбор и обработку{' '}
                                                <a target="_blank" href="/data-policy" style={{ color: '#49C172' }}>
                                                    персональных данных
                                                </a>
                                            </>
                                        }
                                    />
                                    {inputErrors.baza_gbd && (
                                        <Typography sx={{ color: '#FF6464' }}>{inputErrors.baza_gbd.message}</Typography>
                                    )}
                                </Box>
                                <Box style={{ marginBottom: '25px' }}>
                                    <FormControlLabel
                                        style={{ height: '30px' }}
                                        control={
                                            <Checkbox
                                                defaultChecked
                                                icon={<ReactSVG src={CheckboxIcon} />}
                                                checkedIcon={<ReactSVG src={CheckboxCheckedIcon} />}
                                                name="usl_sogl"
                                                {...register('usl_sogl', { required: true })}
                                            />
                                        }
                                        label={
                                            <>
                                                Я принимаю условия{' '}
                                                <a target="_blank" href="/user-policy" style={{ color: '#49C172' }}>
                                                    пользовательского соглашения
                                                </a>
                                            </>
                                        }
                                    />
                                    {inputErrors.usl_sogl && (
                                        <Typography sx={{ color: '#FF6464', marginBottom: '5px' }}>
                                            {inputErrors.usl_sogl.message}
                                        </Typography>
                                    )}
                                </Box>

                                <ReCAPTCHA
                                    style={{ display: 'inline-block', cursor: 'hover' }}
                                    theme="light"
                                    ref={recaptcha}
                                    sitekey="6Lde4j8hAAAAAFlCrasw-ufT_TSkibHRGACg60ls"
                                    onChange={() => {
                                        setCaptcha(true);
                                    }}
                                    // asyncScriptOnLoad={this.asyncScriptOnLoad}
                                />
                            </FormGroup>
                        </FormControl>
                    </StyledPaper>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} textAlign="right">
                    <RoundedButton
                        // disabled={!isValid || !captcha || createServiceMutation.isLoading}
                        disabled={!isValid || createServiceMutation.isLoading}
                        variant="contained"
                        color="success"
                        type="submit"
                    >
                        Отправить SMS-код
                    </RoundedButton>
                </Grid>
            </Grid>
        </form>
    );
};

export default FormRegister;
