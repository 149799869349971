import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import { Logo, HeaderTextModal } from '@/components/createComponents';

const useStyles = makeStyles((theme) => ({
    btn: {
        color: theme.palette.success.main,
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        border: `1px solid ${theme.palette.grey[450]}`,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.background.paperLight
        }
    }
}));

const MainModal = ({ handleClose, setCreateState, setSwitchModal, switchModal }) => {
    const classes = useStyles();

    return (
        <>
            <HeaderTextModal handleClose={handleClose} text="Добавление партнера" />
            <Button
                sx={{ mt: 5 }}
                className={classes.btn}
                onClick={() => {
                    setCreateState(true);
                    setSwitchModal(!switchModal);
                }}
            >
                Добавление с автопроверкой партнера
            </Button>
            <Button
                sx={{ mt: 2 }}
                className={classes.btn}
                onClick={() => {
                    setCreateState(false);
                    setSwitchModal(!switchModal);
                }}
            >
                Добавление вручную без автопроверки
            </Button>
        </>
    );
};

export default MainModal;
