import { subMonths, subWeeks, subYears, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import React from 'react';
import { createStaticRanges, defaultInputRanges } from 'react-date-range';
import DateRangePicker from 'react-date-range/dist/components/DateRangePicker';

import 'react-date-range/dist/styles.css';
import './theme.css';
import i18n from '@/core/i18n';

const { t } = i18n;
const sideBarOptions = () => [
    {
        label: t('components:rangedDatePicker.today'),
        range: () => ({
            startDate: new Date(),
            endDate: new Date()
        })
    },
    {
        label: t('components:rangedDatePicker.7days'),
        range: () => ({
            startDate: subWeeks(new Date(), 1),
            endDate: new Date()
        })
    },
    {
        label: t('components:rangedDatePicker.1month'),
        range: () => ({
            startDate: subMonths(new Date(), 1),
            endDate: new Date()
        })
    },
    {
        label: t('components:rangedDatePicker.1year'),
        range: () => ({
            startDate: subYears(new Date(), 1),
            endDate: new Date()
        })
    }
];

const RangedDatePicker = ({ range, setRange, maxDate }) => {
    const staticRanges = [...createStaticRanges(sideBarOptions())];

    return (
        <DateRangePicker
            onChange={(item) => {
                const startDate = format(item.selection.startDate, 'yyyy-MM-dd');
                const endDate = format(item.selection.endDate, 'yyyy-MM-dd');
                setRange([
                    {
                        startDate: new Date(startDate),
                        endDate: new Date(endDate),
                        key: 'selection'
                    }
                ]);
            }}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={range}
            startDatePlaceholder={t('components:rangedDatePicker.placeholders.startDate')}
            endDatePlaceholder={t('components:rangedDatePicker.placeholders.endDate')}
            direction="horizontal"
            locale={ru}
            maxDate={maxDate || new Date()}
            rangeColors={['transparent']}
            color="#ECEEF0"
            staticRanges={staticRanges}
            calendarFocus="backward"
            inputRanges={[
                {
                    ...defaultInputRanges[0],
                    label: t('components:rangedDatePicker.daysToCurrent')
                }
            ]}
        />
    );
};

export default RangedDatePicker;
