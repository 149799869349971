import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import HasAccess from '@/components/auth/permissions/hasAccess';
import { RoundedAddButton } from '@/components/button/styledButtons';
import ArmListLayoutHeader from '@/components/layouts/armListLayout/header';
import ListTable from '@/components/listTable';
import Pagination from '@/components/pagination';
import useModal from '@/core/hooks/useModal';
import usePagination from '@/core/hooks/usePagination';
import i18n from '@/core/i18n';
import { orderGroupServices } from '@/core/services/orderGroupServices';
import { orderServices } from '@/core/services/orderServices';
import { SORTER } from '@/core/utils/constants/constants';
import { addNotification } from '@/toolkit/reducers/toastr';
import OrderGroupsTableRow from '@/views/orderGroups/list/tableRow';
import DistributeModal from '@/views/orders/components/distributeModal';

const { t } = i18n;

const headers = [
    { title: 'ID группы', key: 'id' },
    { title: 'Дата создания', key: 'created_at' },
    { title: 'Сортировщик', key: 'sorter' },
    { title: 'Курьер', key: 'courier' },
    { title: 'Менеджер', key: 'manager' },
    { title: 'Количество заявок', key: 'count' },
    { title: 'Дата последнего обновления', key: 'updated_at' },
    { title: 'Статус группы', key: 'status' }
];

const OrderGroupsList = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { currentPage, changePage, pageLimit, setPageLimitHandler } = usePagination('orderGroupsLimit', 6);
    const { data: orderGroups } = useQuery(['orderGroups', currentPage, pageLimit], () =>
        orderGroupServices.getList({ page: currentPage, page_limit: pageLimit })
    );

    const [createModalOpened, openCreateModal, closeCreateModal] = useModal();

    const { data: orders, isLoading } = useQuery(
        ['packedOrders', createModalOpened],
        () => orderServices.getList({ current_status_slug: 'upakovano', limit: 100 }),
        {
            enabled: createModalOpened
        }
    );
    const createOrderGroupMutation = useMutation(orderGroupServices.create);

    const createOrderGroup = async (orders) => {
        try {
            await createOrderGroupMutation.mutateAsync({ orders });
            await queryClient.invalidateQueries(orderGroups);
            dispatch(addNotification({ message: t('notifications:orderGroupCreatedSuccess') }));
            closeCreateModal();
        } catch (e) {
            console.log(e);
            dispatch(addNotification({ message: t('notifications:orderGroupCreatedFailed'), type: 'error' }));
        }
    };

    return (
        <>
            <ArmListLayoutHeader
                title={t('orderGroups:title')}
                buttons={
                    <HasAccess roles={[SORTER]}>
                        <RoundedAddButton onClick={openCreateModal}>Создать</RoundedAddButton>
                    </HasAccess>
                }
            />
            <ListTable headers={headers}>
                {orderGroups?.data?.items?.map((act, i) => (
                    <OrderGroupsTableRow key={i} orderGroup={act} />
                ))}
            </ListTable>
            <Pagination
                setPageLimit={setPageLimitHandler}
                onPageChange={changePage}
                pageLimit={pageLimit}
                totalPages={orderGroups?.data?.total_pages}
                currentPage={currentPage}
                itemsLength={orderGroups?.data?.total}
            />
            <DistributeModal
                title={t('orderGroups:createGroup')}
                showing={createModalOpened}
                close={closeCreateModal}
                leftColumnTitle={t('orderGroups:leftColumnTitle')}
                rightColumnTitle={t('orderGroups:rightColumnTitle')}
                leftColumnOrders={[]}
                rightColumnOrders={orders?.data?.items?.slice(0, 15)}
                isLoading={isLoading}
                mutationLoading={createOrderGroupMutation.isLoading}
                onSubmit={createOrderGroup}
                forceEnableButton
            />
        </>
    );
};

export default OrderGroupsList;
