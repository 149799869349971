import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CancelIcon from '@mui/icons-material/Cancel';
import { Backdrop, Box, IconButton, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import { RoundedOutlinedButton, RoundedSecondaryButton } from '@/components/button/styledButtons';
import RangedDatePicker from '@/components/rangedDatePicker';
import i18n from '@/core/i18n';
import { DEFAULT_RANGE } from '@/core/utils/constants/constants';
import { devices } from '@/core/utils/devices';

const { t } = i18n;

const StyledDatepickerModal = styled(Box)`
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--paper-bg);
    border-radius: 10px;
    @media screen and ${devices.tablet} {
        padding: 15px;
    }
`;

const DateRangeModal = ({ isShowing, close, range, setRange, maxDate }) => {
    const [localRange, setLocalRange] = useState(range);

    function onClickApply() {
        setRange(localRange);
        close();
    }

    function onClickReset() {
        setRange(DEFAULT_RANGE);
        setLocalRange(DEFAULT_RANGE);
    }

    return (
        <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
            sx={{
                overflowY: 'auto'
            }}
            open={isShowing}
            onClose={close}
        >
            <StyledDatepickerModal>
                <RangedDatePicker range={localRange} setRange={setLocalRange} maxDate={maxDate} />
                <Box textAlign="center" mt={1}>
                    <RoundedSecondaryButton sx={{ mr: '10px' }} onClick={onClickReset}>
                        {t('buttons:reset')}
                    </RoundedSecondaryButton>
                    <RoundedOutlinedButton variant="contained" color="success" onClick={onClickApply}>
                        {t('buttons:apply')}
                    </RoundedOutlinedButton>
                </Box>
            </StyledDatepickerModal>
        </Modal>
    );
};

DateRangeModal.Button = ({ openModal, range, setRange, hideClearIcon }) => (
    <Box position="relative" sx={{ mr: '10px' }}>
        <IconButton onClick={openModal} sx={{ color: 'var(--main-text-color)' }}>
            <CalendarMonthIcon />
        </IconButton>
        {range[0].startDate && !hideClearIcon && (
            <IconButton
                sx={{ position: 'absolute', top: '-5px', right: '-5px', p: 0, color: 'var(--main-text-color)' }}
                onClick={() => {
                    setRange(DEFAULT_RANGE);
                }}
            >
                <CancelIcon sx={{ fontSize: '14px' }} />
            </IconButton>
        )}
    </Box>
);

export default DateRangeModal;
