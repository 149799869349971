import axios from 'axios';
import L from 'leaflet';
import React, { useEffect, useMemo, useState } from 'react';

import { GET_TILE_LAYER, NOMINATIM_BASE_URL } from '@/core/utils/constants/constants';

const useOSM = (selectedCity, mapId = 'map-container', zoom = 13, { minZoom, maxZoom } = {}) => {
    const MapWrapper = useMemo(() => <div id={mapId} style={{ width: '100%', height: '100%' }} />);

    const [mapInstance, setMapInstance] = useState(null);
    const [mapContainer, setMapContainer] = useState(null);

    const getCityCoordinates = async (selectedCity) => {
        if (selectedCity) {
            const response = await axios.get(`${NOMINATIM_BASE_URL}/search.php?q=${selectedCity}&format=jsonv2`);
            if (response.data.length) {
                const { lon, lat } = response.data[0];
                return [lat, lon];
            }
        }
        return [43.24887, 76.92001];
    };

    useEffect(() => {
        const container = L.DomUtil.get(mapId);
        if (container != null) {
            // eslint-disable-next-line no-underscore-dangle
            container._leaflet_id = null;
        }
        if (mapContainer) {
            console.log(selectedCity);
            getCityCoordinates(selectedCity).then((coords) => {
                const params = {
                    center: coords,
                    zoom,
                    draggable: true
                };
                if (maxZoom) params.maxZoom = maxZoom;
                if (minZoom) params.minZoom = minZoom;

                const map = L.map(mapId, params);

                setMapInstance(map);
            });
        }
    }, [mapContainer]);

    useEffect(() => {
        setMapContainer(document.getElementById(mapId));

        return () => {
            mapInstance?.off();
            mapInstance?.remove();
        };
    }, []);

    useEffect(() => {
        const container = L.DomUtil.get(mapId);
        if (container != null) {
            // eslint-disable-next-line no-underscore-dangle
            container._leaflet_id = null;
        }
        if (mapInstance) {
            mapInstance.invalidateSize();
            GET_TILE_LAYER().addTo(mapInstance);
        }
    }, [mapInstance]);

    return {
        Map: MapWrapper,
        mapInstance,
        getCityCoordinates
    };
};

export default useOSM;
