import Box from '@mui/material/Box';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import { TableDataGrid, BasicTable, TableCheckbox, UpdateTable } from '@/components';
import { PartnerHeader, PartnerSetting } from '@/components/createComponents';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '3rem',
        backgroundColor: theme.palette.grey[200],
        height: '100vh',
        width: '100vw'
    }
}));

const Partners = ({ handleOpen, dataTable, columns, styling }) => {
    const handleEdit = (e) => {};
    const handleDelete = (e) => {};

    const theme = useTheme();
    const classes = useStyles();
    const clr = theme.palette;

    return (
        <Box className={classes.root}>
            <PartnerHeader handleOpen={handleOpen} />
            <Box
                sx={{
                    // border: `1px solid ${clr.grey[400]}`,
                    // height: 'calc(100% - 21vh)',
                    m: 2
                    // borderRadius: 50
                }}
            >
                <UpdateTable dataTable={dataTable} columns={columns} />
            </Box>
        </Box>
    );
};

export default Partners;
