import { httpClient } from '@/api/httpClient/httpClient';

export const commonServices = {
    getHistory(modelType, modelId, pageLimit, page) {
        return httpClient.get('/history/list', {
            params: {
                model_type: modelType,
                model_id: modelId,
                page_limit: pageLimit,
                page
            }
        });
    }
};
