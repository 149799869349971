import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledDropdown = styled(Popper)`
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    z-index: 100;
`;

const Dropdown = ({ Button, dropdownOpened, dropdownClose, dropdownToggle, anchorRef, options, forceToggle }) => (
    <>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <Button.type
            {...Button.props}
            ref={anchorRef}
            id="composition-button"
            aria-controls={dropdownOpened ? 'composition-menu' : undefined}
            aria-expanded={dropdownOpened ? 'true' : undefined}
            aria-haspopup="true"
            onClick={options.length === 1 && !forceToggle ? options[0].action : dropdownToggle}
        />
        <StyledDropdown open={dropdownOpened} anchorEl={anchorRef.current} placement="bottom-start" transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={dropdownClose}>
                            <MenuList autoFocusItem={dropdownOpened} id="composition-menu" aria-labelledby="composition-button">
                                {options.map((option) => (
                                    <MenuItem key={option.text} onClick={option.action} sx={{ color: 'var(--main-text-color)' }}>
                                        {option.text}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </StyledDropdown>
    </>
);

export default Dropdown;
