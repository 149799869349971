import TocIcon from '@mui/icons-material/Toc';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import { TableBody } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import StyledTable from '@/components/listTable/components/styledTable';
import StyledTableSwitcher from '@/components/listTable/components/styledTableSwitcher';
import ListTableHeader from '@/components/listTable/header';
import { isMobileAndTablet } from '@/core/utils/utils';

const ListTable = ({ children, headers, colWidthList, verticalalign, setHeaders, checked, checkboxHandler }) => {
    const [tableView, setTableView] = useState('column'); // views: table, column
    const changeView = () => {
        setTableView((prev) => (prev === 'table' ? 'column' : 'table'));
    };

    const tableRef = useRef();

    useEffect(() => {
        const rows = tableRef.current.querySelectorAll('tr');
        rows.forEach((row) => {
            headers.forEach((header, i) => {
                row.querySelectorAll('td')?.[i]?.setAttribute('data-title', header.title);
            });
        });
    }, [children]);

    return (
        <div>
            <StyledTableSwitcher onClick={changeView}>{tableView === 'column' ? <TocIcon /> : <ViewAgendaIcon />}</StyledTableSwitcher>
            <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                <StyledTable
                    ref={tableRef}
                    aria-label="couriers list"
                    verticalalign={verticalalign}
                    columnsCount={headers.length}
                    className={isMobileAndTablet() ? tableView : ''}
                >
                    {colWidthList && (
                        <colgroup>
                            {colWidthList.map((width, i) => (
                                <col key={i} style={{ minWidth: width }} />
                            ))}
                        </colgroup>
                    )}
                    <ListTableHeader checked={checked} checkboxHandler={checkboxHandler} setHeaders={setHeaders} headers={headers} />
                    <TableBody>{children}</TableBody>
                </StyledTable>
            </div>
        </div>
    );
};

export default ListTable;
