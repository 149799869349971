import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    couriers: [],
    postcontrols: []
};

export const notificationsSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        addCourier: (state, { payload }) => {
            state.couriers.push(payload);
        },
        addPostControl: (state, { payload }) => {
            state.postcontrols.push(payload);
        },
        resetCouriersState: (state) => {
            state.couriers = [];
        },
        resetPostControlState: (state) => {
            state.postcontrols = [];
        }
    }
});

export const { addCourier, addPostControl, resetPostControlState, resetCouriersState } = notificationsSlice.actions;

export default notificationsSlice.reducer;
