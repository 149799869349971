import { Box, TextField } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { Controller } from 'react-hook-form';

import FieldLabel from '@/components/formField/fieldLabel';
import i18n from '@/core/i18n';

const { t } = i18n;
export const TextFieldCustom = styled(TextField)`
    width: 100%;
    & .MuiOutlinedInput-input {
        background-color: var(--input-bg);
        font-family: IBM Plex Sans, sans-serif;
        font-size: 15px;
        color: var(--main-text-color);
        border: ${({ error, success }) => `1px solid ${error ? 'red' : success}`};
        border-radius: 4px;
        &[disabled] {
            cursor: not-allowed;
        }
    }
    & .MuiOutlinedInput-root {
        background: var(--input-bg);
        border-radius: 4px;
        & .MuiOutlinedInput-notchedOutline {
            border: none;
        }
        &.Mui-focused fieldset {
            border-color: var(--border-color);
        }
    }
`;

const TextAreaInput = React.forwardRef(
    (
        {
            id,
            label,
            placeholder,
            errors,
            control,
            type,
            InputProps,
            ContainerProps,
            readonly,
            onClick,
            height = null,
            defaultValue = '',
            required,
            minLength = 10,
            disabled,
            ...props
        },
        ref
    ) => (
        <Controller
            name={id}
            {...props}
            control={control}
            defaultValue={defaultValue}
            innerRef={ref}
            rules={{ required: true }}
            render={({ field: { onChange, ref, value }, formState: { errors } }) => (
                <Box sx={{ position: 'relative' }} className="form_field_input" {...ContainerProps}>
                    <FieldLabel htmlFor={id} required={required}>
                        {label}
                    </FieldLabel>
                    <TextFieldCustom
                        aria-label="empty textarea"
                        placeholder={placeholder || t('common:placeholders.addComment')}
                        multiline
                        rows={5}
                        error={errors[id]}
                        success={value.length > minLength && !errors[id] ? '#49C172' : 'transparent'}
                        value={value}
                        ref={ref}
                        onChange={onChange}
                        disabled={disabled}
                    />
                </Box>
            )}
        />
    )
);

export default TextAreaInput;
