import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, ButtonBase, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { devices } from '@/core/utils/devices';
import PageLayout from '@/views/arm/components/pageLayout';

const StyledButton = styled(ButtonBase)`
    &:hover {
        color: var(--accent-color);
        transition: 0.3s;
    }
`;
const StyledHeader = styled(Stack)`
    padding-bottom: 40px;
    @media screen and ${devices.tablet} {
        padding-bottom: 20px;
        flex-direction: column;
        align-items: flex-start;
    }
`;
const StyledBreadcrumbs = styled(Stack)`
    margin-bottom: ${({ title }) => (title ? '30px' : 0)};
    color: var(--medium-text-color);
    @media screen and ${devices.tablet} {
        margin-bottom: ${({ title }) => (title ? '10px' : 0)};
    }
    & .breadcrumb_link {
        margin: 0 5px;
        display: inline-block;
    }
    & .breadcrumb_text {
        @media screen and ${devices.mobileS} {
            display: none;
        }
    }
`;
const StyledTitle = styled(Typography)`
    font-size: 35px;
    color: var(--title-color);
    font-weight: 700;
    @media screen and ${devices.tablet} {
        font-size: 25px;
    }
`;
const StyledButtonContainer = styled(Box)`
    min-width: 440px;
    @media screen and ${devices.tablet} {
        margin-top: 20px;
        min-width: auto;
    }
`;

const ArmListLayoutHeader = ({ buttons, title, subTitle = null, subtitleLink }) => {
    const navigate = useNavigate();
    return (
        <PageLayout.Header>
            <StyledHeader direction="row" justifyContent="space-between" alignItems="flex-end">
                <Box>
                    {subTitle && (
                        <StyledBreadcrumbs direction="row" alignItems="center" title={title}>
                            <StyledButton
                                onClick={() => {
                                    if (subtitleLink) {
                                        navigate(subtitleLink);
                                    } else {
                                        navigate(-1);
                                    }
                                }}
                            >
                                <KeyboardBackspaceIcon />
                                <Typography className="breadcrumb_link">{subTitle.split('/')[0]}</Typography>
                            </StyledButton>
                            <Typography className="breadcrumb_text"> / {subTitle.split('/')[1]} </Typography>
                        </StyledBreadcrumbs>
                    )}
                    <StyledTitle>{title}</StyledTitle>
                </Box>
                <StyledButtonContainer display="flex" justifyContent="flex-end">
                    <Box display="flex">{buttons}</Box>
                </StyledButtonContainer>
            </StyledHeader>
        </PageLayout.Header>
    );
};

export default ArmListLayoutHeader;
