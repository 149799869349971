import * as Sentry from '@sentry/react';
import axios from 'axios';

import reduxStore from '../toolkit/store';

import { serverUrl } from '@/core/utils/constants/constants';
import { refreshUser } from '@/toolkit/actionCreators/auth';
import { logout } from '@/toolkit/reducers/authSlice';

const baseURL = serverUrl;
// const baseURL = process.env.SERVER_URL;
// const baseURL = 'https://preprod.back.loggy.kz';
// const baseURL = 'https://back-saas.loggy.kz';
// const baseURL = 'http://localhost:5001';

const frontTokens = {
    dev: 'olkgj45oq9j3059yjq4yq35Yq4^q$^uW$5yW4JK75wEUY6QWtEjRU^&8EWyUhwETjWEyjW,tujRThwtyw45g',
    preprod: 'lkd509jhgE&J68E*LKEW58KIT8(;P068TRIEYHWETARGAESRGFAERGerghaetgawrfaeHtsrTEhWREgaer',
    prod: `qetHq$j6E*ik&9W5^YhQ#t5HGW5ki8ikT&98:P)I_'68t(&I8keYThwQEtrFQEDaFaerGtEtAHGw`
};

// eslint-disable-next-line import/prefer-default-export
export const userAxios = axios.create({
    baseURL,
    timeout: 90000, // большой тайм нужно запустить асинхронку на django Q
    headers: {
        accept: 'application/json',
        'x-front-token': frontTokens[process.env.REACT_APP_NODE_ENV],
        'Accept-Language': 'ru'
        // 'Content-Type': 'application/json'
    }
});

userAxios.interceptors.request.use((config) => {
    const token = reduxStore.getState().auth?.user?.access_token;
    if (token) {
        config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
    return config;
});

userAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (process.env.REACT_APP_NODE_ENV === 'prod' && error.response.status !== 401) {
            class AxiosException extends Error {
                constructor(error) {
                    super(error);
                    Object.assign(this, error);
                }
            }

            const sentryError = new AxiosException(error);
            sentryError.name = `${originalRequest.method}: ${error.name}`;

            const user = reduxStore.getState().auth?.user;
            Sentry.captureException(sentryError, {
                user: {
                    id: user?.data?.id,
                    username: user?.data?.phone_number,
                    organization: user?.organization?.name_ru,
                    role: user?.data?.profile?.profile_type,
                    response: JSON.stringify(error?.response?.data, null, 2),
                    responseUrl: error?.request?.responseURL,
                    payload: originalRequest.data ? JSON.stringify(JSON.parse(originalRequest.data), null, 2) : null
                },
                tags: {
                    exception: 'xhrError'
                }
            });
        }

        if (typeof error.response === 'undefined') {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({ error });
        }
        if (error.response.status === 401) {
            if (!originalRequest.url.includes('/token/refresh')) {
                const refreshToken = reduxStore.getState().auth?.user?.refresh_token;
                const profile = reduxStore.getState().auth?.user?.data?.profile;
                if (refreshToken && profile) {
                    try {
                        await reduxStore
                            .dispatch(refreshUser({ refreshToken, profile: { id: profile.id, type: profile.profile_type } }))
                            .unwrap();
                        return userAxios(originalRequest);
                    } catch (e) {
                        console.log(e);
                        reduxStore.dispatch(logout());
                    }
                }
            }

            reduxStore.dispatch(logout());
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ error, axiosInterceptError: true });
    }
);
