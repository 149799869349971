import { Button, Box, Typography, Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, useController } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import { MainParent, MainBody, MainHeader, MainBodyForm, InputForms, FinishInputForm } from '@/components/createRowTable';
import { tableServices } from '@/core/services/tableServices';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const valuePartners = [
    { name: 'bin_partner', label: 'БИН партнера', plc: 'Введите БИН', type: 'text', text: '12 цифр', pattern: /[0-9]{12}/ },
    { name: 'name_partner', label: 'Наименование партнера', plc: 'Введите наименование', type: 'text', text: 'Поле обязательное' },
    { name: 'art', label: 'Артикул', plc: 'Введите артикул', type: 'text', text: 'Поле обязательное' },
    {
        name: 'email',
        label: 'E-mail',
        plc: 'Введите e-mail',
        type: 'text',
        text: 'Введите email',
        pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
    },
    { name: 'logo', label: 'Логотип', plc: 'Введите коментарий', type: 'text', text: 'Поле обязательное' }
];
const sheffValue = [
    { name: 'last_name', label: 'Фамилия', plc: 'Введите фамилию', type: 'text', text: 'Поле обязательное' },
    { name: 'first_name', label: 'Имя', plc: 'Введите имя', type: 'text', text: 'Поле обязательное' },
    { name: 'second_name', label: 'Отчество', plc: 'Введите отчество', type: 'text', text: 'Поле обязательное' },
    // { name: 'iin', label: 'ИИН', plc: 'Введите ИИН', type: 'text', text: '12 цифр', pattern: /[0-9]{12}/ },
    { name: 'number', label: 'Номер телефона', plc: 'Введите номер телефона', type: 'text', text: 'Поле обязательное' }
];

const AddPartnerForm = ({ setNoPartner, setToggleMainForm }) => {
    const theme = useTheme();
    const [valueTab, setValueTab] = useState(0); // состояние Таба переключатель
    const [finishedFormsPartner, setFinishedFormsPartner] = useState(false); // сост(false); // после кнопки сохранить меняется состояние
    const [finishedFormsSheff, setFinishedFormsSheff] = useState(false); // сост(false); // после кнопки сохранить меняется состояние
    // const [stateFormPartner, setStateFormPartner] = useState();
    // const [stateFormSheff, setStateFormSheff] = useState();
    const createPartner = useMutation(['CreateNewPartner'], (res) => tableServices.createNewPartner(res));
    const queryClient = useQueryClient();
    const [managerId, setManagerId] = useState(3);

    const defaultValues = {
        bin_partner: '',
        name_partner: '',
        art: '',
        email: '',
        logo: ''
    };
    const defaultValues2 = {
        last_name: '',
        first_name: '',
        second_name: '',
        number: ''
    };
    const {
        errors,
        control,
        reset,
        getValues,
        formState: { isDirty, isValid }
    } = useForm({
        mode: 'onChange',
        defaultValues,
        reValidateMode: 'onChange'
    });

    const {
        errors: errors2,
        control: control2,
        getValues: getValues2,
        formState: { isDirty: isDirty2, isValid: isValid2 }
    } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues2,
        reValidateMode: 'onChange'
    });

    const onSubmit = (data) => {};

    const onSubmit2 = (data) => {
        setManagerId((prev) => prev + 1);
        const newRow = {
            name: getValues().name_partner,
            activity_name_ru: getValues().name_partner,
            address: 'City Street 10, house 30',
            affiliated: false,
            article: getValues().art,
            bin: getValues().bin_partner,
            is_commerce: true,
            consent_confirmed: true,
            is_government: false,
            is_international: false,
            manager_id: '1',
            leader: {
                iin: getValues().bin_partner,
                first_name: getValues2().first_name,
                middle_name: getValues2().last_name,
                second_name: getValues2().second_name,
                number: getValues2().number
            },
            email: getValues().email,
            name_ru: getValues().name_partner,
            registration_date: new Date().toISOString(),
            responsible_person: 'Person',
            courier_org_id: null
        };
        createPartner
            .mutateAsync(newRow)
            .then((response) => {
                queryClient.invalidateQueries('partners');
            })
            .catch((e) => console.log(e));
    };

    const tabValue = [
        {
            name: 'Данные партнера',
            type: 'docs'
        },
        {
            name: 'Руководитель',
            type: 'chamd'
        }
    ];

    return (
        <MainParent>
            <MainHeader
                type="twoText"
                text="Добавления партнера"
                textLower="Партнеры"
                isValid={isValid}
                isDirty={isDirty}
                onSubmit={onSubmit}
                onSubmit2={onSubmit2}
                isValid2={isValid2}
                isDirty2={isDirty2}
                setFinishedFormsPartner={setFinishedFormsPartner}
                setFinishedFormsSheff={setFinishedFormsSheff}
                finishedFormsPartner={finishedFormsPartner}
                finishedFormsSheff={finishedFormsSheff}
                valueTab={valueTab}
                setNoPartner={setNoPartner}
            />
            <MainBodyForm tabs={tabValue} valueTab={valueTab} setValueTab={setValueTab}>
                <TabPanel value={valueTab} index={0}>
                    {finishedFormsPartner ? (
                        <FinishInputForm stateForm={getValues()} text="Данные партнера" />
                    ) : (
                        <InputForms control={control} nameForm="Данные партнера" formValue={valuePartners} />
                    )}
                </TabPanel>
                <TabPanel value={valueTab} index={1}>
                    {finishedFormsSheff ? (
                        <FinishInputForm stateForm={getValues2()} text="Руководитель" />
                    ) : (
                        <InputForms control={control2} nameForm="Руководитель" formValue={sheffValue} />
                    )}
                </TabPanel>
            </MainBodyForm>
        </MainParent>
    );
};

export default AddPartnerForm;
