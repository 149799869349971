import { Grid, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';

import registerBg from '../../assets/images/auth/register-bg.jpg';

import { devices } from '@/core/utils/devices';
import { RegisterLeftIcon, RegisterLeftDescr, RegisterRight } from '@/pages/register/registerComponent';

const useStyles = makeStyles(() => ({
    leftContainer: {
        background: `url(${registerBg}) 50% 50% no-repeat`,
        backgroundSize: 'cover'
    },
    rightContainer: {
        backgroundColor: 'var(--page-bg-color)',
        width: '100%',
        padding: '0 15px'
    }
}));

function Register() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Регистрация';
    }, []);
    return (
        <Grid>
            <CssBaseline />
            <Grid container sx={{ minHeight: '100vh', minWidth: '100vw' }}>
                <Grid
                    item
                    xs={4}
                    className={`${classes.leftContainer} register_bg`}
                    sx={{
                        [`@media screen and ${devices.tablet}`]: {
                            display: 'none'
                        }
                    }}
                >
                    <Box style={{ margin: '10vh 0 0 5vw' }}>
                        <RegisterLeftIcon />
                        <RegisterLeftDescr />
                    </Box>
                </Grid>
                <Grid item md={8} className={`${classes.rightContainer} register_right`}>
                    <RegisterRight />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Register;
