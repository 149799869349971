import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React from 'react';

import { devices } from '@/core/utils/devices';

export const RoundedButton = styled(Button)`
    color: #fff;
    background: var(--accent-color);
    height: 40px;
    max-width: 450px;
    font-size: 14px;
    min-width: 200px;
    padding: 11px 30px;
    border-radius: 4px;
    box-shadow: none;
    font-weight: 600;
    text-transform: none;
    white-space: nowrap;
    @media screen and ${devices.tablet} {
        min-width: 140px;
        height: 35px;
        font-size: 12px;
        padding: 11px 20px;
    }
    &:hover {
        background: var(--accent-color);
    }
    &[disabled] {
        background: var(--accent-color);
        opacity: 0.3;
        color: #fff;
    }
`;
export const RoundedSecondaryButton = styled(RoundedButton)`
    background: transparent;
    color: #888;
    border: 1px solid #888;
    &:hover {
        background: #888;
        color: #fff !important;
    }
    &:disabled {
        background: transparent;
        color: #888;
        opacity: 0.3;
    }
`;
export const RoundedOutlinedButton = styled(RoundedButton)`
    background: transparent;
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
    &:hover {
        background: var(--accent-color);
        color: #fff;
    }
    &:disabled {
        background: transparent;
        color: var(--accent-color);
        opacity: 0.3;
    }
`;
export const TextPrimaryButton = styled(RoundedButton)`
    color: var(--accent-color);
    background: none;
    border: none;
    &:hover {
        color: #fff;
    }
`;
export const TextSecondaryButton = styled(TextPrimaryButton)`
    color: #6b778c;
    &:hover {
        background: #6b778c;
        color: #fff;
    }
`;
export const DangerOutlinedButton = styled(RoundedOutlinedButton)`
    border-color: #ed5d5d;
    color: #ed5d5d;
    &:hover {
        background: #ed5d5d;
        color: #fff;
    }
`;

export const ButtonWithLoader = ({ children, loading, disabled, ...props }) => (
    <Box display="inline-block" position="relative">
        <RoundedButton color="success" variant="contained" disabled={disabled || loading} {...props}>
            {children}
        </RoundedButton>
        {loading ? (
            <CircularProgress
                size={24}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                }}
            />
        ) : null}
    </Box>
);

const StyledAddIcon = styled(AddIcon)`
    position: relative;
    right: 20px;
    @media screen and ${devices.tablet} {
        right: 10px;
    }
`;

export const RoundedAddButton = React.forwardRef((props, ref) => (
    <RoundedButton {...props} ref={ref} startIcon={<StyledAddIcon />} variant="contained">
        {props.children}
    </RoundedButton>
));
