import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { TextSecondaryButton } from '@/components/button/styledButtons';
import { devices } from '@/core/utils/devices';

const StyledBlock = styled(Box)`
    width: 100%;
    margin-bottom: 30px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    @media screen and ${devices.tablet} {
        margin-bottom: 15px;
    }
    & .DetailsInfoBlock_wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
    }
    & h3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 20px;
        font-weight: 600;
        font-size: 16px;
        color: var(--title-color);
        background: rgba(99, 153, 214, 0.08);
        @media screen and ${devices.tablet} {
            padding: 11px 15px;
        }
    }
`;
const StyledChild = styled(Box)`
    width: ${({ width }) => width || '350px'};
    margin-bottom: 30px;
    padding-right: 30px;
    @media (max-width: 1840px) {
        width: ${({ width }) => width || '225px'};
    }
    @media screen and ${devices.tablet} {
        width: 50%;
        padding-right: 0;
        margin-bottom: 20px;
    }
    @media screen and ${devices.mobileM} {
        width: 100%;
    }
    & p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: var(--main-text-color);
    }
`;
const StyledLabel = styled('span')`
    display: block;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--medium-text-color);
    @media screen and ${devices.tablet} {
        margin-bottom: 5px;
    }
`;

const DetailsInfoBlock = ({ children, title, sx, innerSx, visible = true, button = null }) =>
    visible ? (
        <StyledBlock sx={sx}>
            <Typography variant="h3">
                {title}
                {button}
            </Typography>
            <Box sx={innerSx} className="DetailsInfoBlock_wrapper">
                {children}
            </Box>
        </StyledBlock>
    ) : null;
DetailsInfoBlock.Child = ({ label, info, sx, width, sxInfo, sxLabel, visible = true }) =>
    visible ? (
        <StyledChild sx={sx} width={width}>
            <StyledLabel variant="span" sx={sxLabel}>
                {label}
            </StyledLabel>
            <Typography sx={sxInfo}>{info}</Typography>
        </StyledChild>
    ) : null;

const StyledCustomChild = styled(Box)`
    width: ${({ width }) => width || '320px'};
    margin-bottom: 30px;
    margin-right: 30px;
    & .custom_child_label {
        display: block;
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: var(--medium-text-color);
    }
    &:last-of-type {
        margin-right: 0;
        margin-bottom: 0;
    }
    @media screen and ${devices.mobileL} {
        margin-bottom: 15px;
        margin-right: 0;
        max-width: 100%;
    }
    @media screen and ${devices.mobileM} {
        width: 100%;
    }
`;

DetailsInfoBlock.CustomChild = ({ children, label, visible = true, ...props }) =>
    visible ? (
        <>
            <StyledCustomChild {...props}>
                {label && (
                    <Typography className="custom_child_label" variant="span">
                        {label}
                    </Typography>
                )}
                {children}
            </StyledCustomChild>
        </>
    ) : null;

export default DetailsInfoBlock;
