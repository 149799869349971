import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, InputLabel, MenuItem, FormControl, Select, Checkbox, TextField, Tooltip, Radio } from '@mui/material';
import React from 'react';

function FormCustomerRequisites(props) {
    const { requisiteState, requisiteHandlers, requisiteErrors } = props;
    const { requisiteList, translationTypeOfProps, translationBank } = requisiteState;

    const deleteTitle = `Выберите флаж(ок)ки`;

    return (
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2} sx={{ mt: 1 }}>
            <Box gridColumn="span 12" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="contained" startIcon={<CreateNewFolderIcon />} onClick={requisiteHandlers.handleAddRequisite}>
                    Создать
                </Button>

                <Tooltip title={deleteTitle}>
                    <Box>
                        <Button variant="contained" startIcon={<DeleteIcon />} onClick={requisiteHandlers.handleRemoveRows}>
                            Удалить
                        </Button>
                    </Box>
                </Tooltip>
            </Box>
            <Box
                gridColumn="span 12"
                component="form"
                sx={{
                    '& .MuiTextField-root': { mr: 5, mt: 2, mb: 2, width: '38ch' }
                }}
                noValidate
                autoComplete="off"
            >
                {requisiteList.map((requisite, index) => (
                    <div key={requisite.id}>
                        <div>
                            <FormControl className="MuiTextField-root" style={{ width: '2ch' }}>
                                <Checkbox
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    checked={requisite.checked}
                                    onChange={requisiteHandlers.handleCheckbox.bind(this, index)}
                                />
                            </FormControl>
                            <FormControl className="MuiTextField-root">
                                <InputLabel id={`select-type-of-props-${requisite.id}`}>Вид реквизита</InputLabel>
                                <Select
                                    disabled={!requisite.checked}
                                    labelId={`select-type-of-props-${requisite.id}`}
                                    id={`select-type-of-props-${requisite.id}`}
                                    value={requisite.typeOfProps}
                                    label="Вид реквизита"
                                    onChange={requisiteHandlers.handleTypeOfProps.bind(this, index)}
                                >
                                    {translationTypeOfProps.map((item) => (
                                        <MenuItem key={item.id} value={item.name}>
                                            {item.translate}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                disabled={!requisite.checked}
                                id={`customer-prop-value-${requisite.id}`}
                                label="Значение"
                                value={requisite.propsValue}
                                onChange={requisiteHandlers.handlePropsValue.bind(this, index)}
                            />

                            <FormControl sx={{ mt: 2, flexDirection: 'row', alignItems: 'center' }}>
                                <Radio
                                    disabled={!requisite.checked}
                                    {...requisiteHandlers.controlBasicRequisite(index)}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 28
                                        }
                                    }}
                                />
                                {requisiteHandlers.controlBasicRequisite(index).checked && 'По умолчанию'}
                            </FormControl>
                        </div>
                        <div style={{ marginLeft: '55px' }}>
                            <FormControl className="MuiTextField-root">
                                <InputLabel id={`select-bank-${requisite.id}`}>Банк</InputLabel>
                                <Select
                                    disabled={!requisite.checked}
                                    labelId={`select-bank-${requisite.id}`}
                                    id={`select-bank-${requisite.id}`}
                                    value={requisite.bank}
                                    label="Банк"
                                    onChange={requisiteHandlers.handleBank.bind(this, index)}
                                >
                                    {translationBank.map((item) => (
                                        <MenuItem key={item.id} value={item.name}>
                                            {item.translate}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                style={{ width: '58ch' }}
                                disabled={!requisite.checked}
                                id={`customer-note-${requisite.id}`}
                                label="Примечание"
                                value={requisite.note}
                                onChange={requisiteHandlers.handleNote.bind(this, index)}
                            />
                        </div>
                    </div>
                ))}
            </Box>
        </Box>
    );
}

export default FormCustomerRequisites;
