import { useState } from 'react';

const useAutocomplete = (names, setValue, trigger) => {
    const [latlon, setLatlon] = useState({});
    const [mapOpened, setMapOpened] = useState(false);

    const onSelectAddress = async ({ latitude, longitude, address }) => {
        setLatlon({ lat: latitude, lon: longitude });
        names.forEach((name) => {
            setValue(name, address);
        });
        trigger();
    };
    const openMap = () => {
        setMapOpened(true);
    };
    const selectAddressOnMap = async (address, point) => {
        setLatlon(point);
        setMapOpened(false);
        names.forEach((name) => {
            setValue(name, address);
        });
        trigger();
    };

    return { latlon, setLatlon, mapOpened, setMapOpened, onSelectAddress, openMap, selectAddressOnMap };
};

export default useAutocomplete;
