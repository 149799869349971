import { Box, ListSubheader, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { Controller } from 'react-hook-form';
import { ReactSVG } from 'react-svg';

import ArrowDown from '@/assets/images/icons/chevron-down.svg';
import FieldLabel from '@/components/formField/fieldLabel';
import StyledSelect from '@/components/formField/styledSelect';

const StyledSubheader = styled(ListSubheader)`
    padding: 0;
    line-height: 2;
    background: transparent;
    color: var(--main-text-color);
    opacity: 0.5;
    padding-left: 5px;
`;
const StyledOption = styled(MenuItem)`
    padding: 6px 15px;
    cursor: pointer;
    background: var(--paper-bg);
    color: var(--main-text-color);
    & label {
        display: block;
        width: 100%;
        font-weight: 400;
        cursor: pointer;
    }
    &:hover {
        background: var(--select-focused-option);
    }
    &:last-of-type {
        border-bottom: none;
    }
`;

const SelectInput = React.forwardRef(
    ({
        id,
        label,
        placeholder,
        defaultValue = '',
        options,
        errors,
        control,
        SelectProps,
        required,
        successColor = null,
        placeholderDisabled = true,
        ContainerProps,
        ...props
    }) => {
        props.disabledText = props.disabled ? props.disabledText : '';
        return (
            <Box style={{ position: 'relative' }} className="form_field_select" sx={ContainerProps}>
                <Controller
                    control={control}
                    {...props}
                    render={({ field }) => (
                        <>
                            {label && (
                                <FieldLabel htmlFor={id} required={required}>
                                    {label}
                                </FieldLabel>
                            )}
                            <StyledSelect
                                {...field}
                                {...props}
                                name={id}
                                displayEmpty
                                disabled={props.disabled}
                                success={field.value && !errors[id] ? '#49C172' : 'transparent'}
                                error={errors[id]}
                                defaultValue={defaultValue}
                                required={required}
                                MenuProps={{
                                    PaperProps: {
                                        sx: { background: 'var(--paper-bg)', maxHeight: '250px' }
                                    }
                                }}
                                IconComponent={({ className, ...rest }) => (
                                    <ReactSVG src={ArrowDown} {...rest} className={[className, 'select_icon'].join(' ')} />
                                )}
                            >
                                {props.disabledText || placeholder ? (
                                    <StyledOption value="">{props.disabledText || placeholder}</StyledOption>
                                ) : null}
                                {props.grouped
                                    ? Object.keys(options || {})
                                          .map((key) => [
                                              <StyledSubheader key={key}>{key}</StyledSubheader>,
                                              options?.[key]?.map((option) => (
                                                  <StyledOption key={option.id} value={option.id} role="option">
                                                      {option.name}
                                                  </StyledOption>
                                              ))
                                          ])
                                          .flat()
                                    : options?.map((option) => (
                                          <StyledOption key={option.id} value={option.id}>
                                              {option.name}
                                          </StyledOption>
                                      ))}
                            </StyledSelect>
                            <Typography
                                className="required_error_message"
                                sx={{
                                    color: '#FF6464',
                                    position: 'absolute',
                                    bottom: '-19px',
                                    transition: '0.2s',
                                    left: 0,
                                    opacity: errors[id] ? 1 : 0
                                }}
                            >
                                {errors[id]?.message}
                            </Typography>
                        </>
                    )}
                />
            </Box>
        );
    }
);

export default SelectInput;
