import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { devices } from '@/core/utils/devices';

const StyledPageLayout = styled(Box)`
    max-height: 100vh;
    background-color: var(--page-bg-color);
    padding: 36px 42px;
    @media screen and ${devices.tablet} {
        padding: 15px;
    }
`;

const PageLayout = ({ children }) => <StyledPageLayout>{children}</StyledPageLayout>;

const Header = ({ children }) => (
    <Box
        style={{
            minHeight: 60,
            backgroundColor: 'var(--page-bg-color)',
            zIndex: 1000
        }}
    >
        {children}
    </Box>
);
const Content = ({ children }) => <Box pb="15px">{children}</Box>;

PageLayout.Header = Header;
PageLayout.Content = Content;

export default PageLayout;
