import { Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { ReactSVG } from 'react-svg';

import CheckboxCheckedIcon from '@/assets/images/icons/checkbox-checked.svg';
import CheckboxIcon from '@/assets/images/icons/checkbox.svg';

export const StyledLabel = styled('label')`
    cursor: pointer;
    color: var(--main-text-color);
`;
export const SxCheckbox = styled(Checkbox)`
    border-radius: 4px;
    padding: 0;
    height: 24px;
    width: 24px;
    margin-right: 3px;
    & svg {
        position: relative;
        top: 1px;
    }
    color: #8e8e93;
    &.Mui-checked {
        color: var(--accent-color);
    }
`;

const StyledCheckbox = ({ onChange, id, checked, label, ...props }) => (
    <>
        <SxCheckbox
            icon={<ReactSVG src={CheckboxIcon} />}
            checkedIcon={<ReactSVG src={CheckboxCheckedIcon} />}
            checked={checked}
            onChange={onChange}
            id={id}
            {...props}
        />
        {label ? <StyledLabel htmlFor={id}>{label}</StyledLabel> : null}
    </>
);

export default StyledCheckbox;
