import { Typography } from '@mui/material';
import React from 'react';
import { ReactSVG } from 'react-svg';

import logo from '@/assets/images/logoCircle.svg';
import { feedbackMap } from '@/pages/clientFeedback/utils';

const RatingHeading = ({ rating, productName, isVisible }) =>
    isVisible && (
        <>
            {/* <ReactSVG src={logo} style={{ marginBottom: '20px' }} /> */}
            <Typography sx={{ fontSize: 16, fontWeight: 600, mb: '30px' }} variant="h2">
                {productName}
            </Typography>
            <Typography sx={{ fontSize: 20, fontWeight: 600, mb: '10px' }} variant="h1">
                {feedbackMap.get(rating).title}
            </Typography>
            <Typography>{feedbackMap.get(rating).text}</Typography>
        </>
    );

export default RatingHeading;
