import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, InputLabel, MenuItem, FormControl, Select, Checkbox, TextField, Tooltip, Radio } from '@mui/material';
import React from 'react';

function FormCustomerContacts(props) {
    const { contactState, contactHandlers, contactErrors } = props;
    const { contactList, translationOfContactTypes } = contactState;

    const deleteTitle = `Выберите флаж(ок)ки`;

    return (
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2} sx={{ mt: 1 }}>
            <Box gridColumn="span 12" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="contained" startIcon={<CreateNewFolderIcon />} onClick={contactHandlers.handleAddContact}>
                    Создать
                </Button>

                <Tooltip title={deleteTitle}>
                    <Box>
                        <Button variant="contained" startIcon={<DeleteIcon />} onClick={contactHandlers.handleRemoveRows}>
                            Удалить
                        </Button>
                    </Box>
                </Tooltip>
            </Box>
            <Box
                gridColumn="span 12"
                component="form"
                sx={{
                    '& .MuiTextField-root': { mr: 5, mt: 2, mb: 2, width: '38ch' }
                }}
                noValidate
                autoComplete="off"
            >
                {contactList.map((contact, index) => (
                    <div key={contact.id}>
                        <FormControl className="MuiTextField-root" style={{ width: '2ch' }}>
                            <Checkbox
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                checked={contact.checked}
                                onChange={contactHandlers.handleCheckbox.bind(this, index)}
                            />
                        </FormControl>
                        <FormControl className="MuiTextField-root">
                            <InputLabel id={`select-legal-form-${contact.id}`}>Тип контакта</InputLabel>
                            <Select
                                disabled={!contact.checked}
                                labelId={`select-legal-form-${contact.id}`}
                                id={`select-legal-form-${contact.id}`}
                                value={contact.typeContact}
                                label="Тип контакта"
                                onChange={contactHandlers.handleLegalform.bind(this, index)}
                            >
                                {translationOfContactTypes.map((item) => (
                                    <MenuItem key={item.id} value={item.name}>
                                        {item.translate}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            disabled={!contact.checked}
                            id={`customer-phone-number-${contact.id}`}
                            label="Номер телефона"
                            value={contact.phoneNumber}
                            onChange={contactHandlers.handlePhoneNumber.bind(this, index)}
                        />
                        <FormControl sx={{ mt: 2, flexDirection: 'row', alignItems: 'center' }}>
                            <Radio
                                disabled={!contact.checked}
                                {...contactHandlers.controlBasicContact(index)}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 28
                                    }
                                }}
                            />
                            {contactHandlers.controlBasicContact(index).checked && 'Основной'}
                        </FormControl>
                    </div>
                ))}
            </Box>
        </Box>
    );
}

export default FormCustomerContacts;
