import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Typography, Modal, Stack, IconButton, InputLabel, Link, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import loaderIcon from '@/assets/images/icons/loader.svg';
import UncontrolledSelect from '@/components/formField/uncontrolledSelect';
import i18n from '@/core/i18n';
import { authServices } from '@/core/services/authServices';
import { COURIER, PROFILE_TYPES } from '@/core/utils/constants/constants';
import { InputCode } from '@/pages/register/registerComponent/registerStepsComponent';
import { fetchCurrentUser, login } from '@/toolkit/actionCreators/auth';

const { t } = i18n;

const grey = {
    50: '#F3F6F9',
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    700: '#3E5060',
    800: '#2D3843',
    900: '#1A2027'
};

const StyledInputElement = styled('input')(
    ({ theme, success }) => `
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background: #F1F1F1;
  border: 1px solid ${success};
  border-radius: 14px;
  padding: 12px 12px;
  margin-top: 0 !important;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${success};
  }

  &:focus {
    outline: 1px solid rgba(0, 0, 0, 0.5);
  }
`
);

const CustomButton = styled(Button)({
    width: '100%',
    backgroundColor: '#49C172',
    borderRadius: '20px',
    height: '45px',
    textDecoration: 'none',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#49C172',
        color: 'white',
        opacity: '0.5'
    },
    '&:disabled': {
        color: 'white',
        opacity: 0.5
    }
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 450,
    bgcolor: 'background.paper',
    // border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
    '& .MuiBackdrop-root': { 'backdrop-filter': 'blur(10px)' }
};

const defaultValues = {
    number: ''
};

const Loader = styled(Box)`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8) url(${loaderIcon}) 50% 50% no-repeat;
    z-index: 1000;
`;

const AuthorizateModal2 = ({ open, handleClose }) => {
    const dispatch = useDispatch();
    const [flag, setFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [codeSms, setCodeSms] = useState();
    const [seconds, setSeconds] = useState(45);
    const [err, setErr] = useState(false);
    const [code, setCode] = useState([...Array(4)].map(() => ''));
    const [errNumber, setErrNumber] = useState();
    const [profiles, setProfiles] = useState();
    const [selectedProfile, setSelectedProfile] = useState();
    const navigate = useNavigate();

    const schema = yup.object({
        number: yup.string().required(t('errors:required'))
        // .matches(PHONE_REGEX, t('errors:incorrectPhone'))
    });

    const {
        control,
        handleSubmit,
        getValues,
        reset,
        watch,
        formState: { errors, isDirty, isValid }
    } = useForm({ mode: 'onChange', reValidateMode: 'onSubmit', defaultValues, resolver: yupResolver(schema) });

    const handleAutorizeOTP = useMutation(['AutorizeOTP'], (res) => authServices.registerOTP(res));
    const handleRegisterOTPValidate = useMutation(['RegisterOTPValidate'], (res) => authServices.registerOTPValidate(res));

    const onSubmit = async () => {
        const result = { phone_number: getValues().number.replaceAll(/[() -]/g, ''), type: 'auth' };
        try {
            await handleAutorizeOTP.mutateAsync(result);
            setFlag(true);
        } catch (e) {
            const msg = e.error.response.data.detail[0].msg;
            const msgNotReg = e.error.response.data.detail;
            if (msg || msgNotReg === 'Phone number is not valid') {
                setErrNumber(t('errors:incorrectPhone'));
            } else if (msg || msgNotReg === 'The phone number does not registered') {
                setErrNumber(t('errors:phoneDoesNotExists'));
            }
        }
    };

    const onProfileSelected = async (profile) => {
        setLoading(true);
        try {
            await dispatch(login({ username: getValues().number.replaceAll(/[() -]/g, ''), password: codeSms, profile })).unwrap();
            await dispatch(fetchCurrentUser()).unwrap();
            navigate('/arm/orders');
        } catch (e) {
            setErr(true);
        }
        setLoading(false);
    };

    const sendSms = async () => {
        if (codeSms?.length === 4) {
            const result = { phone_number: getValues().number.replaceAll(/[() -]/g, ''), otp: codeSms, agree: false };

            try {
                await handleRegisterOTPValidate.mutateAsync(result);
                const response = await dispatch(
                    login({ username: getValues().number.replaceAll(/[() -]/g, ''), password: codeSms })
                ).unwrap();
                setProfiles(response.profiles.filter((p) => p.type !== COURIER));
                if (
                    response.profiles.length === 1 ||
                    (response.profiles.length === 2 && response.profiles.some((p) => p.type === COURIER))
                ) {
                    await onProfileSelected(response.profiles.find((p) => p.type !== COURIER));
                }
            } catch (e) {
                setErr(true);
            }
        }
    };

    useEffect(() => {
        if (profiles) {
            if (profiles.length === 1) setSelectedProfile(profiles[0]);
            if (profiles.length === 2 && profiles.some((p) => p.type === COURIER)) {
                setSelectedProfile(profiles.find((p) => p.type !== COURIER));
            }
        }
    }, [profiles]);

    const onLogin = async () => {
        if (!flag) {
            await onSubmit();
            return null;
        }
        if (!profiles) {
            await sendSms();
            return null;
        }
        if (selectedProfile) {
            await onProfileSelected(selectedProfile);
            return null;
        }
        return null;
    };

    const closeModal = () => {
        setFlag();
        handleClose();
        reset({
            number: ''
        });
        setErrNumber();
    };

    useEffect(() => {
        let myInterval = null;
        if (flag) {
            myInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    clearInterval(myInterval);
                }
            }, 1000);
        }
        return () => {
            clearInterval(myInterval);
        };
    });

    useEffect(() => {
        if (watch().number.length < 18) {
            setErrNumber();
        }
    }, [watch().number]);

    let buttonText = t('buttons:getSmsCode');

    if (flag) buttonText = t('buttons:accept');
    if (profiles) buttonText = t('buttons:login');

    return (
        <Modal open={open} onClose={closeModal}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="column" spacing={3} sx={style}>
                    {(handleRegisterOTPValidate.isLoading || handleAutorizeOTP.isLoading) && (
                        <LinearProgress color="success" style={{ position: 'absolute', top: 0, width: '100%', left: 0, height: '6px' }} />
                    )}
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography sx={{ fontSize: 20, fontWeight: 900, color: 'black' }}>{t('modals:authorizateModal.login')}</Typography>
                        <IconButton onClick={() => closeModal()}>
                            <ClearIcon sx={{ color: 'black' }} />
                        </IconButton>
                    </Stack>
                    <Stack direction="column">
                        <TextInput
                            id="number"
                            label={t('modals:authorizateModal.labels.phoneNumber')}
                            control={control}
                            errors={errors}
                            placeholder="+7 (  )  -  -"
                            mask="+7 (999) 999-99-99"
                            errNumber={errNumber}
                        />
                    </Stack>

                    {profiles?.length > 1 && (
                        <Stack direction="column">
                            <UncontrolledSelect
                                id="number"
                                label={t('modals:authorizateModal.labels.accountTypeForLogin')}
                                options={profiles?.map((p) => ({ id: p.type, name: PROFILE_TYPES[p.type] }))}
                                value={selectedProfile?.type}
                                onChange={(e) => {
                                    setSelectedProfile(profiles?.find((p) => p.type === e.target.value));
                                }}
                            />
                        </Stack>
                    )}
                    {flag && !profiles && (
                        <Box className="input_box">
                            <Box>
                                <InputLabel sx={{ pl: 0.5, pb: 0.5 }}>{t('modals:authorizateModal.enterCodeFromSms')}</InputLabel>
                                <InputCode
                                    length={4}
                                    loading={loading}
                                    onComplete={() => {
                                        setTimeout(() => setLoading(false), 1);
                                    }}
                                    setCodeSms={setCodeSms}
                                    error={err}
                                    name={err && t('modals:authorizateModal.wrongCode')}
                                    code={code}
                                    setCode={setCode}
                                />
                            </Box>
                            <Box sx={{ pl: 0.5, pt: 1 }}>
                                {seconds > 0 && !err ? (
                                    <Typography sx={{ fontSize: 16, color: 'black', fontWeight: 300, letterSpacing: 0 }}>
                                        {t('modals:authorizateModal.resendCodeAfter')}:{' '}
                                        <span style={{ fontWeight: 'bold' }}>00:{seconds < 10 ? `0${seconds}` : seconds}</span>
                                    </Typography>
                                ) : (
                                    <Typography variant="h4" sx={{ pt: 1 }}>
                                        <Link
                                            sx={{ color: '#49C172', textDecoration: 'none', cursor: 'hover' }}
                                            onClick={() => {
                                                setCodeSms();
                                                setErr(false);
                                                setSeconds(45);
                                                setCode([...Array(4)].map(() => ''));
                                            }}
                                        >
                                            {t('modals:authorizateModal.resendCode')}
                                        </Link>
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )}
                    <CustomButton disabled={!flag ? !isDirty || !isValid : codeSms?.length !== 4} onClick={onLogin}>
                        {buttonText}
                    </CustomButton>
                </Stack>
                {loading && <Loader />}
            </form>
        </Modal>
    );
};

const TextInput = ({ id, label, placeholder, control, mask, errNumber }) => (
    <Controller
        name={id}
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, ref, value } }) => (
            <>
                <InputLabel id={`${id}-label`}>{label}</InputLabel>
                <InputMask mask={mask} maskChar="" onChange={onChange} value={value}>
                    {() => (
                        <StyledInputElement
                            type="text"
                            placeholder={placeholder}
                            ref={ref}
                            success={value.indexOf('_') === -1 && value.length > 4 ? '#49C172' : '#F1F1F1'}
                        />
                    )}
                </InputMask>
                <Typography sx={{ color: '#FF6464', marginTop: '6px !important' }}>{errNumber}</Typography>
                {/* <TextField type="text" placeholder={placeholder} onChange={onChange} ref={ref} value={value} /> */}
            </>
        )}
    />
);
export default AuthorizateModal2;
