import { Box, ListSubheader, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

import ArrowDown from '@/assets/images/icons/chevron-down.svg';
import { RoundedButton } from '@/components/button/styledButtons';
import StyledCheckbox from '@/components/checkbox';
import FieldLabel from '@/components/formField/fieldLabel';
import StyledSelect from '@/components/formField/styledSelect';
import i18n from '@/core/i18n';

const { t } = i18n;

const StyledOption = styled(MenuItem)`
    padding: 0;
    cursor: pointer;
    background: var(--paper-bg);
    color: var(--main-text-color);
    & label {
        display: block;
        width: 100%;
        padding: 6px 15px;
        font-weight: 400;
        cursor: pointer;
    }
    &:hover {
        background: var(--select-focused-option);
    }
    &:last-of-type {
        border-bottom: none;
    }
`;
const StyledBadge = styled(Box)`
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background: var(--accent-color);
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    border-radius: 4px;
`;

const SelectWithCheckboxes = ({
    onSelect,
    listSelected,
    withButton = false,
    options = [],
    onDelete,
    grouped,
    withSelectAll,
    setListSelected,
    ContainerStyles,
    disabled,
    disabledText,
    ...props
}) => {
    const [allSelected, setAllSelected] = useState(false);
    const [openStyledSelect, setOpenStyledSelect] = useState(false);
    const selectRef = useRef();
    return (
        <Box className="checkbox_select" sx={ContainerStyles}>
            {!!props.label && (
                <FieldLabel htmlFor={props.id} required={props.required}>
                    {props.label}
                </FieldLabel>
            )}
            <Box position="relative">
                <StyledSelect
                    ref={selectRef}
                    open={openStyledSelect}
                    onOpen={() => setOpenStyledSelect(true)}
                    onClose={() => setOpenStyledSelect(false)}
                    MenuProps={{
                        PaperProps: {
                            sx: { background: 'var(--paper-bg)', maxHeight: '250px', '& ul': withButton ? { pb: 0 } : null }
                        }
                    }}
                    disabled={disabled}
                    renderValue={(selected) => {
                        if (disabled) return disabledText;
                        if (props.placeholder && !selected.length) return props.placeholder;
                        if (grouped) {
                            const optionsArray = Object.keys(options).reduce((acc, opt) => acc.concat(options[opt]), []);
                            if (selected.length === 1) {
                                return optionsArray.find((option) => option?.id === selected[0])?.name;
                            }
                            if (selected.length > 1) {
                                return t('components:formField.selected');
                            }
                            return '';
                        }
                        if (selected.length === 1) {
                            return options.find((option) => option?.id === selected[0])?.name;
                        }
                        if (selected.length > 1) {
                            return t('components:formField.selected');
                        }
                        return '';
                    }}
                    multiple
                    value={listSelected}
                    displayEmpty
                    defaultValue={props.placeholder}
                    {...props}
                    IconComponent={({ className, ...rest }) => (
                        <ReactSVG src={ArrowDown} {...rest} className={[className, 'select_icon'].join(' ')} />
                    )}
                    label=""
                >
                    {grouped ? (
                        Object.keys(options).map((opt) => {
                            if (!options[opt]?.length) return null;
                            return (
                                <Box key={opt}>
                                    <ListSubheader sx={{ backgroundColor: 'var(--paper-bg)', color: 'var(--main-text-color)' }}>
                                        {opt}
                                    </ListSubheader>
                                    {options[opt].map((option) => (
                                        <StyledOption key={option.id}>
                                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                            <label htmlFor={option.id}>
                                                <StyledCheckbox
                                                    checked={listSelected.includes(option.id)}
                                                    onChange={() => {
                                                        onSelect(option.id);
                                                    }}
                                                    id={option.id}
                                                />
                                                {option.name}
                                            </label>
                                        </StyledOption>
                                    ))}
                                </Box>
                            );
                        })
                    ) : (
                        <Box>
                            {withSelectAll && (
                                <StyledOption>
                                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                    <label>
                                        <StyledCheckbox
                                            checked={allSelected}
                                            onChange={() => {
                                                setAllSelected(!allSelected);
                                                if (allSelected) {
                                                    setListSelected([]);
                                                } else {
                                                    options.forEach((option) => {
                                                        if (!listSelected.includes(option.id)) onSelect(option.id);
                                                    });
                                                }
                                            }}
                                        />
                                        {t('components:formField.labels.selectAll')}
                                    </label>
                                </StyledOption>
                            )}
                            {options.map((option) => (
                                <StyledOption key={option.id}>
                                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                    <label htmlFor={option.id}>
                                        <StyledCheckbox
                                            checked={listSelected.includes(option.id)}
                                            onChange={() => {
                                                onSelect(option.id);
                                            }}
                                            id={option.id}
                                        />
                                        {option.name}
                                    </label>
                                </StyledOption>
                            ))}
                        </Box>
                    )}
                    {withButton && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                position: 'sticky',
                                bottom: 0,
                                width: '100%',
                                backgroundColor: 'var(--paper-bg)'
                            }}
                        >
                            <RoundedButton sx={{ minWidth: '100px', mr: '16px', mb: '16px' }} onClick={() => setOpenStyledSelect(false)}>
                                {t('buttons:ok')}
                            </RoundedButton>
                        </Box>
                    )}
                </StyledSelect>
                {listSelected?.length > 1 ? <StyledBadge className="select_badge">{listSelected?.length}</StyledBadge> : null}
            </Box>
            <Typography style={{ color: 'red' }}>{props.errors?.[props.id] ? props.errors[props.id]?.message : null}</Typography>
        </Box>
    );
};

export default SelectWithCheckboxes;
