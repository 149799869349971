export const ORDER_LIST = 'orders';
export const ORDER_INFO = 'order';
export const ORDER_BY_ID = 'order{0}';
export const COURIER_ORDERS = 'courierOrder-{0}';

export const POST_CONTROL_LIST = 'postControl';

export const PRODUCTS_LIST = 'products';
export const PRODUCT_INFO = 'productInfo';
export const COURIERS_LIST = 'couriers';
export const COURIERS_LIST_SHORT = 'couriersShort';
export const COURIERS_INVITED = 'couriersInvited';
export const PARTNERS_LIST = 'partners';
export const SHIPMENT_POINTS = 'shipment_points';
export const SHIPMENT_POINT = 'shipment_point';
export const PARTNER_INFO = 'partnerInfo';
export const CITY_LIST = 'cities';
export const REGION_LIST = 'regions';
export const COUNTRY_LIST = 'countries';
export const DELIVERY_GRAPHS = 'deliveryGraphs';

export const USER_LIST = 'users';
export const USER_INFO = 'userInfo';

export const PARTNER_MANAGER_LIST = 'partnerManagers';
export const DISPATCHER_LIST = 'dispatchers';
export const SERVICE_MANAGER_LIST = 'serviceManagers';

export const PLACE_INFO = 'placeInfo';

export const DELIVERY_ZONES_LIST = 'deliveryZones-{0}';

export const STATUS_LIST = 'status_list';
