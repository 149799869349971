import { Table } from '@mui/material';
import { styled } from '@mui/material/styles';

import i18n from '@/core/i18n';
import { devices } from '@/core/utils/devices';

const { t } = i18n;

const StyledTable = styled(Table)`
    position: relative;
    width: 100%;
    overflow-x: auto;
    &::before {
        content: ' ';
        position: absolute;
        left: 50%;
        bottom: 0;
        display: block;
        width: calc(100% - 30px);
        border-bottom: 1px solid var(--border-color);
        transform: translateX(-50%);
    }
    &::after {
        line-height: 1em;
        content: '\\200C';
        display: block;
    }
    & thead {
        position: relative;
        &::after {
            content: ' ';
            position: absolute;
            left: 50%;
            bottom: 0;
            display: block;
            width: calc(100% - 30px);
            border-bottom: 1px solid var(--border-color);
            transform: translateX(-50%);
        }
    }
    & th {
        font-size: 12px;
        font-weight: 600;
        color: var(--main-text-color);
        border: none;
        white-space: nowrap;
        padding: 20px 15px;
        vertical-align: middle;
        @media screen and ${devices.tablet} {
            padding: 5px 12px;
        }
    }
    & tbody {
        & td {
            border: none;
            background: var(--page-bg-color);
            font-size: 14px;
            padding: 13px 15px;
            color: var(--main-text-color);
            vertical-align: ${({ verticalalign }) => verticalalign || 'top'};
            @media screen and ${devices.tablet} {
                padding: 9px 12px;
            }
        }
        & tr {
            &:hover td {
                background: rgba(213, 220, 233, 0.2);
                & .status_icon {
                    background: var(--order-table-hover-icon);
                }
            }
        }
        &::before {
            line-height: 1em;
            content: '\\200C';
            display: block;
        }

        & td:first-of-type {
            border-top-left-radius: 9px;
            border-bottom-left-radius: 9px;
        }

        & td:last-of-type {
            border-bottom-right-radius: 9px;
            border-top-right-radius: 9px;
        }
    }
    &.column {
        & th {
            display: none;
        }
        & tbody td,
        & tbody tr {
            display: block;
        }
        & tbody tr {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 5px;
            padding-bottom: 5px;
            border-bottom: 1px solid var(--border-color);
        }
        & tbody td {
            width: 50%;
            &[data-title=${t('orders:cols.status')}] {
                width: calc(100% - 10px);
            }
        }
        & tr {
            &:hover td {
                background: var(--page-bg-color);
                & .status_icon {
                    background: var(--page-bg-color);
                }
            }
        }
        & tbody td::before {
            content: attr(data-title) ': ';
            display: block;
            font-weight: 600;
        }
    }
`;

export default StyledTable;
