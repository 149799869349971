import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import image404 from '@/assets/images/errors/404.svg';
import image500 from '@/assets/images/errors/500.png';
import image503 from '@/assets/images/errors/503.png';
import commonError from '@/assets/images/errors/commonError.png';
import reduxStore from '@/toolkit/store';
import ErrorPage from '@/views/errorPage';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError(error) {
        if (process.env.REACT_APP_NODE_ENV === 'prod') {
            const user = reduxStore.getState().auth?.user;
            Sentry.captureException(error, {
                user: {
                    id: user?.data?.id,
                    username: user?.data?.phone_number,
                    organization: user?.organization?.name_ru,
                    role: user?.data?.profile?.profile_type
                },
                tags: {
                    exception: 'browserException'
                }
            });
        }
        return { hasError: true };
    }

    render() {
        if (this.state.hasError && !this.props.showErrorComponent) {
            return (
                <ErrorPage
                    image={commonError}
                    showButton
                    onClick={() => this.setState({ hasError: false })}
                    title="Что-то пошло не так"
                    text="Не удалось отобразить страницу. Обратитесь к администратору сайта"
                />
            );
        }
        return this.props.children;
    }
}

export function withHandleErrors(WrappedComponent, showErrorComponent = false) {
    return (props) => (
        <ErrorBoundary showErrorComponent={showErrorComponent}>
            <WrappedComponent {...props} />
        </ErrorBoundary>
    );
}

const WithErrorBoundary = (WrappedComponent, axios) => {
    const NewComponent = (props) => {
        const [ready, setReady] = useState(false); // HERE
        const [error, setError] = useState(null);
        const location = useLocation();

        useEffect(() => {
            setError(null);
        }, [location.pathname]);

        useEffect(() => {
            const res = axios.interceptors.response.use(null, (error) => {
                if (
                    (error?.error?.response?.config?.method === 'get' && error?.error?.response?.data?.status_code === 404) ||
                    error?.error?.response?.data?.status_code === 500 ||
                    error?.error?.response?.data?.status_code === 503
                ) {
                    setError(error?.error?.response?.data?.status_code);
                }
                return Promise.reject(error);
            });

            setReady(true); // HERE

            return () => {
                axios.interceptors.response.eject(res);
            };
        }, []);

        if (!ready) return null; // HERE

        return (
            <div>
                {error === 404 && (
                    <ErrorPage
                        showButton={true}
                        image={image404}
                        title="Страница не найдена (404)"
                        text={
                            'Страница, на которую Вы хотели перейти не найдена.\n' +
                            'Возможно, введён некорректный адрес или страница была удалена'
                        }
                    />
                )}
                {error === 500 && (
                    <ErrorPage
                        image={image500}
                        title="Ошибка сервера (500)"
                        text="На сервере произошла ошибка. Мы скоро все исправим. Просим прощения за неудобства"
                    />
                )}
                {error === 503 && (
                    <ErrorPage
                        image={image503}
                        title="Сервис недоступен (503)"
                        text="На данный момент сервис недоступен. Повторите попытку через 10 минут"
                    />
                )}
                {!error && <WrappedComponent {...props} />}
            </div>
        );
    };
    return NewComponent;
};

export default WithErrorBoundary;
