import React from 'react';

const PrivacyPolicy = () => (
    <div style={{ maxWidth: '1200px', margin: '30px auto' }}>
        <h1 style={{ marginBottom: '30px' }}>Политика конфиденциальности</h1>
        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            Настоящая политика конфиденциальности и обработки персональных данных регулирует порядок обработки и
                            использования персональных и иных данных сайта ТОО &laquo;Центр сертификации продукции, услуг&raquo; (дальше
                            &mdash; Оператор). Действующая редакция настоящей Политики конфиденциальности, постоянно доступна для
                            ознакомления, и размещена в сети Интернет по адресу: https://loggy.kz/privacy_policy
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            Передавая Оператору персональные и иные данные посредством Сайта, Пользователь подтверждает свое согласие на
                            использование указанных данных на условиях, изложенных в настоящей Политике конфиденциальности.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            Если Пользователь не согласен с условиями настоящей Политики конфиденциальности, он обязан прекратить
                            использование Сайта.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            Безусловным акцептом настоящей Политики конфиденциальности является начало использования Сайта Пользователем.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>&nbsp;</p>

        <p>
            <strong>
                <span style={{ fontSize: '8pt' }}>
                    <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                        <span style={{ color: '#172b4d' }}>
                            <span style={{ backgroundColor: '#f4f5f7' }}>1.ТЕРМИНЫ</span>
                        </span>
                    </span>
                </span>
            </strong>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            1.1. Сайт - сайт, расположенный в сети Интернет по адресу https://loggy.kz
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            Все исключительные права на Сайт и его отдельные элементы (включая программное обеспечение, дизайн) принадлежат
                            Оператору в полном объеме. Передача исключительных прав Пользователю не является предметом настоящей Политики
                            конфиденциальности.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>1.2. Пользователь &mdash; лицо использующее Сайт.</span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            1.3. Законодательство &mdash; действующее законодательство Республики Казахстан.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            1.4. Персональные данные &mdash; персональные данные Пользователя, которые Пользователь предоставляет
                            самостоятельно при регистрации или в процессе использования функционала Сайта.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            1.5. Данные &mdash; иные данные о Пользователе (не входящие в понятие Персональных данных).
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            1.6. Регистрация &mdash; заполнение Пользователем Регистрационной формы, расположенной на Сайте, путем указания
                            необходимых сведений и отправки сканированных документов.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            1.7. Регистрационная форма &mdash; форма, расположенная на Сайте, которую Пользователь должен заполнить для
                            возможности использования сайта в полном объеме.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            1.8. Услуга(и) &mdash; услуги, предоставляемые Оператором на основании соглашения.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>&nbsp;</p>

        <p>
            <strong>
                <span style={{ fontSize: '8pt' }}>
                    <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                        <span style={{ color: '#172b4d' }}>
                            <span style={{ backgroundColor: '#f4f5f7' }}>2. СБОР И ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</span>
                        </span>
                    </span>
                </span>
            </strong>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            2.1. Оператор собирает и хранит только те Персональные данные, которые необходимы для оказания Услуг Оператором
                            и взаимодействия с Пользователем.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>2.2. Персональные данные могут использоваться в следующих целях:</span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>2.2.1 оказание Услуг Пользователю;</span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>2.2.2 идентификация Пользователя;</span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>2.2.3 взаимодействие с Пользователем;</span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            2.2.4 направление Пользователю рекламных материалов, информации и запросов;
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>2.2.5 проведение статистических и иных исследований;</span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>2.3. Оператор в том числе обрабатывает следующие данные:</span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>2.3.1 фамилия, имя и отчество;</span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>2.3.2 адрес электронной почты;</span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>2.3.3 номер телефона (в т.ч. мобильного).</span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            2.4. Пользователю запрещается указывать на Сайте персональные данные третьих лиц(за исключением условия
                            представления интересов этих лиц, имея документальное подтверждение третьих лиц на осуществление таких
                            действий).
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>&nbsp;</p>

        <p>
            <strong>
                <span style={{ fontSize: '8pt' }}>
                    <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                        <span style={{ color: '#172b4d' }}>
                            <span style={{ backgroundColor: '#f4f5f7' }}>3. ПОРЯДОК ОБРАБОТКИ ПЕРСОНАЛЬНЫХ И ИНЫХ ДАННЫХ</span>
                        </span>
                    </span>
                </span>
            </strong>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            3.1. Оператор обязуется использовать Персональные данные в соответствии с Законом &laquo;О персональных
                            данных&raquo; Республики Казахстан и внутренними документами Оператора.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            3.2. В отношении Персональных данных и иных Данных Пользователя сохраняется их конфиденциальность, кроме
                            случаев, когда указанные данные являются общедоступными.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            3.3. Оператор имеет право сохранять архивную копию Персональных данных.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            Оператор имеет право хранить Персональные данные и Данные на серверах вне территории Республики Казахстан.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>&nbsp;</p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            3.4. Оператор имеет право передавать Персональные данные и Данные Пользователя без согласия Пользователя
                            следующим лицам:
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            3.4.1 государственным органам, в том числе органам дознания и следствия, и органам местного самоуправления по их
                            мотивированному запросу;
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            3.4.2 в иных случаях, прямо предусмотренных действующим законодательством Республики Казахстан.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            3.5. Оператор имеет право передавать Персональные данные и Данные третьим лицам, не указанным в п.3.4. настоящей
                            Политики конфиденциальности, в следующих случаях:
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>3.5.1 Пользователь выразил свое согласие на такие действия;</span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            3.5.2 передача необходима в рамках использования Пользователем Сайта или оказания Услуг Пользователю;
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            3.6. Оператор осуществляет автоматизированную обработку Персональных данных и Данных.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>&nbsp;</p>

        <p>
            <strong>
                <span style={{ fontSize: '8pt' }}>
                    <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                        <span style={{ color: '#172b4d' }}>
                            <span style={{ backgroundColor: '#f4f5f7' }}>4. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</span>
                        </span>
                    </span>
                </span>
            </strong>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            4.1. Оператор осуществляет надлежащую защиту Персональных и иных данных в соответствии с Законодательством и
                            принимает необходимые и достаточные организационные и технические меры для защиты Персональных данных.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            4.2. Применяемые меры защиты в том числе позволяют защитить Персональные данные от неправомерного или случайного
                            доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных
                            действий с ними третьих лиц.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>&nbsp;</p>

        <p>
            <strong>
                <span style={{ fontSize: '8pt' }}>
                    <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                        <span style={{ color: '#172b4d' }}>
                            <span style={{ backgroundColor: '#f4f5f7' }}>5. ИНЫЕ ПОЛОЖЕНИЯ</span>
                        </span>
                    </span>
                </span>
            </strong>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            5.1. К настоящей Политике конфиденциальности и отношениям между Пользователем и Оператором, возникающим в связи
                            с применением Политики конфиденциальности, подлежит применению право Республики Казахстан.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            5.2. Все возможные споры, вытекающие из настоящего Соглашения, подлежат разрешению в соответствии с действующим
                            законодательством по месту регистрации Оператора.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            Перед обращением в суд Пользователь должен соблюсти обязательный до судебный порядок и направить Оператору
                            соответствующую претензию в письменном виде. Срок ответа на претензию составляет 30 (тридцать) рабочих дней.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            5.3. Если по тем или иным причинам одно или несколько положений Политики конфиденциальности будут признаны
                            недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или
                            применимость остальных положений Политики конфиденциальности.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            5.4. Оператор имеет право в любой момент изменять Политику конфиденциальности (полностью или в части) в
                            одностороннем порядке без предварительного согласования с Пользователем. Все изменения вступают в силу с момента
                            ее размещения на Сайте.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            5.5. Пользователь обязуется самостоятельно следить за изменениями Политики конфиденциальности путем ознакомления
                            с актуальной редакцией.
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>
            <span style={{ fontSize: '8pt' }}>
                <span style={{ fontFamily: "'Roboto Mono',monospace" }}>
                    <span style={{ color: '#172b4d' }}>
                        <span style={{ backgroundColor: '#f4f5f7' }}>
                            5.6. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по электронной почте:
                            aidos.s@globerce.capital
                        </span>
                    </span>
                </span>
            </span>
        </p>

        <p>&nbsp;</p>
    </div>
);

export default PrivacyPolicy;
