import { styled, Button } from '@mui/material';

const ButtonHeader = styled(Button, { shouldForwardProp: (prop) => prop !== 'type' })(({ theme, type }) => ({
    ...theme.typography.fontFamily,
    ...(type === 'success' && {
        backgroundColor: theme.palette.success.dark,
        color: 'white',
        fontWeight: 900,
        padding: '5px 28px',
        borderRadius: '5px'
    }),
    ...(type === 'entrance' && {
        border: '2px solid white',
        color: 'white',
        fontWeight: 900,
        padding: '5px 28px',
        borderRadius: '5px'
    })
}));

export default ButtonHeader;
