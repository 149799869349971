import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';

import ArmListLayoutHeader from '@/components/layouts/armListLayout/header';

const ErrorOrderChain = () => {
    const params = useParams();

    const { id: orderChainId, '*': path } = params;

    const isUpdatePage = path.includes('update');
    const subTitle = isUpdatePage
        ? `Вернуться к многоэтапной доставке №${orderChainId} / Редактирование многоэтапной доставки №${orderChainId}`
        : `Вернуться к многоэтапным доставкам / Многоэтапная доставка №${orderChainId}`;

    return (
        <>
            <ArmListLayoutHeader title={`Многоэтапная доставка №${orderChainId}`} subTitle={subTitle} />
            <Typography variant="h4" textAlign="center" color="var(--main-text-color)">
                Не удалось загрузить данные
            </Typography>
        </>
    );
};

export default ErrorOrderChain;
