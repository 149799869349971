import L from 'leaflet';

import i18n from '@/core/i18n';

export const serverUrl = process.env.SERVER_URL;
export const apiUrl = `${serverUrl}/api/v1`;
export const wssUrl = process.env.WSS_URL;
export const frontendUrl = window.location.origin;

export const PARTNER_MANAGER = 'manager';
export const SERVICE_MANAGER = 'service_manager';
export const COURIER = 'courier';
export const SUPERVISOR = 'dispatcher';
export const FILIAL_MANAGER = 'branch_manager';
export const PARTNER_FILIAL_MANAGER = 'partner_branch_manager';
export const SORTER = 'sorter';

export const ORGANIZATION_TYPES = {
    too: i18n.t('constants:organizationTypes.too'),
    ip: i18n.t('constants:organizationTypes.ip'),
    fl: i18n.t('constants:organizationTypes.fl')
};

export const ACTIONS = {
    POST: i18n.t('constants:actions.created'),
    PUT: i18n.t('constants:actions.updated'),
    PATCH: i18n.t('constants:actions.updated')
};

export const DEFAULT_DELIVERY_STATUSES = {
    'on-the-way-to-call-point': i18n.t('constants:orderStatuses.otw'),
    is_delivered: i18n.t('constants:orderStatuses.isDelivered'),
    cancelled: i18n.t('constants:orderStatuses.canceled'),
    postponed: i18n.t('constants:orderStatuses.postponed'),
    noncall: i18n.t('constants:orderStatuses.noncall')
};

export const ADDITIONAL_DELIVERY_STATUSES = {
    rescheduled: i18n.t('constants:orderStatuses.rescheduled'),
    being_finalized: i18n.t('constants:orderStatuses.beingFinalized'),
    cancelled_at_client: i18n.t('constants:orderStatuses.cancelledAtClient'),
    video_check_passed: i18n.t('constants:orderStatuses.videoCheckPassed'),
    video_unavailable: i18n.t('constants:orderStatuses.videoUnavailable'),
    video_postcontrol: i18n.t('constants:orderStatuses.videoPostcontrol'),
    video_postcontrol_being_finalized: i18n.t('constants:orderStatuses.videoPostcontrolBeingFinalized'),
    video_check_failed: i18n.t('constants:orderStatuses.videoCheckFailed'),
    sms_postcontrol: i18n.t('constants:orderStatuses.smsPostcontrol'),
    sms_postcontrol_being_finalized: i18n.t('constants:orderStatuses.smsPostcontrolBeingFinalized'),
    sms_unavailable: i18n.t('constants:orderStatuses.smsUnavailable'),
    sms_failed: i18n.t('constants:orderStatuses.smsFailed')
};

export const NEW_GROUP = 'new_group';
export const READY_FOR_PICKUP = 'ready_for_pickup';
export const REVISE = 'revise';
export const EXPORTED = 'exported';
export const UNDER_REVIEW = 'under_revision';
export const COURIER_SERVICE_ACCEPTED = 'courier_service_accepted';
export const COURIER_APPOINTED = 'courier_appointed';

export const ORDER_GROUP_STATUSES = {
    [NEW_GROUP]: i18n.t('constants:orderGroupsStatuses.newOrder'),
    [READY_FOR_PICKUP]: i18n.t('constants:orderGroupsStatuses.readyForPickup'),
    [REVISE]: i18n.t('constants:orderGroupsStatuses.revise'),
    [EXPORTED]: i18n.t('constants:orderGroupsStatuses.exported'),
    [UNDER_REVIEW]: i18n.t('constants:orderGroupsStatuses.underReview'),
    [COURIER_SERVICE_ACCEPTED]: i18n.t('constants:orderGroupsStatuses.courierServiceAccepted'),
    [COURIER_APPOINTED]: i18n.t('constants:orderGroupsStatuses.courierAppointed')
};

export const ALL_DELIVERY_STATUSES = {
    ...DEFAULT_DELIVERY_STATUSES,
    ...ADDITIONAL_DELIVERY_STATUSES
};

export const ORDER_CREATION_MODES = {
    import: i18n.t('constants:orderCreationModes.import'),
    integration: i18n.t('constants:orderCreationModes.integration'),
    service: i18n.t('constants:orderCreationModes.service')
};

export const ITEM_CATEGORIES = {
    document: i18n.t('constants:itemCategories.doc'),
    food: i18n.t('constants:itemCategories.food'),
    common: i18n.t('constants:itemCategories.common')
};

export const REVIEW_STATUSES = {
    new: { title: i18n.t('constants:reviewStatuses.new'), color: '#5B96FF' },
    declined: { title: i18n.t('constants:reviewStatuses.declined'), color: '#ED5D5D' },
    approved: { title: i18n.t('constants:reviewStatuses.accepted'), color: '#49C172' }
};
export const PROFILE_TYPES = {
    [SERVICE_MANAGER]: i18n.t('constants:profileTypes.manager'),
    [PARTNER_MANAGER]: i18n.t('constants:profileTypes.partnerManager'),
    [SUPERVISOR]: i18n.t('constants:profileTypes.supervisor'),
    receiver: i18n.t('constants:profileTypes.receiver'),
    [FILIAL_MANAGER]: i18n.t('constants:profileTypes.filialManager'),
    [COURIER]: i18n.t('constants:profileTypes.courier'),
    [PARTNER_FILIAL_MANAGER]: i18n.t('constants:profileTypes.partnerBranchManager'),
    [SORTER]: i18n.t('constants:profileTypes.sorter')
};

export const TRANSPORT_TYPES = {
    car: i18n.t('constants:transportTypes.car'),
    truck: i18n.t('constants:transportTypes.truck'),
    pickup: i18n.t('constants:transportTypes.pickup'),
    bicycle: i18n.t('constants:transportTypes.bicycle'),
    motorcycle: i18n.t('constants:transportTypes.motorcycle'),
    foot: i18n.t('constants:transportTypes.foot')
};

export const SHOW_AUTO_INFO = ['car', 'truck', 'pickup'];

export const ORDER_TYPES = {
    planned: i18n.t('constants:orderTypes.planned'),
    urgent: i18n.t('constants:orderTypes.urgent'),
    pickup: i18n.t('constants:orderTypes.pickup')
};

// const NOMINATIM_BASE_URL = "https://nominatim-geocoder.trafficwave.kz";
export const NOMINATIM_BASE_URL = 'https://nominatim.openstreetmap.org';

// export const GET_TILE_LAYER = () =>
//     L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
//         foo: 'bar',
//         attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//     });

export const GET_TILE_LAYER = () => L.tileLayer('http://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}');
// export const GET_TILE_LAYER = () =>
//     L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
//         subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
//     });

export const UNWANTED_DATABASES = {
    EGV02: { success: 'Не состоит на учёте нуждающихся в жилье', error: 'Состоит на учёте нуждающихся в жилье' },
    KGD15: {
        success: 'Не находится в списке неблагонадёжных налогоплательщиков',
        error: 'Находится в списке неблагонадёжных налогоплательщиков'
    },
    KGD12: {
        success: 'Не состоит в списке налогоплательщиков, признанных банкротами',
        error: 'Состоит в списке налогоплательщиков, признанных банкротами'
    },

    EGV06: {
        success: 'Нет задолженности по соц.отчислениям',
        error: 'Имеется задолженность по соц.отчислениям'
    },
    'MU01/02': {
        success: 'Не находится в едином реестре должников временно ограниченных на выезд должников',
        error: 'Находится в едином реестре должников временно ограниченных на выезд должников'
    },
    KPS02: {
        success: 'Нет задолженности по алмиментам',
        error: 'Имеется задолженность по алиментам'
    },
    KGD05: {
        success: 'Не имеет задолженностей и штрафов, учёт которых ведётся в госорганах',
        error: 'Имеется задолженность или штраф, учёт которых ведётся в госорганах'
    },
    KGD02: {
        error: 'Находится в списке неблагонадёжных налогоплательщиков',
        success: 'Не находится в списке неблагонадёжных налогоплательщиков'
    },
    KGD03: {
        error: 'Находится в списке несостоятельных должников или в списке банкротов',
        success: 'Не находится в списке несостоятельных должников и в списке банкротов'
    },
    KGD01: {
        error: 'Имеется задолженность по таможенным платежам, налогам и пеням, не погашенная в течение 4 месяцев со дня ее возникновения',
        success: 'Не имеет задолженности по таможенным платежам, налогам и пеням, не погашенной в течение 4 месяцев со дня ее возникновения'
    },
    KMF01: {
        error: 'Находится в перечне организаций и лиц, связанных с финансированием терроризма и экстремизма',
        success: 'Не находится в перечне организаций и лиц, связанных с финансированием терроризма и экстремизма'
    },
    KPS01: {
        error: 'В розыске преступников, должников, без вести пропавших лиц Комитетом по правовой статистике и специальным учетам ГП РК',
        success:
            'Не находится в розыске преступников, должников, без вести пропавших лиц Комитетом по правовой статистике и специальным учетам ГП РК'
    },
    EGV01: {
        error: 'Находится в списке привлеченных к уголовной отвественности за совершение уголовных правонарушений против половой неприкосновенности несовершеннолетних',
        success:
            'Не находится в списке привлеченных к уголовной отвественности за совершение уголовных правонарушений против половой неприкосновенности несовершеннолетних'
    }
};

export const SMS_RESEND_MIN = 2;

export const VALID_PHONE_CODES = ['700', '701', '702', '705', '706', '707', '708', '747', '771', '775', '776', '777', '778'];

export const PHONE_REGEX = new RegExp(
    `^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(${VALID_PHONE_CODES.join('|')})[-. )]*(\\d{3})[-. ]*(\\d{2})[-. )]*(\\d{2})[-. )]*?\\s*$`
);

export const DEFAULT_RANGE = [
    {
        startDate: null,
        endDate: null,
        key: 'selection'
    }
];

export const ORDER_STATUSES = [
    'novaia-zaiavka',
    'posledkontrol',
    'gotovo-k-vyvozu',
    'kurer-k-vyvozu-naznachen',
    'upakovano',
    'na-sverke',
    'vkliucheno-v-gruppu',
    'vyvezeno-kurerom',
    'priniato-kurerskoi-sluzhboi'
];

export const ORDER_STATUSES_IDS = {
    new: '1',
    post_control: '12',
    gotovo_k_vyvozu: '28',
    kurer_k_vyvozu_naznachen: '31',
    upakovano: '32',
    na_sverke: '33',
    vkliucheno_v_gruppu: '35',
    vyvezeno_kurerom: '30',
    priniato_kurerskoi_sluzhboi: '29'
};

export const TABS_WITHOUT_CHECKBOX = ['is_delivered', 'archived', ...ORDER_STATUSES.filter((status) => status !== 'novaia-zaiavka')];
