import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { PhoneInput, parseCountry, defaultCountries } from 'react-international-phone';

import 'react-international-phone/style.css';
import FieldLabel from '../fieldLabel';

const StyledPhoneNumber = styled(PhoneInput)`
    border: ${({ error, success }) => `1px solid ${error ? '#FF6464' : success}`};
    border-radius: 4px;
    &.react-international-phone-input-container .react-international-phone-input {
        width: 100%;
        height: auto;
        padding: 10px 15px 10px 0;
        color: var(--main-text-color);
        font-size: 14px;
        line-height: 1.8;
        background: var(--input-bg);
        border: 1px solid transparent;
    }
    &.react-international-phone-input-container .react-international-phone-country-selector-button {
        height: 100%;
        padding: 5px 10px;
        background: var(--input-bg);
        border: 1px solid transparent;
    }
    &.react-international-phone-input-container .react-international-phone-country-selector-dropdown {
        background: var(--paper-bg);
        color: var(--main-text-color);
        & .react-international-phone-country-selector-dropdown__list-item {
            &[data-country='us'] {
                display: none;
            }
            &:hover {
                background: var(--select-focused-option);
                color: var(--main-text-color);
            }
        }
        & .react-international-phone-country-selector-dropdown__list-item--selected {
            background: var(--select-focused-option);
            color: var(--main-text-color);
        }
    }
`;

const PhoneNumberInput = ({ control, name, label, id, required, errors, isValid }) => {
    const countries = defaultCountries.filter((country) => {
        const { iso2 } = parseCountry(country);
        return ['us', 'ua', 'uz', 'kz', 'ru', 'kg', 'ae'].includes(iso2);
    });

    return (
        <Controller
            name={name}
            control={control}
            rules={{
                validate: {
                    required: isValid
                }
            }}
            render={({ field: { onChange, value } }) => (
                <>
                    <FieldLabel htmlFor={id} required={required}>
                        {label}
                    </FieldLabel>
                    <StyledPhoneNumber
                        forceDialCode
                        value={value}
                        onChange={onChange}
                        countries={countries}
                        defaultCountry="kz"
                        required={required}
                        success={value?.length > 4 && !errors[id] && isValid ? '#49C172' : 'transparent'}
                        error={!!errors[id]}
                        id={id}
                    />
                    <Typography
                        className="required_error_message"
                        style={{
                            marginTop: '4px',
                            color: '#FF6464',
                            fontSize: '12px',
                            opacity: errors[id] ? 1 : 0
                        }}
                    >
                        {errors[id]?.message}
                    </Typography>
                </>
            )}
        />
    );
};

export default PhoneNumberInput;
