import { httpClient } from '@/api/httpClient/httpClient';

export const cityServices = {
    getCountriesList() {
        return httpClient.get(`/country`);
    },
    getRegionsList(countryId) {
        return httpClient.get(`/region/list/${countryId}`);
    },
    getList(regionId = 1) {
        return httpClient.get(`/city/list/${regionId}`);
    },
    getListByPartnerId(partnerId) {
        const params = {
            partner_id: partnerId
        };
        return httpClient.get('/cities', { params });
    },
    getCity(id) {
        return httpClient.get(`/city/${id}`);
    }
};
