import { FormControl, MenuItem, PaginationItem, Select, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MUIPagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';
import React from 'react';

import i18n from '@/core/i18n';
import { devices } from '@/core/utils/devices';

const { t } = i18n;

const StyledLimitSelect = styled(Select)`
    background: var(--input-bg);
    & .MuiSelect-select.MuiInputBase-input {
        background: var(--input-bg);
        color: var(--main-text-color);
    }
    & .MuiSvgIcon-root {
        color: var(--medium-text-color);
    }
    & fieldset.MuiOutlinedInput-notchedOutline {
        border-color: var(--border-color);
    }
`;
const StyledPagination = styled(Box)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and ${devices.mobileS} {
        flex-direction: column-reverse;
        align-items: flex-end;
    }
`;

const Pagination = ({ totalPages = 1, currentPage = 1, onPageChange, pageLimit, setPageLimit, itemsLength, totalItemsLength }) =>
    totalPages > 1 || (setPageLimit && totalPages > 0 && itemsLength) ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '24px' }}>
            <Box>
                {totalItemsLength > 0 && (
                    <Tooltip title={`Показано ${itemsLength} заявок из ${totalItemsLength}`}>
                        <Typography color="var(--medium-text-color)">{`${itemsLength} из ${totalItemsLength}`}</Typography>
                    </Tooltip>
                )}
            </Box>
            <StyledPagination>
                <>
                    {setPageLimit && (
                        <Tooltip placement="left" title={t('components:pagination.numberOfElements')}>
                            <FormControl size="small">
                                <StyledLimitSelect
                                    sx={{ paddingTop: 0, paddingBottom: 0, width: '80px', paddingRight: '5px' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={pageLimit}
                                    placeholder=""
                                    onChange={(e) => setPageLimit(e.target.value)}
                                >
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                    {/* <MenuItem value={300}>300</MenuItem> */}
                                    {/* <MenuItem value={500}>500</MenuItem> */}
                                </StyledLimitSelect>
                            </FormControl>
                        </Tooltip>
                    )}
                    <MUIPagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(e, value) => onPageChange(value)}
                        size="small"
                        shape="rounded"
                        renderItem={(item) => (
                            <PaginationItem
                                {...item}
                                sx={{
                                    '&.Mui-selected': {
                                        backgroundColor: 'var(--pagination-page-bg)',
                                        color: 'var(--pagination-page-color)',
                                        borderRadius: '4px'
                                    }
                                }}
                            />
                        )}
                    />
                </>
            </StyledPagination>
        </Box>
    ) : null;

export default Pagination;
