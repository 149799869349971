import { Typography } from '@mui/material';
import React from 'react';

import FormModal from '@/components/formModal';

const AcceptGroupModal = ({ opened, close, onSubmit }) => (
    <FormModal isShowing={opened} handleClose={close} title="Принять?" buttonText="Да" onSubmit={onSubmit} isValid={true}>
        <Typography>Вы действительно хотите принять заявки у курьера?</Typography>
    </FormModal>
);

export default AcceptGroupModal;
