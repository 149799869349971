import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import CitySelectNew from '@/components/citySelectNew';
import Autocomplete from '@/components/formField/autocomplete';
import FormModal from '@/components/formModal';
import { cityServices } from '@/core/services/cityServices';
import { courierServices } from '@/core/services/courierServices';
import { orderGroupServices } from '@/core/services/orderGroupServices';
import { addNotification } from '@/toolkit/reducers/toastr';

const AddCourierModal = ({ groupId, close, cityId, queryClient }) => {
    const {
        getValues,
        setValue,
        register,
        watch,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            city: cityId
        }
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { data: countries, isSuccess: isCountriesListLoaded } = useQuery('countries', cityServices.getCountriesList);
    const { data: couriers, isLoading: isCouriersListLoading } = useQuery(
        [`couriers`, watch('city')],
        () => courierServices.getCouriersListShort({ cityId: watch('city') }),
        {
            enabled: !!watch('city')
        }
    );

    const updateGroupMutation = useMutation((data) => orderGroupServices.update(groupId, data));

    return (
        <FormModal
            isShowing={true}
            handleClose={close}
            title="Назначить курьера"
            isValid={watch('courier')}
            buttonText="Выбрать"
            onSubmit={async (e) => {
                e.preventDefault();
                try {
                    await updateGroupMutation.mutateAsync({ courier_id: getValues('courier') });
                    await queryClient.invalidateQueries(`orderGroup${groupId}`);
                    dispatch(addNotification({ message: 'Курьер назначен' }));
                    close();
                } catch (e) {
                    console.log(e);
                    dispatch(addNotification({ message: 'Не удалось назначить курьера', type: 'error' }));
                }
            }}
        >
            {isCountriesListLoaded && (
                <CitySelectNew
                    options={countries?.data}
                    id="city"
                    label={t('orders:create.labels.city')}
                    placeholder={t('orders:create.labels.city')}
                    required
                    value={watch('city')}
                    saveCity={false}
                    {...register('city')}
                />
            )}
            <Autocomplete
                id="courier"
                label={t('modals:changeCourierModal.label')}
                setValue={setValue}
                defaultValueId={watch('courier')}
                disabled={!couriers?.data?.length}
                disabledText={
                    isCouriersListLoading
                        ? t('modals:changeCourierModal.loadingCouriersList')
                        : t('modals:changeCourierModal.noCouriersInSelectedCity')
                }
                options={
                    couriers?.data?.map((courier) => ({
                        name: `${courier.user.first_name} ${courier.user.last_name}`,
                        id: courier.id
                    })) || []
                }
                errors={errors}
                required
            />
        </FormModal>
    );
};

export default AddCourierModal;
