import { Grid } from '@mui/material';
import React from 'react';

const css = `
    .western {
        text-indent: 20px;
        margin-bottom: 5px;
    }
`;

const UserPolicy = () => (
    <>
        <style>{css}</style>
        <Grid container justifyContent="center" sx={{ pt: 10, pb: 10 }}>
            <Grid item xs={10}>
                <font size="3">
                    Настоящее пользовательское соглашение представляет собой оферту (предложение) ТОО «Loggy» (далее - Компания) о
                    заключении с Клиентом договора о предоставлении неисключительного права использования Сервиса на нижеизложенных
                    условиях.{' '}
                </font>

                <p className="western" align="justify">
                    <br />
                </p>
                <p className="western" align="center">
                    <br />
                </p>
                <p className="western" align="center">
                    <font size="3">
                        <b>1. Термины и определения, используемые в Соглашении</b>
                    </font>
                </p>
                <p className="western" align="center">
                    <br />
                </p>
                <p className="western" align="justify">
                    <font size="3">1.1.</font>
                    <font size="3">
                        <b>Сервис – </b>
                    </font>
                    <font size="3">
                        программный продукт «Loggy», предназначенный для комплексного управления, мониторинга и контроля деятельности по
                        доставке продуктов Клиента, вне зависимости от вида продукта.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">1.2.</font>
                    <font size="3">
                        <b>Клиент – </b>
                    </font>
                    <font size="3">
                        физическое или юридическое лицо, прошедшее регистрацию в Сервисе, и получившее право использовать Сервис на
                        условиях, установленных Соглашением.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">1.3.</font>
                    <font size="3">
                        <b>Пользователь – </b>
                    </font>
                    <font size="3">
                        Клиент, сотрудники Клиента и иные лица, зарегистрированные Клиентом в Сервисе, непосредственно осуществляющие работу
                        в Сервисе.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">1.4.</font>
                    <font size="3">
                        <b>Личный кабинет – </b>
                    </font>
                    <font size="3">
                        раздел Сервиса, выделенный Клиенту для организации работы Клиента, ввода и изменения данных Клиента,
                        самостоятельного выбора тарифного плана в рамках Соглашения
                    </font>
                </p>
                <p className="western" align="center">
                    <br />
                </p>
                <p className="western" align="center">
                    <font size="3">
                        <b>2. Предмет Соглашения</b>
                    </font>
                </p>
                <p className="western" align="justify">
                    <br />
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        2.1. По настоящему соглашению Компания предоставляет Клиенту неисключительное право использования Сервиса, а Клиент
                        обязуется уплачивать Компании вознаграждение за использование Сервиса в соответствии с выбранным тарифным планом и
                        условиями настоящего Соглашения.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        2.2. Регистрацией и/или авторизацией в Сервисе Клиент полностью и безоговорочно подтверждает, что ознакомился с
                        условиями настоящего Соглашения, принял все условия Соглашения и обязуется использовать Сервис на условиях,
                        описанных в настоящем Соглашении.
                    </font>
                </p>
                <p className="western" align="justify">
                    <br />
                </p>
                <p className="western" align="center">
                    <font size="3">
                        <b>3. Порядок предоставления доступа к Сервису</b>
                    </font>
                </p>
                <p className="western" align="justify">
                    <br />
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        3.1. Для получения доступа к использованию Сервиса Клиент обязан пройти в Сервисе процедуру регистрации, указав свои
                        данные в установленной Компанией регистрационной форме, а также приложив документы, перечень которых определен
                        Компанией в регистрационной форме.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        3.2. После прохождения процедуры регистрации для Клиента создается Личный кабинет с уникальным логином и паролем.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        3.3. Для использования Сервиса Клиенту необходимо выбрать тарифный план и внести оплату согласно выбранному
                        тарифному плану в сроки, определенные Соглашением.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        3.4. Право использования Сервиса предоставляется Клиенту после осуществления Клиентом полной уплаты вознаграждения
                        за использование Сервиса по выбранному тарифному плану.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        3.5. Функциональные возможности использования Сервиса определяются исходя из выбранного тарифного плана.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        3.6. Компания вправе предоставить Клиенту после прохождения регистрации возможность доступа к ограниченной
                        функционально бесплатной версии Программы на определенный Компанией срок.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        3.7. Клиент имеет возможность через личный кабинет создавать доступы для иных пользователей, перечень и количество
                        которых определяется соответствующим тарифным планом. При этом обязанность по получению согласия на сбор и обработку
                        персональных данных пользователей возлагается на Клиента. Ответственность за отсутствие согласия на сбор и обработку
                        персональных данных несет Клиент.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        3.8. Пользователи, зарегистрированные в Сервисе Клиентом обязаны соблюдать условия настоящего Соглашения и тарифного
                        плана. Ответственность за неисполнение условий Соглашения несет Клиент.
                    </font>
                </p>
                <p className="western" align="justify">
                    <br />
                </p>
                <p className="western" align="center">
                    <font size="3">
                        <b>4. Права и обязанности сторон</b>
                    </font>
                </p>
                <p className="western" align="justify">
                    <br />
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        <b>4.1. Компания обязана:</b>
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.1.1. предоставить Клиенту право использования Сервиса на условиях, в порядке и сроки, установленные Соглашением;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.1.2. уведомлять Клиента о внесении существенных изменений в работу Сервиса, изменении размера вознаграждения за
                        использование Сервиса путем размещения информации в Сервисе;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.1.3. сохранять конфиденциальность информации, предоставленной Клиентом в Сервисе и обеспечивать их защиту от
                        несанкционированного доступа третьих лиц;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.1.4. Не разглашать персональную информацию о Клиенте и Пользователях, кроме случаев, предусмотренных настоящим
                        Соглашением и действующим законодательством Республики Казахстан.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        <b>4.2. Компания вправе:</b>
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.2.1. В любое время устанавливать обновления Сервиса, а также вносить иные изменения и улучшения в Сервис;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.2.2. В одностороннем порядке изменять размер вознаграждения за использование Сервиса, тарифные планы, а также
                        функционал, предусмотренный тарифным планом, уведомив об этом Клиента в Сервисе не менее чем за 10 дней до
                        предполагаемой даты изменений;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.2.3. В одностороннем порядке вносить изменения в настоящее Соглашение без предварительного уведомления Клиента.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.2.4. В случае нарушения Клиентом своих обязательств по уплате вознаграждения за использование Сервиса, а также
                        иных условий Соглашения приостановить или прекратить доступ Клиента и Пользователей, зарегистрированных Клиентом, к
                        Сервису.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.2.5. Требовать от Клиента представления документов, подтверждающих достоверность данных, указанных при
                        регистрации;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.2.6. Требовать от Клиента выполнение им своих обязательств, предусмотренных настоящим Соглашением.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        <b>4.3. Клиент обязан:</b>
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.3.1. уплатить Исполнителю вознаграждение за право использования Сервиса в порядке и сроки, установленные
                        Договором;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.3.2. использовать Сервис в соответствии с условиями Соглашения и только в целях, не противоречащих
                        законодательству Республики Казахстан;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.3.3. не предъявлять Компании претензий в случае предоставления Клиентом неполной или недостоверной информации в
                        рамках использования Сервиса;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.3.4. не модернизировать, не дорабатывать, не усовершенствовать, не перерабатывать и не переделывать Сервис, а
                        также обеспечить защиту Сервиса от изменений Пользователями, зарегистрированными Клиентом, работниками Клиента и
                        третьими лицами;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.3.5. не передавать предоставленный доступ к Сервису, в том числе логин и пароль от личного кабинета третьим лицам;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.3.6. ознакомить Пользователей, зарегистрированных в Сервисе Клиентом, с условиями настоящего Соглашения и
                        обеспечить наличие их согласий на сбор и обработку персональных данных и передачу Компании;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.3.7. незамедлительно уведомить Компанию о нарушении конфиденциальности, утере, или любом несанкционированном
                        использовании пароля и аккаунта Клиента и/или Пользователей, зарегистрированных Клиентом. До получения Компанией
                        такого уведомления все действия в Сервисе, совершенные с использованием логина и пароля Клиента и/или
                        зарегистрированного им Пользователя, считаются совершенными Клиентом или соответствующим Пользователем;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.3.8. не разбирать программу Сервиса на составляющие коды, не декомпилировать и/или не пытаться иным образом
                        получить исходный код Сервиса или любой его части;
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.3.9. Не передавать свои права и обязанности по Соглашению третьим лицам, без письменного согласования с Компанией.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        <b>4.4. Клиент вправе:</b>
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">4.4.1.</font>
                    <font size="3">
                        <b />
                    </font>
                    <font size="3">Использовать Сервис на условиях, предусмотренных настоящим Соглашением;</font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        4.4.2. В случае несогласия с изменениями тарифов и условий настоящего Соглашения, отказаться от использования
                        Сервиса, письменно уведомив Компанию за 10 дней до предполагаемой даты отказа от сервиса.
                    </font>
                </p>
                <p className="western" align="justify">
                    <br />
                </p>
                <p className="western" align="center">
                    <font size="3">
                        <b>5. Размер вознаграждения и порядок оплаты</b>
                    </font>
                </p>
                <p className="western" align="center">
                    <br />
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        5.1. Размер вознаграждения за использование Сервиса рассчитывается исходя из тарифного плана, выбранного Клиентом в
                        Личном кабинете.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        5.2. Стоимость тарифного плана и его функциональные возможности определяются Компанией в одностороннем порядке, а
                        информация о тарифном плане и функциональных возможностях и размещаются в Приложении No1 к настоящему Соглашению.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        5.3. Уплата вознаграждения за использование Сервиса производится Клиентом на условиях 100% предоплаты не позднее
                        последнего дня месяца, предшествующего месяцу использования Сервиса.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        5.4. Вознаграждение считается уплаченным с момента зачисления денежных средств на расчетный счет Компании.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        5.5. В случае если Клиент не уплатил вознаграждение в вышеуказанный срок, право на использование Сервиса
                        приостанавливается, доступ к использованию Сервиса блокируется.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        5.6. Вознаграждение, уплаченное за использование Сервиса, возврату не полежит независимо от фактического
                        использования или не использования Клиентом Сервиса.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        5.7. Компания вправе предоставить Клиенту тарифный план с бесплатным пробным периодом для использования Сервиса.
                        Срок предоставления бесплатного доступа и функциональные возможности такого тарифного плана определяются Компанией в
                        одностороннем порядке, а информация о бесплатном тарифном плане и функциональных возможностях размещаются в Сервисе.
                        Компания вправе в любой момент в одностороннем порядке прекратить бесплатный доступ к Сервису, разместив информацию
                        о прекращении бесплатного доступа в Сервисе.
                    </font>
                </p>
                <p className="western" align="justify">
                    <br />
                </p>
                <p className="western" align="center">
                    <font size="3">
                        <b>6. Ответственность Сторон</b>
                    </font>
                </p>
                <p className="western" align="center">
                    <br />
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        6.1 Стороны несут ответственность за неисполнение или ненадлежащее исполнение своих обязательств по Соглашению в
                        соответствии с Соглашением и законодательством Республики Казахстан.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        6.2. В случае если в результате использования Сервисов, Клиент совершает действия или бездействия, в результате
                        которых причинен вред Компании, Клиент обязуется по требованию Компании возместить убытки, которые понесены или с
                        будут понесены Компанией в будущем.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        6.3. Убытки Компании возмещаются Клиентом в течение 5 (пяти) рабочих дней с момента предъявления Компанией
                        соответствующего требования и копий документов, подтверждающих наличие убытков.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        6.4. Компания ни при каких обстоятельствах не несет перед Клиентом ответственность за упущенную выгоду и косвенные
                        убытки, возникшие в результате или в связи с использованием Сервиса.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        6.5. Клиент соглашается, что право использования Сервиса предоставляется по принципу «как есть» («as is»). Компания
                        не предоставляет никаких гарантий в отношении безошибочной и бесперебойной работы Сервиса или отдельных его
                        компонентов и функций, в отношении соответствия Сервиса конкретным целям и ожиданиям Клиента, сохранности файлов
                        и/или данных Пользователей, а также не предоставляет никаких иных гарантий, прямо не указанных в настоящем
                        Соглашении.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        6.6. Клиент полностью несёт ответственность за отправку посредствам Сервиса незаконных и запрещённых
                        законодательством веществ.
                    </font>
                </p>

                <p className="western" align="justify">
                    <br />
                </p>
                <p className="western" align="center">
                    <font size="3">
                        <b>7. Порядок изменения и расторжения Соглашения</b>
                    </font>
                </p>
                <p className="western" align="center">
                    <br />
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        7.1. Настоящее Соглашение может быть дополнено и/или изменено Компанией в одностороннем порядке путём направления
                        соответствующих уведомлений на электронную почту Клиента указанную при регистрации за 5 (пять) календарных дней до
                        даты такого дополнения и/или изменения.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        7.2. Настоящее Соглашение может быть расторгнуто по соглашению Сторон. Кроме того, настоящее Соглашение считается
                        расторгнутым в случае если Клиент не использует Сервис в течение 3 (трёх) месяцев подряд.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        7.3. В случае нарушения Клиентом и Пользователем, зарегистрированным Клиентом, условий настоящего Соглашения,
                        Компания вправе расторгнуть настоящее Соглашение в одностороннем порядке с блокированием и удалением Личного
                        кабинета Клиента. При этом уплаченная Клиентом сумма вознаграждения за использование Сервиса возврату не подлежит.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        7.4. Компания имеет право отказаться от Соглашения в одностороннем внесудебном порядке без объяснения причин, с
                        предварительным письменным уведомлением Клиента не менее чем за 10 (Десять) календарных дней до предполагаемой даты
                        расторжения Соглашения.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        7.5. В случае несогласия Клиента с изменениями тарифов, условий настоящего Соглашения, Клиент вправе отказаться от
                        использования Сервиса без возврата уплаченной суммы вознаграждения за использование Сервиса.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        7.6. Клиент также имеет право отказаться от Соглашения в одностороннем внесудебном порядке без объяснения причин при
                        условии предварительного письменного уведомления Компании не менее чем за 10 (Десять) календарных дней о
                        предполагаемой даты расторжения Соглашения. При этом уплаченная Клиентом сумма вознаграждения за использование
                        Сервиса возврату не подлежит.
                    </font>
                </p>
                <p className="western" align="justify">
                    <br />
                </p>
                <p className="western" align="center">
                    <font size="3">
                        <b>8. Заключительные положения</b>
                    </font>
                </p>
                <p className="western" align="center">
                    <br />
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        8.1. Настоящее соглашение вступает в силу с момента регистрации Клиента в Сервисе и действует до полного исполнения
                        сторонами своих обязательств по Соглашению.
                    </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">8.2. Применимым правом по Соглашению является право Республики Казахстан. </font>
                </p>
                <p className="western" align="justify">
                    <font size="3">
                        8.3. Любые разногласия, споры или претензии, которые могут возникнуть между сторонами при исполнении Соглашения,
                        должны быть урегулированы между Компанией и Клиентом посредством консультаций и переговоров. Стороны обязуются в
                        случае возникновения подобных разногласий, споров или претензий, которые не могут быть урегулированы в процессе
                        таких консультаций в течение 15 рабочих дней, урегулировать их исключительно и окончательно в судебном порядке в
                        соответствии с законодательством Республики Казахстан.
                    </font>
                </p>
            </Grid>
        </Grid>
    </>
);

export default UserPolicy;
