import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

import FieldLabel from '@/components/formField/fieldLabel';

export const StyledInputElement = styled('input')(
    ({ success, error, height, type }) => `
  width: 100%;
  font-family: "Inter","Roboto",arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
  color: var(--main-text-color);
  background: var(--input-bg);
  border: 1px solid ${error ? 'red' : success};
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: 0 !important;
  transition: 0.2s;
  height: ${height};
  padding-right:  ${type === 'number' ? '34px' : '15px'};

  &:hover {
    background: var(--input-bg-focused);
    border-color: ${error ? 'red' : success};
  }

  &:focus {
    outline: none;
    background: var(--input-bg-focused);
    border-color: ${error ? 'red' : success};
  }
`
);

const UncontrolledTextInput = React.forwardRef(
    ({ label, type = 'text', id, value, error, placeholder, onChange, innerSx, inputSx, ...props }, ref) => (
        <Box display="inline-block" sx={innerSx}>
            {!!label && (
                <FieldLabel htmlFor={id} required={props.required}>
                    {label}
                </FieldLabel>
            )}
            <StyledInputElement
                {...props}
                id={id}
                type={type}
                name={id}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                error={error}
                success={value?.length > 2 && !error ? '#49C172' : 'transparent'}
                style={inputSx}
                ref={ref}
            />
            <Typography
                className="required_error_message"
                sx={{
                    color: '#FF6464',
                    position: 'absolute',
                    bottom: '-19px',
                    transition: '0.2s',
                    left: 0,
                    opacity: error ? 1 : 0
                }}
            >
                {error}
            </Typography>
        </Box>
    )
);

export default UncontrolledTextInput;
