import { ClickAwayListener } from '@mui/base';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Chip, IconButton, ListSubheader, MenuItem, Paper, Popper, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import StyledCheckbox from '@/components/checkbox';
import FieldLabel from '@/components/formField/fieldLabel';
import UncontrolledTextInput from '@/components/formField/uncontrolledTextInput';

const StyledOption = styled(MenuItem)`
    padding: 0;
    cursor: pointer;
    background: var(--paper-bg);
    color: var(--main-text-color);
    padding-left: 15px;
    & label {
        display: block;
        width: 100%;
        padding: 6px 15px;
        font-weight: 400;
        cursor: pointer;
    }
    &:hover {
        background: var(--select-focused-option);
    }
    &:last-of-type {
        border-bottom: none;
    }
`;
const StyledBadge = styled(Box)`
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background: var(--accent-color);
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    border-radius: 4px;
`;

const StyledChip = styled(Chip)`
    height: auto;
    background: var(--chip-bg);
    padding: 15px 12px 15px 15px;
    margin-right: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--main-text-color);
    border-radius: 5px;
    margin-top: -7px;

    & span {
        max-width: 200px;
        padding: 0 20px 0 0;
    }
    &.MuiChip-deletable .MuiChip-deleteIcon {
        font-size: 16px;
        color: var(--medium-text-color);
    }
`;

const CitySelectNew = React.forwardRef(
    (
        {
            options,
            selectedCities,
            value,
            id,
            required,
            label,
            multiple,
            onChange,
            placeholder,
            inputWrapperProps,
            chips = true,
            saveCity = true,
            onClear,
            ...props
        },
        ref
    ) => {
        const [isOpen, setIsOpen] = useState(false);
        const [allSelected, setAllSelected] = useState({});
        const [inputValue, setInputValue] = useState('');
        const [filteredOptions, setFilteredOptions] = useState([]);
        const [selectedId, setSelectedId] = useState();
        const [selectedList, setSelectedList] = useState(selectedCities || []);
        const anchorRef = useRef();

        useEffect(() => {
            if (selectedCities) {
                setSelectedList(selectedCities);
            }
        }, [selectedCities]);

        const deleteFromList = (id) => {
            const cities = [...selectedList];
            const index = cities.findIndex((objId) => objId === id);
            cities.splice(index, 1);
            setSelectedList(cities);
        };

        const cities = useMemo(() => {
            if (options) {
                return options
                    .map((o) => o.regions)
                    .flat()
                    .map((r) => r.cities)
                    .flat();
            }
            return null;
        }, [options]);

        useEffect(() => {
            if (options) {
                options.forEach((option) => {
                    const regions = option.regions.map((r) => r.name);
                    regions.forEach((r) =>
                        setAllSelected((prevState) => {
                            prevState[r] = false;
                            return prevState;
                        })
                    );
                });
                if (value) {
                    if (multiple) {
                        setSelectedList(value);
                    } else if (cities) {
                        const defaultOption = cities.find((o) => o.id === value);
                        setInputValue(defaultOption?.name || '');
                        setSelectedId(defaultOption?.id);
                    }
                }
                const savedCityId = localStorage.getItem('city_id');

                if (savedCityId && !multiple && cities && saveCity) {
                    const defaultOption = cities.find((o) => o.id === +savedCityId);
                    setInputValue(defaultOption?.name || '');
                    setSelectedId(defaultOption?.id || '');
                }
            }
        }, [options, cities, value]);

        useEffect(() => {
            if (onChange) {
                const e = {
                    target: { name: props.name }
                };
                if (multiple) {
                    e.target.value = selectedList;
                    onChange(e);
                } else {
                    if (saveCity && selectedId) {
                        localStorage.setItem('city_id', selectedId);
                    }
                    e.target.value = selectedId;
                    onChange(e);
                }
            }
        }, [selectedList, selectedId]);

        const onChangeInput = (e) => {
            setSelectedId(undefined);
            setInputValue(e.target.value);
            setFilteredOptions(
                options.map((option) => ({
                    ...option,
                    regions: option.regions.map((region) => ({
                        ...region,
                        cities: region.cities.filter((c) => c.name.toLowerCase().includes(e.target.value.toLowerCase()))
                    }))
                }))
            );
        };

        const onClickOption = (option) => {
            setInputValue(option.name);
            setIsOpen(false);
            setSelectedId(option.id);
            anchorRef.current.blur();
        };

        useEffect(() => {
            if (multiple) {
                if (selectedList.length > 1) {
                    setInputValue('Выбрано несколько');
                } else if (selectedList.length === 1) {
                    const city = cities.find((o) => o.id === selectedList[0]);
                    setInputValue(city.name);
                } else {
                    setInputValue('');
                }
            }
        }, [selectedList]);

        const selectedCitiesObjects = cities?.filter((city) => selectedList?.includes(city.id));

        return (
            <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <Box display="flex" width="100%" flexWrap="wrap" alignItems="center" gap="15px">
                    <Box
                        display="inline-block"
                        position="relative"
                        width="100%"
                        maxWidth={multiple && chips ? '300px' : '100%'}
                        mb="30px"
                        {...inputWrapperProps}
                    >
                        <FieldLabel htmlFor={id} required={required}>
                            {label}
                        </FieldLabel>
                        <Box position="relative" ref={anchorRef}>
                            <UncontrolledTextInput
                                innerSx={{ width: '100%' }}
                                onChange={onChangeInput}
                                onFocus={() => {
                                    setIsOpen(true);
                                    setFilteredOptions(options);
                                }}
                                ref={ref}
                                placeholder={placeholder}
                                autoComplete="off"
                                id={id}
                                {...props}
                                value={inputValue}
                            />
                            {selectedList?.length > 1 ? <StyledBadge className="select_badge">{selectedList?.length}</StyledBadge> : null}
                            {!required && inputValue && (
                                <Tooltip title="Сбросить">
                                    <IconButton
                                        onClick={(e) => {
                                            setInputValue('');
                                            setIsOpen(false);
                                            setAllSelected({});
                                            setFilteredOptions([]);
                                            setSelectedId(undefined);
                                            setSelectedList([]);
                                            if (onClear) {
                                                onClear();
                                            }
                                        }}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '10px',
                                            transform: 'translateY(-50%)',
                                            color: 'var(--main-text-color)'
                                        }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                    <Popper open={isOpen} anchorEl={anchorRef.current}>
                        <Paper
                            square
                            style={{
                                width: anchorRef.current ? anchorRef.current.clientWidth : null,
                                maxHeight: '250px',
                                overflowY: 'auto'
                            }}
                        >
                            {filteredOptions?.map((option) => (
                                <Box key={option.id}>
                                    {option.regions.reduce((acc, region) => acc.concat(region.cities), []).length > 0 && (
                                        <ListSubheader
                                            sx={{
                                                backgroundColor: 'var(--paper-bg)',
                                                color: 'var(--main-text-color)',
                                                p: '6px 16px',
                                                lineHeight: 'normal'
                                            }}
                                        >
                                            {option.name}
                                        </ListSubheader>
                                    )}
                                    {option.regions?.map((region) => (
                                        <>
                                            {region.cities.length > 0 && (
                                                <ListSubheader
                                                    key={`${option.id}-${region.id}`}
                                                    sx={{
                                                        backgroundColor: 'var(--paper-bg)',
                                                        color: 'var(--main-text-color)',
                                                        p: '6px 16px',
                                                        pl: '21px',
                                                        lineHeight: 'normal'
                                                    }}
                                                >
                                                    {multiple ? (
                                                        <StyledCheckbox
                                                            checked={allSelected[region.name]}
                                                            onChange={() => {
                                                                if (allSelected[region.name]) {
                                                                    const regionCitiesIds = region.cities.map((c) => c.id);
                                                                    setSelectedList((prevState) =>
                                                                        prevState.filter((id) => !regionCitiesIds.includes(id))
                                                                    );
                                                                } else {
                                                                    region.cities.forEach((city) => {
                                                                        if (!selectedList.includes(city.id))
                                                                            setSelectedList((prevState) => prevState.concat(city.id));
                                                                    });
                                                                }
                                                                setAllSelected((prevState) => {
                                                                    prevState[region.name] = !prevState[region.name];
                                                                    return prevState;
                                                                });
                                                            }}
                                                            id={region.id}
                                                            label={region.name}
                                                        />
                                                    ) : (
                                                        region.name
                                                    )}
                                                </ListSubheader>
                                            )}

                                            {region.cities.map((city) => (
                                                <StyledOption
                                                    key={`${option.id}-${region.id}-${city.id}`}
                                                    onClick={!multiple ? () => onClickOption(city) : null}
                                                >
                                                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                    <label htmlFor={city.id}>
                                                        {multiple && (
                                                            <StyledCheckbox
                                                                checked={selectedList.includes(city.id)}
                                                                onChange={() => {
                                                                    setSelectedList((prevState) =>
                                                                        !prevState.includes(city.id)
                                                                            ? prevState.concat(city.id)
                                                                            : prevState.filter((elem) => elem !== city.id)
                                                                    );
                                                                }}
                                                                id={city.id}
                                                            />
                                                        )}

                                                        {city.name}
                                                    </label>
                                                </StyledOption>
                                            ))}
                                        </>
                                    ))}
                                </Box>
                            ))}
                        </Paper>
                    </Popper>
                    {multiple &&
                        chips &&
                        selectedCitiesObjects?.map((city) => (
                            <StyledChip key={city.id} label={city.name} onDelete={() => deleteFromList(city.id)} />
                        ))}
                </Box>
            </ClickAwayListener>
        );
    }
);

export default CitySelectNew;
