import React from 'react';
import { Route, Routes } from 'react-router';

import ArmListLayout from '@/components/layouts/armListLayout';
import OrderGroupsDetails from '@/views/orderGroups/details';
import OrderGroupsList from '@/views/orderGroups/list';

const OrderGroups = () => (
    <>
        <ArmListLayout>
            <Routes>
                <Route element={<OrderGroupsList />} path="" />
                <Route element={<OrderGroupsDetails />} path=":id" />
            </Routes>
        </ArmListLayout>
    </>
);

export default OrderGroups;
