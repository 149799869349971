import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import AddressAutocomplete from '@/components/addressAutocomplete';
import StyledCheckbox from '@/components/checkbox';
import CitySelectNew from '@/components/citySelectNew';
import LocalizedDatePicker from '@/components/datePicker';
import FieldLabel from '@/components/formField/fieldLabel';
import MaskedInput from '@/components/formField/maskedInput';
import PhoneNumberInput from '@/components/formField/phoneNumberInput';
import SelectInput from '@/components/formField/selectInput';
import TextInput from '@/components/formField/textInput';
import FormModal from '@/components/formModal';
import useAutocomplete from '@/core/hooks/useAutocomplete';
import i18n from '@/core/i18n';
import { itemServices } from '@/core/services/itemServices';
import { orderChainServices } from '@/core/services/orderChainServices';
import { PRODUCTS_LIST } from '@/core/utils/constants/queryKeys';
import { checkIin } from '@/core/utils/utils';
import { addNotification } from '@/toolkit/reducers/toastr';
import ShipmentPointsField from '@/views/orderChain/components/shipmentPointsField';
import { stageTypes } from '@/views/orderChain/utils';

const { t } = i18n;

const schema = yup.object({
    stageIsLast: yup.boolean(),
    stageType: yup.string().required(t('errors:required')),
    stageProductId: yup.string().required(t('errors:required')),
    stageOrderDeliveryType: yup.string().required(t('errors:required')),
    stageOrderReceiverName: yup.string().required(t('errors:required')),
    stageOrderReceiverAddress: yup.string().when('stageOrderDeliveryType', (value, schema) => {
        if (value !== 'pickup') {
            return schema.required(t('errors:required'));
        }
        return schema.notRequired();
    }),
    stageOrderShipmentPoint: yup.string().when('stageOrderDeliveryType', (value, schema) => {
        if (value === 'pickup') {
            return schema.required(t('errors:required'));
        }
        return schema.notRequired();
    }),
    stageOrderReceiverPhoneNumber: yup.string().required(t('errors:required')),
    stageOrderReceiverCityId: yup.string().required(t('errors:required')),
    stageOrderDeliveryDate: yup.date().required(t('errors:required')),
    stageOrderComment: yup.string(),
    stageOrderReceiverUinRequired: yup.boolean(),
    stageOrderReceiverUin: yup.string().when('stageOrderReceiverUinRequired', (value, schema) => {
        if (value) {
            return schema
                .required(t('errors:required'))
                .matches(/\d{12}/, t('errors:uinLengthRequired'))
                .test('recipientUin', t('errors:incorrectUin'), (value) => checkIin(value));
        }
        return schema.notRequired();
    })
});

const CreateStageModal = ({
    partners,
    countries,
    cities,
    selectedPartner,
    stages,
    setStages,
    handleClose,
    shouldAddStage,
    orderChainId,
    orderChainQueryKey,
    orderChainReceiverLatLon,
    orderChainGetValues
}) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const partnerId = selectedPartner.id;

    const defaultExpectedDate = new Date();
    defaultExpectedDate.setDate(defaultExpectedDate.getDate() + 1);
    defaultExpectedDate.setHours(23);
    defaultExpectedDate.setMinutes(59);

    const {
        control,
        watch,
        setValue,
        register,
        reset,
        getValues,
        handleSubmit,
        trigger,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: { stageOrderDeliveryDate: defaultExpectedDate, stageOrderReceiverUinRequired: false, stageIsLast: false }
    });

    const { data: productsData } = useQuery([PRODUCTS_LIST, partnerId], () => itemServices.getItemList(partnerId), {
        enabled: !!partnerId
    });
    const products = productsData?.data;

    const selectedProduct = watch('stageProductId') && products.find((p) => p.id === getValues('stageProductId'));

    const stageOrderAddressAutocomplete = useAutocomplete(['stageOrderReceiverAddress'], setValue, () =>
        trigger('stageOrderReceiverAddress')
    );

    const {
        openMap: openStageOrderMap,
        setMapOpened: setStageOrderMapOpened,
        onSelectAddress: onSelectStageOrderAddress,
        mapOpened: stageOrderMapOpened,
        selectAddressOnMap: selectStageOrderAddressOnMap,
        latlon: stageOrderLatLon
    } = stageOrderAddressAutocomplete;

    const addStageMutation = useMutation(({ orderChainId, data }) => orderChainServices.addStage({ orderChainId, data }));

    const onSubmit = async (data) => {
        const stage = {
            is_last: data.stageIsLast,
            type: data.stageType,
            position: stages.length,
            order: {
                city_id: data.stageOrderReceiverCityId,
                comment: data.stageOrderComment,
                delivery_datetime: data.stageOrderDeliveryDate,
                item_id: data.stageProductId,
                shipment_point_id: data.stageOrderShipmentPoint,
                receiver_name: data.stageOrderReceiverName,
                receiver_phone_number: data.stageOrderReceiverPhoneNumber.replaceAll(/[() -]/g, ''),
                receiver_iin: data.stageOrderReceiverUin,
                type: data.stageOrderDeliveryType,
                callbacks: {},
                address: data.stageOrderReceiverAddress,
                latitude: Number(stageOrderLatLon.lat).toFixed(8),
                longitude: Number(stageOrderLatLon.lon).toFixed(8)
            }
        };
        if (shouldAddStage) {
            try {
                const result = await addStageMutation.mutateAsync({ orderChainId, data: stage });
                setStages((p) => p.concat(result.data));
                dispatch(addNotification({ message: 'Этап успешно добавлен' }));
                await queryClient.invalidateQueries({ queryKey: orderChainQueryKey });
                handleClose();
            } catch (error) {
                dispatch(addNotification({ message: 'Не удалось создать этап', type: 'error' }));
            }
        } else {
            setStages((p) => p.concat(stage));
            dispatch(addNotification({ message: 'Этап успешно добавлен' }));
            handleClose();
        }
    };

    function onCheckStageIsLast(checked) {
        if (checked) {
            setValue('stageOrderReceiverName', orderChainGetValues('receiverName'));
            setValue('stageOrderReceiverPhoneNumber', orderChainGetValues('receiverPhoneNumber'));
            setValue('stageOrderReceiverCityId', orderChainGetValues('receiverCityId'));
            setValue('stageOrderReceiverAddress', orderChainGetValues('receiverAddress'));
            stageOrderLatLon.lat = orderChainReceiverLatLon.lat;
            stageOrderLatLon.lon = orderChainReceiverLatLon.lon;
        } else {
            setValue('stageOrderReceiverName', '');
            setValue('stageOrderReceiverPhoneNumber', '');
            //                тут специально ' ' ↓, чтобы сбросить значение в CitySelectNew
            setValue('stageOrderReceiverCityId', ' ');
            setValue('stageOrderReceiverAddress', '');
            stageOrderLatLon.lat = undefined;
            stageOrderLatLon.lon = undefined;
        }
    }

    return (
        <FormModal
            title="Создание этапа"
            buttonText="Создать"
            isShowing={true}
            onSubmit={handleSubmit(onSubmit)}
            handleClose={handleClose}
            isValid={true}
        >
            <StyledCheckbox
                id="stageIsLast"
                label="Последний этап"
                onChange={(e) => {
                    setValue('stageIsLast', e.target.checked);
                    onCheckStageIsLast(e.target.checked);
                }}
                checked={watch('stageIsLast')}
            />
            <SelectInput
                ContainerProps={{ mt: 3 }}
                id="stageType"
                label="Тип этапа"
                options={stageTypes}
                required
                control={control}
                errors={errors}
                {...register('stageType')}
            />
            <SelectInput
                id="stageProductId"
                label={t('orders:create.labels.product')}
                options={products}
                disabled={!selectedPartner}
                disabledText="Сначала выберите партнера"
                required
                control={control}
                errors={errors}
                {...register('stageProductId', {
                    onChange: (e) => {
                        const hasPostcontrol = products.find((p) => p.id === e.target.value).has_postcontrol;
                        setValue('stageOrderReceiverUinRequired', hasPostcontrol);
                    }
                })}
            />
            <SelectInput
                id="stageOrderDeliveryType"
                label={t('orders:create.labels.type')}
                type="text"
                required
                disabled={!selectedProduct}
                disabledText="Сначала выберите продукт"
                control={control}
                errors={errors}
                options={
                    selectedProduct?.delivery_type?.includes('pickup')
                        ? [
                              { id: 'urgent', name: t('constants:orderTypes.urgent') },
                              { id: 'planned', name: t('constants:orderTypes.planned') },
                              { id: 'pickup', name: t('constants:orderTypes.pickup') }
                          ]
                        : [
                              { id: 'urgent', name: t('constants:orderTypes.urgent') },
                              { id: 'planned', name: t('constants:orderTypes.planned') }
                          ]
                }
                {...register('stageOrderDeliveryType')}
            />
            <TextInput
                id="stageOrderReceiverName"
                type="text"
                label={t('orders:create.labels.name')}
                required
                control={control}
                errors={errors}
                {...register('stageOrderReceiverName')}
            />
            {watch('stageOrderReceiverUinRequired') && (
                <MaskedInput
                    id="stageOrderReceiverUin"
                    label={t('orders:create.labels.uin')}
                    control={control}
                    errors={errors}
                    required
                    mask="999999999999"
                    {...register('stageOrderReceiverUin')}
                />
            )}
            <Box mb="30px">
                <PhoneNumberInput
                    id="stageOrderReceiverPhoneNumber"
                    name="stageOrderReceiverPhoneNumber"
                    defaultCountry="kz"
                    control={control}
                    errors={errors}
                    label={t('orders:create.labels.phoneNumber')}
                    required
                    {...register('stageOrderReceiverPhoneNumber')}
                />
            </Box>
            <CitySelectNew
                id="stageOrderReceiverCityId"
                label={t('orderChain:create.labels.receiverCity')}
                options={countries.data}
                saveCity={false}
                required
                value={watch('stageOrderReceiverCityId')}
                {...register('stageOrderReceiverCityId')}
            />
            {watch('stageOrderDeliveryType') === 'pickup' ? (
                <ShipmentPointsField
                    partners={partners}
                    selectedPartnerId={partnerId}
                    selectedCityId={watch('stageOrderReceiverCityId')}
                    control={control}
                    errors={errors}
                    register={register}
                    registerId="stageOrderShipmentPoint"
                    t={t}
                />
            ) : (
                <AddressAutocomplete
                    id="stageOrderReceiverAddress"
                    control={control}
                    errors={errors}
                    required
                    register={register}
                    city={cities?.find((c) => c.id === watch('stageOrderReceiverCityId'))?.name}
                    disabled={!watch('stageOrderReceiverCityId')}
                    openMap={openStageOrderMap}
                    setMapOpened={setStageOrderMapOpened}
                    onSelect={onSelectStageOrderAddress}
                    mapOpened={stageOrderMapOpened}
                    selectAddressOnMap={selectStageOrderAddressOnMap}
                />
            )}
            <Box mb="30px">
                <FieldLabel>{t('orders:create.labels.date')}</FieldLabel>
                <LocalizedDatePicker
                    sx={{
                        width: '100%',
                        '& .MuiFormControl-root': { width: '100% !important' }
                    }}
                    minDate={new Date()}
                    value={watch('stageOrderDeliveryDate')}
                    onChange={(v) => setValue('stageOrderDeliveryDate', v)}
                />
                {errors.date && (
                    <Typography
                        sx={{
                            color: '#FF6464',
                            marginTop: '6px !important'
                        }}
                    >
                        {errors.date.message}
                    </Typography>
                )}
            </Box>
            <TextInput
                id="stageOrderComment"
                label="Комментарий к этапу"
                type="text"
                control={control}
                errors={errors}
                {...register('stageOrderComment')}
            />
        </FormModal>
    );
};

export default CreateStageModal;
