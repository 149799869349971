import { Box, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { httpClient } from '@/api/httpClient/httpClient';
import { RoundedButton } from '@/components/button/styledButtons';
import DetailsInfoBlock from '@/components/detailsInfoBlock';
import FieldLabel from '@/components/formField/fieldLabel';
import UncontrolledSelect from '@/components/formField/uncontrolledSelect';
import { courierServices } from '@/core/services/courierServices';
import { itemServices } from '@/core/services/itemServices';
import { shipmentPointServices } from '@/core/services/shipmentPointServices';
import { SERVICE_MANAGER } from '@/core/utils/constants/constants';
import { PRODUCTS_LIST, SHIPMENT_POINTS } from '@/core/utils/constants/queryKeys';
import { addNotification } from '@/toolkit/reducers/toastr';

const PartnerSettings = ({ profileType, organizationId }) => {
    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState(null);

    const dispatch = useDispatch();

    console.log(profileType);

    const {
        data: settings,
        isLoading: isSettingsLoading,
        refetch: refetchSettings
    } = useQuery(['settings', profileType], () => httpClient.get('/partner/settings/get'), { enabled: profileType === SERVICE_MANAGER });
    const { data: shipmentPoints, isLoading: isShipmentPointsLoading } = useQuery(
        [SHIPMENT_POINTS, profileType],
        () => shipmentPointServices.getList({ partner_id: organizationId }),
        { enabled: profileType === SERVICE_MANAGER }
    );
    const { data: products, isLoading: isProductsLoading } = useQuery([PRODUCTS_LIST, profileType], () => itemServices.getItemList(), {
        enabled: profileType === SERVICE_MANAGER
    });
    // const { data: couriers, isLoading: isCouriersLoading } = useQuery(
    //     [`acceptedCouriers`, selectedDeliveryPoint?.city?.id],
    //     () => courierServices.getCouriersListShort(selectedDeliveryPoint?.city?.id),
    //     { enabled: !!selectedDeliveryPoint }
    // );

    const updateSettingsMutation = useMutation((data) => httpClient.patch('/partner/settings/patch', data));

    const points = shipmentPoints?.data?.items;

    const { getValues, watch, setValue, register } = useForm({
        mode: 'onChange',
        defaultValues: {
            default_delivery_point_for_order_group_id: '',
            auto_item_for_order_group_id: ''
            // default_order_group_courier_id: ''
        }
    });

    useEffect(() => {
        if (settings) {
            setValue('default_delivery_point_for_order_group_id', settings.data.default_delivery_point_for_order_group?.id, true);
            setValue('auto_item_for_order_group_id', settings.data.auto_item_for_order_group?.id, true);
            // setValue('default_order_group_courier_id', settings.data.default_order_group_courier?.id, true);
        }
    }, [settings]);

    useEffect(() => {
        if (watch().default_delivery_point_for_order_group_id) {
            setSelectedDeliveryPoint(points?.find((p) => p.id === watch().default_delivery_point_for_order_group_id));
        }
    }, [shipmentPoints, watch().default_delivery_point_for_order_group_id]);

    useEffect(() => {
        register('default_delivery_point_for_order_group_id');
        register('auto_item_for_order_group_id');
        // register('default_order_group_courier_id');
    }, [register]);

    return (
        <>
            <DetailsInfoBlock title="Настройки организации" visible={profileType === SERVICE_MANAGER}>
                {isShipmentPointsLoading || isProductsLoading || isSettingsLoading ? (
                    <Skeleton sx={{ width: '100%', height: '150px', transform: 'scale(1, 1)' }} />
                ) : (
                    <>
                        <DetailsInfoBlock.CustomChild>
                            <FieldLabel>Адрес офиса курьерской службы</FieldLabel>
                            <UncontrolledSelect
                                options={points}
                                disabled={!points?.length}
                                placeholder="Выберите офис"
                                disabledText="У вас нет точек вывоза"
                                onChange={(e) => {
                                    setValue('default_delivery_point_for_order_group_id', e.target.value, true);
                                }}
                                value={getValues().default_delivery_point_for_order_group_id}
                            />
                        </DetailsInfoBlock.CustomChild>
                        <DetailsInfoBlock.CustomChild>
                            <FieldLabel>Продукт по умолчанию для группы заявок</FieldLabel>
                            <UncontrolledSelect
                                options={products?.data}
                                disabled={!products?.data.length || !points.length}
                                placeholder="Выберите продукт"
                                disabledText="Сначала выберите адрес курьерской службы"
                                onChange={(e) => {
                                    setValue('auto_item_for_order_group_id', e.target.value, true);
                                }}
                                value={getValues().auto_item_for_order_group_id}
                            />
                        </DetailsInfoBlock.CustomChild>
                        {/* <DetailsInfoBlock.CustomChild>
                            <FieldLabel>Курьер по умолчанию для группы заявок</FieldLabel>
                            <UncontrolledSelect
                                options={
                                    couriers?.data?.map((courier) => ({
                                        name: `${courier.user.first_name} ${courier.user.last_name}`,
                                        id: courier.id
                                    })) || []
                                }
                                disabled={!couriers?.data?.length || !points.length}
                                placeholder="Выберите курьера"
                                disabledText="Сначала выберите адрес курьерской службы"
                                onChange={(e) => {
                                    setValue('default_order_group_courier_id', e.target.value, true);
                                }}
                                value={getValues().default_order_group_courier_id}
                            />
                        </DetailsInfoBlock.CustomChild> */}
                        <Box width="100%">
                            <RoundedButton
                                onClick={async () => {
                                    const data = { ...getValues() };
                                    // eslint-disable-next-line no-restricted-syntax
                                    for (const key in data) {
                                        if (!key[data]) delete key[data];
                                    }
                                    await updateSettingsMutation.mutateAsync(data);
                                    refetchSettings();
                                    dispatch(addNotification({ message: 'Настройки изменены' }));
                                }}
                            >
                                Сохранить
                            </RoundedButton>
                        </Box>
                    </>
                )}
            </DetailsInfoBlock>
        </>
    );
};

export default PartnerSettings;
