import { Box, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import loader from '@/assets/images/icons/loader.svg';
import TextInput from '@/components/formField/textInput';
import MapModal from '@/components/mapModal';
import { useThrottle } from '@/core/hooks/useThrottle';
import Geocoder from '@/core/utils/Geocoder';

const InputWrapper = styled(Box)`
    position: relative;
`;

const MapLink = styled('span')(
    ({ city }) => `
    color: #49C172;
    font-size: 12px;
    position: absolute;
    top: 47px;
    right: 30px;
    transform: translateY(-50%);
    text-decoration: underline;
    cursor: ${city ? 'pointer' : 'not-allowed'};
`
);

const Loader = styled(Box)`
    position: absolute;
    right: 0;
    background: url(${loader}) 50% 50% no-repeat;
    background-size: 25px;
    width: 30px;
    height: 30px;
    top: calc(50% - 3px);
`;

const AddressAutocomplete = React.forwardRef(
    ({
        onChange,
        onSelect,
        city,
        control,
        register,
        disabled,
        errors,
        setError,
        openMap,
        required,
        mapOpened,
        setMapOpened,
        selectAddressOnMap,
        ...props
    }) => {
        const { t } = useTranslation();
        const [isOpen, setIsOpen] = useState(false);
        const [search, setSearch] = useState(null);
        const throttleSearch = useThrottle(search, 2000);
        const anchorRef = useRef();

        const geocoder = useRef(new Geocoder('2gis'));

        const { data: results, isLoading } = useQuery(['search', throttleSearch], () => geocoder.current.geocode(throttleSearch, city), {
            enabled: !!throttleSearch
        });

        const onChangeAddress = async ({ target: { value } }) => {
            setSearch(value);
        };
        const onSelectAddress = (item) => {
            onSelect(item);
            anchorRef.current.blur();
        };

        return (
            <>
                <InputWrapper>
                    <TextInput
                        id={props.id}
                        label={t('components:addressAutocomplete.labels.address')}
                        autocomplete="off"
                        control={control}
                        errors={errors}
                        onFocus={() => {
                            setIsOpen(true);
                        }}
                        placeholder={
                            disabled
                                ? t('components:addressAutocomplete.selectCityFirst')
                                : t('components:addressAutocomplete.enterAddress')
                        }
                        disabled={disabled}
                        InputProps={{ paddingRight: '90px' }}
                        type="text"
                        required={required}
                        {...register(props.id, {
                            onChange: onChangeAddress,
                            onBlur: () => {
                                setIsOpen(false);
                            }
                        })}
                        ref={anchorRef}
                    />
                    <MapLink onClick={city ? openMap : null} city={city}>
                        {t('components:addressAutocomplete.onMap')}
                    </MapLink>
                    <Popper open={isOpen} anchorEl={anchorRef.current}>
                        <Paper
                            square
                            style={{
                                width: anchorRef.current ? anchorRef.current.clientWidth : null,
                                maxHeight: '250px',
                                overflowY: 'auto'
                            }}
                        >
                            {!!results?.length && (
                                <MenuList>
                                    {results.map((address) => (
                                        <MenuItem
                                            sx={{ color: 'var(--medium-text-color)' }}
                                            key={address.id}
                                            onMouseDown={(e) => e.preventDefault()}
                                            onClick={() => onSelectAddress(address)}
                                        >
                                            {address.address}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            )}
                        </Paper>
                    </Popper>
                    {isLoading ? <Loader /> : null}
                </InputWrapper>
                <MapModal isShowing={mapOpened} close={() => setMapOpened(false)} selectedCity={city} clickHandler={selectAddressOnMap} />
            </>
        );
    }
);

export default AddressAutocomplete;
