import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, Fade, IconButton, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import L from 'leaflet';
import React from 'react';

import Map from '../map/osm';

const useStyles = makeStyles(() => ({
    mapModal: {
        position: 'fixed',
        width: '80%',
        height: '80%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 10000,
        overflow: 'hidden',
        boxShadow: '0px 3px 25px -6px rgb(0 0 0 / 50%)',
        borderRadius: '10px'
    }
}));
const CloseButton = styled(IconButton)`
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    right: 70px;
    background: #fff9;
    box-shadow: 0 0 12px -2px rgb(0 0 0 / 50%);
    z-index: 1000;
    &:hover {
        background: #ffff;
    }
`;

const MapModal = ({ isShowing, close, clickHandler, selectedCity }) => {
    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isShowing}
            closeAfterTransition
            BackdropComponent={Backdrop}
        >
            <Fade in={isShowing}>
                <div className={classes.mapModal}>
                    <Map clickHandler={clickHandler} selectedCity={selectedCity} />
                    <CloseButton onClick={close}>
                        <CloseIcon />
                    </CloseButton>
                </div>
            </Fade>
        </Modal>
    );
};

export default MapModal;
