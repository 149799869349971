import { serverUrl } from './constants/constants';

const toString = (query) => {
    const toJSON = (param) => (typeof param === `object` ? JSON.stringify(param) : param);
    const urify = (param) => toJSON(param);
    return Object.keys(query)
        .map((key) => `${urify(key)}=${encodeURIComponent(urify(query[key]))}`)
        .join(`&`);
};

const request = (method) => (endpoint, params) => {
    const token = localStorage.getItem('user') || null;

    let uri = `${serverUrl}${endpoint}`;

    const opts = {
        headers: {
            'Content-Type': `application/json; charset=utf-8`,
            accept: `*/*`,
            'Access-Control-Allow-Origin': 'no-cors'
        },
        method
    };

    if (/GET|DELETE/.test(method) && params) {
        if (Object.entries(params).length > 0) {
            uri = `${serverUrl}${endpoint}?${toString(params)}`;
        }
    }

    if (/PATCH|POST|PUT/.test(method)) {
        opts.body = JSON.stringify(params);
    }
    return fetch(uri, opts).then((res) => {
        if (res.status === 401) {
            localStorage.removeItem('user');
            window.location.href = `${window.location.origin}/login`;
        }
        return res;
    });
};

export default {
    get: request(`GET`),
    patch: request(`PATCH`),
    post: request(`POST`),
    put: request(`PUT`),
    delete: request(`delete`)
};
