import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import { MainModal, WithCheck, WithOutCheck, ModalApplication } from '@/components/createComponents';

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '35rem',
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: 24,
        padding: '2rem 1rem'
    },
    btn: {
        color: theme.palette.success.main,
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        border: `1px solid ${theme.palette.grey[450]}`,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.background.paperLight
        }
    }
}));

const ModalWindow = ({ handleClose, open, setPage, type, setOpenFormTablePage }) => {
    const [createState, setCreateState] = useState(null);
    const [switchModal, setSwitchModal] = useState(false);
    const classes = useStyles();
    const [state, setState] = useState();
    const theme = useTheme();

    useEffect(() => {
        switch (type) {
            case 'application':
                setState({
                    modalComp: <ModalApplication handleClose={handleClose} setOpenFormTablePage={setOpenFormTablePage} />,
                    styling: { backgroundColor: theme.palette.grey[200], borderRadius: 15 }
                });
                break;
            case 'partner':
                setState({
                    modalComp: (
                        <WithOutCheck
                            handleClose={handleClose}
                            setCreateState={setCreateState}
                            setSwitchModal={setSwitchModal}
                            switchModal={switchModal}
                        />
                    ),
                    styling: { backgroundColor: 'none' }
                });
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        if (createState !== null) {
            setPage((prev) => prev + 1);
        }
    }, [switchModal]);

    // const mapShowModal = new Map();
    // mapShowModal.set(0, state?.modalComp);
    // mapShowModal.set(1, createState ? () => <WithCheck handleClose={handleClose} /> : () => <WithOutCheck handleClose={handleClose} />);

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className={classes.modal} style={state?.styling}>
                {state?.modalComp}
            </Box>
        </Modal>
    );
};

export default ModalWindow;
