import { Chip, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonWithLoader, RoundedOutlinedButton } from '@/components/button/styledButtons';
import CommonModal from '@/components/commonModal';
import DateRangeModal from '@/components/dateRangeModal';
import useModal from '@/core/hooks/useModal';
import { DEFAULT_RANGE } from '@/core/utils/constants/constants';
import Controls from '@/views/orders/components/distributeModal/controls';
import OrdersList from '@/views/orders/components/distributeModal/ordersList';

const DistributeModal = ({
    close,
    showing,
    title,
    leftColumnTitle,
    rightColumnTitle,
    leftColumnOrders,
    rightColumnOrders,
    isLoading,
    mutationLoading,
    onSubmit,
    forceEnableButton,
    dateFilter = true
}) => {
    const { t } = useTranslation();

    const [ordersForDistribute, setOrdersForDistribute] = useState([]);
    const [excludedOrderIds, setExcludedOrderIds] = useState([]);
    const [selectedOrderIds, setSelectedOrderIds] = useState([]);
    const [selectedExcludedOrderIds, setSelectedExcludedOrderIds] = useState([]);
    const [ordersState, setOrdersState] = useState([]);
    const [excludedOrdersState, setExcludedOrdersState] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedExcludedProducts, setSelectedExcludedProducts] = useState([]);
    const [selectedExcludedCities, setSelectedExcludedCities] = useState([]);
    const [dateValue, setDateValue] = useState();
    const [range, setRange] = useState(DEFAULT_RANGE);

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        if (leftColumnOrders) setOrders(leftColumnOrders);
        if (rightColumnOrders?.length) {
            setOrders(leftColumnOrders?.concat(rightColumnOrders));
            setExcludedOrderIds(rightColumnOrders?.map((o) => o.id));
        }
    }, [leftColumnOrders, rightColumnOrders]);

    const [openedDateModal, openDateModal, closeDateModal] = useModal();

    const getExcludedOrders = () => orders?.filter((o) => excludedOrderIds.includes(o.id));
    const getOrders = () => orders?.filter((o) => !excludedOrderIds.includes(o.id));
    const getOrdersCount = () => orders?.filter((o) => !excludedOrderIds.includes(o.id)).length;
    const getFilteredOrders = (orders, selectedProducts, selectedCities) => {
        let filteredOrders = [...(orders || [])];
        if (selectedProducts.length > 0) {
            filteredOrders = filteredOrders.filter((order) => selectedProducts.includes(order.item_id));
        }
        if (selectedCities.length > 0) {
            filteredOrders = filteredOrders.filter((order) => selectedCities.includes(order.city_id));
        }
        if (range[0].startDate) {
            filteredOrders = filteredOrders.filter(
                (order) =>
                    new Date(order.delivery_datetime).setHours(0, 0, 0, 0) >= new Date(range[0].startDate).setHours(0, 0, 0, 0) &&
                    new Date(order.delivery_datetime).setHours(0, 0, 0, 0) <= new Date(range[0].endDate).setHours(0, 0, 0, 0)
            );
        }
        return filteredOrders;
    };

    const changeDate = (newValue) => {
        setDateValue(newValue);
    };

    const filterOrders = (e, setOrders, ordersColumn, baseOrders) => {
        const { value } = e.target;
        setOrders(
            baseOrders
                ?.filter((order) => ordersColumn.includes(order.id))
                .filter(
                    (order) =>
                        order.receiver_iin?.includes(value) ||
                        order.receiver_phone_number?.includes(value) ||
                        order.receiver_name?.toLowerCase().includes(value.toLowerCase())
                )
        );
    };

    const orderIdsRef = useRef();

    const selectOrderToExclude = (id) => {
        setSelectedOrderIds((prevState) => (!prevState.includes(id) ? prevState.concat(id) : prevState.filter((elem) => elem !== id)));
    };
    useEffect(() => {
        setOrdersState(getFilteredOrders(getOrders(), selectedProducts, selectedCities));
    }, [orders, selectedProducts, selectedCities, range]);

    useEffect(() => {
        setExcludedOrdersState(getFilteredOrders(getExcludedOrders(), selectedExcludedProducts, selectedExcludedCities));
    }, [orders, selectedExcludedProducts, selectedExcludedCities, range]);

    const selectOrderToInclude = (id) => {
        setSelectedExcludedOrderIds((prevState) =>
            !prevState.includes(id) ? prevState.concat(id) : prevState.filter((elem) => elem !== id)
        );
    };

    const excludeOrders = () => {
        setExcludedOrderIds((prevState) => prevState.concat(selectedOrderIds));
        setSelectedOrderIds([]);
    };
    const includeOrders = () => {
        setExcludedOrderIds((prevState) => prevState.filter((id) => !selectedExcludedOrderIds.includes(id)));
        setSelectedExcludedOrderIds([]);
    };

    useEffect(() => {
        setOrdersForDistribute(getOrders()?.map((o) => o.id));
        setExcludedOrdersState(getFilteredOrders(getExcludedOrders(), selectedExcludedProducts, selectedExcludedCities));
        setOrdersState(getFilteredOrders(getOrders(), selectedProducts, selectedCities));
    }, [excludedOrderIds]);

    useEffect(() => {
        if (orders) {
            orderIdsRef.current = getOrders()?.map((o) => o.id);
            setOrdersState(orders.filter((order) => !excludedOrderIds.includes(order.id)));
            setOrdersForDistribute(orderIdsRef.current);
        }
    }, [orders]);

    const closeModal = () => {
        setOrdersForDistribute([]);
        setExcludedOrdersState([]);
        setOrdersState([]);
        setSelectedOrderIds([]);
        setExcludedOrderIds([]);
        // setOrders([]);
        close();
    };

    useEffect(() => {
        if (!showing) closeModal();
    }, [showing]);

    const submitOrders = () => {
        onSubmit(ordersForDistribute);
    };

    return (
        <>
            <CommonModal extraStyles={{ width: '1130px' }} title={title} isShowing={showing} close={closeModal} isLoading={mutationLoading}>
                <Grid container direction="row" alignItems="stretch" justifyContent="space-between">
                    <Grid item width="470px">
                        <Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>{leftColumnTitle}</div>
                                <Chip
                                    style={{ marginLeft: '5px', paddingLeft: '2px', paddingRight: '2px', color: 'var(--mein-text-color)' }}
                                    size="small"
                                    label={getOrdersCount() || 0}
                                />
                                {dateFilter && <DateRangeModal.Button range={range} setRange={setRange} openModal={openDateModal} />}
                            </div>
                        </Typography>
                        <OrdersList
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                            selectedCities={selectedCities}
                            setSelectedCities={setSelectedCities}
                            orders={ordersState}
                            selectOrder={selectOrderToExclude}
                            selectedOrders={selectedOrderIds}
                            dateValue={dateValue}
                            changeDate={changeDate}
                            search={(e) =>
                                filterOrders(
                                    e,
                                    setOrdersState,
                                    ordersForDistribute,
                                    getFilteredOrders(getOrders(), selectedProducts, selectedCities)
                                )
                            }
                            setSelectedOrders={setSelectedOrderIds}
                            loading={isLoading}
                        />
                    </Grid>
                    <Grid item display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <Controls
                            excludeOrders={excludeOrders}
                            includeOrders={includeOrders}
                            disabledInclude={!selectedExcludedOrderIds?.length}
                            disabledExclude={!selectedOrderIds?.length}
                        />
                    </Grid>
                    <Grid item width="470px">
                        <Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>{rightColumnTitle}</div>
                                <Chip
                                    style={{ marginLeft: '5px', paddingLeft: '2px', paddingRight: '2px', color: 'var(--mein-text-color)' }}
                                    size="small"
                                    label={orders?.length - getOrdersCount() || 0}
                                />
                            </div>
                        </Typography>
                        <OrdersList
                            selectedProducts={selectedExcludedProducts}
                            selectedCities={selectedExcludedCities}
                            setSelectedCities={setSelectedExcludedCities}
                            setSelectedProducts={setSelectedExcludedProducts}
                            orders={excludedOrdersState}
                            selectedOrders={selectedExcludedOrderIds}
                            selectOrder={selectOrderToInclude}
                            search={(e) =>
                                filterOrders(
                                    e,
                                    setExcludedOrdersState,
                                    excludedOrderIds,
                                    getFilteredOrders(getExcludedOrders(), selectedExcludedProducts, selectedExcludedCities)
                                )
                            }
                            setSelectedOrders={setSelectedExcludedOrderIds}
                        />
                    </Grid>
                </Grid>
                <Box textAlign="right" mt="40px">
                    <ButtonWithLoader
                        sx={{ mr: '10px' }}
                        disabled={forceEnableButton ? false : !ordersForDistribute?.length}
                        variant="contained"
                        color="success"
                        onClick={submitOrders}
                        loading={mutationLoading}
                    >
                        {t('buttons:save')}
                    </ButtonWithLoader>
                    <RoundedOutlinedButton onClick={closeModal}>{t('buttons:cancel')}</RoundedOutlinedButton>
                </Box>
            </CommonModal>
            <DateRangeModal
                range={range}
                setRange={setRange}
                isShowing={openedDateModal}
                close={closeDateModal}
                maxDate={new Date(new Date().setMonth(new Date().getMonth() + 1))}
            />
        </>
    );
};

export default DistributeModal;
