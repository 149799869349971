import { Box, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import HasAccess from '@/components/auth/permissions/hasAccess';
import DetailsInfoBlock from '@/components/detailsInfoBlock';
import HistoryDetailsBlock from '@/components/detailsInfoBlock/historyDetailsBlock';
import ArmListLayoutHeader from '@/components/layouts/armListLayout/header';
import Pagination from '@/components/pagination';
import StyledTable from '@/components/table';
import useModal from '@/core/hooks/useModal';
import usePagination from '@/core/hooks/usePagination';
import i18n from '@/core/i18n';
import { commonServices } from '@/core/services/common/commonServices';
import { orderGroupServices } from '@/core/services/orderGroupServices';
import { orderServices } from '@/core/services/orderServices';
import {
    COURIER_APPOINTED,
    COURIER_SERVICE_ACCEPTED,
    ORDER_GROUP_STATUSES,
    READY_FOR_PICKUP,
    serverUrl,
    SERVICE_MANAGER
} from '@/core/utils/constants/constants';
import { detectChangesInArray } from '@/core/utils/utils';
import { addNotification } from '@/toolkit/reducers/toastr';
import AcceptGroupModal from '@/views/orderGroups/components/acceptModal';
import AddCourierModal from '@/views/orderGroups/components/addCourierModal';
import OrderGroupDetailsButtons from '@/views/orderGroups/components/detailButtons';
import OrderRow from '@/views/orderGroups/components/orderRow';
import DistributeModal from '@/views/orders/components/distributeModal';

const { t } = i18n;

const headers = [
    {
        title: t('orders:cols.id'),
        key: 'id'
    },
    {
        title: t('orders:cols.phoneNumber'),
        key: 'number'
    },
    {
        title: t('orders:cols.uin'),
        key: 'uin'
    },
    {
        title: t('orders:cols.address'),
        key: 'address'
    },
    {
        title: t('orders:cols.recipientName'),
        key: 'name'
    }
];

const StyledLink = styled('a')`
    display: block;
    color: var(--accent-color);
    font-size: 11px;
`;
const StyledDate = styled('datetime')`
    display: block;
    font-size: 10px;
    color: #7f7a7a;
`;
const StyledList = styled('ul')`
    list-style: none;
    & li {
        margin-bottom: 10px;
    }
`;

const OrderGroupsDetails = () => {
    const params = useParams();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const pagination = usePagination('actsHistoryLimit');
    const { changePage, currentPage, pageLimit, setPageLimitHandler } = usePagination('ordersLimit');
    const [editModalOpened, openEditModal, closeEditModal] = useModal();
    const [acceptModalOpened, openAcceptModal, closeAcceptModal] = useModal();
    const [courierModalOpened, openCourierModal, closeCourierModal] = useModal();
    const [loading, setLoading] = useState(false);
    const [showStatuses, setShowStatuses] = useState(false);

    const {
        data: orders,
        isLoading,
        refetch
    } = useQuery([`orders${params.id}`, editModalOpened], () => orderServices.getList({ order_group_id: params.id }), {
        enabled: editModalOpened
    });

    const { data: packedOrders } = useQuery(
        ['packedOrders', editModalOpened],
        () => orderServices.getList({ current_status_slug: 'upakovano', limit: 100 }),
        {
            enabled: editModalOpened
        }
    );

    const { data: orderGroup } = useQuery(`orderGroup${params.id}`, () => orderGroupServices.get(params.id));
    const { data: history } = useQuery('orderGroupHistory', () =>
        commonServices.getHistory('OrderGroup', params.id, pagination.pageLimit, pagination.currentPage)
    );

    const addOrdersMutation = useMutation(({ id, data }) => orderGroupServices.addOrders(id, data));
    const removeOrdersMutation = useMutation(({ id, data }) => orderGroupServices.removeOrders(id, data));
    const addStatusMutation = useMutation((status) => orderGroupServices.addStatus(params.id, status));

    const editGroup = async (receivedOrders) => {
        setLoading(true);
        const arrayChanges = detectChangesInArray(
            orders?.data?.items?.map((o) => o.id),
            receivedOrders,
            (el) => el,
            (a, b) => a === b
        );

        try {
            await addOrdersMutation.mutateAsync({ id: params.id, data: { orders: arrayChanges.addedItems } });
            await removeOrdersMutation.mutateAsync({ id: params.id, data: { orders: arrayChanges.removedItems } });
            await queryClient.invalidateQueries(`orderGroup${params.id}`);
            await queryClient.invalidateQueries(`orders${params.id}`);
            await queryClient.invalidateQueries(`packedOrders`);
            closeEditModal();
            dispatch(addNotification({ message: 'Список заявок в группе отредактирован' }));
        } catch (e) {
            console.log(e);
            dispatch(addNotification({ message: 'Не удалось отредактировать список заявок в группе', type: 'error' }));
        }
        setLoading(false);
    };

    const addStatus = async (status) => {
        await addStatusMutation.mutateAsync(status);
        await queryClient.invalidateQueries(`orderGroup${params.id}`);
    };

    const currentStatus = orderGroup?.data?.statuses?.[0];

    return (
        <>
            <ArmListLayoutHeader
                title={t('orderGroups:details.title', { id: params.id })}
                subTitle={t('orderGroups:details.subtitle', { id: params.id })}
                buttons={
                    <OrderGroupDetailsButtons
                        openEditModal={openEditModal}
                        openAcceptModal={openAcceptModal}
                        currentStatus={orderGroup?.data?.statuses?.[0]}
                        ordersCount={orderGroup?.data?.count}
                        addStatus={addStatus}
                    />
                }
            />
            <DetailsInfoBlock title={t('orderGroups:details.groupInfo')}>
                <DetailsInfoBlock.Child
                    label={t('orderGroups:details.labels.sorter')}
                    info={
                        orderGroup?.data?.sorter
                            ? `${orderGroup?.data?.sorter?.user?.last_name} ${orderGroup?.data?.sorter?.user?.first_name}`
                            : t('orderGroups:details.notAssigned')
                    }
                />
                <DetailsInfoBlock.Child
                    label={t('orderGroups:details.labels.courier')}
                    info={
                        <Box>
                            {orderGroup?.data?.courier
                                ? `${orderGroup?.data?.courier?.user?.last_name} ${orderGroup?.data?.courier?.user?.first_name}`
                                : t('orderGroups:details.notAssigned')}
                            {[READY_FOR_PICKUP, COURIER_APPOINTED].includes(currentStatus?.name) && (
                                <HasAccess roles={[SERVICE_MANAGER]}>
                                    <StyledLink
                                        onClick={(e) => {
                                            e.preventDefault();
                                            openCourierModal();
                                        }}
                                        href="#"
                                    >
                                        {t('orderGroups:details.assignCourier')}
                                    </StyledLink>
                                </HasAccess>
                            )}
                        </Box>
                    }
                />
                <DetailsInfoBlock.Child
                    label={t('orderGroups:details.labels.manager')}
                    info={
                        orderGroup?.data?.courier_service_manager
                            ? `${orderGroup?.data?.courier_service_manager?.user?.last_name} ${orderGroup?.data?.courier_service_manager?.user?.first_name}`
                            : '-'
                    }
                />
                <DetailsInfoBlock.Child
                    label={t('orderGroups:details.labels.status')}
                    sx={{
                        '@media (max-width: 1840px)': {
                            width: '250px'
                        }
                    }}
                    info={
                        <Box>
                            {showStatuses ? (
                                <StyledList>
                                    {[...(orderGroup?.data?.statuses || [])]?.reverse()?.map((status) => (
                                        <li key={status.name}>
                                            {ORDER_GROUP_STATUSES[status.name] || ORDER_GROUP_STATUSES.new_group}
                                            <StyledDate>{new Date(status.created_at).toLocaleString()}</StyledDate>
                                        </li>
                                    ))}
                                </StyledList>
                            ) : (
                                ORDER_GROUP_STATUSES[orderGroup?.data?.statuses?.[0]?.name]
                            )}
                            {orderGroup?.data?.statuses?.length > 1 && (
                                <StyledLink
                                    href="#"
                                    onClick={() => {
                                        setShowStatuses((prevState) => !prevState);
                                    }}
                                >
                                    {!showStatuses ? t('orderGroups:details.showAllStatuses') : t('orderGroups:details.hide')}
                                </StyledLink>
                            )}
                        </Box>
                    }
                />
                <DetailsInfoBlock.Child
                    label={t('orderGroups:details.labels.actOfAcceptance')}
                    info={
                        !orderGroup?.data?.act ? (
                            t('orderGroups:details.notCreated')
                        ) : (
                            <StyledLink href={`${serverUrl}${orderGroup?.data?.act}`} target="_blank">
                                {t('orderGroups:details.openDocument')}
                            </StyledLink>
                        )
                    }
                />
            </DetailsInfoBlock>
            <HistoryDetailsBlock history={history?.data} pagination={pagination} />
            <DetailsInfoBlock
                title={t('orderGroups:details.ordersCount', { count: orderGroup?.data?.count })}
                visible={orderGroup?.data?.count > 0}
            >
                {orders?.data?.items?.length ? (
                    <Box width="100%">
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) => (
                                        <TableCell key={header.key}>{header.title}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders?.data?.items?.map((order) => (
                                    <OrderRow key={order?.id} order={order} />
                                ))}
                            </TableBody>
                        </StyledTable>
                        <Pagination
                            itemsLength={orders?.data?.total}
                            currentPage={currentPage}
                            totalPages={orders?.data?.total_pages}
                            pageLimit={pageLimit}
                            onPageChange={changePage}
                            setPageLimit={setPageLimitHandler}
                        />
                    </Box>
                ) : (
                    <Typography sx={{ display: 'block', width: '100%', textAlign: 'center', color: 'var(--main-text-color)', fz: '16px' }}>
                        <StyledLink
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                refetch();
                            }}
                        >
                            {t('orderGroups:details.getOrdersList')}
                        </StyledLink>
                    </Typography>
                )}
            </DetailsInfoBlock>
            <AcceptGroupModal
                close={closeAcceptModal}
                opened={acceptModalOpened}
                onSubmit={(e) => {
                    e.preventDefault();
                    addStatus(COURIER_SERVICE_ACCEPTED);
                    closeAcceptModal();
                }}
            />
            {courierModalOpened && (
                <AddCourierModal
                    queryClient={queryClient}
                    close={closeCourierModal}
                    cityId={orderGroup?.data?.delivery_point?.city?.id}
                    groupId={params.id}
                />
            )}
            <DistributeModal
                title={t('orderGroups:details.editAct')}
                showing={editModalOpened}
                close={closeEditModal}
                leftColumnTitle={t('orderGroups:details.leftColumnTitle')}
                rightColumnTitle={t('orderGroups:details.rightColumnTitle')}
                leftColumnOrders={orders?.data?.items}
                rightColumnOrders={packedOrders?.data?.items}
                isLoading={isLoading}
                mutationLoading={loading}
                forceEnableButton
                onSubmit={editGroup}
            />
        </>
    );
};

export default OrderGroupsDetails;
