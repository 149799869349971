import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { PatternFormat } from 'react-number-format';

import FieldLabel from '@/components/formField/fieldLabel';

const StyledInputElement = styled(PatternFormat)(
    ({ success, error }) => `
  width: 100%;
  font-family: "Inter","Roboto",arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
  color: var(--main-text-color);
  background: var(--input-bg);
  border: 1px solid ${error ? 'red' : success};
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: 0 !important;
  transition: 0.2s;

  &:hover {
    background: var(--input-bg-focused);
    borderColor: ${error ? 'red' : success};
  }

  &:focus {
    outline: none;
    background: var(--input-bg-focused);
    borderColor: ${error ? 'red' : success};
  }
`
);

const MaskedPhoneNumberInput = ({ id, label, onChange, autoFocus, format, errors, required }) => (
    <Box sx={{ position: 'relative' }} className="form_field_input">
        <FieldLabel htmlFor={id} required={required}>
            {label}
        </FieldLabel>
        <div>
            <StyledInputElement
                name={id}
                autoFocus={autoFocus}
                format={format}
                allowEmptyFormatting
                valueIsNumericString
                onValueChange={onChange}
                error={errors[id]}
                success={!errors[id] ? '#49C172' : 'transparent'}
            />
        </div>
        <Typography
            className="required_error_message"
            style={{
                marginTop: '4px',
                color: '#FF6464',
                fontSize: '12px',
                opacity: errors[id] ? 1 : 0
            }}
        >
            {errors[id]?.message}
        </Typography>
    </Box>
);

export default MaskedPhoneNumberInput;
