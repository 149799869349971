import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import i18n from '@/core/i18n';

const { t } = i18n;

const PartnerHeader = ({ open, setOpen, handleClose, handleOpen }) => {
    const theme = useTheme();
    return (
        <>
            <Box
                sx={{
                    height: '5rem',
                    backgroundColor: theme.palette.grey[450],
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                    p: '0 1rem',
                    pb: '0.5rem'
                }}
            >
                <Typography variant="h3" component="h2">
                    {t('common:partners')}
                </Typography>
                <Button
                    onClick={() => handleOpen()}
                    sx={{
                        backgroundColor: theme.palette.success.main,
                        color: 'white',
                        display: 'block'
                    }}
                >
                    {t('buttons:addPartner')}
                </Button>
            </Box>
        </>
    );
};

export default PartnerHeader;
