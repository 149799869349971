import { Box } from '@mui/material';
import React from 'react';

import HasAccess from '@/components/auth/permissions/hasAccess';
import { RoundedButton, RoundedOutlinedButton } from '@/components/button/styledButtons';
import {
    COURIER_APPOINTED,
    COURIER_SERVICE_ACCEPTED,
    EXPORTED,
    NEW_GROUP,
    READY_FOR_PICKUP,
    REVISE,
    SERVICE_MANAGER,
    SORTER,
    UNDER_REVIEW
} from '@/core/utils/constants/constants';

const OrderGroupDetailsButtons = ({ openEditModal, ordersCount, currentStatus, addStatus, openAcceptModal }) => (
    <>
        <HasAccess roles={[SORTER]}>
            <RoundedButton
                disabled={!(!currentStatus || [NEW_GROUP, READY_FOR_PICKUP, COURIER_APPOINTED, UNDER_REVIEW].includes(currentStatus?.name))}
                onClick={() => {
                    openEditModal();
                }}
            >
                Редактировать
            </RoundedButton>
            <Box ml="15px">
                {(!currentStatus || [UNDER_REVIEW, NEW_GROUP].includes(currentStatus?.name)) && (
                    <RoundedOutlinedButton disabled={!ordersCount} onClick={() => addStatus(READY_FOR_PICKUP)}>
                        Готово к вывозу
                    </RoundedOutlinedButton>
                )}
            </Box>
        </HasAccess>
        <HasAccess roles={[SERVICE_MANAGER]}>
            <RoundedButton disabled={currentStatus?.name !== EXPORTED} onClick={openAcceptModal}>
                Принять
            </RoundedButton>
        </HasAccess>
    </>
);

export default OrderGroupDetailsButtons;
