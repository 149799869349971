import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, Fade, IconButton, LinearProgress, Modal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import i18n from '@/core/i18n';
import { devices } from '@/core/utils/devices';

const { t } = i18n;

const StyledBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 22px 32px;
    transform: translate(-50%, -50%);
    width: 506px;
    max-width: 90%;
    max-height: 95vh;
    overflow-y: auto;
    background: var(--paper-bg);
    border-radius: 10px;
    z-index: 100000;
    @media screen and ${devices.mobileM} {
        padding: 10px 15px;
    }
    & .MuiLinearProgress-root {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    & h2 {
        color: var(--title-color);
    }
    & .form_field_select,
    & .checkbox_select {
        margin-bottom: 30px;
    }
    & .form_field_input {
        margin-bottom: 30px;
    }
    & .close_modal_icon {
        position: absolute;
        top: 23px;
        right: 20px;
        padding: 0;
        color: var(--medium-text-color);
    }
    & .back_modal_icon {
        position: absolute;
        top: 25px;
        left: 25px;
        padding: 0;
        color: var(--medium-text-color);
    }
`;

const ViewStageDocumentModal = ({ title, handleClose, sxStyle, children }) => (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        sx={{ zIndex: 100000 }}
        BackdropProps={{
            timeout: 500
        }}
    >
        <Fade in={true}>
            <StyledBox>
                <Typography pb={4} id="transition-modal-title" variant="h3" component="h2" sx={{ pl: 0, ...sxStyle }}>
                    {title}
                    <IconButton className="close_modal_icon" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Typography>
                {children}
            </StyledBox>
        </Fade>
    </Modal>
);

export default ViewStageDocumentModal;
