/* eslint-disable no-useless-escape */

import { PhoneNumberUtil } from 'google-libphonenumber';

export const normalizePhoneNumber = (phone) => `+${phone?.replaceAll(/\D/g, '')}`;

export const checkIin = (value) => {
    if (!value) return undefined;
    // Проверка контрольного разряда
    const b1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const b2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];
    const a = [];
    let control = 0;
    for (let i = 0; i < 12; i++) {
        a[i] = parseInt(value.substring(i, i + 1), 10);
        if (i < 11) control += a[i] * b1[i];
    }
    control %= 11;
    if (control === 10) {
        control = 0;
        for (let i = 0; i < 11; i++) control += a[i] * b2[i];
        control %= 11;
    }
    return control === a[11];
};

const pluralize = (count, words) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return `${count} ${words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]]}`;
};

const padTo2Digits = (num) => num.toString().padStart(2, '0');
export const toHoursAndMinutes = (totalMinutes) => {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    // return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
    return `${hours > 0 ? pluralize(hours, ['час', 'часа', 'часов']) : ''} ${pluralize(minutes, ['минута', 'минуты', 'минут'])}`;
};
export const humanDateFormat = (date) =>
    new Intl.DateTimeFormat('ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    }).format(date);
export const humanDateTimeFormat = (date) =>
    new Intl.DateTimeFormat('ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    }).format(date);
export const machineDateFormat = (date) => {
    if (!date) return;
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    // eslint-disable-next-line consistent-return
    return date.toISOString().split('T')[0];
};

export const getDailyIntervalDates = (date) => {
    const now = date ? new Date(date) : new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    start.setMinutes(start.getMinutes() - start.getTimezoneOffset());
    const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59);
    end.setMinutes(end.getMinutes() - end.getTimezoneOffset());
    return { start, end };
};

export const isMobile = function () {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};
export const isMobileAndTablet = function () {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

export const selectProfile = (user, profileType) => user?.profiles?.find((p) => p.profile_type === profileType);

const mapItems = (items, getKey) => {
    const map = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
        const key = getKey(item);
        if (key in items) {
            throw new Error(`Item key is duplicated (${key}).`);
        }
        map[key] = item;
    }
    return map;
};

export const detectChangesInArray = (prevItems, nextItems, getKey, compareItems) => {
    const mappedItems = mapItems(prevItems, getKey);
    const addedItems = [];
    const updatedItems = [];
    const removedItems = [];
    const unchangedItems = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const nextItem of nextItems) {
        const itemKey = getKey(nextItem);
        if (itemKey in mappedItems) {
            const prevItem = mappedItems[itemKey];
            if (delete mappedItems[itemKey] && compareItems(prevItem, nextItem)) {
                unchangedItems.push(nextItem);
            } else {
                updatedItems.push(nextItem);
            }
        } else {
            addedItems.push(nextItem);
        }
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const itemKey in mappedItems) {
        if (itemKey in mappedItems) {
            removedItems.push(mappedItems[itemKey]);
        }
    }
    return { addedItems, updatedItems, removedItems, unchangedItems };
};

export const getLocalIsoStringRange = (startDateTime, endDateTime) => {
    const startDateOffset = new Date(startDateTime).getTimezoneOffset() * 60000;
    const endDateOffset = new Date(endDateTime).getTimezoneOffset() * 60000;
    const startDate = new Date(new Date(startDateTime).setHours(0, 0, 0) - startDateOffset).toISOString().slice(0, -1);
    const endDate = new Date(new Date(endDateTime).setHours(23, 59, 0) - endDateOffset).toISOString().slice(0, -1);

    return [startDate, endDate];
};

const phoneUtil = PhoneNumberUtil.getInstance();
export const isPhoneValid = (phone) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};
