import { httpClient } from '@/api/httpClient/httpClient';
import { getDailyIntervalDates } from '@/core/utils/utils';

export const orderServices = {
    getList(params) {
        if (params.delivery_datetime__gte && params.delivery_datetime__lte) {
            const start = getDailyIntervalDates(params.delivery_datetime__gte).start;
            const end = getDailyIntervalDates(params.delivery_datetime__lte).end;
            params.delivery_datetime__gte = start?.toISOString()?.slice(0, 19)?.replace('T', ' ');
            params.delivery_datetime__lte = end?.toISOString()?.slice(0, 19)?.replace('T', ' ');
        }
        params.page_limit = params.page_limit || 100;

        let url = `/order/list?`;
        if (params.selected_cities?.length) {
            url += `&city_id__in=${params.selected_cities.join('&city_id__in=')}`;
        }
        if (params.selected_items?.length) {
            url += `&item_id__in=${params.selected_items.join('&item_id__in=')}`;
        }
        if (params.selected_partners?.length) {
            url += `&partner_id__in=${params.selected_partners.join('&partner_id__in=')}`;
        }
        delete params.selected_cities;
        delete params.selected_items;
        delete params.selected_partners;

        Object.keys(params).forEach((key) => {
            if (!params[key]) delete params[key];
        });

        return httpClient.get(url, { params });
    },
    selectiveDistribution(data) {
        return httpClient.post('/order/distribution/selective', data);
    },
    exportOrdersReport(data) {
        return httpClient.post('/order/report', data, { responseType: 'blob' });
    },
    exportOrderGroupsReport(params) {
        console.log(params);
        Object.keys(params).forEach((key) => {
            if (!params[key] || params[key].length === 0) delete params[key];
        });
        let url = `/order_group/report?`;
        ['partner_id__in', 'courier_id__in', 'sorter_id__in', 'current_status__in', 'address_set__place_id__in'].forEach((param) => {
            if (params[param]?.length) {
                url += `&${param}=${params[param].join(`&${param}=`)}`;
                delete params[param];
            }
        });
        return httpClient.get(url, { params, responseType: 'blob' });
    },
    downloadImportSample() {
        return httpClient.options('/order/import/sample', { responseType: 'blob' });
    },
    importOrders(data) {
        return httpClient.post('/order/import', data, { responseType: 'blob' });
    },
    getOrder(id) {
        return httpClient.get(`/order/${id}`);
    },
    deleteOrder(id) {
        return httpClient.delete(`/order/${id}`);
    },
    getArchivedOrder(id) {
        return httpClient.get(`/order/archived/${id}`);
    },
    courierAssign(data) {
        return httpClient.post(`/order/mass-courier-assign`, data);
    },
    patchOrder({ id, data }) {
        return httpClient.patch(`/order/${id}`, data);
    },
    sendMassPostRequest(data) {
        return httpClient.post('/order/mass-status-update', data);
    },
    massSetStatus(data) {
        return httpClient.put('/order/mass-set-status', data);
    },
    reset({ id, data }) {
        return httpClient.patch(`/order/${id}/restore`, data);
    },
    getPublic(id) {
        return httpClient.get(`/monitoring/public/${id}`);
    },
    changeAddress(id, data) {
        return httpClient.patch(`/order/address/${id}`, data);
    },
    getOrderHistory(id, limit, page) {
        return httpClient.get(`/history/list`, {
            params: {
                model_type: 'Order',
                model_id: id,
                page_limit: limit,
                page
            }
        });
    },
    addPostcontrol({ orderId, data }) {
        return httpClient.post(`/post-control/${orderId}`, data);
    },
    acceptPostcontrol(orderId) {
        return httpClient.put(`/post-control/${orderId}/accept`);
    }
};
