import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import ruLocale from 'date-fns/locale/ru';
import React from 'react';
import './styles.scss';
import { ReactSVG } from 'react-svg';

import CalendarModalIcon from '@/assets/images/icons/calendar-modal.svg';
import CalendarIcon from '@/assets/images/icons/calendar.svg';
import i18n from '@/core/i18n';

const { t } = i18n;

const localeMap = {
    en: enLocale,
    fr: frLocale,
    ru: ruLocale,
    de: deLocale
};

const maskMap = {
    fr: '__/__/____',
    en: '__/__/____',
    ru: '__.__.____',
    de: '__.__.____'
};

const LocalizedDatePicker = React.forwardRef(
    ({ minDate, sx, value, onChange, className = 'AppDatePicker', disabled = false }, props, ref) => {
        const [locale, setLocale] = React.useState('ru');

        return (
            <Box className={className} sx={sx}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
                    <DatePicker
                        minDate={minDate}
                        // mask={maskMap[locale]}
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                        components={{
                            OpenPickerIcon: () => <ReactSVG src={className === 'AppDatePicker' ? CalendarIcon : CalendarModalIcon} />
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: t('common:select'),
                                    readOnly: true
                                }}
                            />
                        )}
                        {...props}
                        res={ref}
                    />
                </LocalizationProvider>
            </Box>
        );
    }
);

export default LocalizedDatePicker;
