import { styled } from '@mui/material/styles';
import React from 'react';

const StyledLabel = styled('label')`
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    & input {
        display: none;
        &:checked + span {
            color: #fff;
            background: #344563;
        }
    }
    & .tag {
        display: inline-block;
        padding: 10px 15px;
        background: rgba(91, 131, 202, 0.2);
        color: #6b778c;
        border-radius: 75px;
        font-size: 14px;
    }
`;

const FeedbackTag = ({ reason, name, control, register, onSelect, field }) => (
    <StyledLabel htmlFor={reason.name}>
        <input
            {...field}
            type="checkbox"
            id={reason.name}
            value={reason.id}
            onChange={() => {
                onSelect(reason.id);
            }}
        />
        <span className="tag">{reason.name}</span>
    </StyledLabel>
);

export default FeedbackTag;
