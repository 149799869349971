import { Box, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import '@/components/timePicker/styles.scss';

import { RoundedOutlinedButton } from '@/components/button/styledButtons';
import DetailsInfoBlock from '@/components/detailsInfoBlock';
import ArmListLayoutHeader from '@/components/layouts/armListLayout/header';
import i18n from '@/core/i18n';
import { ORDER_CHAIN_UPDATE } from '@/core/utils/constants/routes';
import { StagesWrapper } from '@/views/orderChain/components/stages';

const { t } = i18n;

const StyledSkeleton = styled(Skeleton)`
    height: 40px;
    transform: scale(1);
`;

const StyledStageSkeleton = styled(Skeleton)`
    width: 200px;
    height: 140px;
    transform: scale(1);
`;

const LoadingOrderChain = () => {
    const navigate = useNavigate();
    const params = useParams();

    const { id: orderChainId, '*': path } = params;

    const isUpdatePage = path.includes('update');
    const subTitle = isUpdatePage
        ? `Вернуться к многоэтапной доставке №${orderChainId} / Редактирование многоэтапной доставки №${orderChainId}`
        : `Вернуться к многоэтапным доставкам / Многоэтапная доставка №${orderChainId}`;

    return (
        <>
            <ArmListLayoutHeader
                title={`Многоэтапная доставка №${orderChainId}`}
                subTitle={subTitle}
                buttons={
                    !isUpdatePage && (
                        <RoundedOutlinedButton onClick={() => navigate(ORDER_CHAIN_UPDATE.format(orderChainId))}>
                            Редактировать
                        </RoundedOutlinedButton>
                    )
                }
            />

            <DetailsInfoBlock title="Общие данные">
                <DetailsInfoBlock.Child label={t('orders:create.partner')} info={<StyledSkeleton />} />

                <DetailsInfoBlock.Child label="Тип заявки" info={<StyledSkeleton />} />
            </DetailsInfoBlock>

            <DetailsInfoBlock title="Данные о посылке">
                <DetailsInfoBlock.Child label="Название посылки" info={<StyledSkeleton />} />

                <DetailsInfoBlock.Child label="Описание посылки" info={<StyledSkeleton />} />

                <DetailsInfoBlock.Child label="Длина" info={<StyledSkeleton />} />
                <DetailsInfoBlock.Child label="Ширина" info={<StyledSkeleton />} />
                <DetailsInfoBlock.Child label="Высота" info={<StyledSkeleton />} />
            </DetailsInfoBlock>

            <DetailsInfoBlock title="Даты доставки">
                <DetailsInfoBlock.Child label={t('orderChain:create.labels.expectedDeliveryDate')} info={<StyledSkeleton />} />

                <DetailsInfoBlock.Child label={t('orderChain:create.labels.deadlineDeliveryDate')} info={<StyledSkeleton />} />
            </DetailsInfoBlock>

            <Box sx={{ display: 'flex', columnGap: 4 }}>
                <DetailsInfoBlock title="Отправитель">
                    <DetailsInfoBlock.Child label={t('orderChain:create.labels.senderName')} info={<StyledSkeleton />} />

                    <DetailsInfoBlock.Child label={t('orderChain:create.labels.senderPhoneNumber')} info={<StyledSkeleton />} />

                    <DetailsInfoBlock.Child label={t('orderChain:create.labels.senderCity')} info={<StyledSkeleton />} />

                    <DetailsInfoBlock.Child label="Адрес отправителя" info={<StyledSkeleton />} />
                </DetailsInfoBlock>

                <DetailsInfoBlock title="Получатель">
                    <DetailsInfoBlock.Child label={t('orders:create.labels.name')} info={<StyledSkeleton />} />

                    <DetailsInfoBlock.Child label={t('orders:create.labels.phoneNumber')} info={<StyledSkeleton />} />

                    <DetailsInfoBlock.Child label={t('orderChain:create.labels.receiverCity')} info={<StyledSkeleton />} />

                    <DetailsInfoBlock.Child label="Адрес получателя" info={<StyledSkeleton />} />
                </DetailsInfoBlock>
            </Box>

            <DetailsInfoBlock title="Этапы">
                <StagesWrapper>
                    {Array.from({ length: 4 }).map((i) => (
                        <StyledStageSkeleton key={i} />
                    ))}
                </StagesWrapper>
            </DetailsInfoBlock>

            <DetailsInfoBlock title={t('orders:create.blockTitles.comment')}>
                <DetailsInfoBlock.Child label={t('orders:create.blockTitles.comment')} info={<StyledSkeleton />} />
            </DetailsInfoBlock>
        </>
    );
};

export default LoadingOrderChain;
