import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors, MeasuringStrategy } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, defaultAnimateLayoutChanges } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import React, { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Dropdown from '@/components/dropdown';
import useDropdown from '@/core/hooks/useDropdown';
import i18n from '@/core/i18n';
import { orderChainServices } from '@/core/services/orderChainServices';
import { addNotification } from '@/toolkit/reducers/toastr';

const { t } = i18n;

export const StagesWrapper = styled(Box)`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 100%;
`;

export const CreateStageBox = styled(Box)`
    width: 200px;
    height: 100%;
    min-height: 140px;
    border: 1px dashed var(--accent-color);
    border-radius: 6px;
    display: grid;
    justify-content: center;
    align-content: center;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
`;

const StyledStageCard = styled(Box)`
    & > div {
        width: 200px;
        height: 100%;
        min-height: 140px;
        color: var(--main-text-color);
        border: 1px solid var(--border-color);
        border-radius: 6px;
        background-color: var(--page-bg-color);
        & header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 4px 14px;
            background: rgba(99, 153, 214, 0.08);
            font-weight: 500;
        }
        & ul {
            list-style-type: none;
            padding: 10px 14px;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        @keyframes jiggle {
            0% {
                transform: rotate(-1deg);
            }
            50% {
                transform: rotate(1deg);
            }
        }
    }
`;

const measuringConfig = {
    droppable: {
        strategy: MeasuringStrategy.Always
    }
};

export const StagesForUpdate = ({ orderChainId, stages, setStages, children }) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    useMemo(() => {
        stages.forEach((s) => {
            if (!Object.hasOwn(s, 'dndId')) s.dndId = crypto.randomUUID();
        });
    }, [stages]);

    const dndIds = useMemo(() => stages.map((s) => s.dndId), [stages]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            setStages((stgs) => {
                const oldIndex = stgs.findIndex((s) => s.dndId === active.id);
                const newIndex = stgs.findIndex((s) => s.dndId === over.id);

                const arrayWithMovedStage = arrayMove(stgs, oldIndex, newIndex);
                arrayWithMovedStage.forEach((s, i) => {
                    s.position = i;
                });
                return arrayWithMovedStage;
            });
        }
    }

    const deleteStageMutation = useMutation(({ orderChainId, stageId }) => orderChainServices.deleteStage({ orderChainId, stageId }));

    async function onDeleteStage(dndId) {
        const deletingStage = stages.find((s) => s.dndId === dndId);
        const label = `Этап №${deletingStage.position + 1}`;

        if (Object.hasOwn(deletingStage, 'id')) {
            try {
                await deleteStageMutation.mutateAsync({ orderChainId, stageId: deletingStage.id });
                dispatch(addNotification({ message: `${label} успешно удален` }));
            } catch (error) {
                dispatch(addNotification({ message: `Не удалось удалить ${label}`, type: 'error' }));
            }
        }

        setStages((stgs) => {
            const arrayWithDeletedStage = stgs.filter((s) => s.dndId !== dndId);
            arrayWithDeletedStage.forEach((s, i) => {
                s.position = i;
            });
            return arrayWithDeletedStage;
        });
    }

    // const onEditStage = (dndId) => {
    //     const editingStage = stages.find((s) => s.dndId === dndId);

    //     setSearchParams({ editStage: editingStage.id, stageOrderId: editingStage.order.id });
    // };

    const onUploadDocument = (dndId) => {
        const currentStage = stages.find((s) => s.dndId === dndId);

        setSearchParams({ uploadStageDocument: currentStage.id }, { replace: true });
    };

    return (
        <DndContext measuring={measuringConfig} sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={dndIds}>
                <StagesWrapper>
                    {stages.map((s) => (
                        <DraggableStageCard
                            key={s.dndId}
                            dndId={s.dndId}
                            label={`Этап №${s.position + 1}`}
                            onUpload={onUploadDocument}
                            // onEdit={onEditStage}
                            onDelete={onDeleteStage}
                        >
                            <ul>
                                <li>ID заявки: {s.order.id}</li>
                                <li>Тип заявки: {t(`orderChain:stageTypes.${s.type}`)}</li>
                                <li>Текущий статус: {s.order.current_status?.name}</li>
                            </ul>
                        </DraggableStageCard>
                    ))}
                    {children}
                </StagesWrapper>
            </SortableContext>
        </DndContext>
    );
};

export const StagesForCreate = ({ stages, setStages, children }) => {
    useMemo(() => {
        stages.forEach((s) => {
            if (!Object.hasOwn(s, 'dndId')) s.dndId = crypto.randomUUID();
        });
    }, [stages]);

    const dndIds = useMemo(() => stages.map((s) => s.dndId), [stages]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            setStages((stgs) => {
                const oldIndex = stgs.findIndex((s) => s.dndId === active.id);
                const newIndex = stgs.findIndex((s) => s.dndId === over.id);

                const arrayWithMovedStage = arrayMove(stgs, oldIndex, newIndex);
                arrayWithMovedStage.forEach((s, i) => {
                    s.position = i;
                });
                return arrayWithMovedStage;
            });
        }
    }

    async function onDeleteStage(dndId) {
        setStages((stgs) => {
            const arrayWithDeletedStage = stgs.filter((s) => s.dndId !== dndId);
            arrayWithDeletedStage.forEach((s, i) => {
                s.position = i;
            });
            return arrayWithDeletedStage;
        });
    }

    return (
        <DndContext measuring={measuringConfig} sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={dndIds}>
                <StagesWrapper>
                    {stages.map((s) => (
                        <DraggableStageCard key={s.dndId} dndId={s.dndId} label={`Этап №${s.position + 1}`} onDelete={onDeleteStage}>
                            <ul>
                                <li>Тип заявки: {t(`orderChain:stageTypes.${s.type}`)}</li>
                            </ul>
                        </DraggableStageCard>
                    ))}
                    {children}
                </StagesWrapper>
            </SortableContext>
        </DndContext>
    );
};

const animateLayoutChanges = (args) => defaultAnimateLayoutChanges({ ...args, wasDragging: true });

export const DraggableStageCard = ({ dndId, label, onDelete, onEdit, onUpload, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging, isSorting } = useSortable({
        id: dndId,
        animateLayoutChanges
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? '100' : 'auto',
        opacity: isDragging ? 0.5 : 1
    };

    const { dropdownOpened, dropdownClose, dropdownToggle, anchorRef } = useDropdown();

    const canUploadDocument = !!onUpload;

    return (
        <StyledStageCard id={dndId} ref={setNodeRef} style={style}>
            <div
                style={{
                    animation: isSorting && 'jiggle 0.2s infinite'
                }}
            >
                <header>
                    <span
                        style={{
                            width: '50%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {label}
                    </span>
                    <span>
                        {/* <Tooltip title="Перетащить этап">
                            <IconButton
                                // {...attributes}
                                // {...listeners}
                                sx={{ color: 'var(--main-text-color)', cursor: isDragging ? 'grabbing' : 'grab' }}
                            >
                                <DragIndicatorIcon fontSize="small" />
                            </IconButton>
                        </Tooltip> */}
                        <Dropdown
                            Button={
                                <IconButton sx={{ color: 'var(--main-text-color)' }}>
                                    <MoreVertIcon fontSize="small" />
                                </IconButton>
                            }
                            options={[
                                {
                                    text: 'Загрузка документа',
                                    action: () => {
                                        if (canUploadDocument) {
                                            onUpload(dndId);
                                        }
                                        dropdownToggle();
                                    }
                                },
                                // {
                                //     text: 'Редактировать этап',
                                //     action: () => {
                                //         // onEdit(dndId);
                                //         dropdownToggle();
                                //     }
                                // },
                                {
                                    text: 'Удалить этап',
                                    action: () => {
                                        onDelete(dndId);
                                        dropdownToggle();
                                    }
                                }
                            ]}
                            anchorRef={anchorRef}
                            dropdownClose={dropdownClose}
                            dropdownOpened={dropdownOpened}
                            dropdownToggle={dropdownToggle}
                        />
                    </span>
                </header>
                {children}
            </div>
        </StyledStageCard>
    );
};

export const StageCardDetails = ({ label, onViewDocumentClick, onOpenStageClick, children }) => (
    <StyledStageCard>
        <div>
            <header>
                {label}
                <span>
                    {onViewDocumentClick && (
                        <Tooltip title="Посмотреть документ">
                            <IconButton onClick={onViewDocumentClick} sx={{ color: 'var(--main-text-color)' }}>
                                <DescriptionOutlinedIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title="Открыть этап">
                        <IconButton onClick={onOpenStageClick} sx={{ color: 'var(--main-text-color)' }}>
                            <ArrowOutwardIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </span>
            </header>
            {children}
        </div>
    </StyledStageCard>
);
