import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)`
    padding: 25px 35px 25px 35px;
    border-radius: 10px;
    margin-bottom: 15px;
    @media screen and (max-width: 600px) {
        padding: 15px;
    }
`;

export default StyledPaper;
