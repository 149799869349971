export const getSchemaEmptyValues = (schema) =>
    // eslint-disable-next-line no-underscore-dangle
    schema._nodes.reduce((acc, elem) => {
        acc[elem] = '';
        return acc;
    }, {});

export const setDefaultValues = (schema, valuesObj, setValue) => {
    if (Object.keys(valuesObj).length) {
        // eslint-disable-next-line no-underscore-dangle
        schema._nodes.forEach((elem) => {
            setValue(elem, valuesObj[elem], true);
        });
    }
};
