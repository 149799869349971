import { httpClient } from '@/api/httpClient/httpClient';

export const itemServices = {
    getItemList(partnerId) {
        return httpClient.get(`/item/list${partnerId ? `?partner_id=${partnerId}` : ''}`);
    },
    createItem(data) {
        return httpClient.post('/item', data);
    },
    getItem(id) {
        return httpClient.get(`/item/${id}`);
    },
    updateItem(id, data) {
        return httpClient.patch(`/item/${id}`, data);
    },
    deleteItem(id) {
        return httpClient.delete(`/item/${id}`);
    }
};
