import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { styled } from '@mui/system';

import { devices } from '@/core/utils/devices';

const StyledTab = styled(TabUnstyled)`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: var(--medium-text-color);
    background-color: var(--page-bg-color);
    border: 1px solid transparent;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
    text-decoration: none;
    font-family: 'Inter', 'Roboto', arial, sans-serif;
    @media screen and ${devices.tablet} {
        padding: 9px 12px;
        font-weight: 400;
        font-size: 13px;
    }
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 31px;
        background: var(--border-color);
        @media screen and ${devices.tablet} {
            height: 21px;
        }
    }

    &:hover:not(${tabUnstyledClasses.selected}),
    &:hover:not(&.active) {
        color: rgba(var(--accent-color-rgb), 0.5);
        border-color: rgba(var(--accent-color-rgb), 0.5) !important;
    }

    &:hover,
    &.${tabUnstyledClasses.selected}, &.active {
        border-color: var(--accent-color) !important;
        color: var(--accent-color);
        border-radius: 4px !important;
        &::after {
            content: none;
        }
    }
    &.${tabUnstyledClasses.selected}, &.active {
        font-weight: 600;
    }

    &:hover + button::after,
    &.${tabUnstyledClasses.selected} + button::after,
    &:hover + a::after,
    &.active + a::after {
        content: none;
    }
    &:first-of-type::after {
        content: none;
    }
    &:last-of-type {
        border-radius: 0 4px 4px 0;
        border-right-color: var(--border-color);
    }
    &:first-of-type {
        border-radius: 4px 0 0 4px;
        border-left-color: var(--border-color);
    }
    .has_new {
        position: absolute;
        width: 15px;
        height: 15px;
        background: #ff6464;
        right: 0px;
        top: 0px;
        border-radius: 50%;
        z-index: 10;
        color: #fff;
    }
`;

export default StyledTab;
