import { createSlice } from '@reduxjs/toolkit';

import { login, fetchCurrentUser, fetchPartner, refreshUser } from '../actionCreators/auth';

const initialState = {
    user: null
};

export const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData: (state, { payload }) => {
            state.user.data = payload;
        },
        setOrganizationData: (state, { payload }) => {
            state.user.organization = payload;
        },
        logout: (state) => {
            state.user = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
            state.user.data = action.payload;
            state.user.organization = action.payload.partner;
        });
        builder.addCase(fetchPartner.fulfilled, (state, action) => {
            state.user.organization = action.payload;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.user = action.payload;
        });
        builder.addCase(refreshUser.fulfilled, (state, action) => {
            state.user.access_token = action.payload.access_token;
            state.user.refresh_token = action.payload.refresh_token;
            state.user.profiles = action.payload.profiles;
        });
        builder.addCase(login.rejected, (state, action) => {
            console.log(action);
        });
    }
});

export const { setUserData, logout, setOrganizationData } = authSlice.actions;
export default authSlice.reducer;
