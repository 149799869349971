import { httpClient } from '@/api/httpClient/httpClient';

export const authServices = {
    registration(data) {
        return httpClient.post('/register', data);
    },
    registerOTP(data) {
        return httpClient.post('/otp', data);
    },
    registerOTPValidate(data) {
        return httpClient.post('/otp/validate', data);
    },
    authOTP(data) {
        return httpClient.post('/otp', data);
    },
    sendRegisterLink(data) {
        return httpClient.post('/otp/send_register_link', data);
    }
};
