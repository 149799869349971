import {
    Grid,
    Link,
    Typography,
    TextField,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Button,
    Container,
    Box,
    CircularProgress
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, useController } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';

import { getPostAction } from '../../../../redux/reducers/UserReducer/actions';

import { Logo, HeaderTextModal, FormAddPartner } from '@/components/createComponents';
import i18n from '@/core/i18n';

const { t } = i18n;
const useStyles = makeStyles((theme) => ({
    btn: {
        color: theme.palette.success.main,
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        border: `1px solid ${theme.palette.grey[450]}`,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.background.paperLight
        }
    }
}));

const FirstCheck = ({ handleClose, setPageModal }) => {
    const theme = useTheme();
    const {
        register,
        handleSubmit,
        formState: { isValid }
    } = useForm({ mode: 'onChange' });
    const onSubmit = (data) => {
        setPageModal((prev) => prev + 1);
    };
    // const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(getPostAction());
    // }, []);
    return (
        <>
            <HeaderTextModal handleClose={handleClose} text={t('components:withCheck.addingPartner')} />
            <Box sx={{ pt: '1rem', pl: '2rem' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Typography variant="h5" sx={{ fontWeight: 100, color: theme.palette.grey[500] }}>
                        {t('components:withCheck.partnerBin')}
                    </Typography>
                    <InputMask
                        mask="999999999999"
                        maskChar="_"
                        name="modal_bin"
                        {...register('modal_bin', {
                            required: true,
                            validate: (val) => val.indexOf('_') === -1
                        })}
                    >
                        {(inputProps) => (
                            <TextField {...inputProps} type="text" placeholder={t('components:withCheck.enterBin')} sx={{ width: '80%' }} />
                        )}
                    </InputMask>
                    <FormControl component="fieldset" variant="standard" sx={{ pt: 2 }}>
                        <FormGroup>
                            <FormControlLabel
                                sx={{ color: theme.palette.background.paperBlack }}
                                control={<Checkbox color="success" name="baza_gbd" {...register('check_modal', { required: true })} />}
                                label={t('components:withCheck.agree')}
                            />
                        </FormGroup>
                    </FormControl>
                    <Box sx={{ pt: 3, display: 'flex', justifyContent: 'space-around' }}>
                        <Button sx={{ width: '40%', height: '50px', color: theme.palette.success.main }} onClick={() => handleClose()}>
                            {t('buttons:cancel')}
                        </Button>
                        <Button
                            disabled={!isValid}
                            type="submit"
                            sx={{ width: '40%', height: '50px', backgroundColor: theme.palette.success.main, color: 'white' }}
                        >
                            {t('buttons:add')}
                        </Button>
                    </Box>
                </form>
            </Box>
        </>
    );
};

const SecondCheck = ({ setPageModal }) => {
    useEffect(() => {
        const timer = setTimeout(() => setPageModal((prev) => prev + 1), 2000);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <Box sx={{ textAlign: 'center', p: '10rem 0' }}>
            <CircularProgress color="success" />
        </Box>
    );
};

const ThirdCHeck = ({ theme, handleClose, setPageModal }) => {
    const value = [
        { name: 'БИН партнера', val: '123456789123' },
        { name: 'ФИО руководителя', val: 'Иванов Иван Иванович' },
        { name: 'Наименовние партнера', val: 'ТОО “MYKHAT”' }
    ];

    return (
        <Box>
            <HeaderTextModal handleClose={handleClose} text={t('components:withCheck.addingPartner')} />
            {value.map((item, index) => (
                <Box key={index} sx={{ pl: 3 }}>
                    <Typography variant="h4" sx={{ fontWeight: 100, color: theme.palette.grey[500], pt: 3 }}>
                        {item.name}
                    </Typography>
                    <Typography variant="h4" sx={{ pt: 1.5 }}>
                        {item.val}
                    </Typography>
                </Box>
            ))}
            <Link sx={{ color: theme.palette.success.main, display: 'block', textAlign: 'center', pt: 3 }}>
                {t('components:withCheck.more')}
            </Link>
            <Box sx={{ pt: 3, display: 'flex', justifyContent: 'space-around' }}>
                <Button sx={{ width: '40%', height: '50px', color: theme.palette.success.main }} onClick={() => handleClose()}>
                    {t('buttons:cancel')}
                </Button>
                <Button
                    sx={{ width: '40%', height: '50px', backgroundColor: theme.palette.success.main, color: 'white' }}
                    onClick={() => setPageModal((prev) => prev + 1)}
                >
                    {t('buttons:continue')}
                </Button>
            </Box>
        </Box>
    );
};

const WithCheck = ({ handleClose }) => {
    const theme = useTheme();
    const [pageModal, setPageModal] = useState(0);
    const classes = useStyles();
    const showModalWithCheck = new Map();
    showModalWithCheck.set(0, () => <FirstCheck handleClose={handleClose} setPageModal={setPageModal} />);
    showModalWithCheck.set(1, () => <SecondCheck setPageModal={setPageModal} />);
    showModalWithCheck.set(2, () => <ThirdCHeck handleClose={handleClose} theme={theme} setPageModal={setPageModal} />);
    showModalWithCheck.set(3, () => <FormAddPartner handleClose={handleClose} theme={theme} setPageModal={setPageModal} />);

    return <>{showModalWithCheck.get(pageModal)()}</>;
};

export default WithCheck;
