import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import ProgressIcon from '@/assets/images/icons/bycicle-progress.svg';
import EllipseGreen from '@/assets/images/icons/ellipse-green.svg';
import EllipseGrey from '@/assets/images/icons/ellipse-grey.svg';
import StyledPaper from '@/pages/register/registerComponent/StyledPapper';

const ProgressBar = styled(Box)(({ currentStep, steps }) => {
    let width = `${(currentStep * 100) / steps}%`;
    if (currentStep === 0) {
        width = '34px';
    } else if (currentStep === steps) {
        width = `calc(100% - 4px)`;
    }
    return `
    position: relative;
    width: calc(100% - 2px);
    height: 18px;
    top: 8px;
    & .progress_bar_unfilled {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        border-top: 2px dashed #ced1d5;
        &::before,
        &::after {
            content: url(${EllipseGreen});
            position: absolute;
            top: 50%;
            transform: translateY(-12px);
            left: -4px;
        }
        &::after {
            content: url(${currentStep === steps ? EllipseGreen : EllipseGrey});
            top: 50%;
            right: -4px;
            left: auto;
        }
    }
    & .progress_bar_filled {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: ${width};
        border-top: 2px solid var(--accent-color);
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-71%);
            right: -2px;
            width: 33px;
            height: 19px;
            background: var(--paper-bg) url(${ProgressIcon}) 50% 50% no-repeat;
        }
    }
`;
});

const FormRegisterHeaderText = ({ text = '', progress }) => (
    <>
        <StyledPaper>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: '30px', fontWeight: '600', color: 'var(--title-color)' }}>{text}</Typography>
                <ProgressBar steps={2} currentStep={progress}>
                    <Box className="progress_bar_unfilled" />
                    <Box className="progress_bar_filled" />
                </ProgressBar>
            </Grid>
        </StyledPaper>
    </>
);

export default FormRegisterHeaderText;
