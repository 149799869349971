import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { RoundedButton } from '@/components/button/styledButtons';
import FeedbackTag from '@/components/feedback/tag';
import SelectWithCheckboxes from '@/components/formField/selectWithCheckboxes';
import TextAreaInput from '@/components/textAreaInput';
import i18n from '@/core/i18n';
import { addNotification } from '@/toolkit/reducers/toastr';

const { t } = i18n;

const FeedbackForm = ({ reasons, isVisible, mutation, rating, schema, order, successCallback }) => {
    const dispatch = useDispatch();
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const {
        control,
        formState: { errors, isValid },
        getValues,
        register
    } = useForm({
        mode: 'onChange',
        defaultValues: { comment: '' },
        resolver: yupResolver(schema)
    });

    const onSelectReason = (id) => {
        setSelectedReasons((prevState) => (!prevState.includes(id) ? prevState.concat(id) : prevState.filter((elem) => elem !== id)));
    };
    const onSelectTag = (id) => {
        setSelectedTags((prevState) => (!prevState.includes(id) ? prevState.concat(id) : prevState.filter((elem) => elem !== id)));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const reasonIds = selectedReasons.concat(selectedTags);

        try {
            await mutation.mutateAsync({
                rate: rating,
                comment: getValues().comment,
                reasons: reasonIds,
                order_id: order?.data?.id,
                author_full_name: order?.data?.receiver_name,
                author_phone: order?.data?.receiver_phone_number
            });
            successCallback();
        } catch (e) {
            dispatch(addNotification({ message: t('notifications:feedbackSendFailed'), type: 'error' }));
        }
    };

    const tags = useMemo(() => reasons?.filter((reason) => reason.is_tag), [reasons]);
    reasons = useMemo(() => reasons?.filter((reason) => !reason.is_tag), [reasons]);
    console.log(selectedReasons, selectedTags);
    return (
        isVisible && (
            <>
                <form onSubmit={onSubmit}>
                    <Box display="flex" justifyContent="center" gap="15px 20px" flexWrap="wrap" mb="30px">
                        {tags?.map((reason) => (
                            <FeedbackTag
                                key={reason.id}
                                reason={reason}
                                name="reasons"
                                control={control}
                                register={register}
                                onSelect={onSelectTag}
                            />
                        ))}
                    </Box>
                    <Box textAlign="left">
                        {reasons?.filter((reason) => !reason.is_tag).length > 0 && (
                            <Box mb="30px">
                                <SelectWithCheckboxes
                                    label={t('feedback:allReasons')}
                                    control={control}
                                    errors={errors}
                                    placeholder={t('feedback:youCanSelectMultipleReasons')}
                                    id="reasons"
                                    listSelected={selectedReasons}
                                    onSelect={onSelectReason}
                                    options={reasons?.filter((reason) => !reason.is_tag)}
                                    {...register('reasons')}
                                />
                            </Box>
                        )}

                        <Box mb="55px">
                            <TextAreaInput
                                label={t('feedback:addComment')}
                                control={control}
                                errors={errors}
                                ContainerProps={{ marginBottom: '55px' }}
                                placeholder={t('feedback:youCanSelectMultipleReasons')}
                                id="comment"
                                {...register('comment')}
                            />
                        </Box>
                        <RoundedButton
                            variant="contained"
                            color="success"
                            type="submit"
                            disabled={!(selectedReasons.length || selectedTags.length)}
                            sx={{ width: '100%' }}
                        >
                            {t('buttons:ready')}
                        </RoundedButton>
                    </Box>
                </form>
            </>
        )
    );
};

export default FeedbackForm;
