import { Box, Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        // paddingTop: '3rem',
        backgroundColor: theme.palette.background.paperLight
        // height: '100vh',
        // width: '100vw'
    }
}));

const ParentComponent = ({ children }) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Box className={classes.root}>
            <Grid container sx={{ backgroundColor: theme.palette.grey[200] }}>
                {children}
            </Grid>
        </Box>
    );
};

export default ParentComponent;
