import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import completedImg from '@/assets/images/completed-img.png';
import emailIcon from '@/assets/images/icons/email.svg';
import phoneIcon from '@/assets/images/icons/phone.svg';

const StyledLink = styled('a')`
    display: inline-block;
    padding: 5px 0 5px 37px;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    color: #6b778c;
    background: ${({ icon }) => `url(${icon}) 0 50% no-repeat`};
`;

const CompletedMessage = ({ isVisible }) =>
    isVisible && (
        <>
            <img style={{ maxWidth: '100%' }} src={completedImg} alt="completed" />
            <Typography variant="h1">Спасибо</Typography>
            <Typography>Мы ценим полученную от вас обратную связь</Typography>
            {/* <Typography>
                Если у вас остались нерешенные проблемы, связанные с этим заказом, просим связаться со службой поддержки.
            </Typography>
            <div style={{ marginBottom: '10px' }}>
                <StyledLink href="tel:+7 777 777 77 77" icon={phoneIcon}>
                    +7 777 777 77 77
                </StyledLink>
            </div>
            <StyledLink href="mailto:loggy.support@gmail.com" icon={emailIcon}>
                loggy.support@gmail.com
            </StyledLink> */}
        </>
    );

export default CompletedMessage;
