import { configureStore } from '@reduxjs/toolkit';

import authReducer from './reducers/authSlice';
import filtersReducer from './reducers/filtersSlice';
import monitoringReducer from './reducers/monitoringSlice';
import notificationsReducer from './reducers/notificationsSlice';
// eslint-disable-next-line import/no-cycle
import ordersReducer from './reducers/orders';
import toastrReducer from './reducers/toastr';

const localStorageMiddleware =
    ({ getState }) =>
    (next) =>
    (action) => {
        const result = next(action);
        localStorage.setItem('user', JSON.stringify(getState().auth.user));
        return result;
    };

const reHydrateStore = () => {
    if (localStorage.getItem('user') !== null) {
        return { auth: { user: JSON.parse(localStorage.getItem('user')) } };
    }
    return undefined;
};

export const store = configureStore({
    reducer: {
        monitoring: monitoringReducer,
        auth: authReducer,
        toastr: toastrReducer,
        orders: ordersReducer,
        notifications: notificationsReducer,
        filters: filtersReducer
    },
    devTools: true,
    preloadedState: reHydrateStore(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware)
});

store.subscribe(() => {
    if (store.getState().auth) {
        localStorage.setItem('user', JSON.stringify(store.getState().auth.user));
    }
});

export default store;
