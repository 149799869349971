import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router';

import ArmListLayout from '@/components/layouts/armListLayout';
import ProfileDetails from '@/views/profile/details';
import ProfileUpdate from '@/views/profile/update';

const Profile = () => {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = t('profile:title');
    }, []);
    return (
        <ArmListLayout>
            <Routes>
                <Route path="" element={<ProfileDetails />} />
                <Route path="/update" element={<ProfileUpdate />} />
            </Routes>
        </ArmListLayout>
    );
};

export default Profile;
