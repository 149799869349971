import ErrorIcon from '@mui/icons-material/Error';
import { Alert, Slide, Snackbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { ReactSVG } from 'react-svg';

import NotificationSuccessIcon from '../../assets/images/icons/notification-success.svg';

const StyledAlert = styled(Alert)`
    font-size: 14px;
    color: var(--main-text-color);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12), 0 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 99999999;

    & strong {
        display: block;
        margin-bottom: 5px;
        font-weight: 400;
        color: #000;
        font-size: 16px;
    }
`;

const CustomSnackbar = ({ message, open, handleClose, type }) => (
    <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        autoHideDuration={10000}
        sx={{ zIndex: '99999999' }}
    >
        <StyledAlert
            iconMapping={type ? { error: <ErrorIcon /> } : { success: <ReactSVG src={NotificationSuccessIcon} /> }}
            onClose={handleClose}
            severity={type ? 'error' : 'success'}
            sx={{ width: '100%' }}
        >
            {message}
        </StyledAlert>
    </Snackbar>
);

export default CustomSnackbar;
