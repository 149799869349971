import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { userAxios } from '@/api/axiosSettings';
import CustomSnackbar from '@/components/snackbar';
import { NavigationScroll } from '@/containers';
import WithErrorBoundary, { withHandleErrors } from '@/core/HOC/ErrorBoundary';
import themes from '@/core/themes';
import Routes from '@/routes';
import { resetToastrState } from '@/toolkit/reducers/toastr';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
            // refetchOnMount: false,
            // cacheTime: 1 * 60 * 60 * 1000,
            // staleTime: 1 * 60 * 60 * 1000,
            // retry: 1
        }
    }
});

const App = () => {
    const toastrState = useSelector((state) => state.toastr);
    const customization = {
        fontFamily: '"Inter", "Roboto", arial, sans-serif'
    };

    const dispatch = useDispatch();

    return (
        <QueryClientProvider client={queryClient}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <CustomSnackbar
                        handleClose={() => {
                            dispatch(resetToastrState());
                        }}
                        open={toastrState.isVisible}
                        message={toastrState.message}
                        type={toastrState.type}
                    />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </QueryClientProvider>
    );
};

export default withHandleErrors(WithErrorBoundary(App, userAxios));
