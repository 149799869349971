export const feedbackMap = new Map([
    [
        1,
        {
            title: 'Просим прощения! Что пошло не так?',
            text: 'Мы хотим это исправить. Что пошло не так как, как нужно? Ваши комментарии очень помогут.'
        }
    ],
    [
        2,
        {
            title: 'Просим прощения! Что пошло не так?',
            text: 'Мы хотим это исправить. Что пошло не так как, как нужно? Ваши комментарии очень помогут.'
        }
    ],
    [
        3,
        {
            title: 'Хорошо. Что нам следует улучшить?',
            text: 'Средне - это не круто. Что нам исправить в следующий раз?'
        }
    ],
    [
        4,
        {
            title: 'Благодарим. Что мы могли бы улучшить?',
            text: '4/5 это хорошо - но не идеально. Что нам стоит улучшить в следующий раз?'
        }
    ],
    [
        5,
        {
            title: 'Хорошо, что всё прошло славно!',
            text: 'Что вам понравилось больше всего?'
        }
    ],
    [
        null,
        {
            title: 'Насколько вы довольны доставкой?',
            text: 'Ваш отзыв поможет улучшить качество нашей доставки'
        }
    ]
]);
