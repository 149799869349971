import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Skeleton, Typography, Box } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import AddressAutocomplete from '@/components/addressAutocomplete';
import { RoundedButton } from '@/components/button/styledButtons';
import CitySelectNew from '@/components/citySelectNew';
import LocalizedDatePicker from '@/components/datePicker';
import '@/components/timePicker/styles.scss';
import DetailsInfoBlock from '@/components/detailsInfoBlock';
import FieldLabel from '@/components/formField/fieldLabel';
import PhoneNumberInput from '@/components/formField/phoneNumberInput';
import SelectInput from '@/components/formField/selectInput';
import TextInput from '@/components/formField/textInput';
import TextAreaInput from '@/components/textAreaInput';
import useAutocomplete from '@/core/hooks/useAutocomplete';
import useModal from '@/core/hooks/useModal';
import i18n from '@/core/i18n';
import { cityServices } from '@/core/services/cityServices';
import { orderChainServices } from '@/core/services/orderChainServices';
import { partnerServices } from '@/core/services/partnerServices';
import { PARTNERS_LIST } from '@/core/utils/constants/queryKeys';
import { ORDER_CHAIN_DETAILS } from '@/core/utils/constants/routes';
import { addNotification } from '@/toolkit/reducers/toastr';
import CreateStageModal from '@/views/orderChain/components/createStageModal';
import { StagesForCreate, CreateStageBox } from '@/views/orderChain/components/stages';
import { orderChainTypes } from '@/views/orderChain/utils';

const { t } = i18n;

const schema = yup.object({
    partnerId: yup.string().required(t('errors:required')),

    orderChainType: yup.string().required(t('errors:required')),

    expectedDeliveryDate: yup.date().required(t('errors:required')),
    deadlineDeliveryDate: yup.date().required(t('errors:required')),

    packageName: yup.string().required(t('errors:required')),
    packageDescription: yup.string(),
    packageLength: yup.string(),
    packageHeight: yup.string(),
    packageWidth: yup.string(),
    packageWeight: yup.string(),

    senderName: yup.string().required(t('errors:required')),
    senderPhoneNumber: yup.string().required(t('errors:required')),
    senderAddress: yup.string().required(t('errors:required')),
    senderCityId: yup.number().required(t('errors:required')),

    receiverName: yup.string().required(t('errors:required')),
    receiverPhoneNumber: yup.string().required(t('errors:required')),
    receiverAddress: yup.string().required(t('errors:required')),
    receiverCityId: yup.number().required(t('errors:required')),

    comment: yup.string()
});

const OrderChainCreate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const defaultExpectedDate = new Date();
    defaultExpectedDate.setDate(defaultExpectedDate.getDate() + 1);
    defaultExpectedDate.setHours(23);
    defaultExpectedDate.setMinutes(59);

    const defaultDeadlineDate = new Date();
    defaultDeadlineDate.setDate(defaultDeadlineDate.getDate() + 2);
    defaultDeadlineDate.setHours(23);
    defaultDeadlineDate.setMinutes(59);

    const [stages, setStages] = useState([]);

    const stagesHaveLast = stages.some((s) => s.is_last);

    const {
        watch,
        getValues,
        setValue,
        register,
        handleSubmit,
        trigger,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            expectedDeliveryDate: defaultExpectedDate,
            deadlineDeliveryDate: defaultDeadlineDate,

            orderChainType: orderChainTypes[0].id
        },
        mode: 'all',
        resolver: yupResolver(schema)
    });

    const { data: partnersData, isLoading: partnersLoading } = useQuery(PARTNERS_LIST, partnerServices.getPartners);
    const partners = partnersData?.data;

    const selectedPartner = watch('partnerId') && partners.find((p) => p.id === getValues('partnerId'));

    const { data: countries } = useQuery('countries', cityServices.getCountriesList);

    const cities = useMemo(() => {
        if (countries) {
            return countries?.data
                ?.map((o) => o.regions)
                ?.flat()
                ?.map((r) => r.cities)
                ?.flat();
        }
        return null;
    }, [countries]);

    const senderAddressAutocomplete = useAutocomplete(['senderAddress'], setValue, () => trigger('senderAddress'));
    const receiverAddressAutocomplete = useAutocomplete(['receiverAddress'], setValue, () => trigger('receiverAddress'));

    const {
        openMap: openSenderMap,
        setMapOpened: setSenderMapOpened,
        onSelectAddress: onSelectSenderAddress,
        mapOpened: senderMapOpened,
        selectAddressOnMap: selectSenderAddressOnMap,
        latlon: senderLatLon
    } = senderAddressAutocomplete;

    const {
        openMap: openReceiverMap,
        setMapOpened: setReceiverMapOpened,
        onSelectAddress: onSelectReceiverAddress,
        mapOpened: receiverMapOpened,
        selectAddressOnMap: selectReceiverAddressOnMap,
        latlon: receiverLatLon
    } = receiverAddressAutocomplete;

    const orderChainCreation = useMutation((data) => orderChainServices.createOrder(data));

    const onSubmit = async (data) => {
        try {
            const payload = {
                partner_id: data.partnerId,

                type: data.orderChainType,

                expected_delivery_datetime: data.expectedDeliveryDate,
                deadline_delivery_datetime: data.deadlineDeliveryDate,

                package_params: {
                    name: data.packageName,
                    description: data.packageDescription,
                    length: data.packageLength,
                    height: data.packageHeight,
                    width: data.packageWidth,
                    weight: data.packageWeight
                },

                sender: {
                    name: data.senderName,
                    phone_number: data.senderPhoneNumber.replaceAll(/[() -]/g, ''),
                    city_id: data.senderCityId,
                    address: data.senderAddress,
                    latitude: Number(senderLatLon.lat).toFixed(8),
                    longitude: Number(senderLatLon.lon).toFixed(8)
                },

                receiver: {
                    name: data.receiverName,
                    phone_number: data.receiverPhoneNumber.replaceAll(/[() -]/g, ''),
                    city_id: data.receiverCityId,
                    address: data.receiverAddress,
                    latitude: Number(receiverLatLon.lat).toFixed(8),
                    longitude: Number(receiverLatLon.lon).toFixed(8)
                },

                stages,

                comment: data.comment
            };
            const result = await orderChainCreation.mutateAsync(payload);
            dispatch(addNotification({ message: 'Многоэтапная доставка успешно создана' }));
            navigate(ORDER_CHAIN_DETAILS.format(result.data.id));
        } catch (e) {
            dispatch(addNotification({ message: 'Не удалось создать многоэтапную доставку', type: 'error' }));
        }
    };

    const [createStageModalOpened, openCreateStageModal, closeCreateStageModal] = useModal();

    const onCreateStageClick = () => {
        if (selectedPartner) {
            openCreateStageModal();
        } else {
            dispatch(addNotification({ message: 'Не выбран партнер', type: 'error' }));
        }
    };

    return (
        <>
            <DetailsInfoBlock title="Общие данные">
                <DetailsInfoBlock.CustomChild label={!partners ? t('orders:create.partner') : null}>
                    {partnersLoading && (
                        <Skeleton
                            animation="wave"
                            width="100%"
                            height="47px"
                            style={{ width: '100%', height: '47px', transform: 'scale(1)' }}
                        />
                    )}
                    {partners && (
                        <SelectInput
                            id="partnerId"
                            label={t('orders:create.labels.partner')}
                            options={partners.map(({ id, name_ru: name }) => ({ id, name }))}
                            required
                            control={control}
                            errors={errors}
                            {...register('partnerId')}
                        />
                    )}
                </DetailsInfoBlock.CustomChild>
                <DetailsInfoBlock.CustomChild>
                    <SelectInput
                        id="orderChainType"
                        label="Тип заявки"
                        options={orderChainTypes}
                        required
                        control={control}
                        errors={errors}
                        {...register('orderChainType')}
                    />
                </DetailsInfoBlock.CustomChild>
            </DetailsInfoBlock>
            <DetailsInfoBlock title="Данные о посылке">
                <DetailsInfoBlock.CustomChild>
                    <TextInput
                        id="packageName"
                        type="text"
                        label="Название посылки"
                        required
                        control={control}
                        errors={errors}
                        {...register('packageName')}
                    />
                </DetailsInfoBlock.CustomChild>
                <DetailsInfoBlock.CustomChild>
                    <TextInput
                        id="packageDescription"
                        type="text"
                        label="Описание посылки"
                        control={control}
                        errors={errors}
                        {...register('packageDescription')}
                    />
                </DetailsInfoBlock.CustomChild>
                <DetailsInfoBlock.CustomChild sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <TextInput
                        id="packageLength"
                        label="Длина"
                        type="text"
                        control={control}
                        errors={errors}
                        {...register('packageLength')}
                    />
                    <TextInput
                        id="packageHeight"
                        label="Высота"
                        type="text"
                        control={control}
                        errors={errors}
                        {...register('packageHeight')}
                    />
                    <TextInput
                        id="packageWidth"
                        label="Ширина"
                        type="text"
                        control={control}
                        errors={errors}
                        {...register('packageWidth')}
                    />
                    <TextInput
                        id="packageWeight"
                        label="Вес"
                        type="text"
                        control={control}
                        errors={errors}
                        {...register('packageWeight')}
                    />
                </DetailsInfoBlock.CustomChild>
            </DetailsInfoBlock>
            <DetailsInfoBlock title="Даты доставки">
                <DetailsInfoBlock.CustomChild>
                    <FieldLabel>{t('orderChain:create.labels.expectedDeliveryDate')}</FieldLabel>
                    <LocalizedDatePicker
                        sx={{
                            width: '100%',
                            '& .MuiFormControl-root': { width: '100% !important' }
                        }}
                        minDate={new Date()}
                        value={watch('expectedDeliveryDate')}
                        onChange={(v) => setValue('expectedDeliveryDate', v)}
                    />
                    {errors.date && (
                        <Typography
                            sx={{
                                color: '#FF6464',
                                marginTop: '6px !important'
                            }}
                        >
                            {errors.date.message}
                        </Typography>
                    )}
                </DetailsInfoBlock.CustomChild>
                <DetailsInfoBlock.CustomChild>
                    <FieldLabel>{t('orderChain:create.labels.deadlineDeliveryDate')}</FieldLabel>
                    <LocalizedDatePicker
                        sx={{
                            width: '100%',
                            '& .MuiFormControl-root': { width: '100% !important' }
                        }}
                        minDate={new Date()}
                        value={watch('deadlineDeliveryDate')}
                        onChange={(v) => setValue('deadlineDeliveryDate', v)}
                    />
                    {errors.date && (
                        <Typography
                            sx={{
                                color: '#FF6464',
                                marginTop: '6px !important'
                            }}
                        >
                            {errors.date.message}
                        </Typography>
                    )}
                </DetailsInfoBlock.CustomChild>
            </DetailsInfoBlock>
            <Box sx={{ display: 'flex', columnGap: 4 }}>
                <DetailsInfoBlock title="Отправитель">
                    <DetailsInfoBlock.CustomChild>
                        <TextInput
                            id="senderName"
                            type="text"
                            label={t('orderChain:create.labels.senderName')}
                            required
                            control={control}
                            errors={errors}
                            {...register('senderName')}
                        />
                    </DetailsInfoBlock.CustomChild>
                    <DetailsInfoBlock.CustomChild>
                        <PhoneNumberInput
                            id="senderPhoneNumber"
                            name="senderPhoneNumber"
                            defaultCountry="kz"
                            control={control}
                            errors={errors}
                            label={t('orderChain:create.labels.senderPhoneNumber')}
                            required
                            {...register('senderPhoneNumber')}
                        />
                    </DetailsInfoBlock.CustomChild>
                    <DetailsInfoBlock.CustomChild>
                        <CitySelectNew
                            id="senderCityId"
                            label={t('orderChain:create.labels.senderCity')}
                            options={countries?.data}
                            saveCity={false}
                            required
                            value={watch('senderCityId')}
                            {...register('senderCityId')}
                        />
                    </DetailsInfoBlock.CustomChild>
                    <DetailsInfoBlock.CustomChild>
                        <AddressAutocomplete
                            id="senderAddress"
                            control={control}
                            errors={errors}
                            required
                            register={register}
                            city={cities?.find((c) => c.id === watch('senderCityId'))?.name}
                            disabled={!watch('senderCityId')}
                            openMap={openSenderMap}
                            onSelect={onSelectSenderAddress}
                            mapOpened={senderMapOpened}
                            setMapOpened={setSenderMapOpened}
                            selectAddressOnMap={selectSenderAddressOnMap}
                        />
                    </DetailsInfoBlock.CustomChild>
                </DetailsInfoBlock>
                <DetailsInfoBlock title="Получатель">
                    <DetailsInfoBlock.CustomChild>
                        <TextInput
                            id="receiverName"
                            type="text"
                            label={t('orders:create.labels.name')}
                            required
                            control={control}
                            errors={errors}
                            {...register('receiverName')}
                        />
                    </DetailsInfoBlock.CustomChild>
                    <DetailsInfoBlock.CustomChild>
                        <PhoneNumberInput
                            id="receiverPhoneNumber"
                            name="receiverPhoneNumber"
                            defaultCountry="kz"
                            control={control}
                            errors={errors}
                            label={t('orders:create.labels.phoneNumber')}
                            required
                            {...register('receiverPhoneNumber')}
                        />
                    </DetailsInfoBlock.CustomChild>
                    <DetailsInfoBlock.CustomChild>
                        <CitySelectNew
                            id="receiverCityId"
                            label={t('orderChain:create.labels.receiverCity')}
                            options={countries?.data}
                            saveCity={false}
                            required
                            value={watch('receiverCityId')}
                            {...register('receiverCityId')}
                        />
                    </DetailsInfoBlock.CustomChild>
                    <DetailsInfoBlock.CustomChild>
                        <AddressAutocomplete
                            id="receiverAddress"
                            control={control}
                            errors={errors}
                            required
                            register={register}
                            city={cities?.find((c) => c.id === watch('receiverCityId'))?.name}
                            disabled={!watch('receiverCityId')}
                            openMap={openReceiverMap}
                            onSelect={onSelectReceiverAddress}
                            mapOpened={receiverMapOpened}
                            setMapOpened={setReceiverMapOpened}
                            selectAddressOnMap={selectReceiverAddressOnMap}
                        />
                    </DetailsInfoBlock.CustomChild>
                </DetailsInfoBlock>
            </Box>
            <DetailsInfoBlock title="Этапы">
                <StagesForCreate stages={stages} setStages={setStages}>
                    {!stagesHaveLast && (
                        <CreateStageBox onClick={onCreateStageClick}>
                            <Box sx={{ textAlign: 'center' }}>
                                <AddCircleOutlineIcon fontSize="medium" htmlColor="var(--accent-color)" />
                                <Typography color="var(--accent-color)" fontWeight={600}>
                                    Создать этап
                                </Typography>
                            </Box>
                        </CreateStageBox>
                    )}
                </StagesForCreate>
            </DetailsInfoBlock>
            <DetailsInfoBlock title={t('orders:create.blockTitles.comment')}>
                <DetailsInfoBlock.CustomChild>
                    <TextAreaInput
                        id="comment"
                        label={t('orders:create.blockTitles.comment')}
                        type="text"
                        minLength={0}
                        control={control}
                        errors={errors}
                        {...register('comment')}
                    />
                </DetailsInfoBlock.CustomChild>
            </DetailsInfoBlock>
            <Box width="100%" display="flex" justifyContent="end">
                <RoundedButton type="submit" onClick={handleSubmit(onSubmit)}>
                    {t('buttons:create')}
                </RoundedButton>
            </Box>
            {createStageModalOpened && (
                <CreateStageModal
                    partners={partners}
                    selectedPartner={selectedPartner}
                    countries={countries}
                    cities={cities}
                    stages={stages}
                    setStages={setStages}
                    handleClose={closeCreateStageModal}
                    orderChainReceiverLatLon={receiverLatLon}
                    orderChainGetValues={getValues}
                />
            )}
        </>
    );
};

export default OrderChainCreate;
