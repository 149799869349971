import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import SelectInput from '@/components/formField/selectInput';
import { shipmentPointServices } from '@/core/services/shipmentPointServices';
import { PARTNER_FILIAL_MANAGER } from '@/core/utils/constants/constants';
import { SHIPMENT_POINTS } from '@/core/utils/constants/queryKeys';

const ShipmentPointsField = ({ partners, selectedPartnerId, selectedCityId, control, errors, register, registerId, t }) => {
    const user = useSelector((state) => state.auth?.user);

    let { data: shipmentPoints } = useQuery(
        [SHIPMENT_POINTS, selectedCityId, selectedPartnerId],
        () => shipmentPointServices.getList({ partner_id: selectedPartnerId, city_id: selectedCityId }),
        { enabled: !!selectedCityId && !!selectedPartnerId }
    );
    shipmentPoints = shipmentPoints?.data?.items;
    let { data: selfOwnPoints } = useQuery(['shipment_points_self', selectedCityId], () =>
        shipmentPointServices.getList({ partner_id: user?.organization?.id, city_id: selectedCityId })
    );
    selfOwnPoints = selfOwnPoints?.data?.items;

    const points = {};

    if (user.data.profile.profile_type === PARTNER_FILIAL_MANAGER && !shipmentPoints?.length) {
        shipmentPoints?.push({
            ...user.data.profile.profile_content.shipment_point,
            id: user.data.profile.profile_content.shipment_point_id
        });
    }

    if (selfOwnPoints?.length) points['Собственные точки'] = selfOwnPoints;
    if (shipmentPoints?.length) points[`Точки ${partners?.data?.find((p) => selectedPartnerId === p.id)?.name_ru}`] = shipmentPoints;

    return (
        <SelectInput
            id={registerId}
            label={t('orders:create.labels.shipmentPoint')}
            control={control}
            errors={errors}
            type="text"
            grouped
            options={points}
            placeholder="Выберите точку вывоза"
            disabled={!Object.keys(points).length}
            disabledText={t('orders:create.placeholders.shipmentPoint')}
            {...register(registerId)}
        />
    );
};
export default ShipmentPointsField;
