import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';

import HasAccess from '@/components/auth/permissions/hasAccess';
import useDragAndDrop from '@/core/hooks/useDragAndDrop';
import { FILIAL_MANAGER, PARTNER_MANAGER, SERVICE_MANAGER, SUPERVISOR } from '@/core/utils/constants/constants';
import { ALL_COLS_OBJ } from '@/views/orders/list/colsCongif';
import './styles.css';

const ListTableHeader = ({ headers, setHeaders, checked, checkboxHandler }) => {
    const { dragEndHandler, dragOverHandler, dragStartHandler, dragLeaveHandler, dropHandler } = useDragAndDrop(
        headers,
        setHeaders,
        'header_drag_right',
        'header_drag_left',
        'headerKey'
    );
    return (
        <TableHead>
            <TableRow>
                <HasAccess roles={[SERVICE_MANAGER, SUPERVISOR, PARTNER_MANAGER, FILIAL_MANAGER]}>
                    {!!checkboxHandler && (
                        <TableCell
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                width: '20px',
                                height: '100%'
                            }}
                        >
                            <Checkbox checked={checked} onChange={checkboxHandler} color="success" />
                        </TableCell>
                    )}
                </HasAccess>
                {headers.map((header) => (
                    <TableCell key={header.headerKey}>
                        <div
                            onDragLeave={dragLeaveHandler}
                            onDragStart={(e) => dragStartHandler(e, header)}
                            onDrop={(e) => dropHandler(e)}
                            onDragOver={(e) => dragOverHandler(e, header)}
                            onDragEnd={(e) => dragEndHandler(e, header)}
                            draggable={!!setHeaders && !header.notDrag}
                            style={{
                                cursor: !!setHeaders && !header.notDrag ? 'grab' : '',
                                display: 'inline-flex',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            {header.title || ALL_COLS_OBJ[header.headerKey].title}
                            {header.Render && <header.Render />}
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default ListTableHeader;
