import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isVisible: false,
    message: '',
    type: ''
};

export const toastrSlice = createSlice({
    name: 'toastr',
    initialState,
    reducers: {
        addNotification: (state, { payload }) => {
            state.isVisible = true;
            state.message = payload.message;
            state.type = payload.type;
        },
        resetToastrState: (state) => {
            state.isVisible = false;
            state.message = '';
        }
    }
});

export const { addNotification, resetToastrState } = toastrSlice.actions;

export default toastrSlice.reducer;
