import { Skeleton, TableCell, TableRow } from '@mui/material';
import React from 'react';

const RowSkeleton = ({ amount }) => (
    <TableRow>
        {Array(amount)
            .fill('')
            .map((_, i) => (
                <TableCell key={i}>
                    <Skeleton sx={{ transform: 'scale(1, 0.9)' }} animation="wave" width="100%" height="30px" />
                </TableCell>
            ))}
    </TableRow>
);

export default RowSkeleton;
