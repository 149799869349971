import { Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import React from 'react';

import loader from '@/assets/images/icons/loader.svg';

const LoaderWrapper = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1301,
    width: '100%'
});

const Loader = () => (
    <LoaderWrapper>
        <LinearProgress color="primary" />
    </LoaderWrapper>
);

export const MainLoader = styled(Box)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9) url(${loader}) 50% 50% no-repeat;
    z-index: 2000;
    display: ${(props) => (props.loading ? 'block' : 'none')};
`;

export default Loader;
