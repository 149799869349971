import { useState } from 'react';

const useDragAndDrop = (items, setItems, onDragOverRightCssClass, onDragOverLeftCssClass, uniqueFieldName) => {
    const [lastOver, setLastOver] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);

    const getItemIndex = (item) => items.findIndex((i) => (i && i[uniqueFieldName]) === (item && item[uniqueFieldName]));

    const dragStartHandler = (e, header) => {
        setCurrentItem(header);
    };

    const dragLeaveHandler = (e) => {
        e.currentTarget.classList.remove(onDragOverRightCssClass);
        e.currentTarget.classList.remove(onDragOverLeftCssClass);
    };

    const dragEndHandler = (e, item) => {
        if (item[uniqueFieldName] === lastOver[uniqueFieldName]) return;

        const prevHeaderIndex = getItemIndex(lastOver);
        const currentHeaderIndex = getItemIndex(item);
        const itemsCopy = [...items];
        itemsCopy.splice(currentHeaderIndex, 1);
        itemsCopy.splice(prevHeaderIndex, 0, item);
        setItems(itemsCopy);
    };

    const dropHandler = (e) => {
        e.preventDefault();
        e.currentTarget.classList.remove(onDragOverRightCssClass);
        e.currentTarget.classList.remove(onDragOverLeftCssClass);
    };

    const dragOverHandler = (e, item) => {
        setLastOver(item);

        if ((item && item[uniqueFieldName]) === (currentItem && currentItem[uniqueFieldName])) return;
        e.preventDefault();

        const currentItemIndex = getItemIndex(currentItem);
        const itemIndex = getItemIndex(item);

        if (currentItemIndex > itemIndex) {
            e.currentTarget.classList.add(onDragOverLeftCssClass);
        } else {
            e.currentTarget.classList.add(onDragOverRightCssClass);
        }

        e.currentTarget.style.transition = '0.3s';
    };
    return { dragOverHandler, dropHandler, dragEndHandler, dragLeaveHandler, dragStartHandler };
};

export default useDragAndDrop;
