import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ListTable from '@/components/listTable';
import { orderChainServices } from '@/core/services/orderChainServices';
import { ORDER_CHAIN_DETAILS } from '@/core/utils/constants/routes';
import { humanDateFormat } from '@/core/utils/utils';

const headers = [
    { title: 'ID заявки', key: 'id' },
    { title: 'Партнер', key: 'partner' },
    { title: 'Даты доставки: Ожидаемая - Крайняя', key: 'created_at' },
    { title: 'Отправитель', key: 'manager' },
    { title: 'Получатель', key: 'updated_at' },
    { title: 'Посылка', key: 'status' },
    { title: 'Кол-во этапов', key: 'stages' }
];

const OrderChainList = () => {
    const { t } = useTranslation();

    const { data: orderChainQuery } = useQuery('orderChains', orderChainServices.getList);
    const items = orderChainQuery?.data.items;
    const orderChains = items?.length ? items : null;

    return (
        <ListTable headers={headers}>
            {orderChains ? (
                orderChains.map((order) => <OrderChainTableRow key={order.id} orderChain={order} />)
            ) : (
                <tr>
                    <td colSpan={headers?.length} align="center">
                        {t('common:emptyList')}
                    </td>
                </tr>
            )}
        </ListTable>
    );
};
export default OrderChainList;

const OrderChainTableRow = ({ orderChain }) => {
    const navigate = useNavigate();

    return (
        <TableRow sx={{ cursor: 'pointer' }} onClick={() => navigate(ORDER_CHAIN_DETAILS.format(orderChain.id))}>
            <TableCell>{orderChain.id}</TableCell>
            <TableCell>{orderChain.partner.name_ru}</TableCell>
            <TableCell sx={{ fontVariantNumeric: 'tabular-nums' }}>
                {`
                ${humanDateFormat(new Date(orderChain.expected_delivery_datetime))}
                -
                ${humanDateFormat(new Date(orderChain.deadline_delivery_datetime))}
            `}
            </TableCell>
            <TableCell>{orderChain.sender.name}</TableCell>
            <TableCell>{orderChain.receiver.name}</TableCell>
            <TableCell>{orderChain.package_params.name}</TableCell>
            <TableCell>{orderChain.stages.length}</TableCell>
        </TableRow>
    );
};
