import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import i18n from '@/core/i18n';
import { getRedirectTab } from '@/views/orders/utils/localStorage';

const ErrorPage = ({ image, title, text, showButton, onClick }) => (
    <div style={{ marginTop: '9%', maxWidth: '540px', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
        <img width={300} alt="konus" src={image} />
        <div style={{ marginTop: '60px', fontWeight: 700, fontSize: '35px', color: 'var(--title-color)' }}>{title}</div>
        <div style={{ marginTop: '25px', color: 'var(--main-text-color)' }}>{text}</div>
        {showButton && (
            <Button
                component={Link}
                to={`/arm/orders/${getRedirectTab([])}`}
                style={{ marginTop: '30px', color: '#49C172', borderColor: '#49C172', textTransform: 'None' }}
                variant="outlined"
                onClick={onClick || null}
            >
                {i18n.t('buttons:backToOrders')}
            </Button>
        )}
    </div>
);

export default ErrorPage;
