import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSelect = styled(Select)`
    width: 100%;
    font-family: 'Inter', 'Roboto', arial, sans-serif;
    border-radius: 4px;
    overflow: hidden;
    background: var(--input-bg);
    & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${({ error, success }) => (error ? 'red' : success || 'transparent')};
    }
    &:hover .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${({ error, success }) => (error ? 'red' : success || 'transparent')};
    }
    &.Mui-focused {
        & .MuiOutlinedInput-notchedOutline {
            border: 1px solid ${({ error, success }) => (error ? 'red' : success || 'transparent')};
        }
    }
    & .MuiSelect-select {
        padding: 10px 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.8;
        color: var(--main-text-color);
        background: var(--input-bg);
        border: 1px solid transparent;
        &.Mui-disabled {
            color: var(--main-text-color);
            -webkit-text-fill-color: initial;
            opacity: 0.3;
        }
    }
    & .select_icon {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    & .MuiSelect-iconOpen.select_icon {
        top: 10px;
    }
`;

export default StyledSelect;
