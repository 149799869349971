import i18n from '@/core/i18n';

const { t } = i18n;

export const DEFAULT_TABS_OBJ = {
    all: {
        title: t('orders:tabs.all'),
        to: 'list/all',
        ordersTabKey: 'all'
    },
    new: {
        title: t('orders:tabs.new'),
        to: 'list/new',
        slug: 'novaia-zaiavka',
        ordersTabKey: 'new'
    },
    otw: {
        title: t('orders:tabs.OTW'),
        slug: 'on-the-way-to-call-point',
        to: 'list/on-the-way-to-call-point',
        ordersTabKey: 'otw'
    },
    posledkontrol: {
        title: t('orders:tabs.postcontrol'),
        slug: 'posledkontrol',
        to: 'list/post_control',
        ordersTabKey: 'posledkontrol'
    },
    postponed: {
        title: t('orders:tabs.postponed'),
        slug: 'postponed',
        to: 'list/postponed',
        ordersTabKey: 'postponed'
    },
    noncall: {
        title: t('orders:tabs.noncall'),
        slug: 'noncall',
        to: 'list/noncall',
        ordersTabKey: 'noncall'
    },
    cancelled: {
        title: t('orders:tabs.canceled'),
        slug: 'cancelled',
        to: 'list/cancelled',
        ordersTabKey: 'cancelled'
    },
    cancelled_at_client: {
        title: t('orders:tabs.canceledAtClient'),
        slug: 'cancelled_at_client',
        to: 'list/cancelled_at_client',
        ordersTabKey: 'cancelled_at_client'
    },
    rescheduled: {
        title: t('orders:tabs.rescheduled'),
        slug: 'rescheduled',
        to: 'list/rescheduled',
        ordersTabKey: 'rescheduled'
    },
    being_finalized: {
        title: t('orders:tabs.beingFinalized'),
        slug: 'being_finalized',
        to: 'list/being_finalized',
        ordersTabKey: 'being_finalized'
    },
    is_delivered: {
        title: t('orders:tabs.delivered'),
        slug: 'is_delivered',
        to: 'list/is_delivered',
        ordersTabKey: 'is_delivered'
    },
    archived: {
        title: t('orders:tabs.archived'),
        slug: 'archived',
        to: 'list/archived',
        ordersTabKey: 'archived'
    }
};

export const DEFAULT_TABS = Object.keys(DEFAULT_TABS_OBJ).map((key) => ({ ordersTabKey: DEFAULT_TABS_OBJ[key].ordersTabKey }));

export const DEFAULT_SORTER_TABS = [
    {
        title: t('orders:tabs.all'),
        to: 'list/all'
    },
    {
        title: t('orders:tabs.new'),
        to: 'list/new',
        slug: 'novaia-zaiavka'
    },
    {
        title: t('orders:tabs.packed'),
        to: 'list/packed',
        slug: 'upakovano'
    },
    {
        title: t('orders:tabs.addedToGroup'),
        to: 'list/group-included',
        slug: 'vkliucheno-v-gruppu'
    },
    {
        title: t('orders:tabs.readyToSend'),
        to: 'list/ready-for-pickup',
        slug: 'gotovo-k-vyvozu'
    },
    {
        title: t('orders:tabs.courierAssignedForDelivery'),
        to: 'list/courier-appointed',
        slug: 'kurer-k-vyvozu-naznachen'
    },
    {
        title: t('orders:tabs.naSverke'),
        to: 'list/revised',
        slug: 'na-sverke'
    },
    {
        title: t('orders:tabs.takenByCourier'),
        to: 'list/exported',
        slug: 'vyvezeno-kurerom'
    },
    {
        title: t('orders:tabs.acceptedByCourierService'),
        to: 'list/accepted-by-service',
        slug: 'priniato-kurerskoi-sluzhboi'
    },
    {
        title: t('orders:tabs.delivered'),
        slug: 'is_delivered',
        to: 'list/is_delivered'
    },
    { title: t('orders:tabs.archived'), slug: 'archived', to: 'list/archived' }
];
