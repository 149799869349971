import { Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { TypographyMain, ButtonHeader, LogoSection, TabChild } from '@/components';

const useStyles = makeStyles((theme) => ({
    roundRoot: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    round: {
        width: '15px',
        height: '15px',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF'
    }
}));

const RegisterLeftDescr = () => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Grid container>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <TypographyMain
                    theme={theme}
                    type="h4"
                    sx={{ fontSize: '1.2vw', lineHeight: 1.4, maxWidth: '18vw', fontWeight: 600, textAlign: 'left' }}
                >
                    Быстро оформляем заказы, планируем и оптимизируем маршрут
                </TypographyMain>
            </Grid>
            {/* <Grid gap="10px" direction="row" container justifyContent="center" align="center" sx={{ mt: 14 }} className={classes.roundRoot}> */}
            {/*    <Box className={classes.round} /> */}
            {/*    <Box className={classes.round} /> */}
            {/*    <Box className={classes.round} /> */}
            {/* </Grid> */}
        </Grid>
    );
};

export default RegisterLeftDescr;
