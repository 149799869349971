import { Grid } from '@mui/material';
import React from 'react';

const DataPolicy = () => (
    <Grid container justifyContent="center" sx={{ pt: 10 }}>
        <Grid item xs={9} sx={{ fontSize: 20, lineHeight: 1.4 }}>
            В соответствии с Законом Республики Казахстан от 21 мая 2013 года No 94-V «О персональных данных и их защите» даю свое согласие
            ТОО «LOGGY» БИН 200840020420 на сбор и обработку персональных данных, предоставляемых мной через сервис Loggy (далее - Сервис).
            <br />
            <br />
            Данным согласием разрешаю ТОО «LOGGY» осуществлять действия, направленные на накопление, хранение, изменение, дополнение,
            использование, распространение, обезличивание, блокирование и уничтожение моих персональных данных, предоставляемых через
            Сервис, на передачу персональных данных третьим лицам, в том числе на трансграничную передачу персональных данных на территорию
            иностранных государств, обеспечивающих защиту персональных данных.
            <br />
            <br />
            Согласие на сбор и обработку персональных данных дается мной для следующих целей: авторизация в Сервисе; предоставление
            возможности пользоваться функциями Сервиса; информационная поддержка и обеспечение возможности обратной связи; информирование о
            новых функциях и возможностях Сервиса; иные цели, необходимые для функционирования и развития Сервиса.
            <br />
            <br />
            Также даю свое согласие на предоставление и передачу информации, касающейся меня и моих финансовых и других обязательств
            имущественного характера, из государственных и негосударственных баз данных, от операторов и/или владельцев государственных баз
            данных, кредитных бюро и иных источников, в том числе информации, которая поступит в будущем.
            <br />
            <br />
            Настоящее согласие выдано на неограниченный срок
        </Grid>
    </Grid>
);

export default DataPolicy;
