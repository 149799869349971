import { httpClient } from '@/api/httpClient/httpClient';

export const reviewServices = {
    get(id) {
        return httpClient.get(`/feedbacks/${id}`);
    },
    updateStatus(id, status, finalScore) {
        return httpClient.put(`/feedbacks/${id}/update/status`, { status, final_score: finalScore });
    },
    createByReceiver(data) {
        return httpClient.post('/feedbacks/create/receiver', data);
    },
    createByManager(data) {
        return httpClient.post('/feedbacks/create/manager', data);
    }
};
