import { Box } from '@mui/material';
import React, { useState } from 'react';

import { FormRegister, FormRegisterSms, FormRegisterHeaderText, FormSuccessValidate } from '@/components/auth/authRegister';

const RegisterStepFirst = ({ setSignIn }) => {
    const [page, setPage] = useState(0);
    const [number, setNumber] = useState();
    const [iin, setIin] = useState('');
    const [profile, setProfile] = useState({});
    const [userId, setUserId] = useState({});

    const mapShowFirstText = new Map();
    const mapShowFirstBody = new Map();

    mapShowFirstText
        .set(0, () => <FormRegisterHeaderText progress={page} text="Регистрация" />)
        .set(1, () => <FormRegisterHeaderText progress={page} text="Введите SMS-код" />)
        .set(2, () => <FormRegisterHeaderText progress={page} text="Регистрация завершена" />);
    mapShowFirstBody
        .set(0, () => <FormRegister setPage={setPage} setNumber={setNumber} setIin={setIin} setUserId={setUserId} />)
        .set(1, () => <FormRegisterSms setPage={setPage} number={number} iin={iin} setProfile={setProfile} userId={userId} />)
        .set(2, () => <FormSuccessValidate setSignIn={setSignIn} profile={profile} iin={iin} />);

    return (
        <>
            <Box className="register_step_first" sx={{ width: '606px', marginTop: '30px' }}>
                {mapShowFirstText.get(page)()}
                {mapShowFirstBody.get(page)()}
            </Box>
        </>
    );
};

export default RegisterStepFirst;
