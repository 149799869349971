import { httpClient } from '@/api/httpClient/httpClient';

export const reasonServices = {
    get(partnerId) {
        return httpClient.get(`/feedback_reasons/${partnerId}`);
    },
    getByRate(partnerId, rate) {
        return httpClient.get(`/feedback_reasons/${partnerId}?rate=${rate}`);
    },
    create(data) {
        return httpClient.post('/feedback_reason', data);
    },
    delete(id) {
        return httpClient.delete(`/feedback_reasons/${id}`);
    },
    update(id, data) {
        return httpClient.put(`/feedback_reason/${id}`, data);
    }
};
