import TabsUnstyled from '@mui/base/TabsUnstyled';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import StyledTab from '@/components/tabs/StyledTab';
import StyledTabsList from '@/components/tabs/StyledTabsList';
import useDragAndDrop from '@/core/hooks/useDragAndDrop';
import i18n from '@/core/i18n';
import './style.css';

const { t } = i18n;

const ListTabs = ({ children, tabsContent, hideTabs, addTab, setTabs }) => {
    const { dragEndHandler, dragOverHandler, dragStartHandler, dragLeaveHandler, dropHandler } = useDragAndDrop(
        tabsContent,
        setTabs,
        'tab_drag_right',
        'tab_drag_left',
        'title',
        'orders_tab'
    );
    const newPostControls = useSelector((state) => state.notifications.postcontrols);
    const couriers = useSelector((state) => state.notifications.couriers);
    return (
        <TabsUnstyled style={{ maxHeight: '100%' }} defaultValue={0}>
            {hideTabs ? null : (
                <StyledTabsList variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example" value={0}>
                    {tabsContent[0] && 'to' in tabsContent[0]
                        ? tabsContent.map((tab, i) => (
                              <StyledTab
                                  data-drag="orders_tab"
                                  onDragLeave={dragLeaveHandler}
                                  onDragStart={(e) => dragStartHandler(e, tab)}
                                  onDrop={(e) => dropHandler(e)}
                                  onDragOver={(e) => dragOverHandler(e, tab)}
                                  onDragEnd={(e) => dragEndHandler(e, tab)}
                                  draggable={!!setTabs}
                                  style={setTabs && { cursor: 'grab' }}
                                  as={NavLink}
                                  to={tab.to}
                                  end
                                  key={i}
                              >
                                  {(tab.title === t('orders:tabs.postcontrol') && newPostControls.length) ||
                                  (tab.title === t('couriers:tabs.suspended') && couriers.length) ? (
                                      <span className="has_new" />
                                  ) : null}
                                  {tab.title}
                              </StyledTab>
                          ))
                        : tabsContent.map((tab, i) => <StyledTab key={i}>{tab.title}</StyledTab>)}
                    {addTab && (
                        <StyledTab onClick={addTab} as={Link}>
                            <div>
                                <SettingsIcon fontSize="small" />
                            </div>
                        </StyledTab>
                    )}
                </StyledTabsList>
            )}
            {children}
        </TabsUnstyled>
    );
};

export default ListTabs;
