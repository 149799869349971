import { MenuItem, MenuList, Paper, Popper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import FieldLabel from '@/components/formField/fieldLabel';
import { StyledInputElement } from '@/components/formField/textInput';

const Autocomplete = ({ id, label, setValue, defaultValueId, errors, required, disabled, disabledText, options, removeMarginBottom }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const anchorRef = useRef();

    useEffect(() => {
        if (options) {
            if (defaultValueId) {
                const defaultOption = options.find((o) => o.id === defaultValueId);
                setInputValue(defaultOption?.name || '');
                setSelectedId(defaultOption?.id || '');
            } else {
                // setInputValue('');
                setSelectedId('');
            }
        }
    }, [options]);

    useEffect(() => {
        if (defaultValueId) {
            setValue(id, defaultValueId);
        }
    }, []);

    const onChange = (e) => {
        setSelectedId(null);
        setInputValue(e.target.value);
        setFilteredOptions(options.filter((o) => o.name.toLowerCase().includes(e.target.value.toLowerCase())));
        setValue(id, '');
    };
    const onClickOption = (option) => {
        setInputValue(option.name);
        setIsOpen(false);
        setValue(id, option.id);
        setSelectedId(option.id);
        anchorRef.current.blur();
    };

    return (
        <div style={{ marginBottom: removeMarginBottom ? '' : '30px' }}>
            <FieldLabel htmlFor={id} required={required}>
                {label}
            </FieldLabel>
            <StyledInputElement
                id={id}
                autoComplete="off"
                onFocus={() => {
                    setIsOpen(true);
                    setFilteredOptions(options);
                }}
                ref={anchorRef}
                value={inputValue}
                placeholder={disabled ? disabledText : null}
                onChange={onChange}
                onBlur={() => setIsOpen(false)}
                error={errors[id]}
                required={required}
                success={!!selectedId && !errors[id] ? '#49C172' : 'transparent'}
                disabled={disabled}
            />
            <Popper open={isOpen} anchorEl={anchorRef.current}>
                <Paper
                    square
                    style={{
                        width: anchorRef.current ? anchorRef.current.clientWidth : null,
                        maxHeight: '250px',
                        overflowY: 'auto'
                    }}
                >
                    {!!filteredOptions.length && (
                        <MenuList>
                            {filteredOptions.map((option) => (
                                <MenuItem
                                    sx={{ color: 'var(--medium-text-color)' }}
                                    key={option.id}
                                    // если .is_active равно undefined, null или же когда есть параметр .is_active который равен true, то не отключать кнопку. Если же есть параметр .is_active и он равен false то выключать кнопку. Подробнее в задаче CP-1838(https://globerce.atlassian.net/browse/CP-1838)
                                    disabled={!(option.is_active ?? true)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={() => onClickOption(option)}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    )}
                </Paper>
            </Popper>
        </div>
    );
};

export default Autocomplete;
