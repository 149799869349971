import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, Fade, IconButton, LinearProgress, Modal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonWithLoader, RoundedSecondaryButton, TextSecondaryButton } from '../button/styledButtons';

import i18n from '@/core/i18n';
import { devices } from '@/core/utils/devices';

const { t } = i18n;

const StyledBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 22px 32px;
    transform: translate(-50%, -50%);
    width: 506px;
    max-width: 90%;
    max-height: 95vh;
    overflow-y: auto;
    background: var(--paper-bg);
    border-radius: 10px;
    z-index: 100000;
    @media screen and ${devices.mobileM} {
        padding: 10px 15px;
    }
    & .MuiLinearProgress-root {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    & h2 {
        color: var(--title-color);
    }
    & .form_field_select,
    & .checkbox_select {
        margin-bottom: 30px;
    }
    & .form_field_input {
        margin-bottom: 30px;
    }
    & .close_modal_icon {
        position: absolute;
        top: 23px;
        right: 20px;
        padding: 0;
        color: var(--medium-text-color);
    }
    & .back_modal_icon {
        position: absolute;
        top: 25px;
        left: 25px;
        padding: 0;
        color: var(--medium-text-color);
    }
`;
const StyledButtonContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    @media screen and ${devices.mobileM} {
        flex-direction: column-reverse;
        & :last-child {
            margin-bottom: 5px;
        }
    }
`;

const isShowingButton = (flag, handleClose, isValid) => {
    if (flag) {
        return (
            <StyledButtonContainer>
                <RoundedSecondaryButton className="modal_form_secondary_button" onClick={handleClose} variant="text">
                    {t('buttons:cancel')}
                </RoundedSecondaryButton>
                <ButtonWithLoader className="modal_form_button" variant="contained" color="success" disabled={!isValid} type="submit">
                    {t('buttons:accept')}
                </ButtonWithLoader>
            </StyledButtonContainer>
        );
    }
    return null;
};

const FormModal = ({
    children,
    isShowing,
    handleClose,
    onSubmit,
    isValid,
    buttonText,
    title,
    isLoading,
    backButton = false,
    sxStyle,
    showingButton,
    handleBack
}) => {
    const { t } = useTranslation();
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isShowing}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            sx={{ zIndex: 100000 }}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={isShowing}>
                <StyledBox>
                    {isLoading ? <LinearProgress color="success" /> : null}
                    <Typography pb={4} id="transition-modal-title" variant="h3" component="h2" sx={{ pl: backButton ? 4 : 0, ...sxStyle }}>
                        {backButton && (
                            <IconButton className="back_modal_icon" onClick={handleBack}>
                                <ArrowBackIcon />
                            </IconButton>
                        )}
                        {title}
                        <IconButton className="close_modal_icon" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Typography>
                    <form onSubmit={onSubmit}>
                        {children}
                        {backButton ? (
                            isShowingButton(showingButton, handleClose, isValid)
                        ) : (
                            <StyledButtonContainer>
                                <TextSecondaryButton onClick={handleClose}>{t('buttons:cancel')}</TextSecondaryButton>
                                <ButtonWithLoader
                                    loading={isLoading}
                                    className="modal_form_button"
                                    variant="contained"
                                    color="success"
                                    disabled={!isValid || isLoading}
                                    type="submit"
                                >
                                    {buttonText}
                                </ButtonWithLoader>
                            </StyledButtonContainer>
                        )}
                    </form>
                </StyledBox>
            </Fade>
        </Modal>
    );
};

export default FormModal;
