import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import application from '../../../../assets/images/create-application.svg';
import partner from '../../../../assets/images/create-partner.svg';

import { ModalWindow, ParentComponent, ParentComponentBody, ParentComponentHeader } from '@/components/createComponents';
import i18n from '@/core/i18n';
import { ADD_APPLICATION } from '@/redux/reducers/UserReducer/types';

const { t } = i18n;

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '3rem',
        backgroundColor: theme.palette.background.paperLight,
        height: '100vh',
        width: '100vw'
    },
    paperImg: {
        backgroundImage: ({ state }) => `url(${state?.svg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    }
}));

const NoPartner = ({ open, handleClose, handleOpen, setPage, page, type, setOpenFormTablePage }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [state, setState] = React.useState();
    const props = {
        state
    };
    const classes = useStyles(props);
    const dispatch = useDispatch();

    React.useEffect(() => {
        switch (type) {
            case 'application':
                setState({
                    textHeader: t('components:noPartner.orders'),
                    textBody: t('components:noPartner.noOrders'),
                    textDescr: t('components:noPartner.addOrder'),
                    svg: application
                });
                break;
            case 'partner':
                setState({
                    textHeader: t('components:noPartner.partners'),
                    textBody: t('components:noPartner.noPartners'),
                    textDescr: t('components:noPartner.addPartner'),
                    svg: partner
                });
                break;
            default:
                break;
        }
    }, [type]);

    return (
        <ParentComponent>
            <ParentComponentHeader>
                <Typography variant="h3" component="h3" sx={{ pt: '2.8rem', pl: '1.5rem' }}>
                    {state?.textHeader}
                </Typography>
            </ParentComponentHeader>
            <ParentComponentBody>
                <Box className={classes.paperImg} sx={{ width: '14rem', height: '14rem', m: '0 auto' }} />
                <Typography variant="h3" textAlign="center" sx={{ pt: 1 }}>
                    {state?.textBody}
                </Typography>
                <Typography variant="h3" textAlign="center" sx={{ pt: 1, fontWeight: 100 }}>
                    {state?.textDescr}
                </Typography>

                <Button
                    onClick={() => handleOpen()}
                    variant="contained"
                    sx={{
                        p: '10px 50px',
                        mt: 6,
                        backgroundColor: theme.palette.success.main,
                        color: 'white',
                        ml: '50%',
                        transform: 'translateX(-50%)'
                    }}
                >
                    {t('buttons:add')}
                </Button>
                <ModalWindow
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    open={open}
                    setPage={setPage}
                    page={page}
                    type={type}
                    setOpenFormTablePage={setOpenFormTablePage}
                />
            </ParentComponentBody>
        </ParentComponent>
    );
};

export default NoPartner;
