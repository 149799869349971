import { Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Tooltip } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import ListSkeleton from '@/components/listSkeleton';
import ListTable from '@/components/listTable';
import i18n from '@/core/i18n';
import { commonServices } from '@/core/services/common/commonServices';
import { PROFILE_TYPES } from '@/core/utils/constants/constants';

const { t } = i18n;

const headers = ['Дата и время', 'Автор', 'Действие'];

function getAction(actionData, requestMethod) {
    if (!actionData) return 'Создана';

    if (!actionData.info && requestMethod === 'PATCH') return 'Редактирование заявки';

    let text;

    switch (actionData.info) {
        case 'add_stage':
            text = 'Добавление этапа';
            break;
        case 'delete_stage':
            text = 'Удаление этапа';
            break;
        case 'add_support_document':
            text = 'Добавление документа в этап';
            break;
        case 'update_support_document':
            text = 'Редактирование документа в этапе';
            break;
        case 'delete_support_document':
            text = 'Удаление документа из этапа';
            break;
        default:
            text = '-';
            break;
    }

    return text;
}

const OrderChainHistory = () => {
    const params = useParams();
    const orderChainId = params.id;
    const { data: orderChainHistoryQuery, isLoading: isOrderChainHistoryLoading } = useQuery(['orderChainHistory', orderChainId], () =>
        commonServices.getHistory('OrderChain', orderChainId, 100, 1)
    );
    const orderChainHistory = orderChainHistoryQuery?.data;

    if (isOrderChainHistoryLoading) {
        return (
            <ListTable
                headers={headers.map((h, index) => ({
                    title: h,
                    key: `reviews_header_${index}`
                }))}
            >
                <ListSkeleton rows={6} cols={3} />
            </ListTable>
        );
    }

    if (orderChainHistory.total === 0) {
        return (
            <ListTable
                headers={headers.map((h, index) => ({
                    title: h,
                    key: `reviews_header_${index}`
                }))}
            >
                <tr>
                    <td colSpan={headers.length} align="center">
                        {t('common:emptyList')}
                    </td>
                </tr>
            </ListTable>
        );
    }

    return (
        <ListTable
            headers={headers.map((h, index) => ({
                title: h,
                key: `reviews_header_${index}`
            }))}
        >
            {orderChainHistory.items.map((item) => (
                <TableRow key={item.created_at}>
                    <TableCell>{new Date(item.created_at).toLocaleString(i18n.language)}</TableCell>
                    <TableCell>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {Object.keys(item.initiator).length
                            ? item.initiator_type !== 'ExternalService'
                                ? `${item.initiator?.first_name} ${item.initiator?.last_name} (${item.initiator?.profile_types
                                      .map((profileType) => PROFILE_TYPES[profileType])
                                      .join(', ')})`
                                : item.initiator?.name_ru
                            : t('orders:history.userNotFound')}
                    </TableCell>
                    <TableCell>{getAction(item.action_data, item.request_method)}</TableCell>
                </TableRow>
            ))}
        </ListTable>
    );
};

export default OrderChainHistory;
