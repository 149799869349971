import { Tabs } from '@mui/material';
import { styled } from '@mui/system';

import { devices } from '@/core/utils/devices';

const StyledTabsList = styled(Tabs)`
    position: relative;
    margin-bottom: 20px;
    @media screen and ${devices.tablet} {
        min-height: 0;
    }
    & .MuiTabs-indicator {
        display: none;
    }
    & .MuiTabScrollButton-root {
        position: absolute;
        top: 0;
        height: 100%;
        z-index: 10;
        background: rgba(var(--accent-color-rgb, 0.3));
        color: #fff;
        & svg {
            transform: scale(1.5);
        }
        @media (max-width: 599.95px) {
            display: inline-flex;
            width: 20px;
            //opacity: 0.5;
            & svg {
                transform: scale(1.2);
            }
        }
    }
    & .MuiTabScrollButton-root:first-of-type {
        left: 0;
    }
    & .MuiTabScrollButton-root:last-of-type {
        right: 0;
    }
`;

export default StyledTabsList;
