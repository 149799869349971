import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Typography, Stack, InputLabel, Link, LinearProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import * as yup from 'yup';

import darkLogo from '@/assets/images/logo-dark.png';
import lightLogo from '@/assets/images/logo.png';
import { RoundedButton } from '@/components/button/styledButtons';
import MaskedPhoneNumberInput from '@/components/formField/maskedPhoneNumberInput';
import PhoneNumberInput from '@/components/formField/phoneNumberInput';
import TextInput from '@/components/formField/textInput';
import UncontrolledSelect from '@/components/formField/uncontrolledSelect';
import i18n from '@/core/i18n';
import { authServices } from '@/core/services/authServices';
import { COURIER, PROFILE_TYPES } from '@/core/utils/constants/constants';
import { isPhoneValid, normalizePhoneNumber } from '@/core/utils/utils';
import { InputCode } from '@/pages/register/registerComponent/registerStepsComponent';
import { fetchCurrentUser, login } from '@/toolkit/actionCreators/auth';
import { getRedirectTab } from '@/views/orders/utils/localStorage';

const { t } = i18n;

const getCurrentTheme = () => {
    if (localStorage.getItem('theme')) {
        return localStorage.getItem('theme');
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark';
    }
    return 'light';
};

const setTheme = (theme) => {
    document.documentElement.setAttribute('data-theme', theme);
};

const schema = yup.object({
    loginMethodIsNumber: yup.boolean(),
    // number: yup.string().when('loginMethodIsNumber', {
    //     is: true,
    //     then: yup.string().required(t('errors:required')).min(10, t('errors:incorrectPhone'))
    // }),
    email: yup.string().when('loginMethodIsNumber', {
        is: false,
        then: yup.string().email(t('errors:incorrectEmail')).required(t('errors:required'))
    })
});

const Login = () => {
    const dispatch = useDispatch();
    const [isOTPReceived, setIsOTPReceived] = useState(false);
    const [loading, setLoading] = useState(false);
    const [codeSms, setCodeSms] = useState();
    const [seconds, setSeconds] = useState(45);
    const [err, setErr] = useState(false);
    const [code, setCode] = useState([...Array(4)].map(() => ''));
    const [profiles, setProfiles] = useState();
    const [selectedProfile, setSelectedProfile] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    const theme = getCurrentTheme();
    setTheme(theme);

    useEffect(() => {
        let myInterval = null;
        if (isOTPReceived) {
            myInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    clearInterval(myInterval);
                }
            }, 1000);
        }
        return () => {
            clearInterval(myInterval);
        };
    });

    const { state } = location;

    const user = useSelector((state) => state.auth.user);

    const {
        control,
        watch,
        setValue,
        handleSubmit,
        getValues,
        setError,
        register,
        formState: { errors, isDirty, isValid }
    } = useForm({ mode: 'all', resolver: yupResolver(schema), defaultValues: { loginMethodIsNumber: true } });

    const handleAutorizeOTP = useMutation(['AutorizeOTP'], (res) => authServices.registerOTP(res));
    const handleRegisterOTPValidate = useMutation(['RegisterOTPValidate'], (res) => authServices.registerOTPValidate(res));

    // eslint-disable-next-line consistent-return
    const onSubmit = async () => {
        const result = {
            credentials: getValues('loginMethodIsNumber')
                ? { phone_number: normalizePhoneNumber(getValues('number')) }
                : { email: getValues('email') },
            type: 'auth'
        };
        try {
            await handleAutorizeOTP.mutateAsync(result);
            setIsOTPReceived(true);
        } catch (e) {
            if (getValues('loginMethodIsNumber')) {
                const msg = e.error.response.data.detail[0].msg;
                const msgNotReg = e.error.response.data.detail;
                if (e.error?.response?.status === 403) return setError('number', { message: t('errors:accountInactive') });
                if (e.error?.response?.status === 404) return setError('number', { message: t('errors:phoneDoesNotExists') });
                if (msg || msgNotReg === 'Phone number is not valid') {
                    setError('number', { message: t('errors:incorrectPhone') });
                } else if (msg || msgNotReg === 'The phone number does not registered') {
                    setError('number', { message: t('errors:phoneDoesNotExists') });
                }
            } else {
                if (e.error?.response?.status === 403) return setError('email', { message: t('errors:accountInactive') });
                if (e.error?.response?.status === 404) return setError('email', { message: t('errors:emailDoesNotExists') });
            }
        }
    };

    const onProfileSelected = async (profile) => {
        const username = getValues('loginMethodIsNumber') ? normalizePhoneNumber(getValues('number')) : getValues('email');
        try {
            setLoading(true);
            await dispatch(login({ username, password: codeSms, profile })).unwrap();
            await dispatch(fetchCurrentUser()).unwrap();
            setLoading(false);
            navigate(state.prevPath);
        } catch (e) {
            setErr(true);
        }
    };

    const sendSms = async () => {
        if (codeSms?.length === 4) {
            const username = getValues('loginMethodIsNumber') ? normalizePhoneNumber(getValues('number')) : getValues('email');

            try {
                const response = await dispatch(login({ username, password: codeSms, agree: false })).unwrap();
                setProfiles(response.profiles.filter((p) => p.type !== COURIER));
                if (
                    response.profiles.length === 1 ||
                    (response.profiles.length === 2 && response.profiles.some((p) => p.type === COURIER))
                ) {
                    await onProfileSelected(response.profiles.find((p) => p.type !== COURIER));
                }
            } catch (e) {
                setErr(true);
            }
        }
    };

    useEffect(() => {
        if (profiles) {
            if (profiles.length === 1) setSelectedProfile(profiles[0]);
            if (profiles.length === 2 && profiles.some((p) => p.type === COURIER)) {
                setSelectedProfile(profiles.find((p) => p.type !== COURIER));
            }
        }
    }, [profiles]);

    const onLogin = async () => {
        if (!isOTPReceived) {
            await onSubmit();
            return null;
        }
        if (!profiles) {
            await sendSms();
            return null;
        }
        if (selectedProfile) {
            await onProfileSelected(selectedProfile);
            return null;
        }
        return null;
    };

    let buttonText = t('buttons:getOtpCode');
    if (isOTPReceived) buttonText = t('buttons:accept');
    if (profiles) buttonText = t('buttons:login');

    const checkPhoneNumber = isPhoneValid(watch('number'));

    return (
        <Box display="flex" justifyContent="center" mt="50px" height="calc(100vh - 50px)">
            {(handleRegisterOTPValidate.isLoading || handleAutorizeOTP.isLoading || loading) && (
                <LinearProgress color="success" style={{ position: 'absolute', top: 0, width: '100%', left: 0, height: '6px' }} />
            )}
            {user?.data ? (
                <Navigate to={`/arm/orders/${getRedirectTab([], user.data.profile.profile_type)}`} />
            ) : (
                <form style={{ width: '300px' }} onSubmit={handleSubmit(onLogin)}>
                    <Box sx={{ textAlign: 'center', mb: '40px' }}>
                        <img width={120} height={24} src={theme === 'dark' ? lightLogo : darkLogo} alt="Loggy logo" />
                    </Box>
                    <Stack direction="column" gap={3}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography sx={{ fontSize: 20, fontWeight: 700, color: 'var(--main-text-color)' }}>
                                {t('modals:authorizateModal.login')}
                            </Typography>
                        </Stack>
                        {watch('loginMethodIsNumber') ? (
                            <Stack direction="column">
                                <PhoneNumberInput
                                    id="number"
                                    defaultCountry="kz"
                                    name="number"
                                    control={control}
                                    errors={errors}
                                    label={t('users:labels.phone')}
                                    isValid={checkPhoneNumber}
                                    {...register('number')}
                                />
                            </Stack>
                        ) : (
                            <Stack>
                                <TextInput
                                    placeholder="Введите email"
                                    label={t('common:email')}
                                    id="email"
                                    name="email"
                                    type="email"
                                    control={control}
                                    errors={errors}
                                    {...register('email')}
                                />
                            </Stack>
                        )}
                        {isOTPReceived && !profiles && (
                            <Box className="input_box">
                                <Box>
                                    <InputLabel sx={{ pl: 0.5, mb: '5px', color: 'var(--medium-text-color)' }}>
                                        {t('modals:authorizateModal.enterCode')}
                                    </InputLabel>
                                    <InputCode
                                        length={4}
                                        loading={loading}
                                        onComplete={() => null}
                                        setCodeSms={setCodeSms}
                                        error={err}
                                        name={err && t('modals:authorizateModal.wrongCode')}
                                        code={code}
                                        setCode={setCode}
                                    />
                                </Box>
                                <Box sx={{ pl: 0.5, pt: 1 }}>
                                    {seconds > 0 && !err ? (
                                        <Typography
                                            sx={{ fontSize: 16, color: 'var(--medium-text-color)', fontWeight: 300, letterSpacing: 0 }}
                                        >
                                            {t('modals:authorizateModal.resendCodeAfter')}:{' '}
                                            <span style={{ fontWeight: 'bold' }}>00:{seconds < 10 ? `0${seconds}` : seconds}</span>
                                        </Typography>
                                    ) : (
                                        <Typography variant="h4" sx={{ pt: 1 }}>
                                            <Link
                                                sx={{ color: '#49C172', textDecoration: 'none', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setCodeSms();
                                                    setErr(false);
                                                    setSeconds(45);
                                                    setCode([...Array(4)].map(() => ''));
                                                    onSubmit();
                                                }}
                                            >
                                                {t('modals:authorizateModal.resendCode')}
                                            </Link>
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        )}
                        {profiles?.length > 1 && (
                            <Stack direction="column">
                                <UncontrolledSelect
                                    id="accountTypeForLogin"
                                    defaultOpen
                                    label={t('modals:authorizateModal.labels.accountTypeForLogin')}
                                    options={profiles?.map((p) => ({ id: p.type, name: PROFILE_TYPES[p.type] }))}
                                    value={selectedProfile?.type}
                                    onChange={(e) => {
                                        setSelectedProfile(profiles?.find((p) => p.type === e.target.value));
                                    }}
                                />
                            </Stack>
                        )}
                        <RoundedButton type="submit" disabled={isOTPReceived && codeSms?.length !== 4}>
                            {buttonText}
                        </RoundedButton>
                        <Link
                            sx={{
                                mt: 2,
                                color: '#49C172',
                                textDecoration: 'none',
                                cursor: 'pointer',
                                fontSize: 12
                            }}
                            href="/user-policy"
                            target="_blank"
                        >
                            Пользовательское соглашение
                        </Link>
                        {!isOTPReceived && (
                            <Link
                                sx={{
                                    // mt: ,
                                    color: '#49C172',
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                    fontSize: 12
                                }}
                                onClick={() => {
                                    setValue('loginMethodIsNumber', !getValues('loginMethodIsNumber'));
                                }}
                            >
                                {watch('loginMethodIsNumber') ? t('common:loginWithEmail') : t('common:loginWithNumber')}
                            </Link>
                        )}
                        {isOTPReceived && (
                            <Box>
                                <Typography sx={{ color: 'var(--medium-text-color)', fontSize: 16, mt: 2 }}>
                                    Не получается войти?
                                </Typography>
                                <Link
                                    sx={{
                                        color: '#49C172',
                                        textDecoration: 'underline',
                                        textUnderlineOffset: 3,
                                        cursor: 'pointer',
                                        fontSize: 12
                                    }}
                                    onClick={() => {
                                        setCodeSms();
                                        setCode([...Array(4)].map(() => ''));
                                        setIsOTPReceived(false);
                                        setProfiles(undefined);
                                        setSeconds(45);
                                        setValue('loginMethodIsNumber', !getValues('loginMethodIsNumber'));
                                    }}
                                >
                                    Попробуйте {watch('loginMethodIsNumber') ? t('common:loginWithEmail') : t('common:loginWithNumber')}
                                </Link>
                            </Box>
                        )}
                    </Stack>
                </form>
            )}
        </Box>
    );
};

export default Login;
