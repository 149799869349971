import axios from 'axios';
import L from 'leaflet';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { areaServices } from '@/core/services/areaServices';
import { cityServices } from '@/core/services/cityServices';
import { GET_TILE_LAYER, NOMINATIM_BASE_URL, SERVICE_MANAGER } from '@/core/utils/constants/constants';
import { CITY_LIST } from '@/core/utils/constants/queryKeys';

const MapWrapper = React.memo(
    () => <div id="map-container" style={{ width: '100%', height: '100%' }} />,
    () => true
);

const OSM = ({ clickHandler, selectedCity }) => {
    const user = useSelector((state) => state.auth?.user);
    const [map, _setMap] = useState(null);

    const mapRef = useRef(map);
    const setMap = (data) => {
        _setMap(data);
        mapRef.current = data;
    };

    const isManager = user?.data?.profile?.profile_type === SERVICE_MANAGER;

    const { data: cities } = useQuery(CITY_LIST, () => cityServices.getList());
    const city = cities?.data?.find((c) => c.name === selectedCity);

    const { data: areas } = useQuery(
        [`deliveryZones-${isManager ? user?.organization?.id : user?.organization?.courier_partner_id}`, city],
        () => areaServices.getList(city?.id),
        { enabled: !!city }
    );

    const getAddressByPoint = useCallback((data) => {
        const { city, locality, house_number: houseNumber, road, building, city_district: cityDistrict, amenity, leisure } = data.address;
        const displayName = [city, locality, road, houseNumber, building, cityDistrict, amenity, leisure].filter((i) => !!i).join(', ');
        clickHandler(displayName, { lat: data.lat, lon: data.lon });
    }, []);

    const getCityCoordinates = useCallback(async () => {
        if (selectedCity) {
            const response = await axios.get(`${NOMINATIM_BASE_URL}/search.php?q=${selectedCity}&format=jsonv2`);
            if (response.data.length) {
                const { lon, lat } = response.data[0];
                return [lat, lon];
            }
        }
        return [43.24887, 76.92001];
    }, [selectedCity]);

    useEffect(() => {
        getCityCoordinates().then((coords) => {
            setMap(
                L.map('map-container', {
                    center: coords,
                    zoom: 13
                })
            );
            GET_TILE_LAYER().addTo(mapRef.current);
        });

        // Destroy the map on unmounted
        return () => {
            mapRef.current?.off();
            mapRef.current?.remove();
            mapRef.current = null;
        };
    }, []);

    useEffect(() => {
        if (map) {
            mapRef.current.on('click', (e) => {
                const { lat, lng } = e.latlng;
                axios.get(`${NOMINATIM_BASE_URL}/reverse.php?lat=${lat}&lon=${lng}&zoom=18&format=jsonv2`).then((response) => {
                    getAddressByPoint(response.data);
                });
            });

            if (areas) {
                // const bounds = L.latLngBounds();
                areas?.data.forEach((area) => {
                    const coords = area.scope.map((point) => [point.longitude, point.latitude]);
                    const polygon = L.polygon(coords, {
                        color: area.stroke_color,
                        fillColor: area.fill_color,
                        fillOpacity: 0.3,
                        opacity: area.stroke_opacity
                    });
                    polygon.bindTooltip(area.slug, { permanent: true, direction: 'center', opacity: 0.8 }).openTooltip();
                    // bounds.extend(coords);
                    polygon.addTo(mapRef.current);
                });
                // if (Object.keys(bounds).length) map.fitBounds(bounds);
            }
        }
    }, [map, areas]);

    return (
        <div
            style={{
                height: '100vh',
                width: '100%'
            }}
        >
            <MapWrapper />
        </div>
    );
};

export default OSM;
