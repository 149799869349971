import { ThemeContext } from '@emotion/react';
import { FormControl, InputLabel, Select, MenuItem, Button, TextField, Typography, Box } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import { useForm, Controller, useController } from 'react-hook-form';
import InputMask from 'react-input-mask';

const useStyles = makeStyles(() => ({
    input: {
        borderRadius: '10px',
        // color: 'red',
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '10px'
            // backgroundColor: '#F1F1F1'
        }
        // ['placeholder']: {
        //     color: 'red'
        // }
    }
}));

const InputForms = ({ control, formValue, nameForm, type = 'main' }) => {
    const theme = useTheme();
    const clr = theme.palette;
    const classes = useStyles();
    return (
        <>
            <Typography variant="h3">{nameForm}</Typography>
            <Box sx={{ display: type === 'main' && 'flex', gap: 4, justifyContent: 'start' }}>
                {formValue.map((item, i) => (
                    <Controller
                        key={i}
                        control={control}
                        name={item.name}
                        rules={{ required: 'Поле обязательное', pattern: item.pattern ? item.pattern : '' }}
                        render={({ field: { onChange, ref, value, ...field }, fieldState: { invalid }, formState: { errors } }) => (
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <InputLabel sx={{ mt: 2 }}>{item.label}</InputLabel>
                                {item.name === 'number' ? (
                                    <InputMask
                                        mask="+7 (999) 999-99-99"
                                        maskChar="_"
                                        sx={{ pt: 0.5 }}
                                        value={value}
                                        className={classes.input}
                                        placeholder={item.plc}
                                        onChange={onChange} // send value to hook form
                                        inputRef={ref}
                                        error={invalid}
                                        // InputProps={{
                                        //     error: !!errors.details?.name
                                        //     // sx: { input: { color: 'red' } }
                                        // }}
                                        helperText={invalid ? item.text : ''}
                                        fullWidth
                                        type={item.type}
                                    >
                                        {(inputProps) => <TextField {...inputProps} type="text" />}
                                    </InputMask>
                                ) : (
                                    <TextField
                                        sx={{ pt: 0.5 }}
                                        value={value}
                                        className={classes.input}
                                        placeholder={item.plc}
                                        onChange={onChange} // send value to hook form
                                        inputRef={ref}
                                        error={invalid}
                                        // InputProps={{
                                        //     error: !!errors.details?.name
                                        //     // sx: { input: { color: 'red' } }
                                        // }}
                                        helperText={invalid ? item.text : ''}
                                        fullWidth
                                        type={item.type}
                                    />
                                )}
                            </Box>
                        )}
                    />
                ))}
            </Box>
        </>
    );
};
export default InputForms;
