import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Checkbox, Typography, Box, TablePagination, TableRow, TableFooter, IconButton, Menu, MenuItem } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { ModalEditPartner, ModalWarning } from '@/components/createRowTable';

const useStyles = makeStyles((theme) => ({
    root: {
        '& th': {
            color: 'black',
            fontWeight: 900,
            border: 'none'
        },
        '& td': {
            border: 'none'
        }
    }
}));

const UpdateTable = ({
    dataTable,
    columns,
    stateSearch,
    checked,
    setChecked,
    selectedPartner,
    setSelectedPartner,
    isEditPageOpen,
    setIsEditPageOpen,
    data,
    setData,
    tableData,
    isOpenWarningModal,
    setIsOpenWarningModal,
    isBlockPage,
    setIsBlockPage,
    setToggleMainForm
}) => {
    const [partnerState, setPartnerState] = React.useState({});
    const [isAllPartnersSelected, setIsAllPartnersSelected] = useState(false); // все чекобсы выбраны
    const classes = useStyles();
    const theme = useTheme();
    const queryClient = useQueryClient();
    // const { toggleMenu, ...menuProps } = useMenuState();
    const [editRowId, setEditRowId] = useState(null);
    const [contextMenu, setContextMenu] = React.useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX - 2,
                      mouseY: event.clientY - 4
                  }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const isPartnerSelected = (partner) => {
        const index = selectedPartner.findIndex((item) => item === partner);

        return index !== -1;
    };

    const handlePartnerSelect = (partner) => {
        if (!isPartnerSelected(partner)) {
            setSelectedPartner((state) => [...state, partner]);
        } else {
            const copy = [...selectedPartner];

            const index = copy.findIndex((item) => item === partner);
            copy.splice(index, 1);

            setSelectedPartner(copy);
        }
    };

    const handleSelectAll = () => {
        if (!isAllPartnersSelected) {
            const allId = Object.keys(dataTable).map((item, i) => dataTable[item].id);
            setSelectedPartner(allId);
            setIsAllPartnersSelected(true);
        } else {
            setSelectedPartner([]);
            setIsAllPartnersSelected(false);
        }
    };

    const columnsForUpdate = [
        {
            name: '',
            options: {
                sort: false,
                customHeadLabelRender: (dataIndex) => (
                    <>
                        <Checkbox color="success" sx={{ p: 0 }} checked={isAllPartnersSelected} onChange={handleSelectAll} />
                    </>
                ),
                customBodyRenderLite: (dataIndex) => {
                    const { id } = dataTable[dataIndex];
                    return (
                        <>
                            <Box onContextMenu={handleContextMenu}>
                                <Checkbox
                                    color="success"
                                    sx={{ p: 0 }}
                                    checked={isPartnerSelected(id)}
                                    onChange={(e) => {
                                        handlePartnerSelect(id);
                                        if (e.target.checked) {
                                            setEditRowId(id);
                                        }
                                    }}
                                />
                                <Menu
                                    open={selectedPartner.length > 0 && contextMenu !== null}
                                    onClose={handleClose}
                                    anchorReference="anchorPosition"
                                    anchorPosition={
                                        contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
                                    }
                                >
                                    {selectedPartner.length === 1 && (
                                        <MenuItem onClick={() => setIsEditPageOpen(true)}>
                                            <EditIcon sx={{ fontSize: 18 }} />
                                            <Typography variant="h5" sx={{ pl: 1 }}>
                                                Редактировать
                                            </Typography>
                                        </MenuItem>
                                    )}
                                    <MenuItem
                                        onClick={() => {
                                            setIsOpenWarningModal(true);
                                            setIsBlockPage(true);
                                        }}
                                    >
                                        <RemoveCircleIcon sx={{ fontSize: 18 }} />
                                        <Typography variant="h5" sx={{ pl: 1 }}>
                                            Блокировать
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            setIsOpenWarningModal(true);
                                            setIsBlockPage(false);
                                        }}
                                    >
                                        <DeleteIcon sx={{ fontSize: 18 }} />
                                        <Typography variant="h5" sx={{ pl: 1 }}>
                                            Удалить
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </>
                    );
                }
            }
        },
        {
            name: 'name',
            label: 'Наименование',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const { name } = dataTable[dataIndex];
                    return (
                        <>
                            <span>{name}</span>
                        </>
                    );
                }
            }
        },
        {
            name: 'article',
            label: 'Артикул',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const { art } = dataTable[dataIndex];
                    return <span>{art}</span>;
                }
            }
        },
        {
            name: 'bin',
            label: 'БИН',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const { bin } = dataTable[dataIndex];
                    return <span>{bin}</span>;
                }
            }
        },
        {
            name: 'fullName',
            label: 'ФИО руководителя',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const { fullName } = dataTable[dataIndex];
                    return <span>{fullName}</span>;
                }
            }
        }
    ];

    const options = {
        download: false,
        filter: false,
        search: false,
        print: false,
        sort: false,
        viewColumns: false,
        filterType: 'checkbox',
        onRowsSelect: (rowData, rowMeta) => {
            setPartnerState(dataTable[rowMeta.dataIndex]);
        },
        selectableRows: false,
        customSearch: (stateSearch, currentRow) => {
            let isFound = false;
            currentRow.forEach((col) => {
                if (col && col.toString().indexOf(stateSearch) >= 0) {
                    isFound = true;
                }
            });
            return isFound;
        },
        rowsPerPageOptions: [],
        textLabels: {
            pagination: {
                next: 'Next Page',
                previous: 'Previous Page',
                rowsPerPage: 'Rows per page:',
                displayRows: 'из'
            }
        }
    };

    return (
        <>
            <ModalEditPartner
                initialState={partnerState}
                isOpen={isEditPageOpen}
                onClose={() => {
                    setIsEditPageOpen(false);
                }}
                editRowId={editRowId}
                data={data}
                setData={setData}
                tableData={tableData}
                setSelectedPartner={setSelectedPartner}
            />
            <ModalWarning
                isOpen={isOpenWarningModal}
                onClose={() => {
                    setIsOpenWarningModal(false);
                    handleClose();
                }}
                deleteId={selectedPartner}
                isBlockPage={isBlockPage}
                selectedPartner={selectedPartner}
                setSelectedPartner={setSelectedPartner}
            />

            {/* </ContextMenuTrigger> */}
            <MUIDataTable className={classes.root} data={dataTable} columns={columnsForUpdate} options={options} />
        </>
    );
};
export default UpdateTable;
