import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const ArrowButton = styled(IconButton)`
    display: block;
    width: 54px;
    height: 54px;
    margin-bottom: 20px;
    background: #4fcb7a;
    border-radius: 16px;
    text-align: center;
    line-height: 54px;
    color: #fff;
    border: none;
    transform: rotate(${({ rotated }) => (rotated ? 180 : 0)}deg);
    & svg {
        font-size: 30px;
    }
    &:disabled {
        color: #fff;
        background: #4fcb7a;
        opacity: 0.6;
    }
    &:hover {
        background: #71f19e;
    }
`;

const Controls = ({ excludeOrders, includeOrders, disabledExclude, disabledInclude }) => (
    <Box>
        <ArrowButton onClick={excludeOrders} disabled={disabledExclude}>
            <TrendingFlatIcon />
        </ArrowButton>
        <ArrowButton rotated onClick={includeOrders} disabled={disabledInclude}>
            <TrendingFlatIcon />
        </ArrowButton>
    </Box>
);

export default Controls;
