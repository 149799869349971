import { Grid, Link, Typography, TextField, Checkbox, FormControl, FormControlLabel, FormGroup, Button, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useCallback } from 'react';
import { useForm, Controller, useController } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';

import { Logo, HeaderTextModal } from '@/components/createComponents';
import i18n from '@/core/i18n';
import { ADD_PARTNER } from '@/redux/reducers/UserReducer/types';

const { t } = i18n;

const FormAddPartner = ({ setPage, handleClose }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [user, setUser] = React.useState({});
    const {
        register,
        handleSubmit,
        formState: { isValid }
    } = useForm({ mode: 'onChange' });

    const onSubmit = (data) => {
        // const file = data.image[0];
        dispatch({ type: ADD_PARTNER });
    };

    const array = [
        {
            name: t('components:formAddPartner.partnerName'),
            placeHldr: t('components:formAddPartner.partnerNamePlaceHldr'),
            nameUseForm: 'partner_name',
            requiredUseForm: true,
            type: 'text',
            pattern: /[A-Za-z]/
        },
        {
            name: t('components:formAddPartner.article'),
            placeHldr: t('components:formAddPartner.articlePlaceHldr'),
            nameUseForm: 'partner_art',
            requiredUseForm: true,
            type: 'text',
            pattern: /[A-Za-z]/
        },
        {
            name: t('components:formAddPartner.fulName'),
            placeHldr: t('components:formAddPartner.fulNamePlaceHldr'),
            nameUseForm: 'partner_lastName',
            requiredUseForm: true,
            type: 'text',
            pattern: /[A-Za-z]/
        },
        {
            name: t('components:formAddPartner.phoneNumber'),
            placeHldr: t('components:formAddPartner.phoneNumberPlaceHldr'),
            nameUseForm: 'partner_number',
            requiredUseForm: true,
            mask: '+7 (999) 999-99-99',
            maskChar: '_'
        },
        {
            name: t('components:formAddPartner.email'),
            placeHldr: t('components:formAddPartner.emailPlaceHldr'),
            nameUseForm: 'partner_lastName',
            requiredUseForm: true,
            type: 'text',
            pattern: /[A-Za-z]/
        },
        {
            name: t('components:formAddPartner.logo'),
            placeHldr: t('components:formAddPartner.uploadFile'),
            nameUseForm: 'partner_file',
            requiredUseForm: false,
            type: 'file'
        }
    ];

    return (
        <Box>
            <HeaderTextModal handleClose={handleClose} text={t('components:formAddPartner.addingPartner')} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justifyContent="center">
                    {array.map((item, index) => (
                        <Grid item xs={12} key={index}>
                            <Typography variant="h4" sx={{ fontWeight: 100, color: theme.palette.grey[500], pt: 3 }}>
                                {item.name}
                            </Typography>

                            {item.nameUseForm === 'partner_number' ? (
                                <InputMask
                                    mask={item.mask}
                                    maskChar={item.maskChar}
                                    name={item.nameUseForm}
                                    {...register(item.nameUseForm, {
                                        required: true,
                                        validate: (val) => val.indexOf('_') === -1
                                    })}
                                >
                                    {(inputProps) => (
                                        <TextField {...inputProps} type={item.type} placeholder={item.placeHldr} sx={{ width: '100%' }} />
                                    )}
                                </InputMask>
                            ) : (
                                <>
                                    {item.nameUseForm === 'partner_file' ? (
                                        <Button
                                            variant="outlined"
                                            component="label"
                                            fullWidth
                                            sx={{
                                                borderColor: theme.palette.grey[400],
                                                color: theme.palette.success.main,
                                                textDecoration: 'underline',
                                                textTransform: 'none',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                '&:hover': { borderColor: theme.palette.success.main }
                                            }}
                                        >
                                            {t('buttons:uploadFile')}
                                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M0 15C0 14.7348 0.105357 14.4804 0.292893 14.2929C0.48043 14.1054 0.734784 14 1 14H13C13.2652 14 13.5196 14.1054 13.7071 14.2929C13.8946 14.4804 14 14.7348 14 15C14 15.2652 13.8946 15.5196 13.7071 15.7071C13.5196 15.8946 13.2652 16 13 16H1C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15ZM3.293 4.707C3.10553 4.51947 3.00021 4.26516 3.00021 4C3.00021 3.73484 3.10553 3.48053 3.293 3.293L6.293 0.293C6.48053 0.105529 6.73484 0.000213623 7 0.000213623C7.26516 0.000213623 7.51947 0.105529 7.707 0.293L10.707 3.293C10.8892 3.4816 10.99 3.7342 10.9877 3.9964C10.9854 4.2586 10.8802 4.50941 10.6948 4.69482C10.5094 4.88023 10.2586 4.9854 9.9964 4.98767C9.7342 4.98995 9.4816 4.88916 9.293 4.707L8 3.414V11C8 11.2652 7.89464 11.5196 7.70711 11.7071C7.51957 11.8946 7.26522 12 7 12C6.73478 12 6.48043 11.8946 6.29289 11.7071C6.10536 11.5196 6 11.2652 6 11V3.414L4.707 4.707C4.51947 4.89447 4.26516 4.99979 4 4.99979C3.73484 4.99979 3.48053 4.89447 3.293 4.707Z"
                                                    fill="#49C172"
                                                />
                                            </svg>
                                            <input type="file" hidden {...register(item.nameUseForm)} />
                                        </Button>
                                    ) : (
                                        <TextField
                                            type={item.type}
                                            placeholder={item.placeHldr}
                                            name={item.name}
                                            fullWidth
                                            {...register(item.nameUseForm, {
                                                required: true,
                                                pattern: item.pattern
                                            })}
                                        />
                                    )}
                                </>
                            )}
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ pt: 3, display: 'flex', justifyContent: 'space-around' }}>
                    <Button sx={{ width: '40%', height: '50px', color: theme.palette.success.main }} onClick={() => handleClose()}>
                        {t('buttons:cancel')}
                    </Button>
                    <Button
                        disabled={!isValid}
                        type="submit"
                        sx={{ width: '40%', height: '50px', backgroundColor: theme.palette.success.main, color: 'white' }}
                    >
                        {t('buttons:continue')}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default FormAddPartner;
