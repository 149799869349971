import { Search } from '@mui/icons-material';
import { Box, Chip, CircularProgress, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import Checkbox from '@/components/checkbox';
import { cityServices } from '@/core/services/cityServices';
import { itemServices } from '@/core/services/itemServices';
import { CITY_LIST, PRODUCTS_LIST } from '@/core/utils/constants/queryKeys';
import OrdersFilter from '@/views/orders/components/distributeModal/filter';

const StyledOrderList = styled(Box)`
    border: 1px solid var(--border-color);
    border-radius: 12px;
    overflow: hidden;
`;
const ListSearch = styled(TextField)`
    border: none;
    & .MuiInput-input {
        padding: 17px 0;
        font-size: 14px;
        color: var(--main-text-color);
        &::placeholder {
            color: #b3b3b3;
        }
    }
    & svg {
        color: var(--medium-text-color);
        margin-left: 19px;
    }
`;
const ListHeader = styled(Box)`
    display: flex;
    padding: 16px 9px;
    background: rgba(107, 119, 140, 0.05);
    font-weight: 600;
    font-size: 12px;
    color: var(--medium-text-color);
    & > div {
        display: flex;
        align-items: center;
        width: 156px;
        padding: 0 10px;
    }
`;
const ListBody = styled(Box)`
    position: relative;
    height: 300px;
    overflow-x: auto;
    & .list_row {
        display: flex;
        align-items: flex-start;
        padding: 16px 9px;
        color: var(--main-text-color);
    }
    & .list_row > div {
        width: 156px;
        padding: 0 10px;
    }
`;

const OrdersList = ({
    orders,
    selectOrder,
    selectedOrders,
    search,
    setSelectedOrders,
    loading,
    setSelectedProducts,
    selectedProducts,
    selectedCities,
    setSelectedCities
}) => {
    const { t } = useTranslation();
    const [allSelected, setAllSelected] = useState(false);
    useEffect(() => {
        setAllSelected(false);
    }, [orders]);
    const { data: products, isLoading } = useQuery(PRODUCTS_LIST, () => itemServices.getItemList());
    const { data: cities, isLoading: citiesLoading } = useQuery(CITY_LIST, () => cityServices.getList());

    return (
        <StyledOrderList>
            <ListSearch
                onChange={search}
                fullWidth
                variant="standard"
                placeholder={t('modals:distributeModal.searchPlaceholder')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    )
                }}
            />
            <ListHeader>
                <Box>
                    <Checkbox
                        onChange={() => {
                            setAllSelected(!allSelected);
                            if (allSelected) {
                                setSelectedOrders([]);
                            } else {
                                orders.forEach((order) => {
                                    if (!selectedOrders.includes(order.id)) selectOrder(order.id);
                                });
                            }
                        }}
                        checked={allSelected}
                    />
                    {t('modals:distributeModal.cols.id')}
                    {selectedOrders?.length > 0 && (
                        <Chip
                            style={{ marginLeft: '5px' }}
                            size="small"
                            label={selectedOrders.length}
                            onDelete={() => setSelectedOrders([])}
                        />
                    )}
                </Box>
                <Box>
                    {t('modals:distributeModal.cols.product')}
                    {selectedProducts?.length > 0 && (
                        <Chip
                            style={{ marginLeft: '5px' }}
                            size="small"
                            label={selectedProducts.length}
                            onDelete={() => setSelectedProducts([])}
                        />
                    )}
                    <OrdersFilter
                        selected={selectedProducts}
                        loading={isLoading}
                        items={products?.data}
                        setSelected={setSelectedProducts}
                    />
                </Box>
                <Box>
                    {t('modals:distributeModal.cols.city')}
                    {selectedCities?.length > 0 && (
                        <Chip
                            style={{ marginLeft: '5px' }}
                            size="small"
                            label={selectedCities.length}
                            onDelete={() => setSelectedCities([])}
                        />
                    )}
                    <OrdersFilter selected={selectedCities} loading={citiesLoading} items={cities?.data} setSelected={setSelectedCities} />
                </Box>
            </ListHeader>
            <ListBody>
                {orders?.map((order) => (
                    <Box className="list_row" key={order.id}>
                        <Box display="flex" alignItems="center">
                            <Checkbox id={order.id} checked={selectedOrders?.includes(order.id)} onChange={() => selectOrder(order.id)} />{' '}
                            {order.id}
                        </Box>
                        <Box>{order.item.name}</Box>
                        <Box>{order.city.name || 'Не выбран'}</Box>
                    </Box>
                ))}
                {!loading && orders?.length === 0 && (
                    <div style={{ textAlign: 'center', marginTop: '15px' }}>{t('modals:distributeModal.empty')}</div>
                )}
                {loading && (
                    <CircularProgress
                        size={44}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-22px',
                            marginLeft: '-22px'
                        }}
                    />
                )}
            </ListBody>
        </StyledOrderList>
    );
};

export default OrdersList;
