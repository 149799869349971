import { Table } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTable = styled(Table)`
    & th {
        padding: 7px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: var(--main-text-color);
        border: none;
    }
    & td {
        padding: 7px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--main-text-color);
        border: none;
    }
    & tr:hover {
        & td {
            background: rgba(213, 220, 233, 0.2);
        }
    }
    & td:first-of-type {
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
    }

    & td:last-of-type {
        border-bottom-right-radius: 9px;
        border-top-right-radius: 9px;
    }
`;

export default StyledTable;
