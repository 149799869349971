import { httpClient } from '@/api/httpClient/httpClient';

export const orderGroupServices = {
    /**
     * @param  {Object?}  params
     * @param  {Integer?}  params.partner_id
     * @param  {Integer?}   params.page
     * @param  {Integer?} params.page_limit
     */
    getList(params) {
        params = params || {};
        Object.keys(params).forEach((key) => {
            if (!params[key]) delete params[key];
        });
        return httpClient.get(`/order_group/list`, { params });
    },
    create(data) {
        return httpClient.post('/order_group', data);
    },
    get(id) {
        return httpClient.get(`/order_group/${id}`);
    },
    update(id, data) {
        return httpClient.patch(`/order_group/${id}`, data);
    },
    delete(id) {
        return httpClient.delete(`/order_group/${id}`);
    },
    addOrders(groupId, data) {
        return httpClient.patch(`/order_group/${groupId}/add_order`, data);
    },
    removeOrders(groupId, data) {
        return httpClient.patch(`/order_group/${groupId}/remove_order`, data);
    },
    /**
     * @param  {Integer}  groupId
     * @param  {(new_group|ready_for_pickup|revise|exported|under_review|courier_service_accepted)}  status
     */
    addStatus(groupId, status) {
        return httpClient.patch(`/order_group/${groupId}/add_status?status=${status}`);
    }
};
