import { Box } from '@mui/material';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import '@/components/timePicker/styles.scss';
import Lightbox from 'react-image-lightbox';
import { useQuery } from 'react-query';
import { Route, Routes } from 'react-router';
import { useParams, useNavigate } from 'react-router-dom';

import { RoundedOutlinedButton } from '@/components/button/styledButtons';
import DetailsInfoBlock from '@/components/detailsInfoBlock';
import FieldLabel from '@/components/formField/fieldLabel';
import ArmListLayoutHeader from '@/components/layouts/armListLayout/header';
import ListTabs from '@/components/listTabs';
import useModal from '@/core/hooks/useModal';
import i18n from '@/core/i18n';
import { orderChainServices } from '@/core/services/orderChainServices';
import { serverUrl } from '@/core/utils/constants/constants';
import { ORDER_CHAIN, ORDER_CHAIN_UPDATE, ORDER_DETAILS } from '@/core/utils/constants/routes';
import { humanDateFormat } from '@/core/utils/utils';
import ErrorOrderChain from '@/views/orderChain/components/error';
import LoadingOrderChain from '@/views/orderChain/components/loading';
import { StageCardDetails, StagesWrapper } from '@/views/orderChain/components/stages';
import ViewStageDocumentModal from '@/views/orderChain/components/viewStageDocumentModal';
import OrderChainHistory from '@/views/orderChain/details/history';

const { t } = i18n;

const ImageBox = styled('div')`
    cursor: zoom-in;
`;

const StyledInputElement = styled('input')`
    width: 100%;
    font-family: 'Inter', 'Roboto', arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
    color: var(--main-text-color);
    background: var(--input-bg);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 10px 15px;
    margin-top: 0 !important;
    margin-bottom: 30px;
    transition: 0.2s;

    &:hover {
        background: var(--input-bg-focused);
    }

    &:focus {
        outline: none;
        background: var(--input-bg-focused);
    }
`;

const tabs = [
    { title: 'Данные', to: '' },
    { title: 'История', to: 'history' }
];

const OrderChainDetails = () => {
    const params = useParams();
    const navigate = useNavigate();

    const { data: orderChainQuery, isLoading: orderChainLoading } = useQuery(['orderChain', params.id], () =>
        orderChainServices.getOrderChain(params.id)
    );
    const orderChain = orderChainQuery?.data;

    if (orderChainLoading) {
        return <LoadingOrderChain />;
    }

    if (!orderChain) {
        return <ErrorOrderChain />;
    }

    return (
        <>
            <ArmListLayoutHeader
                title={`Многоэтапная доставка №${params.id}`}
                subTitle={`Вернуться к многоэтапным доставкам / Многоэтапная доставка №${params.id}`}
                subtitleLink={ORDER_CHAIN}
                buttons={
                    <RoundedOutlinedButton onClick={() => navigate(ORDER_CHAIN_UPDATE.format(params.id))}>
                        Редактировать
                    </RoundedOutlinedButton>
                }
            />

            <ListTabs tabsContent={tabs}>
                <Routes>
                    <Route path="" element={<OrderChainDetailsInfo orderChain={orderChain} />} />
                    <Route path="history" element={<OrderChainHistory />} />
                </Routes>
            </ListTabs>
        </>
    );
};

const OrderChainDetailsInfo = ({ orderChain }) => {
    const navigate = useNavigate();

    const [currentDocument, setCurrentDocument] = useState();
    const [lightboxIsVisible, setLightboxIsVisible] = useState();

    const orderChainPackage = orderChain.package_params;
    const expectedDeliveryDate = humanDateFormat(new Date(orderChain.expected_delivery_datetime));
    const deadlineDeliveryDate = humanDateFormat(new Date(orderChain.deadline_delivery_datetime));

    const sender = orderChain.sender;
    const receiver = orderChain.receiver;

    const [viewStageDocumentModalOpened, openViewStageDocumentModal, closeViewStageDocumentModal] = useModal();

    function onViewDocumentClick(stage) {
        setCurrentDocument(stage.support_documents[0]);
        openViewStageDocumentModal();
    }

    return (
        <>
            <DetailsInfoBlock title="Общие данные">
                <DetailsInfoBlock.Child label={t('orders:create.partner')} info={orderChain.partner.name_ru} />

                <DetailsInfoBlock.Child label="Тип заявки" info={t(`orderChain:types.${orderChain.type}`)} />
            </DetailsInfoBlock>

            <DetailsInfoBlock title="Данные о посылке">
                <DetailsInfoBlock.Child label="Название посылки" info={orderChainPackage.name} />

                <DetailsInfoBlock.Child label="Описание посылки" info={orderChainPackage.description || 'Не задано'} />

                <DetailsInfoBlock.Child label="Длина" info={orderChainPackage.length || 'Не задано'} />
                <DetailsInfoBlock.Child label="Высота" info={orderChainPackage.height || 'Не задано'} />
                <DetailsInfoBlock.Child label="Ширина" info={orderChainPackage.width || 'Не задано'} />
                <DetailsInfoBlock.Child label="Вес" info={orderChainPackage.weight || 'Не задано'} />
            </DetailsInfoBlock>

            <DetailsInfoBlock title="Даты доставки">
                <DetailsInfoBlock.Child label={t('orderChain:create.labels.expectedDeliveryDate')} info={expectedDeliveryDate} />

                <DetailsInfoBlock.Child label={t('orderChain:create.labels.deadlineDeliveryDate')} info={deadlineDeliveryDate} />
            </DetailsInfoBlock>

            <Box sx={{ display: 'flex', columnGap: 4 }}>
                <DetailsInfoBlock title="Отправитель">
                    <DetailsInfoBlock.Child label={t('orderChain:create.labels.senderName')} info={sender.name} />

                    <DetailsInfoBlock.Child label={t('orderChain:create.labels.senderPhoneNumber')} info={sender.phone_number} />

                    <DetailsInfoBlock.Child label={t('orderChain:create.labels.senderCity')} info={sender.city.name} />

                    <DetailsInfoBlock.Child label="Адрес отправителя" info={sender.address} />
                </DetailsInfoBlock>

                <DetailsInfoBlock title="Получатель">
                    <DetailsInfoBlock.Child label={t('orders:create.labels.name')} info={receiver.name} />

                    <DetailsInfoBlock.Child label={t('orders:create.labels.phoneNumber')} info={receiver.phone_number} />

                    <DetailsInfoBlock.Child label={t('orderChain:create.labels.receiverCity')} info={receiver.city.name} />

                    <DetailsInfoBlock.Child label="Адрес получателя" info={receiver.address} />
                </DetailsInfoBlock>
            </Box>

            <DetailsInfoBlock title="Этапы">
                <StagesWrapper>
                    {orderChain.stages.map((s) => (
                        <StageCardDetails
                            key={s.position}
                            label={`Этап №${s.position + 1}`}
                            onViewDocumentClick={s.support_documents.length > 0 ? () => onViewDocumentClick(s) : null}
                            onOpenStageClick={() => navigate(ORDER_DETAILS.format(s.order.id))}
                        >
                            <ul>
                                <li>ID заявки: {s.order.id}</li>
                                <li>Тип заявки: {t(`orderChain:stageTypes.${s.type}`)}</li>
                                <li>Текущий статус: {s.order.current_status.name}</li>
                            </ul>
                        </StageCardDetails>
                    ))}
                </StagesWrapper>
            </DetailsInfoBlock>

            <DetailsInfoBlock title={t('orders:create.blockTitles.comment')}>
                <DetailsInfoBlock.Child label={t('orders:create.blockTitles.comment')} info={orderChain.comment || 'Не задано'} />
            </DetailsInfoBlock>
            {viewStageDocumentModalOpened && (
                <ViewStageDocumentModal title="Просмотр документа" handleClose={closeViewStageDocumentModal}>
                    <FieldLabel>Номер документа</FieldLabel>
                    <StyledInputElement id="stageDocumentNumber" type="text" readonly value={currentDocument.document_number} />
                    <FieldLabel>Комментарий</FieldLabel>
                    <StyledInputElement id="stageDocumentComment" type="text" readonly value={currentDocument.comment || ''} />
                    <FieldLabel>Фото документа</FieldLabel>
                    <ImageBox onClick={() => setLightboxIsVisible(true)}>
                        <img
                            src={`${serverUrl}${currentDocument.image}`}
                            alt={currentDocument.document_number}
                            style={{ objectFit: 'contain', width: '100%', borderRadius: '6px' }}
                        />
                    </ImageBox>
                    {lightboxIsVisible && (
                        <Lightbox mainSrc={`${serverUrl}${currentDocument.image}`} onCloseRequest={() => setLightboxIsVisible(false)} />
                    )}
                </ViewStageDocumentModal>
            )}
        </>
    );
};

export default OrderChainDetails;
