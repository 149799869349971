import CheckIcon from '@mui/icons-material/Check';
import { Grid, Link, Typography, TextField, Checkbox, FormControl, FormControlLabel, FormGroup, Button, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';

import i18n from '@/core/i18n';

const { t } = i18n;

const FormRegisterValidate = ({ handleNext, success, setPage }) => {
    const theme = useTheme();
    const color = !success ? theme.palette?.error.main : theme.palette.success.main;
    useEffect(() => {
        let timer;
        if (success) {
            timer = setTimeout(() => handleNext((prev) => prev + 1), 2000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Container
                sx={{
                    mt: 12,
                    width: '5rem',
                    height: '5rem',
                    backgroundColor: color,
                    borderRadius: '50%'
                }}
            >
                <CheckIcon
                    fontSize="large"
                    sx={{
                        color: theme.palette.background.paperLight,
                        margin: '60% auto'
                    }}
                />
            </Container>
            <Container sx={{ textAlign: 'center' }}>
                <Typography variant="h3" color={theme.palette.grey[570]} sx={{ mt: 3, fontWeight: 200, letterSpacing: 2, lineHeight: 1.5 }}>
                    {!success ? (
                        <Link onClick={() => setPage((prev) => prev - 1)}>{t('components:formRegisterValidate.tryAgain')}</Link>
                    ) : (
                        <>
                            Процесс проверки <br /> успешно пройден!
                        </>
                    )}
                </Typography>
            </Container>
        </Grid>
    );
};

export default FormRegisterValidate;
