import CropOriginalOutlinedIcon from '@mui/icons-material/CropOriginalOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import Lightbox from 'react-image-lightbox';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import FieldLabel from '@/components/formField/fieldLabel';
import TextInput from '@/components/formField/textInput';
import FormModal from '@/components/formModal';
import { orderChainServices } from '@/core/services/orderChainServices';
import { serverUrl } from '@/core/utils/constants/constants';
import { addNotification } from '@/toolkit/reducers/toastr';

import './style.css';

const DropArea = styled('div')`
    --drop-area-color: ${({ isDragActive }) => (isDragActive ? 'var(--accent-color)' : 'var(--border-color)')};

    display: grid;
    height: 200px;
    border: 2px dashed var(--drop-area-color);
    border-radius: 6px;
    justify-content: center;
    align-content: center;
    cursor: pointer;

    &:hover,
    &:focus {
        --drop-area-color: var(--accent-color);
    }
`;

const ImageBox = styled('div')`
    position: relative;
    cursor: zoom-in;
`;

const UploadStageDocumentModal = ({ stages, handleClose, orderChainQueryKey }) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const stageId = Number(searchParams.get('uploadStageDocument'));
    const stage = stages.find((s) => s.id === stageId);
    const documents = stage.support_documents;
    const hasExistingDocuments = documents.length > 0;
    const documentNumber = hasExistingDocuments ? documents[0].document_number : '';

    const {
        watch,
        register,
        setValue,
        handleSubmit,
        getValues,
        control,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        defaultValues: { lightboxIsVisible: false, stageDocumentNumber: documentNumber }
    });

    useEffect(() => () => URL.revokeObjectURL(getValues('file')), [documentNumber]);

    const uploadStageDocumentMutation = useMutation((data) => orderChainServices.uploadStageDocument(data));

    const onSubmit = async (e) => {
        const data = new FormData();
        data.append('image', getValues('file'));
        data.append('document_number', getValues('stageDocumentNumber'));
        data.append('comment', getValues('stageDocumentComment'));
        data.append('order_chain_stage_id', stageId);

        try {
            await uploadStageDocumentMutation.mutateAsync(data);
            await queryClient.invalidateQueries(orderChainQueryKey);
            setValue('file', '');
            handleClose();
            dispatch(addNotification({ message: 'Документ успешно загружен' }));
        } catch (error) {
            dispatch(addNotification({ message: 'Не удалось загрузить документ', type: 'error' }));
        }
    };

    const deleteStageDocumentMutation = useMutation(({ documentId }) => orderChainServices.deleteStageDocument({ documentId }));

    const onDeleteDocument = async (documentId) => {
        try {
            await deleteStageDocumentMutation.mutateAsync({ documentId });
            await queryClient.invalidateQueries(orderChainQueryKey);
            dispatch(addNotification({ message: 'Документ успешно удален' }));
        } catch (error) {
            dispatch(addNotification({ message: 'Не удалось удалить документ', type: 'error' }));
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: { 'image/*': [] },
        onDrop: (acceptedFiles) => {
            setValue('file', acceptedFiles[0]);
        },
        multiple: false
    });

    const preview = watch('file') && URL.createObjectURL(getValues('file'));

    return (
        <FormModal
            title="Загрузка документа"
            buttonText="Подтвердить"
            isShowing={true}
            onSubmit={handleSubmit(onSubmit)}
            handleClose={handleClose}
            isValid={true}
        >
            <TextInput
                id="stageDocumentNumber"
                type="text"
                label="Номер документа"
                required
                control={control}
                errors={errors}
                {...register('stageDocumentNumber')}
            />
            <TextInput
                id="stageDocumentComment"
                type="text"
                label="Комментарий"
                required
                control={control}
                errors={errors}
                {...register('stageDocumentComment')}
            />
            <FieldLabel required={true}>Фото документа</FieldLabel>
            {documents.map((doc) => (
                <ImageBox key={doc.id} onClick={() => setValue('lightboxIsVisible', true)}>
                    <Tooltip title="Удалить документ">
                        <IconButton
                            sx={{ color: 'var(--main-text-color)', position: 'absolute', right: -20, top: -20 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDeleteDocument(doc.id);
                            }}
                        >
                            <RemoveCircleIcon
                                sx={{
                                    width: 30,
                                    height: 30,
                                    fill: '#ed5d5d'
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                    <img
                        src={`${serverUrl}${doc.image}`}
                        alt={doc.name}
                        style={{ objectFit: 'contain', width: '100%', borderRadius: '6px' }}
                    />
                </ImageBox>
            ))}
            {!hasExistingDocuments && watch('file') && (
                <ImageBox onClick={() => setValue('lightboxIsVisible', true)}>
                    <Tooltip title="Удалить документ">
                        <IconButton
                            sx={{ color: 'var(--main-text-color)', position: 'absolute', right: -20, top: -20 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setValue('file', '');
                            }}
                        >
                            <RemoveCircleIcon
                                sx={{
                                    width: 30,
                                    height: 30,
                                    fill: '#ed5d5d'
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                    <img src={preview} alt={getValues('file').name} style={{ objectFit: 'contain', width: '100%', borderRadius: '6px' }} />
                </ImageBox>
            )}
            {!hasExistingDocuments && !watch('file') && (
                <DropArea isDragActive={isDragActive} {...getRootProps()}>
                    <CropOriginalOutlinedIcon sx={{ color: 'var(--drop-area-color)', width: 50, height: 50 }} />
                    <input {...getInputProps()} />
                </DropArea>
            )}
            {watch('lightboxIsVisible') && (
                <Lightbox mainSrc={`${serverUrl}${documents[0].image}`} onCloseRequest={() => setValue('lightboxIsVisible', false)} />
            )}
        </FormModal>
    );
};

export default UploadStageDocumentModal;
