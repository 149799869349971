import { httpClient } from '@/api/httpClient/httpClient';

export const orderChainServices = {
    getList({ page, size }) {
        const params = {
            page,
            size
        };
        return httpClient.get(`/order_chain/list`, { params });
    },
    getOrderChain(id) {
        return httpClient.get(`/order_chain/${id}`);
    },
    createOrder(data) {
        return httpClient.post(`/order_chain`, data);
    },
    updateOrder({ orderChainId, data }) {
        return httpClient.patch(`/order_chain/${orderChainId}`, data);
    },
    deleteStage({ orderChainId, stageId }) {
        return httpClient.delete(`/order_chain/${orderChainId}/stage/${stageId}`);
    },
    addStage({ orderChainId, data }) {
        return httpClient.post(`/order_chain/${orderChainId}/stage`, data);
    },
    uploadStageDocument(data) {
        return httpClient.post('/order_chain/stage/support_document', data);
    },
    deleteStageDocument({ documentId }) {
        return httpClient.delete(`/order_chain/stage/support_document/${documentId}`);
    }
};
