import { httpClient } from '@/api/httpClient/httpClient';

export const courierServices = {
    getCouriersInvitedList(page = 1, limit = 6, search, searchType) {
        return httpClient.get(`/user/list`, {
            params: {
                profile_type: 'courier',
                invite_status: 'invited',
                page_limit: limit,
                search_type: searchType,
                page,
                search
            }
        });
    },
    getCouriersAcceptList(page = 1, limit = 6) {
        const params = {
            profile_type: 'courier',
            invite_status: 'accepted',
            page_limit: limit,
            page
        };
        return httpClient.get(`/user/list`, {
            params
        });
    },
    getCouriersByCity(cityId, status = 'accepted', page, limit, search, searchType) {
        const params = {
            profile_type: 'courier',
            invite_status: status,
            city_id: cityId || null,
            page_limit: limit,
            search_type: searchType,
            page,
            search
        };
        return httpClient.get(`/user/list`, {
            params
        });
    },
    getSortersList() {
        const params = {
            profile_type: 'sorter'
        };
        return httpClient.get(`/user/list`, {
            params
        });
    },
    getCouriersListShort({ cityId, status }) {
        const params = {
            city_id: cityId,
            status
        };
        return httpClient.get('/profile/courier/list', { params });
    },
    getCouriersPendingList(page = 1, limit = 6) {
        return httpClient.get(`/user/list`, {
            params: {
                profile_type: 'courier',
                invite_status: 'pending',
                page_limit: limit,
                page
            }
        });
    },
    getCouriersUnconfirmedList(page = 1, limit = 6) {
        return httpClient.get(`/user/list`, {
            params: {
                profile_type: 'courier',
                invite_status: 'unconfirmed',
                page_limit: limit,
                page
            }
        });
    },
    getCouriersRefusedList(page = 1, limit = 6) {
        return httpClient.get(`/user/list`, {
            params: {
                profile_type: 'courier',
                invite_status: 'refused',
                page_limit: limit,
                page
            }
        });
    },
    getCourierDetails(id) {
        return httpClient.get(`/user?user_id=${id}`);
    },
    activateCourier(id, data) {
        return httpClient.put(`/profile/${id}`, data);
    },
    blockCourier(id, data) {
        return httpClient.put(`/user/${id}`, data);
    },
    profilePatch(id, data) {
        return httpClient.patch(`/profile/${id}`, data);
    },
    selfProfileUpdate(data) {
        return httpClient.put(`/profile`, data);
    },
    changeSelfStatus(data) {
        return httpClient.patch(`/profile/${data.id}/courier/update/status`, { status: data.status });
    },
    getByArea(areaId) {
        return httpClient.get(`/user/list?profile_type=courier&area_id=${areaId}`);
    },
    changeCourierStatus(userId, status) {
        return httpClient.patch(`/profile/${userId}/update/status`, { status });
    }
};
