import { httpClient } from '@/api/httpClient/httpClient';

export const userServices = {
    getList(params) {
        return httpClient.get('/user/list', {
            params
        });
    },
    createProfile(data) {
        return httpClient.post(`/profile`, data);
    },
    updateUser(id, data) {
        return httpClient.put(`/user/${id}`, data);
    },
    patchProfile(id, data) {
        return httpClient.patch(`/profile/${id}`, data);
    },
    patchByProfileId(userId, profileId, data) {
        return httpClient.patch(`/profile/update_by_profile_id/${userId}?profile_id=${profileId}`, data);
    },
    getUser(id) {
        return httpClient.get(`/user?user_id=${id}`);
    },
    getUserByPhone(phoneNumber) {
        return httpClient.get(`/user?phone_number=${phoneNumber}`, { validateStatus: false });
    },
    updatePhoto(userId, data) {
        return httpClient.put(`/user/${userId}/photo`, data);
    },
    deleteUser(userId) {
        return httpClient.delete(`/user/${userId}`);
    }
};
