import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DetailsInfoBlock from '@/components/detailsInfoBlock';
import Pagination from '@/components/pagination';
import StyledTable from '@/components/table';
import { ACTIONS, PROFILE_TYPES } from '@/core/utils/constants/constants';

const HistoryDetailsBlock = ({ history, sx, pagination }) => {
    const { t, i18n } = useTranslation();
    const { setPageLimitHandler, pageLimit, currentPage, changePage } = pagination;
    return (
        <DetailsInfoBlock title={t('history:title')} sx={sx} innerSx={{ display: 'block !important' }}>
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('history:action')}</TableCell>
                        <TableCell>{t('history:datetime')}</TableCell>
                        <TableCell>{t('history:initiator')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {history?.items?.map((record) => (
                        <TableRow key={record.created_at}>
                            <TableCell>{ACTIONS[record?.request_method]}</TableCell>
                            <TableCell>{new Date(record?.created_at).toLocaleString(i18n.language)}</TableCell>
                            <TableCell>
                                {Object.keys(record.initiator).length
                                    ? `${record?.initiator?.first_name} ${record?.initiator?.last_name} (${
                                          record.initiator?.profile_types
                                              ? record.initiator?.profile_types.map((profileType) => PROFILE_TYPES[profileType]).join(', ')
                                              : null
                                      })`
                                    : t('orders:history.userNotFound')}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
            <Pagination
                itemsLength={history?.total}
                currentPage={currentPage}
                totalPages={history?.total_pages}
                pageLimit={pageLimit}
                onPageChange={changePage}
                setPageLimit={setPageLimitHandler}
            />
        </DetailsInfoBlock>
    );
};

export default HistoryDetailsBlock;
