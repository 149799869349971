export const LOGIN = '/login';
export const REGISTER = '/register';

export const ARM = '/arm';

export const PROFILE = `${ARM}/profile`;

export const STATISTICS = `${ARM}/statistics`;
export const COURIERS_STATISTICS = `${ARM}/statistics/couriers`;

export const COURIER_REGISTER = 'couriers/register';
export const COURIER_REGISTER_PARAM = `${COURIER_REGISTER}/:user_id`;
export const COURIER_REGISTER_BIOMETRY = `${COURIER_REGISTER_PARAM}/biometry`;
export const COURIER_REGISTER_UPDATE = `${COURIER_REGISTER_PARAM}/update`;
export const COURIER_REGISTER_COMPLETE = `${COURIER_REGISTER}/complete`;

export const ORDER_BIOMETRY = 'orders/:id/biometry';
export const ORDER_BIOMETRY_SUCCESS = 'orders/biometry/success';

export const ORDERS = `${ARM}/orders`;
export const ORDER_DETAILS = `${ORDERS}/{0}/detail`;
export const ORDER_CREATE = `${ORDERS}/create`;

export const ORDERS_LIST = `${ORDERS}/list`;
export const ORDERS_ALL = `${ORDERS_LIST}/all`;
export const ORDERS_NEW = `${ORDERS_LIST}/new`;
export const ORDERS_ON_THE_WAY = `${ORDERS_LIST}/on-the-way-to-call-point`;
export const ORDERS_POST_CONTROL = `${ORDERS_LIST}/post_control`;
export const ORDERS_POSTPONED = `${ORDERS_LIST}/postponed`;
export const ORDERS_RESCHEDULED = `${ORDERS_LIST}/rescheduled`;
export const ORDERS_CANCELLED_AT_CLIENT = `${ORDERS_LIST}/cancelled_at_client`;
export const ORDERS_NONCALL = `${ORDERS_LIST}/noncall`;
export const ORDERS_CANCELLED = `${ORDERS_LIST}/cancelled`;
export const ORDERS_BEING_FINALIZED = `${ORDERS_LIST}/being_finalized`;
export const ORDERS_DELIVERED = `${ORDERS_LIST}/is_delivered`;

export const COURIERS = `${ARM}/couriers`;
export const COURIERS_LIST = `${COURIERS}/list`;
export const COURIERS_INVITED = `${COURIERS_LIST}/invited`;
export const COURIERS_CONFIRMED = `${COURIERS_LIST}/confirmed`;
export const COURIERS_SUSPENDED = `${COURIERS_LIST}/suspended`;
export const COURIERS_CANCELLED = `${COURIERS_LIST}/cancelled`;
export const COURIERS_REFUSED = `${COURIERS_LIST}/refused`;

export const COURIER_CREATE = `${COURIERS}/create`;

export const PRODUCTS = `${ARM}/products`;
export const PRODUCT_CREATE = `${PRODUCTS}/create`;

export const PARTNERS = `${ARM}/partners`;
export const SHIPMENT_POINTS = `${ARM}/shipment-points`;
export const MONITORING = `${ARM}/monitoring`;
export const SETTINGS = `${ARM}/settings`;
export const API = `${ARM}/api`;

export const DELIVERY_ZONES = `${SETTINGS}/delivery-zones`;
export const USERS = `${SETTINGS}/users`;

export const USER_CREATE = `${USERS}/create`;
export const USER_UPDATE = `${USERS}/{0}/update`;

export const USER_POLICY = 'user-policy';
export const DATA_POLICY = 'data-policy';
export const PRIVACY_POLICY = 'privacy_policy';
export const CONTACTS = 'contacts';

export const REVIEWS = `${ARM}/reviews`;
export const REVIEWS_LIST = `${REVIEWS}/list`;
export const REVIEWS_ALL = `${REVIEWS_LIST}/all`;
export const REVIEWS_REASONS = `${REVIEWS}/reasons`;

export const RATINGS = `${ARM}/ratings`;
export const RATINGS_CATEGORIES = `${RATINGS}/categories`;
export const RATINGS_STATISTICS = `${RATINGS}/statistics`;

export const ORDER_GROUPS = `${ARM}/order-groups`;
export const ORDER_GROUP_DETAILS = `${ARM}/order-groups/{0}`;

export const ORDER_CHAIN = `${ARM}/order-chain`;
export const ORDER_CHAIN_DETAILS = `${ARM}/order-chain/{0}`;
export const ORDER_CHAIN_UPDATE = `${ARM}/order-chain/{0}/update`;
