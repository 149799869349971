import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ORDER_GROUP_STATUSES } from '@/core/utils/constants/constants';
import { ORDER_GROUP_DETAILS } from '@/core/utils/constants/routes';

const OrderGroupsTableRow = ({ orderGroup }) => {
    const navigate = useNavigate();
    return (
        <TableRow sx={{ cursor: 'pointer' }} onClick={() => navigate(ORDER_GROUP_DETAILS.format(orderGroup.id))}>
            <TableCell>{orderGroup.id}</TableCell>
            <TableCell>{new Date(orderGroup.created_at).toLocaleString()}</TableCell>
            <TableCell>
                {orderGroup?.sorter ? `${orderGroup?.sorter?.user?.last_name} ${orderGroup?.sorter?.user?.first_name}` : 'Не назначен'}
            </TableCell>
            <TableCell>
                {orderGroup?.courier ? `${orderGroup?.courier?.user?.last_name} ${orderGroup?.courier?.user?.first_name}` : 'Не назначен'}
            </TableCell>
            <TableCell>
                {orderGroup?.courier_service_manager
                    ? `${orderGroup?.courier_service_manager?.user?.last_name} ${orderGroup?.courier_service_manager?.user?.first_name}`
                    : '-'}
            </TableCell>
            <TableCell>{orderGroup.count}</TableCell>
            <TableCell>{new Date(orderGroup.updated_at).toLocaleString()}</TableCell>
            <TableCell>{ORDER_GROUP_STATUSES[orderGroup?.statuses?.[0]?.name] || ORDER_GROUP_STATUSES.new_group}</TableCell>
        </TableRow>
    );
};

export default OrderGroupsTableRow;
