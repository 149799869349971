import { Grid, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TypographyMain } from '@/components';
import AuthorizateModal2 from '@/components/autorizateModal2';
import { devices } from '@/core/utils/devices';
import { RegisterStepMain } from '@/pages/register/registerComponent/registerStepsComponent';

const useStyles = makeStyles((theme) => ({
    signIn: {
        color: theme.palette.grey.light,
        textAlign: 'right'
    },
    link: {
        color: theme.palette.success.main
    },
    active: {
        color: theme.palette.success.main
    }
}));

const RegisterRight = () => {
    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [signIn, setSignIn] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const state = useSelector((state) => state.auth.user);

    const isStepSkipped = (step) => skipped.has(step);

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    return (
        <Grid container>
            {signIn ? (
                <></>
            ) : (
                <Grid item xs={12}>
                    <TypographyMain
                        theme={theme}
                        type="h5"
                        className={classes.signIn}
                        sx={{
                            mt: 5,
                            mr: 8,
                            color: theme.palette.grey[550],
                            cursor: 'pointer',
                            [`@media screen and ${devices.tablet}`]: {
                                mr: 0
                            }
                        }}
                    >
                        Уже есть аккаунт?
                        <Link
                            onClick={state ? () => navigate('/arm/couriers') : () => setOpen(true)}
                            underline="hover"
                            className={classes.link}
                            sx={{ pl: 0.5 }}
                        >
                            Войти
                        </Link>
                    </TypographyMain>
                </Grid>
            )}

            <Grid
                className="register_right_container"
                container
                xs={12}
                sx={{
                    ml: '77px',
                    [`@media screen and ${devices.tablet}`]: {
                        ml: 0,
                        justifyContent: 'center'
                    }
                }}
            >
                <RegisterStepMain activeStep={activeStep} handleNext={handleNext} setSignIn={setSignIn} />
                <AuthorizateModal2 open={open} handleClose={() => setOpen(false)} />
            </Grid>
        </Grid>
    );
};

export default RegisterRight;
