import { styled } from '@mui/material/styles';
import React from 'react';

const StyledLabel = styled('label')`
    display: inline-block;
    margin-bottom: 5px;
    color: var(--medium-text-color);
    font-size: 12px;
    line-height: 18px;
    & .required_label_sign {
        display: inline-block;
        margin-left: 2px;
        color: #ff6464;
    }
`;

const FieldLabel = ({ children, required, id, style }) => (
    <StyledLabel htmlFor={id} required={required} style={style}>
        {children}
        {required ? <span className="required_label_sign">*</span> : null}
    </StyledLabel>
);

export default FieldLabel;
