import { Box } from '@mui/material';
import React from 'react';

import PageLayout from '@/views/arm/components/pageLayout';

const ArmListLayout = ({ children }) => (
    <Box>
        <PageLayout>
            <PageLayout.Content sx={{ background: 'green' }}>{children}</PageLayout.Content>
        </PageLayout>
    </Box>
);

export default ArmListLayout;
