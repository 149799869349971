import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import React, { useState, useRef, useEffect } from 'react';

const StyledInputElement = styled('input')(
    ({ theme, success }) => `
 codeInput: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
    },
    codeLabel: {
        marginBottom: '16px'
    },
    codeInputs: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '20px'
    },
    input: {
        backgroundImage: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        textAlign: 'center',
        height: '50px',
        width: '50px',
        borderRadius: '10px',
        margin: '0 4px',
        border: '2px solid #4f5b66',
        fontSize: '30px'
    }
`
);

const useStyles = makeStyles(({ error }) => ({
    codeInput: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
    },
    codeLabel: {
        marginTop: '6px',
        color: 'red',
        marginLeft: '4px',
        fontWeight: 300
    },
    codeInputs: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '20px'
    },
    input: {
        backgroundImage: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        textAlign: 'center',
        height: '50px',
        width: '50px',
        borderRadius: '10px',
        margin: '0 4px',
        fontSize: '15px',
        '&:focus': {
            borderColor: '#49C172 !important'
        }
    }
}));

const InputCode = ({ length, name, loading, onComplete, setCodeSms, color, error, code, setCode }) => {
    const classes = useStyles({ error });
    const inputs = useRef([]);

    const processInput = (e, slot) => {
        const num = e.target.value;
        if (/[^0-9]/.test(num)) return;
        const newCode = [...code];
        newCode[slot] = num;
        setCode(newCode);
        if (setCodeSms) setCodeSms(newCode.join(''));
        if (slot !== length - 1) {
            inputs.current[slot + 1].focus();
        }
        if (newCode.every((num) => num !== '')) {
            onComplete(newCode.join(''));
        }
    };

    const onKeyUp = (e, slot) => {
        if (e.keyCode === 8 && !code[slot] && slot !== 0) {
            const newCode = [...code];
            newCode[slot - 1] = '';
            setCodeSms(newCode.join(''));
            setCode(newCode);
            inputs.current[slot - 1].focus();
        }
    };

    useEffect(() => {
        inputs.current[0].focus();
    }, []);

    return (
        <div className={classes.codeInput}>
            <div className={classes.codeInputs}>
                {code.map((num, idx) => (
                    <input
                        className={classes.input}
                        key={idx}
                        type="text"
                        inputMode="numeric"
                        maxLength={1}
                        value={num}
                        readOnly={loading}
                        onChange={(e) => processInput(e, idx)}
                        onKeyUp={(e) => onKeyUp(e, idx)}
                        ref={(ref) => inputs.current.push(ref)}
                        style={{ border: `1px solid ${!error ? '#84868a' : 'red'}`, color: 'var(--main-text-color)' }}
                    />
                ))}
            </div>
            <p className={classes.codeLabel}>{name}</p>
        </div>
    );
};

export default InputCode;
