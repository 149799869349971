import { MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { ReactSVG } from 'react-svg';

import ArrowDown from '@/assets/images/icons/chevron-down.svg';
import FieldLabel from '@/components/formField/fieldLabel';
import StyledSelect from '@/components/formField/styledSelect';

const StyledOption = styled(MenuItem)`
    padding: 6px 15px;
    cursor: pointer;
    background: var(--paper-bg);
    color: var(--main-text-color);
    & label {
        display: block;
        width: 100%;
        font-weight: 400;
        cursor: pointer;
    }
    &:hover {
        background: var(--select-focused-option);
    }
    &:last-of-type {
        border-bottom: none;
    }
`;

const UncontrolledSelect = ({
    id,
    label,
    placeholder,
    options,
    SelectProps,
    required,
    successColor = null,
    placeholderDisabled = true,
    ContainerProps,
    ...props
}) => (
    <>
        {label && (
            <FieldLabel htmlFor={id} required={required}>
                {label}
            </FieldLabel>
        )}
        <StyledSelect
            {...props}
            name={id}
            displayEmpty
            disabled={props.disabled}
            defaultOpen={props.defaultOpen}
            defaultValue=""
            required={required}
            MenuProps={{
                PaperProps: {
                    sx: { background: 'var(--paper-bg)' }
                }
            }}
            IconComponent={({ className, ...rest }) => (
                <ReactSVG src={ArrowDown} {...rest} className={[className, 'select_icon'].join(' ')} />
            )}
        >
            {props.disabledText || placeholder ? (
                <StyledOption value="">{props.disabled ? props.disabledText : placeholder}</StyledOption>
            ) : null}
            {options?.map((option) => (
                <StyledOption key={option.id} value={option.id}>
                    {option.name}
                </StyledOption>
            ))}
        </StyledSelect>
    </>
);

export default UncontrolledSelect;
