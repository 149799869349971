import { ThemeContext } from '@emotion/react';
import { FormControl, InputLabel, Select, MenuItem, Button, TextField, Typography, Box } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import { useForm, Controller, useController } from 'react-hook-form';

const useStyles = makeStyles(() => ({
    input: {
        borderRadius: '10px',
        '& .MuiSelect-select MuiSelect-outlined': {
            borderRadius: '10px'
        },
        '& svg': {
            fill: 'red'
        }
    }
}));

const SelectForm = ({ control, firstTableSelect, nameForm }) => {
    const theme = useTheme();
    const clr = theme.palette;
    const classes = useStyles();
    return (
        <Box>
            <Typography variant="h3">{nameForm}</Typography>
            {firstTableSelect.map((item, i) => (
                <Controller
                    key={i}
                    control={control}
                    name={item.name}
                    rules={{ required: true }}
                    render={({ field: { onChange, ref }, formState: { errors } }) => (
                        <>
                            <InputLabel sx={{ mt: 2 }}>{item.label}</InputLabel>
                            <Select
                                className={classes.input}
                                onChange={onChange} // send value to hook form
                                inputRef={ref}
                                fullWidth
                            >
                                {item.select.map((val, ind) => (
                                    <MenuItem key={ind} value={val}>
                                        {val}
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                    )}
                />
            ))}

            {/* <Controller
            control={control}
            name="test2"
            rules={{ required: true }}
            render={({ field: { onChange, ref } }) => (
                <TextField
                    onChange={onChange} // send value to hook form
                    inputRef={ref}
                />
            )}
        />
        <Controller
            control={control}
            name="test1"
            rules={{ required: 'поле обязательное' }}
            render={({ field: { onChange, ref }, formState: { errors } }) => (
                <TextField
                    onChange={onChange} // send value to hook form
                    inputRef={ref}
                    InputProps={{
                        error: !!errors.test1
                    }}
                    helperText={errors.test1?.message}
                />
            )}
        /> */}
        </Box>
    );
};
export default SelectForm;

// <Controller
//             control={control}
//             name={valueContoller.quantity}
//             rules={{
//               validate: {
//                 positiveNumber: value => {
//                   return parseFloat(value) > 0;
//                 }
//               }
//             }}
//             render={({ onChange, ...props }) => (
//               <TextField
//                 {...props}
//                 label="Кол-во"
//                 type="text"
//                 variant="outlined"
//                 size="small"
//                 className={classes.input}
//                 defaultValue={1}
//                 InputProps={{
//                   error: !!errors.count,
//                   helperText: errors.count?.message
//                 }}
//                 InputLabelProps={{ shrink: true }}
//                 onChange={value => onChange(value)}
//               />
//             )}
//           />
