import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { RoundedButton } from '@/components/button/styledButtons';
import DetailsInfoBlock from '@/components/detailsInfoBlock';
import i18n from '@/core/i18n';
import StyledPaper from '@/pages/register/registerComponent/StyledPapper';
import { getRedirectTab } from '@/views/orders/utils/localStorage';

const { t } = i18n;

const FormSuccessValidate = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { organization } = location.state;
    return (
        <>
            <StyledPaper>
                <DetailsInfoBlock.Child label="Название компании" info={organization?.name_ru} />
                <DetailsInfoBlock.Child label="БИН организации" info={organization?.identifier} />
                <DetailsInfoBlock.Child
                    label="Дата регистрации"
                    info={organization?.registration_date?.slice(0, 10).replaceAll('-', '.') || '-'}
                />
                <DetailsInfoBlock.Child
                    label="Руководитель"
                    info={
                        organization?.leader_data?.first_name
                            ? `${organization?.leader_data?.first_name} ${organization?.leader_data?.last_name}`
                            : 'Не указано'
                    }
                    sx={{ mb: 0 }}
                />
                <Typography>{t('components:formSuccessValidate.youCanChangeDataInProfile')}</Typography>
            </StyledPaper>

            <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
                <RoundedButton variant="contained" color="success" onClick={() => navigate(`/arm/orders/${getRedirectTab([])}`)}>
                    {t('buttons:beginWork')}
                </RoundedButton>
            </Stack>
        </>
    );
};
export default FormSuccessValidate;
