import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import ordersEn from '@/locales/en/orders.json';
import armRu from '@/locales/ru/arm.json';
import buttonsRu from '@/locales/ru/buttons.json';
import commonRu from '@/locales/ru/common.json';
import componentsRu from '@/locales/ru/components.json';
import constantsRu from '@/locales/ru/constants.json';
import couriersRu from '@/locales/ru/couriers.json';
import deliveryAreasRu from '@/locales/ru/deliveryAreas.json';
import errorsRu from '@/locales/ru/errors.json';
import feedbackRu from '@/locales/ru/feedback.json';
import historyRu from '@/locales/ru/history.json';
import integrationRu from '@/locales/ru/integration.json';
import modalsRu from '@/locales/ru/modals.json';
import monitoringRu from '@/locales/ru/monitoring.json';
import notificationsRu from '@/locales/ru/notifications.json';
import orderChainRu from '@/locales/ru/orderChain.json';
import orderGroupsRu from '@/locales/ru/orderGroups.json';
import ordersRu from '@/locales/ru/orders.json';
import partnersRu from '@/locales/ru/partners.json';
import productsRu from '@/locales/ru/products.json';
import profileRu from '@/locales/ru/profile.json';
import ratingsRu from '@/locales/ru/ratings.json';
import reviewsRu from '@/locales/ru/reviews.json';
import shipmentPointsRu from '@/locales/ru/shipmentPoints.json';
import statisticsRu from '@/locales/ru/statistics.json';
import usersRu from '@/locales/ru/users.json';

const resources = {
    ru: {
        arm: armRu,
        orders: ordersRu,
        orderGroups: orderGroupsRu,
        orderChain: orderChainRu,
        statistics: statisticsRu,
        couriers: couriersRu,
        components: componentsRu,
        constants: constantsRu,
        buttons: buttonsRu,
        modals: modalsRu,
        partners: partnersRu,
        history: historyRu,
        products: productsRu,
        profile: profileRu,
        users: usersRu,
        deliveryAreas: deliveryAreasRu,
        shipmentPoints: shipmentPointsRu,
        ratings: ratingsRu,
        reviews: reviewsRu,
        monitoring: monitoringRu,
        notifications: notificationsRu,
        errors: errorsRu,
        common: commonRu,
        integration: integrationRu,
        feedback: feedbackRu
    }
};
i18next.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('lang') || navigator.languages[0],
    fallbackLng: 'ru',
    debug: false,
    interpolation: {
        escapeValue: false
    }
});
export default i18next;
