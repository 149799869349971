import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { devices } from '@/core/utils/devices';

const StyledTableSwitcher = styled(IconButton)`
    display: none;
    margin-top: 10px;
    color: var(--main-text-color);
    & svg {
        font-size: 26px;
    }
    @media screen and ${devices.mobileM} {
        display: block;
    }
`;
export default StyledTableSwitcher;
