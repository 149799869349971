import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, Fade, IconButton, LinearProgress, Modal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { devices } from '@/core/utils/devices';

const StyledModal = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 504px;
    max-width: 90%;
    max-height: 90%;
    background-color: var(--paper-bg);
    padding: 30px;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow: ${({ overflow }) => overflow};

    & .MuiLinearProgress-root {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    & h3 {
        color: var(--title-color);
    }

    & .close_modal_icon {
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0;
        color: var(--medium-text-color);
    }

    & p:not(.required_error_message) {
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 16px;
        color: var(--main-text-color);
        @media screen and ${devices.mobileM} {
            font-size: 14px;
        }
    }
`;
const CommonModal = ({ isShowing, close, children, extraStyles, title, isLoading, overflow = 'hidden' }) => (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isShowing}
        closeAfterTransition
        BackdropComponent={Backdrop}
        onClose={close}
    >
        <Fade in={isShowing}>
            <StyledModal sx={extraStyles} overflow={overflow}>
                {isLoading ? <LinearProgress color="success" /> : null}
                <Typography variant="h3" mb="30px">
                    {title && title}
                    <IconButton className="close_modal_icon" onClick={close}>
                        <CloseIcon />
                    </IconButton>
                </Typography>
                {children}
            </StyledModal>
        </Fade>
    </Modal>
);
export default CommonModal;
