import { userAxios } from '../axiosSettings';

const getBaseUrl = (url) => `/api/v1${url}`;
const getBaseUrlV2 = (url) => `/api/v2${url}`;

export const httpClient = {
    setToken(token) {
        userAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
    },

    get(url, data) {
        return userAxios.get(getBaseUrl(url), data);
    },

    post(url, data, options) {
        return userAxios.post(getBaseUrl(url), data, options);
    },

    put(url, data, options) {
        return userAxios.put(getBaseUrl(url), data, options);
    },

    patch(url, data, options) {
        return userAxios.patch(getBaseUrl(url), data, options);
    },

    delete(url, data) {
        return userAxios.delete(getBaseUrl(url), data);
    },

    options(url, options) {
        return userAxios.options(getBaseUrl(url), options);
    }
};
export const httpClientV2 = {
    setToken(token) {
        userAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
    },

    get(url, data) {
        return userAxios.get(getBaseUrlV2(url), data);
    },

    post(url, data, options) {
        return userAxios.post(getBaseUrlV2(url), data, options);
    },

    put(url, data, options) {
        return userAxios.put(getBaseUrlV2(url), data, options);
    },

    patch(url, data, options) {
        return userAxios.patch(getBaseUrlV2(url), data, options);
    },

    delete(url, data) {
        return userAxios.delete(getBaseUrlV2(url), data);
    },

    options(url, options) {
        return userAxios.options(getBaseUrlV2(url), options);
    }
};
