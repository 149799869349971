import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Container } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { Header, Sidebar, MainPage } from '@/containers';
import { getDictionaries } from '@/core/services/dictionaries';
import { openSidePanel } from '@/core/store/customization/actions';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginTop: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -240,
            width: `calc(100% - 260px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - 260px)`,
            padding: '8px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - 260px)`,
            padding: '8px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginTop: 0,
        // width: `calc(100% - ${260}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

const MainLayout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // if (sessionStorage.getItem('auth') !== 'true') {
    //     navigate('/login');
    // }

    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const { leftDrawerOpened, currencies, error, pending } = useSelector((state) => ({
        leftDrawerOpened: state?.customization?.opened,
        currencies: state?.dictionaries?.currencies || [],
        error: state?.dictionaries?.error,
        pending: state?.dictionaries?.pending
    }));

    useEffect(() => {
        // dispatch(openSidePanel(!matchDownMd));
    }, [matchDownMd]);

    useEffect(() => {
        // dispatch(getDictionaries());
    }, []);

    const handleLeftDrawerToggle = () => {
        // dispatch(openSidePanel(!leftDrawerOpened));
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                {/* <CssBaseline /> */}

                <AppBar
                    // enableColorOnDark
                    position="fixed"
                    // color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: 'rgba(0, 0, 0, 0.9)',
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                    }}
                >
                    <Toolbar>
                        <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                    </Toolbar>
                </AppBar>

                {/* <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} /> */}
            </Box>
            <Main theme={theme} open={true}>
                <Outlet />
            </Main>
        </>
    );
};

export default MainLayout;
