import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';

import FeedbackForm from '@/components/feedback/feedbackForm';
import { orderServices } from '@/core/services/orderServices';
import { reasonServices } from '@/core/services/reasonServices';
import { reviewServices } from '@/core/services/reviewServices';
import { ORDER_INFO } from '@/core/utils/constants/queryKeys';
import CompletedMessage from '@/pages/clientFeedback/components/completedMessage';
import IconRating from '@/pages/clientFeedback/components/iconRating';
import RatingHeading from '@/pages/clientFeedback/components/ratingHeading';

const StyledContainer = styled(Box)`
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 77px 20px 50px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: var(--main-text-color);
    & h1 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 25px;
    }
    & h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    & p {
        margin-bottom: 30px;
        color: #6b778c;
        font-size: 14px;
        line-height: 18px;
    }
`;

const schema = yup.object({
    comment: yup.string()
});

const ClientFeedback = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [rating, setRating] = useState(null);
    const [completed, setCompleted] = useState(false);

    const { data: order } = useQuery(ORDER_INFO, () => orderServices.getPublic(params.id));

    const reasonsQuery = useQuery([`reasons-${rating}`], () => reasonServices.getByRate(order?.data?.partner?.courier_partner_id, rating), {
        enabled: !!rating && !!order
    });
    const feedbackMutation = useMutation(reviewServices.createByReceiver);

    const reasons = reasonsQuery?.data?.data;

    useEffect(() => {
        setRating(location.state?.rating || null);
        setCompleted(location.state?.completed);
    }, [location.state]);

    const changeRating = (rating) => {
        setRating(rating);
        navigate('', { state: { rating } });
    };

    useEffect(() => {
        if (order?.data?.has_receiver_feedback) {
            setCompleted(true);
        }
    }, [order]);

    return (
        <>
            <StyledContainer>
                <RatingHeading rating={rating} productName={order?.data?.item_name} isVisible={!completed} />
                <IconRating changeRating={changeRating} isVisible={!rating && !completed} />
                <FeedbackForm
                    reasons={reasons}
                    isVisible={!!rating && !completed}
                    rating={rating}
                    schema={schema}
                    order={order}
                    mutation={feedbackMutation}
                    successCallback={() => {
                        setCompleted(true);
                        navigate('', { state: { completed: true } });
                    }}
                />
                <CompletedMessage isVisible={!!completed} />
            </StyledContainer>
        </>
    );
};

export default ClientFeedback;
