import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ORDERS_TABLE_KEY } from '../utils/localStorage';

import i18n from '@/core/i18n';
import { PARTNER_MANAGER } from '@/core/utils/constants/constants';
import { setHideStatuses } from '@/toolkit/reducers/orders';

export const ADDRESS_KEY = 'address';
export const CREATED_AT_KEY = 'created_at';
export const RECEIVER_NAME_KEY = 'receiver_name';
export const RECEIVER_IIN_KEY = 'receiver_iin';
export const RECEIVER_PHONE_KEY = 'receiver_phone_number';
export const ORDER_TYPE_KEY = 'order_type';
export const DELIVERY_DATETIME_KEY = 'delivery_datetime';
export const INITIAL_DELIVERY_DATETIME_KEY = 'initial_delivery_datetime';
export const CITY_KEY = 'city';
export const ORDER_ID_KEY = 'numberApplication';
export const ORDER_KASPI_ID_KEY = 'kaspiId';
export const PARTNER_ID_KEY = 'partner_id';
export const PARTNER_KEY = 'partner';
export const PRODUCT_KEY = 'product';
export const DELIVERY_ZONE_KEY = 'deliveryZone';
export const COURIER_KEY = 'courier';
export const STATUS_KEY = 'status';
export const CREATION_MODE_KEY = 'created_by';
export const PACK_BUTTON_KEY = 'pack_button';

export const HIDE_COLS = [ORDER_ID_KEY];

const { t } = i18n;

const DEFAULT_COLS_OBJECTS = [
    {
        title: t('orders:cols.id'),
        headerKey: ORDER_ID_KEY
    },
    {
        title: t('orders:cols.kaspiId'),
        headerKey: ORDER_KASPI_ID_KEY
    },
    {
        title: t('orders:cols.partnerOrderId'),
        headerKey: PARTNER_ID_KEY
    },
    {
        title: t('orders:cols.partner'),
        headerKey: PARTNER_KEY,
        width: '150px'
    },
    {
        title: t('orders:cols.product'),
        headerKey: PRODUCT_KEY,
        width: '200px'
    },
    {
        title: t('orders:cols.deliveryArea'),
        headerKey: DELIVERY_ZONE_KEY,
        width: '170px'
    },
    {
        title: t('orders:cols.courier'),
        headerKey: COURIER_KEY,
        width: '200px'
    },
    {
        title: t('orders:cols.status'),
        headerKey: STATUS_KEY,
        Render: () => {
            const ordersState = useSelector((state) => state.orders);
            const dispatch = useDispatch();
            return (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label htmlFor="toggleStatuses">
                    <Tooltip title={ordersState.isHideStatuses ? t('orders:cols.showWayTrack') : t('orders:cols.hideWayTrack')}>
                        <IconButton
                            sx={{ color: 'var(--medium-text-color)' }}
                            onClick={() => {
                                dispatch(setHideStatuses());
                            }}
                        >
                            <DirectionsRunOutlinedIcon
                                fontSize="small"
                                style={{ color: ordersState.isHideStatuses ? 'inherit' : 'var(--accent-color)' }}
                            />
                        </IconButton>
                    </Tooltip>
                </label>
            );
        }
    }
];

const ADDITIONAL_COLS_OBJECTS = [
    {
        title: t('orders:cols.address'),
        headerKey: ADDRESS_KEY,
        width: '300px'
    },
    {
        title: t('orders:cols.createdAt'),
        headerKey: CREATED_AT_KEY,
        width: '170px'
    },
    {
        title: t('orders:cols.recipientName'),
        headerKey: RECEIVER_NAME_KEY,
        width: '200px'
    },
    {
        title: t('orders:cols.uin'),
        headerKey: RECEIVER_IIN_KEY,
        width: '13%'
    },
    {
        title: t('orders:cols.phoneNumber'),
        headerKey: RECEIVER_PHONE_KEY,
        width: '13%'
    },
    {
        title: t('orders:cols.type'),
        headerKey: ORDER_TYPE_KEY,
        width: '13%'
    },
    {
        title: t('orders:cols.createdBy'),
        headerKey: CREATION_MODE_KEY
    },
    {
        title: t('orders:cols.datetime'),
        headerKey: DELIVERY_DATETIME_KEY,
        width: '170px'
    },
    {
        title: t('orders:cols.initialDatetime'),
        headerKey: INITIAL_DELIVERY_DATETIME_KEY,
        width: '170px'
    },
    {
        title: t('orders:cols.city'),
        headerKey: CITY_KEY,
        width: '13%'
    }
];

const SORTER_COLS_OBJECTS = [
    {
        title: t('orders:cols.id'),
        headerKey: ORDER_ID_KEY
    },
    {
        title: t('orders:cols.product'),
        headerKey: PRODUCT_KEY,
        width: '200px'
    },
    {
        title: t('orders:cols.phoneNumber'),
        headerKey: RECEIVER_PHONE_KEY,
        width: '13%'
    },
    {
        title: t('orders:cols.recipientName'),
        headerKey: RECEIVER_NAME_KEY,
        width: '200px'
    },
    {
        title: t('orders:cols.address'),
        headerKey: ADDRESS_KEY,
        width: '300px'
    },
    {
        title: t('orders:cols.status'),
        headerKey: STATUS_KEY,
        Render: () => {
            const ordersState = useSelector((state) => state.orders);
            const dispatch = useDispatch();
            return (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label htmlFor="toggleStatuses">
                    <Tooltip title={ordersState.isHideStatuses ? t('orders:cols.showWayTrack') : t('orders:cols.hideWayTrack')}>
                        <IconButton
                            sx={{ color: 'var(--medium-text-color)' }}
                            onClick={() => {
                                dispatch(setHideStatuses());
                            }}
                        >
                            <DirectionsRunOutlinedIcon
                                fontSize="small"
                                style={{ color: ordersState.isHideStatuses ? 'inherit' : 'var(--accent-color)' }}
                            />
                        </IconButton>
                    </Tooltip>
                </label>
            );
        }
    },
    {
        title: t('orders:cols.pack'),
        headerKey: PACK_BUTTON_KEY,
        width: '20px'
    }
];

const DEFAULT_COLS = DEFAULT_COLS_OBJECTS.map((c) => ({ headerKey: c.headerKey }));
const ADDITIONAL_COLS = ADDITIONAL_COLS_OBJECTS.map((c) => ({ headerKey: c.headerKey }));
const SORTER_COLS = SORTER_COLS_OBJECTS.map((c) => ({ headerKey: c.headerKey }));

const ALL_COLS_OBJ = {};

[...DEFAULT_COLS_OBJECTS, ...ADDITIONAL_COLS_OBJECTS, ...SORTER_COLS_OBJECTS].forEach((obj) => {
    ALL_COLS_OBJ[obj.headerKey] = obj;
});

const getDefaultHeadersByRole = (role, receivedCols) => {
    let headers = [...DEFAULT_COLS_OBJECTS];
    let excludedCols = [];
    if (role === PARTNER_MANAGER) {
        excludedCols = [COURIER_KEY, DELIVERY_ZONE_KEY, PARTNER_KEY];
        headers = headers.filter((header) => !excludedCols.includes(header.headerKey));
    }
    if (receivedCols) {
        if (receivedCols.some((col) => excludedCols.some((colKey) => col.headerKey === colKey))) {
            localStorage.removeItem(ORDERS_TABLE_KEY);
        }
    }
    return headers;
};
const getAdditionalHeadersByRole = (role) => {
    const headers = [...ADDITIONAL_COLS_OBJECTS];
    return headers;
};

export { DEFAULT_COLS, ADDITIONAL_COLS, SORTER_COLS, ALL_COLS_OBJ, getDefaultHeadersByRole, getAdditionalHeadersByRole };
