import { styled, Tab } from '@mui/material';
import React from 'react';

const TabChild = styled(Tab)(({ theme }) => ({
    width: 120,
    [theme.breakpoints.down('md')]: {
        width: 'auto',
        fontSize: '13px'
    },
    [theme.breakpoints.down('sm')]: {
        width: '10px',
        fontSize: '11px',
        margin: 0,
        padding: 0
    }
}));

export default TabChild;
