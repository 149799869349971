import { DEFAULT_TABS_OBJ, DEFAULT_TABS } from '@/views/orders/list/defaultTabs';

export const MENU_TABS_KEY = 'menu_tabs';
export const ORDERS_TABS_KEY = 'orders_tabs';
export const ORDERS_TABLE_KEY = 'orders_table';
export const NEW_ORDERS = 'list/new';
export const ON_THE_WAY_TO_CALL_POINT = 'list/on-the-way-to-call-point';
export const ALL_ORDERS = 'list/all';

export const getFirstOrdersTab = (tabs) => {
    if (tabs.some((t) => !Object.hasOwn(t, 'ordersTabKey'))) {
        localStorage.removeItem(ORDERS_TABS_KEY);
        return DEFAULT_TABS[0];
    }
    return tabs[0];
};

export const isTabShow = (tabLink) => {
    const tabs = JSON.parse(localStorage.getItem(ORDERS_TABS_KEY));
    return tabs.findIndex((t) => DEFAULT_TABS_OBJ[t.ordersTabKey].to === tabLink) !== -1;
};

export const getRedirectTab = (priorityLinks, userProfileType) => {
    if (userProfileType === 'sorter') return ALL_ORDERS;

    const tabs = JSON.parse(localStorage.getItem(ORDERS_TABS_KEY));
    if (!tabs) return ALL_ORDERS;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < priorityLinks.length; i++) {
        if (tabs.findIndex((t) => DEFAULT_TABS_OBJ[t.ordersTabKey].to === priorityLinks[i]) !== -1) {
            return priorityLinks[i];
        }
    }
    return DEFAULT_TABS_OBJ[getFirstOrdersTab(tabs).ordersTabKey].to;
};
