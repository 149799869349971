import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import bad from '@/assets/images/feedbackIcons/bad.svg';
import good from '@/assets/images/feedbackIcons/good.svg';
import neutral from '@/assets/images/feedbackIcons/neutral.svg';
import perfect from '@/assets/images/feedbackIcons/perfect.svg';
import terrible from '@/assets/images/feedbackIcons/terrible.svg';
import i18n from '@/core/i18n';

const { t } = i18n;

const StyledFeedbackButton = styled('button')`
    width: 34px;
    height: 34px;
    background: ${({ icon }) => `#F8E85F url(${icon}) 50% 50% no-repeat`};
    border-radius: 50%;
    border: none;
    cursor: pointer;
`;
const StyledLabel = styled(Typography)`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6b778c;
`;
const FeedbackButton = ({ text, icon, onClick }) => (
    <Box sx={{ textAlign: 'center' }}>
        <StyledFeedbackButton icon={icon} onClick={onClick} />
        <StyledLabel>{text}</StyledLabel>
    </Box>
);

const IconRating = ({ isVisible, changeRating }) =>
    isVisible && (
        <Box display="flex" justifyContent="space-between">
            <FeedbackButton onClick={() => changeRating(1)} icon={terrible} text={t('modals:feedbackModal.ratings.1')} />
            <FeedbackButton onClick={() => changeRating(2)} icon={bad} text={t('modals:feedbackModal.ratings.2')} />
            <FeedbackButton onClick={() => changeRating(3)} icon={neutral} text={t('modals:feedbackModal.ratings.3')} />
            <FeedbackButton onClick={() => changeRating(4)} icon={good} text={t('modals:feedbackModal.ratings.4')} />
            <FeedbackButton onClick={() => changeRating(5)} icon={perfect} text={t('modals:feedbackModal.ratings.5')} />
        </Box>
    );

export default IconRating;
