import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import L from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import 'leaflet-routing-machine';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import courierIcon from '@/assets/images/courier-icon.svg';
import mapMarkerBlack from '@/assets/images/map-marker-black.svg';
import mapMarker from '@/assets/images/map-marker.svg';
import useOSM from '@/core/hooks/useOSM';
import { orderServices } from '@/core/services/orderServices';
import { wssUrl } from '@/core/utils/constants/constants';
import { ORDER_INFO } from '@/core/utils/constants/queryKeys';
import { toHoursAndMinutes } from '@/core/utils/utils';

const StyledMonitoringInfo = styled(Box)`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30vh;
    padding: 20px;
    background: #fff;
    font-family: 'Roboto', sans-serif;
    z-index: 1000;
    & h3 {
        margin-bottom: 30px;
        font-size: 18px;
        text-align: center;
    }
`;

const createRoute = (courierLocation, pointsCoords, setDeliveryTime) =>
    L.Routing.control({
        waypoints: [{ latLng: { lat: courierLocation[0], lng: courierLocation[1], name: 'courier' } }, ...pointsCoords],
        fitSelectedRoutes: true,
        draggableWaypoints: false,
        routeWhileDragging: false,
        overview: true,
        steps: false,
        lineOptions: {
            addWaypoints: false,
            styles: [{ color: '#49C172', opacity: 1, weight: 4 }]
        },
        createMarker(i, waypoint) {
            switch (waypoint.latLng.name) {
                case 'shipment_point':
                    return L.marker([waypoint.latLng.lat, waypoint.latLng.lng], {
                        icon: L.icon({
                            iconUrl: mapMarkerBlack,
                            size: [36, 36],
                            iconAnchor: [18, 36]
                        })
                    });
                case 'delivery_point':
                    return L.marker([waypoint.latLng.lat, waypoint.latLng.lng], {
                        icon: L.icon({
                            iconUrl: mapMarker,
                            size: [36, 36],
                            iconAnchor: [18, 36]
                        })
                    });
                default:
                    return L.marker(courierLocation, {
                        icon: L.icon({
                            iconUrl: courierIcon,
                            size: [50, 50],
                            iconAnchor: [25, 25]
                        })
                    });
            }
        }
    });

const SPEED = 40;

const ClientMonitoring = () => {
    const [deliveryTime, setDeliveryTime] = useState(0);
    const { Map, mapInstance, getCityCoordinates } = useOSM('Almaty');
    const params = useParams();
    const queryClient = useQueryClient();
    const { data: order } = useQuery(ORDER_INFO, () => orderServices.getPublic(params.id));

    const [route, _setRoute] = useState(null);
    const routeRef = useRef();
    const setRoute = (data) => {
        routeRef.current = data;
        _setRoute(data);
    };

    const wsRef = useRef();

    useEffect(() => {
        if (mapInstance && order) {
            const points = order.data?.addresses.map((address) => address);
            const pointsCoords = points.map((point) => ({
                latLng: { lat: point.place.latitude, lng: point.place.longitude, name: point.type }
            }));

            wsRef.current = new WebSocket(`${wssUrl}/api/v1/monitoring/client`);

            wsRef.current.onopen = () => {
                wsRef.current.send(order.data.id);
                wsRef.current.send(
                    JSON.stringify({ courier_id: order.data.courier_id, courier_service_id: order.data.courier_partner_id })
                );
            };
            wsRef.current.onmessage = async (e) => {
                const message = JSON.parse(e.data);
                if (message.type === 'location') {
                    routeRef.current?.remove();
                    setRoute(createRoute(message.data.location, pointsCoords));
                    routeRef.current.addTo(mapInstance);
                }
                if ('order_id' in message.data) {
                    await queryClient.invalidateQueries(ORDER_INFO);
                }
            };
        }
        return () => wsRef.current?.close();
    }, [mapInstance, order]);

    useEffect(() => {
        if (route) {
            routeRef.current.on('routesfound', (e) => {
                const routes = e.routes;
                const summary = routes[0].summary;
                const distanceInKm = summary.totalDistance / 1000;
                const time = distanceInKm / SPEED;
                const timeInMinutes = (time * 3600) / 60;
                setDeliveryTime(Math.round(timeInMinutes));
            });
        }
    }, [route]);

    return (
        <Box height="100vh" position="relative">
            {Map}
            <StyledMonitoringInfo>
                <Typography variant="h3">Курьер {order?.data?.courier?.first_name} везет ваш заказ</Typography>
                {/* <Typography> */}
                {/*    {selectTitleStatus(order?.data?.delivery_status.status, order?.data?.status[order?.data?.status?.length - 1]?.name)} */}
                {/* </Typography> */}
                {/* <Box mb="30px"> */}
                {/*    <DeliveryProgress */}
                {/*        width="100%" */}
                {/*        deliveryStatus={order?.data?.delivery_status.status} */}
                {/*        graphs={order?.data?.deliverygraph?.graph} */}
                {/*        statuses={order?.data?.status} */}
                {/*    /> */}
                {/* </Box> */}
                <Typography>Курьер прибудет примерно через: {toHoursAndMinutes(deliveryTime)}</Typography>
            </StyledMonitoringInfo>
        </Box>
    );
};

export default ClientMonitoring;
