import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { RoundedButton } from '@/components/button/styledButtons';
import DetailsInfoBlock from '@/components/detailsInfoBlock';
import MaskedInput from '@/components/formField/maskedInput';
import TextInput from '@/components/formField/textInput';
import ArmListLayoutHeader from '@/components/layouts/armListLayout/header';
import i18n from '@/core/i18n';
import { partnerServices } from '@/core/services/partnerServices';
import { userServices } from '@/core/services/userServices';
import { getSchemaEmptyValues } from '@/core/utils/schemas';
import { fetchCurrentUser } from '@/toolkit/actionCreators/auth';
import { addNotification } from '@/toolkit/reducers/toastr';

const { t } = i18n;

const schema = yup.object({
    firstName: yup
        .string()
        .required(t('errors:required'))
        .matches(/^[а-яёЁА-Яa-zA-Z- ]+$/, t('errors:lettersRequired')),
    lastName: yup
        .string()
        .required(t('errors:required'))
        .matches(/^[а-яёЁА-Яa-zA-Z- ]+$/, t('errors:lettersRequired')),
    middleName: yup.string().matches(/^$|^[а-яёЁА-Яa-zA-Z- ]+$/, t('errors:lettersRequired')),
    organizationName: yup.string().required(t('errors:required')),
    workStartTime: yup.string().matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, t('errors:workDayStart')),
    workEndTime: yup.string().matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, t('errors:workDayEnd'))
});

const ProfileUpdate = () => {
    const user = useSelector((state) => state.auth?.user);
    const {
        control,
        formState: { errors, isValid },
        getValues,
        register,
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: getSchemaEmptyValues(schema),
        resolver: yupResolver(schema)
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const updateUserMutation = useMutation(({ id, data }) => userServices.updateUser(id, data));
    const updatePartnerMutation = useMutation(({ id, data }) => partnerServices.updatePartner(id, data));

    useEffect(() => {
        setValue('firstName', user?.data?.first_name || '');
        setValue('lastName', user?.data?.last_name || '');
        setValue('middleName', user?.data?.middle_name || '');
        setValue('organizationName', user?.organization?.name_ru);
        setValue('workStartTime', user?.organization?.start_work_hour || '');
        setValue('workEndTime', user?.organization?.end_work_hour || '');
    }, [user]);

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateUserMutation.mutateAsync({
                id: user.data.id,
                data: {
                    first_name: getValues('firstName'),
                    last_name: getValues('lastName'),
                    middle_name: getValues('middleName')
                }
            });
            await updatePartnerMutation.mutateAsync({
                id: user.organization.id,
                data: {
                    identifier: user.organization.identifier,
                    type: user.organization.type,
                    name_ru: getValues('organizationName'),
                    start_work_hour: getValues('workStartTime'),
                    end_work_hour: getValues('workEndTime')
                }
            });
            await dispatch(fetchCurrentUser()).unwrap();
            navigate(-1);
            dispatch(addNotification({ message: t('notifications:profileUpdatedSuccess') }));
        } catch (e) {
            dispatch(addNotification({ message: t('notifications:profileUpdatedFailed'), type: 'error' }));
        }
    };

    return (
        <>
            <ArmListLayoutHeader
                title={
                    user?.data?.first_name ? `${user?.data?.first_name || ''} ${user?.data?.last_name || ''}` : t('profile:nameNotExists')
                }
                subTitle={t('profile:breadcrumbs', {
                    fullName: user?.data?.first_name
                        ? `${user?.data?.first_name || ''} ${user?.data?.last_name || ''}`
                        : t('profile:nameNotExists')
                })}
            />
            <form onSubmit={onSubmit}>
                <DetailsInfoBlock title={t('profile:managerData')}>
                    <DetailsInfoBlock.CustomChild>
                        <TextInput
                            label={t('profile:surname')}
                            placeholder={t('profile:placeholders.surname')}
                            required
                            control={control}
                            errors={errors}
                            type="text"
                            id="lastName"
                            {...register('lastName')}
                        />
                    </DetailsInfoBlock.CustomChild>
                    <DetailsInfoBlock.CustomChild>
                        <TextInput
                            label={t('profile:name')}
                            placeholder={t('profile:placeholders.name')}
                            required
                            control={control}
                            errors={errors}
                            type="text"
                            id="firstName"
                            {...register('firstName')}
                        />
                    </DetailsInfoBlock.CustomChild>
                    <DetailsInfoBlock.CustomChild>
                        <TextInput
                            label={t('profile:middleName')}
                            placeholder={t('profile:placeholders.middleName')}
                            control={control}
                            errors={errors}
                            type="text"
                            id="middleName"
                            {...register('middleName')}
                        />
                    </DetailsInfoBlock.CustomChild>
                </DetailsInfoBlock>
                <DetailsInfoBlock title={t('profile:organizationData')}>
                    <DetailsInfoBlock.CustomChild>
                        <TextInput
                            label={t('profile:orgName')}
                            placeholder={t('profile:placeholders.orgName')}
                            required
                            control={control}
                            errors={errors}
                            type="text"
                            id="organizationName"
                            {...register('organizationName')}
                        />
                    </DetailsInfoBlock.CustomChild>
                    <DetailsInfoBlock.CustomChild>
                        <MaskedInput
                            label={t('profile:timeStart')}
                            placeholder="09:00"
                            required
                            mask="99:99"
                            alwaysShowMask={false}
                            control={control}
                            errors={errors}
                            type="text"
                            id="workStartTime"
                            {...register('workStartTime')}
                        />
                    </DetailsInfoBlock.CustomChild>
                    <DetailsInfoBlock.CustomChild>
                        <MaskedInput
                            label={t('profile:timeEnd')}
                            placeholder="18:00"
                            required
                            mask="99:99"
                            alwaysShowMask={false}
                            control={control}
                            errors={errors}
                            type="text"
                            id="workEndTime"
                            {...register('workEndTime')}
                        />
                    </DetailsInfoBlock.CustomChild>
                </DetailsInfoBlock>
                <Box textAlign="right">
                    <RoundedButton variant="contained" color="success" type="submit" disabled={!isValid}>
                        {t('buttons:save')}
                    </RoundedButton>
                </Box>
            </form>
        </>
    );
};

export default ProfileUpdate;
