import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import { useNavigate } from 'react-router';

import i18n from '@/core/i18n';

const { t } = i18n;

const HeaderForm = ({ isDirty, isValid, title, onSubmit, setFormTableState }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const clr = theme.palette;
    return (
        <Box sx={{ display: 'flex', height: '100%', justifyContent: 'space-between', alignItems: 'end' }}>
            <Box sx={{ display: 'flex', alignItems: 'end', pb: 2, pl: 3 }}>
                <IconButton sx={{ p: 0, pb: 0.4 }} onClick={() => navigate('/arm/orders')}>
                    <KeyboardBackspaceIcon sx={{ color: clr.background.paperDark }} fontSize="small" />
                </IconButton>
                <Typography variant="h4" component="h3" sx={{ pl: 1, pb: 0.4 }}>
                    {t('common:orders')}
                </Typography>
                <Typography variant="h3" sx={{ pl: 1 }}>
                    / {title}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, pr: 4, pb: 1.5, height: '3.5rem' }}>
                <IconButton sx={{ width: '8.5rem', borderRadius: 2 }}>
                    <ClearIcon fontSize="small" />
                    <Typography variant="h5" sx={{ fontWeight: 700, pl: 0.5 }}>
                        {t('buttons:cancel')}
                    </Typography>
                </IconButton>

                <IconButton
                    disabled={!isValid || !isDirty}
                    onClick={onSubmit}
                    sx={{ width: '8.5rem', borderRadius: 2, backgroundColor: clr.success.main }}
                >
                    <CheckIcon fontSize="small" sx={{ color: 'white' }} />
                    <Typography variant="h5" sx={{ fontWeight: 700, pl: 0.5, color: 'white' }}>
                        {t('buttons:save')}
                    </Typography>
                </IconButton>
            </Box>
        </Box>
    );
};
export default HeaderForm;
