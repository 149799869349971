import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CheckIcon from '@mui/icons-material/Check';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';

import { FormRegisterList, FormRegisterLoading, FormRegisterValidate, FormSuccessValidate } from '@/components/auth/authRegister';

const list = [
    { ava: <CheckIcon />, desc: 'Ваше лицо полностью помещается в рамку на экране предпросмотра' },
    { ava: <SentimentSatisfiedAltIcon />, desc: 'Ваша голова не слишком сильно наклонена' },
    { ava: <EmojiObjectsOutlinedIcon />, desc: 'Помещение не слишком темное и сзади Вас нет источника света' },
    {
        ava: (
            <svg width="28" height="10" viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M26.7059 2.2538H25.188C24.6091 1.21931 23.5249 0.5 22.2439 0.5H18.7679C17.8293 0.5 16.8844 0.900649 16.2454 1.6568C15.8254 1.27136 15.2715 1.03648 14.665 1.03648H13.335C12.7285 1.03648 12.1746 1.27136 11.7546 1.6568C11.1155 0.900649 10.1707 0.5 9.23212 0.5H5.75611C4.47514 0.5 3.39091 1.21936 2.81205 2.2538H1.29412C0.855553 2.2538 0.5 2.6187 0.5 3.06879C0.5 3.51888 0.855553 3.88378 1.29412 3.88378H2.3639C2.35231 4.3236 2.38693 4.42113 2.68361 6.24779C3.13292 9.01468 6.58029 10.1011 9.16086 9.17774C10.9552 8.53574 12.5491 6.44513 12.5491 3.47292C12.5491 3.02821 12.9016 2.6664 13.335 2.6664H14.665C15.0984 2.6664 15.4509 3.02821 15.4509 3.47292C15.4509 6.44241 17.043 8.53509 18.8391 9.17774C21.4197 10.1011 24.8671 9.01468 25.3164 6.24779C25.6071 4.4577 25.6477 4.32724 25.6361 3.88378H26.7059C27.1444 3.88378 27.5 3.51888 27.5 3.06879C27.5 2.6187 27.1444 2.2538 26.7059 2.2538Z"
                    fill="#696F79"
                />
            </svg>
        ),
        desc: 'На Вас нет солнечных очков и маски'
    },
    { ava: <PhoneIphoneIcon />, desc: 'Телефон расположен на расстоянии 20-100 см от Вашего лица' },
    { ava: <CameraAltOutlinedIcon />, desc: 'Камера направлена только на Ваше лицо' }
];

const RegisterStepSecond = ({ handleNext }) => {
    const theme = useTheme();
    const [page, setPage] = useState(0);

    const mapShowBodySecond = new Map();
    mapShowBodySecond
        .set(0, () => <FormRegisterList setPage={setPage} list={list} />)
        .set(1, () => <FormRegisterLoading setPage={setPage} />)
        .set(2, () => <FormSuccessValidate />);
    return <>{mapShowBodySecond.get(page)()}</>;
};

export default RegisterStepSecond;
