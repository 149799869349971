import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isVisible: false
};

export const monitoringSlice = createSlice({
    name: 'monitoring',
    initialState,
    reducers: {
        setVisibility: (state, { payload }) => {
            state.isVisible = payload;
        }
    }
});

export const { setVisibility } = monitoringSlice.actions;

export default monitoringSlice.reducer;
