import React from 'react';
import './styles.scss';

const Index = () => (
    <div>
        <div className="faq" id="faq">
            <h2 className="section_title faq_title">Контакты</h2>
            <p className="keep_questions">
                В случае возникновения любых вопросов напишите нам на <a href="mailto:loggy@globerce.com">loggy@globerce.com</a>
            </p>
        </div>
    </div>
);
export default Index;
