import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, Outlet } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';

import Landing from './pages/landing';

import image404 from '@/assets/images/errors/404.svg';
import { Loadable } from '@/components';
import PermissionsRoute from '@/components/auth/permissions/permissionsRoute';
import { MainLayout } from '@/containers';
import {
    FILIAL_MANAGER,
    PARTNER_FILIAL_MANAGER,
    PARTNER_MANAGER,
    SERVICE_MANAGER,
    SORTER,
    SUPERVISOR
} from '@/core/utils/constants/constants';
import {
    COURIER_REGISTER_BIOMETRY,
    COURIER_REGISTER_PARAM,
    COURIER_REGISTER_UPDATE,
    COURIER_REGISTER_COMPLETE,
    ORDERS,
    REGISTER,
    LOGIN,
    USERS,
    DELIVERY_ZONES,
    MONITORING,
    PRODUCTS,
    SHIPMENT_POINTS,
    PARTNERS,
    STATISTICS,
    COURIERS,
    USER_POLICY,
    DATA_POLICY,
    ORDER_BIOMETRY_SUCCESS,
    ORDER_BIOMETRY,
    PROFILE,
    REVIEWS,
    RATINGS,
    API,
    ORDER_GROUPS,
    ORDER_CHAIN,
    PRIVACY_POLICY,
    CONTACTS
} from '@/core/utils/constants/routes';
import { Register } from '@/pages';
import ClientFeedback from '@/pages/clientFeedback';
import ClientMonitoring from '@/pages/clientMonitoring';
import Contacts from '@/pages/contacts';
import DataPolicy from '@/pages/dataPolicy';
import Login from '@/pages/login';
import PrivacyPolicy from '@/pages/privacyPolicy';
import Test from '@/pages/test';
import UserPolicy from '@/pages/userPolicy';
import ErrorPage from '@/views/errorPage';
import OrderChain from '@/views/orderChain';
import OrderGroups from '@/views/orderGroups';
import Profile from '@/views/profile';

const CourierRegistration = Loadable(lazy(() => import('@/pages/courierRegistration')));
const ThirdStep = Loadable(lazy(() => import('@/pages/courierRegistration/steps/thirdStep')));
const AfterBiometry = Loadable(lazy(() => import('@/pages/courierRegistration/steps/afterBiometry')));
const SecondStep = Loadable(lazy(() => import('@/pages/courierRegistration/steps/secondStep')));
const BiometryVerification = Loadable(lazy(() => import('@/pages/biometryVerification')));
const DeliveryZones = Loadable(lazy(() => import('@/views/settings/views/deliveryZones')));
const BiometrySuccess = Loadable(lazy(() => import('@/pages/biometryVerification/biometrySuccess')));
const Users = Loadable(lazy(() => import('@/views/settings/views/users')));

const Arm = Loadable(lazy(() => import('@/views/arm')));
const Statistics = Loadable(lazy(() => import('@/views/statistics')));
const Orders = Loadable(lazy(() => import('@/views/orders')));
const Monitoring = Loadable(lazy(() => import('@/views/monitoring')));
const Partners = Loadable(lazy(() => import('@/views/partners')));
const Couriers = Loadable(lazy(() => import('@/views/couriers')));
const ShipmentPoints = Loadable(lazy(() => import('@/views/shipmentPoints')));
const Products = Loadable(lazy(() => import('@/views/products')));
const Reviews = Loadable(lazy(() => import('@/views/reviews')));
const Ratings = Loadable(lazy(() => import('@/views/ratings')));
const Integration = Loadable(lazy(() => import('@/views/integration')));

export const ProtectedRoute = ({ isAllowed, redirectPath, children }) => {
    const location = useLocation();
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace state={{ prevPath: `${location.pathname}${location.search}` }} />;
    }
    return children || <Outlet />;
};

const MainRoutes = () => {
    const user = useSelector((state) => state.auth?.user);
    const [searchParams] = useSearchParams();
    let unauthorizedRedirectPath = '/?login=true';

    if (searchParams.get('photography_only')) {
        unauthorizedRedirectPath = '/login';
    }

    return (
        <Routes>
            <Route
                path="*"
                element={
                    <ErrorPage
                        showButton={true}
                        image={image404}
                        title="Страница не найдена (404)"
                        text={
                            'Страница, на которую Вы хотели перейти не найдена.\n' +
                            'Возможно, введён некорректный адрес или страница была удалена'
                        }
                    />
                }
            />

            <Route index element={<Landing />} />
            {/* <Route path="/" element={<MainLayout />}> */}
            {/*     <Route index element={<Navigate to="/login" />} /> */}
            {/* </Route> */}

            <Route path={COURIER_REGISTER_PARAM} element={<CourierRegistration />} />

            <Route path={COURIER_REGISTER_BIOMETRY} element={<SecondStep />} />
            <Route path={COURIER_REGISTER_UPDATE} element={<AfterBiometry />} />
            <Route path={COURIER_REGISTER_COMPLETE} element={<ThirdStep />} />
            <Route path={ORDER_BIOMETRY} element={<BiometryVerification />} />
            <Route path={ORDER_BIOMETRY_SUCCESS} element={<BiometrySuccess />} />
            <Route path={USER_POLICY} element={<UserPolicy />} />
            <Route path={DATA_POLICY} element={<DataPolicy />} />
            <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={CONTACTS} element={<Contacts />} />

            <Route path="/orders/:id/feedback" element={<ClientFeedback />} />
            <Route path="/orders/:id/monitoring" element={<ClientMonitoring />} />

            <Route
                element={
                    <ProtectedRoute
                        isAllowed={
                            !!user &&
                            [SERVICE_MANAGER, SUPERVISOR, PARTNER_MANAGER, FILIAL_MANAGER, PARTNER_FILIAL_MANAGER, SORTER].includes(
                                user?.data?.profile?.profile_type
                            )
                        }
                        redirectPath={unauthorizedRedirectPath}
                    >
                        <Arm />
                    </ProtectedRoute>
                }
            >
                <Route
                    path={`${STATISTICS}/*`}
                    element={
                        <PermissionsRoute roles={[SERVICE_MANAGER]} redirectPath={ORDERS}>
                            <Statistics />
                        </PermissionsRoute>
                    }
                />
                <Route path={`${PROFILE}/*`} element={<Profile />} />
                <Route
                    path={`${ORDERS}/*`}
                    element={
                        <div style={{ position: 'relative' }}>
                            <Orders />
                        </div>
                    }
                />
                <Route
                    path={`${COURIERS}/*`}
                    element={
                        <PermissionsRoute roles={[SERVICE_MANAGER, SUPERVISOR, FILIAL_MANAGER]} redirectPath={ORDERS}>
                            <Couriers />
                        </PermissionsRoute>
                    }
                />
                <Route
                    path={`${ORDER_GROUPS}/*`}
                    element={
                        <PermissionsRoute roles={[SERVICE_MANAGER, SORTER, PARTNER_MANAGER]} redirectPath={ORDERS}>
                            <OrderGroups />
                        </PermissionsRoute>
                    }
                />
                <Route
                    path={`${ORDER_CHAIN}/*`}
                    element={
                        <PermissionsRoute roles={[SERVICE_MANAGER, SORTER, PARTNER_MANAGER]} redirectPath={ORDERS}>
                            <OrderChain />
                        </PermissionsRoute>
                    }
                />
                <Route
                    path={`${REVIEWS}/*`}
                    element={
                        <PermissionsRoute roles={[SERVICE_MANAGER, FILIAL_MANAGER]} redirectPath={ORDERS}>
                            <Reviews />
                        </PermissionsRoute>
                    }
                />
                <Route
                    path={`${RATINGS}/*`}
                    element={
                        <PermissionsRoute roles={[SERVICE_MANAGER, FILIAL_MANAGER]} redirectPath={ORDERS}>
                            <Ratings />
                        </PermissionsRoute>
                    }
                />
                <Route
                    path={`${PARTNERS}/*`}
                    element={
                        <PermissionsRoute roles={[SERVICE_MANAGER, SUPERVISOR]} redirectPath={ORDERS}>
                            <Partners />
                        </PermissionsRoute>
                    }
                />
                <Route
                    path={`${SHIPMENT_POINTS}/*`}
                    element={
                        <PermissionsRoute roles={[SERVICE_MANAGER, SUPERVISOR, PARTNER_MANAGER, FILIAL_MANAGER]} redirectPath={ORDERS}>
                            <ShipmentPoints />
                        </PermissionsRoute>
                    }
                />
                <Route
                    path={`${PRODUCTS}/*`}
                    element={
                        <PermissionsRoute roles={[SERVICE_MANAGER, SUPERVISOR, PARTNER_MANAGER]} redirectPath={ORDERS}>
                            <Products />
                        </PermissionsRoute>
                    }
                />

                <Route
                    path={MONITORING}
                    element={
                        <PermissionsRoute roles={[SERVICE_MANAGER, SUPERVISOR, FILIAL_MANAGER]} redirectPath={ORDERS}>
                            <Monitoring />
                        </PermissionsRoute>
                    }
                />
                <Route
                    path={DELIVERY_ZONES}
                    element={
                        <PermissionsRoute roles={[SERVICE_MANAGER, FILIAL_MANAGER]} redirectPath={ORDERS}>
                            <DeliveryZones />
                        </PermissionsRoute>
                    }
                />
                <Route
                    path={`${USERS}/*`}
                    element={
                        <PermissionsRoute roles={[SERVICE_MANAGER, PARTNER_MANAGER]} redirectPath={ORDERS}>
                            <Users />
                        </PermissionsRoute>
                    }
                />
                <Route
                    path={`${API}/*`}
                    element={
                        <PermissionsRoute roles={[PARTNER_MANAGER]} redirectPath={ORDERS}>
                            <Integration />
                        </PermissionsRoute>
                    }
                />
            </Route>
            <Route
                path={LOGIN}
                element={
                    // <ProtectedRoute isAllowed={!user?.data} redirectPath={ORDERS}>
                    // Убрал пока что протектед роут, чтобы сработал редирект в компоненте Login, а не редирект протектед роута
                    <Login />
                    // </ProtectedRoute>
                }
            />
            <Route path={REGISTER} element={<Register />} />
            {/* <Route path="test" element={<Test />} /> */}
        </Routes>
    );
};

export default MainRoutes;
