import React from 'react';

import RowSkeleton from '@/components/listSkeleton/rowSkeleton';

const ListSkeleton = ({ rows, cols }) => (
    <>
        {Array(rows)
            .fill('')
            .map((_, i) => (
                <RowSkeleton amount={cols} key={i} />
            ))}
    </>
);

export default ListSkeleton;
