/* eslint-disable no-useless-catch */
import { Grid, Link, Typography, Button, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { RoundedButton } from '@/components/button/styledButtons';
import i18n from '@/core/i18n';
import { authServices } from '@/core/services/authServices';
import { InputCode } from '@/pages/register/registerComponent/registerStepsComponent';
import StyledPaper from '@/pages/register/registerComponent/StyledPapper';
import { fetchCurrentUser, login } from '@/toolkit/actionCreators/auth';
import { setOrganizationData, setUserData } from '@/toolkit/reducers/authSlice';

const { t } = i18n;

const FormRegisterSms = ({ setPage, number, setProfile }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [codeSms, setCodeSms] = useState();
    const [code, setCode] = useState([...Array(4)].map(() => ''));
    const [error, setError] = useState(null);
    const theme = useTheme();
    const [seconds, setSeconds] = useState(59);
    const dispatch = useDispatch();

    const handleRegisterOTPValidate = useMutation(['RegisterOTPValidate'], (res) => authServices.registerOTPValidate(res));
    const handleRegisterOTP = useMutation(['RegisterOTP'], (res) => authServices.registerOTP(res));

    const sendCode = () => {
        if (error) {
            setError(null);
        }
        if (codeSms?.length === 4) {
            const result = { phone_number: number.replaceAll(/[() -]/g, ''), otp: codeSms, agree: false };
            handleRegisterOTPValidate
                .mutateAsync(result)
                .then(async () => {
                    try {
                        await dispatch(login({ username: number.replaceAll(/[() -]/g, ''), password: codeSms })).unwrap();
                        const user = await dispatch(fetchCurrentUser()).unwrap();
                        setProfile(user?.profile);
                        dispatch(setUserData(user));
                        dispatch(setOrganizationData(location.state.organization));
                        navigate('', { state: { ...location.state, user } });
                        setPage(2);
                    } catch (e) {
                        setError(t('errors:sendDataError'));
                        throw new Error('Error');
                    }
                })
                .catch(() => {
                    setError(t('errors:sendDataError'));
                });
        }
    };

    useEffect(() => {
        const myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                clearInterval(myInterval);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });

    return (
        <>
            <StyledPaper>
                <Grid container>
                    <Grid item xs={12}>
                        <Stack direction="column" justifyContent="center" sx={{ marginBottom: '30px' }}>
                            <Typography sx={{ fontSize: 15, color: '#AFAFAF', fontWeight: 200 }}>
                                {t('components:formRegisterSms.codeSentToNumber')}
                            </Typography>
                            <Typography sx={{ fontSize: 16, color: 'black', fontWeight: 500, marginBottom: '20px' }}>{number}</Typography>
                            <Button
                                onClick={() => setPage((prev) => prev - 1)}
                                sx={{
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    p: 0,
                                    fontWeight: 900,
                                    color: '#49C172',
                                    textTransform: 'none'
                                }}
                            >
                                {t('components:formRegisterSms.changeNumber')}
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={10}>
                        <InputCode
                            error={error}
                            length={4}
                            loading={loading}
                            onComplete={() => {}}
                            setCodeSms={setCodeSms}
                            code={code}
                            setCode={setCode}
                        />
                        {error ? <Typography style={{ color: 'red' }}>{error}</Typography> : null}
                    </Grid>
                    <Grid item xs={10}>
                        {seconds > 0 ? (
                            <Typography variant="h4" color={theme.palette.grey[570]} sx={{ fontWeight: 100, mt: 1 }}>
                                {t('components:formRegisterSms.resendCodeAfter')}:{' '}
                                <span style={{ fontWeight: 900 }}>0:{seconds < 10 ? `0${seconds}` : seconds}</span>
                            </Typography>
                        ) : (
                            <Typography variant="h4" color={theme.palette.grey[570]} sx={{ fontWeight: 100, mt: 1 }}>
                                <Link
                                    onClick={() => {
                                        const result = { phone_number: number.replaceAll(/[() -]/g, ''), type: 'register' };
                                        handleRegisterOTP
                                            .mutateAsync(result)
                                            .then(() => {
                                                setSeconds(60);
                                            })
                                            .catch((err) => {
                                                console.dir(err);
                                            });
                                    }}
                                    underline="hover"
                                    sx={{ color: theme.palette.success.main, cursor: 'pointer' }}
                                >
                                    {t('components:formRegisterSms.resendRequest')}
                                </Link>
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </StyledPaper>
            <RoundedButton variant="contained" color="success" onClick={() => sendCode()} disabled={codeSms?.length !== 4}>
                {t('buttons:send')}
            </RoundedButton>
        </>
    );
};

export default FormRegisterSms;
