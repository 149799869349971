import { httpClient, httpClientV2 } from '@/api/httpClient/httpClient';

export const shipmentPointServices = {
    getList(params) {
        params = params || {};
        Object.keys(params).forEach((key) => {
            if (!params[key]) delete params[key];
        });
        return httpClient.get(`/shipment_point/list`, { params });
    },
    getListV2(params) {
        const partners = params.partners.join('&partner_id__in=');
        const cities = params.cities.join('&city_id__in=');
        let url = `/shipment_point/list?`;
        if (params.partners?.length) {
            url += `&partner_id__in=${partners}`;
        }
        if (params.cities?.length) {
            url += `&city_id__in=${cities}`;
        }
        return httpClientV2.get(url);
    },
    getPoint(pointId) {
        return httpClient.get(`/shipment_point/${pointId}`);
    },
    create(data) {
        return httpClient.post(`/shipment_point`, data);
    },
    update(id, data) {
        return httpClient.patch(`/shipment_point/${id}`, data);
    },
    delete(id) {
        return httpClient.delete(`/shipment_point/${id}`);
    }
};
