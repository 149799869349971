import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';

const PermissionsRoute = ({ roles, redirectPath, children }) => {
    const user = useSelector((state) => state.auth?.user);
    const profileType = user?.data?.profile?.profile_type;
    if (!roles.includes(profileType)) {
        return <Navigate to={redirectPath} replace />;
    }
    return children || <Outlet />;
};

export default PermissionsRoute;
