import { Grid, Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';

import digitalId from '../../../assets/images/auth/auth-digital-id.svg';

import { ButtonHeader } from '@/components';
import { FormRegisterHeaderText } from '@/components/auth/authRegister';

const useStyles = makeStyles((theme) => ({
    paperContainer: {
        backgroundImage: `url(${digitalId})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    }
}));

const FormRegisterList = ({ setPage, list }) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Grid container>
            <Grid item xs={8} sx={{ ml: '3.5%', mt: 2 }}>
                <FormRegisterHeaderText
                    text="Видеоидентификация личности
                для руководителя организации"
                />
            </Grid>

            <Grid item xs={10} sx={{ ml: '10.3%' }}>
                <Typography sx={{ mt: 4, ml: 2 }} variant="h4" component="div" color={theme.palette.grey[570]}>
                    Убедитесь, что:
                </Typography>
                <List>
                    {list.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>{item.ava}</ListItemIcon>
                            <ListItemText
                                sx={{
                                    color: theme.palette.grey[570]
                                }}
                                primary={item.desc}
                                // secondary={secondary ? 'Secondary text' : null}
                            />
                        </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid container>
                <Grid item xs={10} sx={{ mt: 6, ml: '11.5%' }}>
                    <ButtonHeader
                        theme={theme}
                        type="success"
                        onClick={() => setPage((prev) => prev + 1)}
                        sx={{ width: '47%', height: '50px' }}
                    >
                        Начать
                    </ButtonHeader>
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <Box className={classes.paperContainer} />
            </Grid>
        </Grid>
    );
};
export default FormRegisterList;
