import { Button, Box, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import { MainParent, MainBody, MainHeader } from '@/components/createRowTable';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '3rem',
        backgroundColor: theme.palette.background.paperLight,
        height: '100vh',
        width: '100vw'
    },
    paperImg: {
        backgroundImage: ({ image }) => `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    }
}));

const AddingPage = ({ textHeader, textFirst, textSecond, image, nextPageFunc, setNoPartner }) => {
    const theme = useTheme();
    const props = {
        image
    };
    const classes = useStyles(props);

    return (
        <MainParent>
            <MainHeader text={textHeader} nextPageFunc={nextPageFunc} />
            <MainBody>
                <Box className={classes.paperImg} sx={{ width: '14rem', height: '14rem', m: '0 auto' }} />
                <Typography variant="h3" textAlign="center" sx={{ pt: 1 }}>
                    {textFirst}
                </Typography>
                <Typography variant="h3" textAlign="center" sx={{ pt: 1, fontWeight: 100 }}>
                    {textSecond}
                </Typography>

                <Button
                    onClick={() => {
                        nextPageFunc(true);
                    }}
                    sx={{
                        p: '10px 50px',
                        mt: 6,
                        backgroundColor: theme.palette.success.main,
                        color: 'white',
                        ml: '50%',
                        transform: 'translateX(-50%)',
                        borderRadius: 3,
                        '&:hover': {
                            backgroundColor: theme.palette.success.main,
                            opacity: 0.7
                        }
                    }}
                >
                    + Добавить
                </Button>
            </MainBody>
        </MainParent>
    );
};

export default AddingPage;
