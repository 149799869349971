import i18n from '@/core/i18n';

const { t } = i18n;

export const orderChainTypes = [
    { id: 'simple', name: t('orderChain:types.simple') },
    { id: 'express', name: t('orderChain:types.express') }
];

export const stageTypes = [
    { id: 'road', name: t('orderChain:stageTypes.road') },
    { id: 'rail', name: t('orderChain:stageTypes.rail') },
    { id: 'sea', name: t('orderChain:stageTypes.sea') },
    { id: 'air', name: t('orderChain:stageTypes.air') }
];
