import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { RoundedAddButton } from '@/components/button/styledButtons';
import ArmListLayout from '@/components/layouts/armListLayout';
import ArmListLayoutHeader from '@/components/layouts/armListLayout/header';
import { ORDER_CHAIN } from '@/core/utils/constants/routes';
import OrderChainCreate from '@/views/orderChain/create';
import OrderChainDetails from '@/views/orderChain/details';
import OrderChainList from '@/views/orderChain/list';
import OrderChainUpdate from '@/views/orderChain/update';

const OrderChain = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <ArmListLayout>
            <Routes>
                <Route
                    element={
                        <>
                            <ArmListLayoutHeader
                                title="Многоэтапная доставка"
                                buttons={<RoundedAddButton onClick={() => navigate('./create')}>Создать</RoundedAddButton>}
                            />
                            <OrderChainList />
                        </>
                    }
                    path=""
                />
                <Route
                    element={
                        <>
                            <ArmListLayoutHeader
                                title="Создать многоэтапную доставку"
                                subTitle={t('orders:create.breadcrumbs')}
                                subtitleLink={ORDER_CHAIN}
                            />
                            <OrderChainCreate />
                        </>
                    }
                    path="/create"
                />
                <Route element={<OrderChainDetails />} path=":id/*" />
                <Route element={<OrderChainUpdate />} path=":id/update" />
            </Routes>
        </ArmListLayout>
    );
};

export default OrderChain;
