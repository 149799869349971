/* eslint-disable import/no-extraneous-dependencies */
import * as Sentry from '@sentry/react';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import React from 'react';
import { createRoot } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { store } from './toolkit/store';
import 'leaflet/dist/leaflet.css';
import './assets/images/scss/style.scss';
import './index.scss';
import '@/core/i18n';
import 'react-image-lightbox/style.css';

let firebaseConfig = {
    apiKey: 'AIzaSyDMGo4MZQpq0PMZw2Bk-qKrbHTlMUvNAGk',
    authDomain: 'loggy-saas-web.firebaseapp.com',
    projectId: 'loggy-saas-web',
    storageBucket: 'loggy-saas-web.appspot.com',
    messagingSenderId: '188075729166',
    appId: '1:188075729166:web:2fb581485096e72988375f',
    measurementId: 'G-MLPDPR5J4X'
};
if (process.env.REACT_APP_NODE_ENV === 'prod') {
    firebaseConfig = {
        apiKey: 'AIzaSyDMGo4MZQpq0PMZw2Bk-qKrbHTlMUvNAGk',
        authDomain: 'loggy-saas-web.firebaseapp.com',
        projectId: 'loggy-saas-web',
        storageBucket: 'loggy-saas-web.appspot.com',
        messagingSenderId: '188075729166',
        appId: '1:188075729166:web:73c3b4430df1c6e488375f',
        measurementId: 'G-FQVT45RQFW'
    };
}

if (!String.prototype.format) {
    // eslint-disable-next-line no-extend-native
    String.prototype.format = function () {
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        return this.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
    };
}

Sentry.init({
    dsn: 'https://a8c48476095f4a3b9b66f9a728c345e5@sentry-loggy.globerce.com/3',
    defaultIntegrations: false,
    integrations: [new Sentry.Integrations.HttpContext()]
});

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Element = () => (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

const container = document.getElementById('app');
const root = createRoot(container);

root.render(<Element />);
