import { httpClient } from '@/api/httpClient/httpClient';

export const tableServices = {
    getTableData() {
        return httpClient.get('/partner');
    },
    getTableRow(id) {
        return httpClient.get(`/partner/${id}`);
    },
    updateTableRow(id, data) {
        return httpClient.put(`/partner/${id}`, data);
    },
    createNewPartner(data) {
        return httpClient.post('/partner', data);
    },
    removePartners(data) {
        return httpClient.delete('/partner/bulk', data);
    }
};
