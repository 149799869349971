import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const usePagination = (localStorageKey, limit = 6) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(localStorage.getItem(localStorageKey) || limit);

    const [search, setSearch] = useSearchParams();

    useEffect(() => {
        if (pageLimit <= 20) {
            localStorage.setItem(localStorageKey, String(pageLimit));
        }
    }, [pageLimit]);

    const changePage = (page) => {
        setCurrentPage(page);
        setSearch({ ...Object.fromEntries(search), page });
    };

    const setPageLimitHandler = (newValue) => {
        setPageLimit((prevState) => {
            changePage(Math.ceil((prevState * (currentPage - 1) + 1) / newValue));
            return newValue;
        });
    };

    useEffect(() => {
        if (search.get('page')) {
            changePage(+search.get('page'));
        }
    }, []);

    return {
        currentPage,
        changePage,
        pageLimit,
        setPageLimitHandler
    };
};

export default usePagination;
