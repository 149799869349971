import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import FieldLabel from '@/components/formField/fieldLabel';

const StyledInputElement = styled('input')(
    ({ success, error }) => `
  width: 100%;
  font-family: "Inter","Roboto",arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
  color: var(--main-text-color);
  background: var(--input-bg);
  border: 1px solid ${error ? 'red' : success};
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: 0 !important;
  transition: 0.2s;

  &:hover {
    background: var(--input-bg-focused);
    borderColor: ${error ? 'red' : success};
  }

  &:focus {
    outline: none;
    background: var(--input-bg-focused);
    borderColor: ${error ? 'red' : success};
  }
`
);

const MaskedInput = React.forwardRef(
    (
        {
            id,
            label,
            placeholder,
            errors,
            control,
            type,
            InputProps,
            ContainerProps,
            mask,
            required,
            alwaysShowMask = true,
            maskChar,
            autocomplete,
            ...props
        },
        ref
    ) => (
        <Controller
            name={id}
            {...props}
            control={control}
            defaultValue=""
            innerRef={ref}
            rules={{ required: true }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
                <Box sx={{ position: 'relative' }} {...ContainerProps} className="form_field_input">
                    <FieldLabel htmlFor={id} required={required}>
                        {label}
                    </FieldLabel>
                    <div>
                        <InputMask
                            mask={mask}
                            sx={InputProps}
                            autoComplete={autocomplete}
                            type="tel"
                            name={id}
                            autoFocus={props.autoFocus}
                            placeholder={placeholder}
                            onChange={onChange}
                            maskChar={maskChar || ' '}
                            alwaysShowMask={alwaysShowMask}
                            value={value}
                            error={errors[id]}
                            success={value.length > 4 && !errors[id] ? '#49C172' : 'transparent'}
                        >
                            {(inputProps) => <StyledInputElement {...inputProps} />}
                        </InputMask>
                    </div>
                    <Typography
                        className="required_error_message"
                        style={{
                            marginTop: '4px',
                            color: '#FF6464',
                            fontSize: '12px',
                            opacity: errors[id] ? 1 : 0
                        }}
                    >
                        {errors[id]?.message}
                    </Typography>
                </Box>
            )}
        />
    )
);

export default MaskedInput;
