import { Box, ButtonBase, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { Controller } from 'react-hook-form';

import arrowDown from '@/assets/images/icons/arrow_down.svg';
import arrowUp from '@/assets/images/icons/arrow_up.svg';
import FieldLabel from '@/components/formField/fieldLabel';
import './style.scss';

export const StyledInputElement = styled('input')(
    ({ success, error, height, type }) => `
  width: 100%;
  font-family: "Inter","Roboto",arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
  color: var(--main-text-color);
  background: var(--input-bg);
  border: 1px solid ${error ? 'red' : success};
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: 0 !important;
  transition: 0.2s;
  height: ${height};
  padding-right:  ${type === 'number' ? '34px' : '15px'};
  
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type=number] {
    -moz-appearance: textfield;
  }

  &:hover {
    background: var(--input-bg-focused);
    border-color: ${error ? 'red' : success};
  }

  &:focus {
    outline: none;
    background: var(--input-bg-focused);
    border-color: ${error ? 'red' : success};
  }
`
);

const TextInput = React.forwardRef(
    (
        {
            id,
            label,
            placeholder,
            errors,
            control,
            type,
            success,
            InputProps,
            ContainerProps,
            readonly,
            onClick,
            height = null,
            defaultValue = '',
            required,
            fullWidth,
            onBlur,
            plusButtonCallback,
            minusButtonCallback,
            ...props
        },
        ref
    ) => (
        <Controller
            name={id}
            {...props}
            control={control}
            defaultValue={defaultValue}
            innerRef={ref}
            rules={{ required: true }}
            render={({ field: { onChange, value, onBlur }, formState: { errors } }) => (
                <Box sx={{ position: 'relative' }} className="form_field_input" {...ContainerProps}>
                    <FieldLabel htmlFor={id} required={required}>
                        {label}
                    </FieldLabel>
                    <div>
                        <div
                            className={`
                                 textInput_wrapper
                                  ${type === 'number' && !fullWidth ? 'textInput_wrapper_number' : ''}
 `}
                        >
                            <StyledInputElement
                                {...props}
                                sx={InputProps}
                                type={type}
                                name={id}
                                autoComplete={props.autocomplete}
                                disabled={props.disabled}
                                readOnly={readonly}
                                placeholder={placeholder}
                                onChange={onChange}
                                onClick={onClick}
                                ref={ref}
                                value={value}
                                min={props.min}
                                error={errors[id]}
                                success={(value?.length > 4 && !errors[id]) || success ? '#49C172' : 'transparent'}
                                height={height || ''}
                                onBlur={onBlur}
                            />
                            {type === 'number' && (
                                <div className="textInput_buttons">
                                    <ButtonBase className="textInput_buttons_item" onClick={plusButtonCallback}>
                                        <img src={arrowUp} alt="arrow up" />
                                    </ButtonBase>
                                    <ButtonBase className="textInput_buttons_item" onClick={minusButtonCallback}>
                                        <img src={arrowDown} alt="arrow down" />
                                    </ButtonBase>
                                </div>
                            )}
                        </div>
                    </div>
                    <Typography
                        className="required_error_message"
                        style={{
                            marginTop: '4px',
                            color: '#FF6464',
                            fontSize: '12px',
                            opacity: errors[id] ? 1 : 0
                        }}
                    >
                        {errors[id]?.message}
                    </Typography>
                </Box>
            )}
        />
    )
);

export default TextInput;
