import { Typography, styled } from '@mui/material';

const TypographyMain = styled(Typography, { shouldForwardProp: (prop) => prop !== 'type' })(({ theme, type }) => ({
    ...theme.typography.fontFamily,
    ...(type === 'h1' && {
        fontSize: theme.typography.h1.fontSize,
        fontWeight: theme.typography.h1.fontWeight,
        color: 'white',
        textAlign: 'center'
    }),
    ...(type === 'h2' && {
        fontSize: theme.typography.h2.fontSize,
        fontWeight: theme.typography.h2.fontWeight,
        color: 'white',
        textAlign: 'center'
    }),
    ...(type === 'h3' && {
        fontSize: theme.typography.h3.fontSize,
        fontWeight: theme.typography.h3.fontWeight,
        color: 'white',
        textAlign: 'center'
    }),
    ...(type === 'h4' && {
        fontSize: theme.typography.h4.fontSize,
        fontWeight: theme.typography.h4.fontWeight,
        color: 'white',
        textAlign: 'center'
    }),
    ...(type === 'h5' && {
        fontSize: theme.typography.h5.fontSize,
        fontWeight: theme.typography.h5.fontWeight,
        color: 'white',
        textAlign: 'center'
    }),
    ...(type === 'h6' && {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: 100,
        color: 'white',
        textAlign: 'center'
    })
}));

export default TypographyMain;
