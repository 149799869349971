import { httpClient } from '@/api/httpClient/httpClient';

export const partnerServices = {
    getPartners() {
        return httpClient.get('/partner');
    },
    getPartnerInfo(id) {
        return httpClient.get(`/partner/${id}`);
    },
    createPartner(data) {
        return httpClient.post(`/partner`, data);
    },
    updatePartner(id, data) {
        return httpClient.patch(`/partner/${id}`, data);
    },
    deletePartner(id) {
        return httpClient.delete(`/partner/${id}`);
    },
    createDeliveryService(data) {
        return httpClient.post('/partner/delivery-service-register', data);
    }
};
